import { t } from "@/mixins/i18nMixin";
import { ICellRendererParams } from "ag-grid-enterprise";
import { CHANGED_BY_ROLE, PRESENCE_SYNC } from "../../../constants";
import { localiseAvailableState } from "../Utils";
import BaseComponent from "./BaseComponent";
export default class AgentStateRenderer extends BaseComponent {
  private eGui: HTMLElement;
  private supervisorId = "";
  private agentState = "";

  constructor() {
    super();
    this.eGui = document.createElement("div");
  }

  renderAgentStateRenderer(params: any): void {
    const element = document.createElement("div");
    element.classList.add("agent-state-container");

    // Check whether the info icon needs to be present or not
    if (this.checkExtStateChange(params)) {
      const infoIconElement = this.createInfoIconElement(params);
      element.appendChild(infoIconElement);
      element.setAttribute("aria-label", this.getToolTipMessage(params));
    }

    // Agent state indicator that shows the state of the agent
    element.appendChild(this.createStateElement());
    this.eGui = element;
  }

  // This method will check if state is changed by supervisor or PRESENCE_SYNC.
  checkExtStateChange(params: any) {
    if (params.data.reason === PRESENCE_SYNC) {
      return true;
    }

    const { changedBy, changedById, changedByName } = params.data;
    return changedBy ? changedBy !== CHANGED_BY_ROLE.SELF : changedById && changedByName;
  }

  getToolTipMessage = (params: ICellRendererParams) => {
    if (params.data.reason === PRESENCE_SYNC) {
      return t("app:tpw.stateChangeByCollab");
    }
    const name: string =
      this.supervisorId === params.data.changedById ? t("app:tpw.stateChange.you") : params.data.changedByName;
    return `${t("app:tpw.SetBy", "", { defaultValue: name })}`;
  };

  createInfoIconElement = (params: ICellRendererParams) => {
    const iconElement = this.createIconElement(["agent-state-info", "icon-info_16"]);

    const toolTipMessage = this.getToolTipMessage(params);
    const infoToolTipWrapper = this.toolTipElement(toolTipMessage);
    infoToolTipWrapper.appendChild(iconElement);
    return infoToolTipWrapper;
  };

  createStateElement() {
    const element = document.createElement("span");
    element.classList.add("agent-state-name");
    element.textContent = localiseAvailableState(this.agentState);
    return element;
  }

  init(params: ICellRendererParams): void {
    this.updateParamsValues(params);
    this.renderAgentStateRenderer(params);
  }

  updateParamsValues = (params: ICellRendererParams) => {
    this.supervisorId = params?.colDef?.cellEditorParams.supervisorId;
    this.agentState = params?.data?.agentState;
  };

  refresh(params: ICellRendererParams) {
    this.refreshAgentStateRenderer(params);
    return true;
  }

  // This method will execute when state value is changed. Value will update using the element reference.
  refreshAgentStateRenderer = (params: ICellRendererParams) => {
    this.updateParamsValues(params);
    // Update the agent state by element reference
    const agentStateName = this.eGui.querySelector(".agent-state-name");
    if (agentStateName) {
      agentStateName.textContent = localiseAvailableState(this.agentState);
    }
    // Update the tooltip.
    const tooltipElement = this.eGui.querySelector(".tpw-custom-tooltip");
    if (this.checkExtStateChange(params)) {
      if (tooltipElement) {
        // Update the existing tooltip message.
        const toolTipMessage = this.getToolTipMessage(params);
        tooltipElement.setAttribute("data-message", toolTipMessage);
      } else {
        // Append the info element before the agent state name.
        const infoIconElement = this.createInfoIconElement(params);
        this.eGui.insertBefore(infoIconElement, agentStateName);
      }
    } else {
      // Remove the existing tooltip element
      tooltipElement?.remove();
    }
  };

  getGui(): HTMLElement {
    return this.eGui;
  }

  destroy() {
    this.eGui.remove();
  }
}
