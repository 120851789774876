import { Service } from "@agentx/agentx-services";
import { DateTime } from "luxon";
import { IvrTranscripts } from "../IvrTranscripts";

const MERIDIEM_TIME_FORMAT = "hh:mm a";
const DATE_TIME_FORMAT = "dd/LL/yy, hh:mm a";
const DATE_FORMAT = "dd/LL/yy";
const IVR_COLORS: Array<IvrTranscripts.TranscriptColor> = [
  "blue",
  "purple",
  "orange",
  "cyan",
  "violet",
  "green",
  "mint",
  "yellow"
];

export const getColorByIndex = (index: number): IvrTranscripts.TranscriptColor => {
  return IVR_COLORS[index % IVR_COLORS.length];
};

export const getTime = (timestamp: number) => {
  return DateTime.fromMillis(timestamp).toFormat(MERIDIEM_TIME_FORMAT);
};

export const getDateTime = (timestamp: number) => {
  return DateTime.fromMillis(timestamp).toFormat(DATE_TIME_FORMAT);
};

export const getDate = (timestamp: number) => {
  return DateTime.fromMillis(timestamp).toFormat(DATE_FORMAT);
};

const getBgStyle = (color: IvrTranscripts.TranscriptColor) => {
  return `background-color:var(--badge-${color}-bg-color)`;
};

const getHilightedText = (content: string, param: string, color: IvrTranscripts.TranscriptColor) => {
  return param
    ? content.replace(new RegExp(param, "gi"), `<span style=${getBgStyle(color)} >${param}</span>`)
    : content;
};

const getSubContent = (
  content: string,
  params: Service.MediaStorage.BotTranscriptParams,
  color: IvrTranscripts.TranscriptColor
) => {
  const paramSubValues: Array<string | number> = params ? Object.values(params) : [];
  paramSubValues.forEach((param: string | number) => {
    param = typeof param === "string" ? param : param.toString();
    content = getHilightedText(content, param, color);
  });
  return content;
};

export const getHighlightedTranscriptContent = (
  params: Service.MediaStorage.BotTranscript["parameters"],
  content: string,
  color: IvrTranscripts.TranscriptColor
) => {
  const paramValues: Array<string | number | Service.MediaStorage.BotTranscriptParams> = params
    ? Object.values(params)
    : [];
  let htmlContent = content;
  paramValues.forEach((param: string | number | Service.MediaStorage.BotTranscriptParams) => {
    if (typeof param === "string" || typeof param === "number") {
      param = typeof param === "string" ? param : param.toString();
      htmlContent = getHilightedText(htmlContent, param, color);
    } else {
      htmlContent = getSubContent(htmlContent, param, color);
    }
  });
  const contentHtml = document.createElement("div");
  contentHtml.innerHTML = htmlContent;
  return contentHtml;
};

export const hasParamData = (botTranscript: Service.MediaStorage.BotTranscript) => {
  return botTranscript.parameters
    ? Object.values(botTranscript.parameters).filter(value => {
        return value !== "";
      }).length !== 0
    : false;
};

export const getRoundedValue = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const showIntent = (data: Service.MediaStorage.IvrConversations) => {
  // This method will check the parameters of the botTranscript and return true if any of the parameters is not empty.
  // This method will check first level and one nested object level.
  return data.some((item: Service.MediaStorage.Transcript) => {
    const botParameters = item?.bot?.parameters;
    if (!botParameters) {
      return false;
    }
    // Check direct properties of botParameters
    return Object.values(botParameters).some(value => {
      if (typeof value === "object" && !Array.isArray(value)) {
        // If the value is an object, check its properties for emptiness
        return Object.values(value ?? {}).some(innerValue => innerValue !== "");
      }
      // Otherwise, check if the value is not an empty string
      return value !== "";
    });
  });
};

export const removeEmptyValues = (data: { [key: string]: any }): { [key: string]: any } => {
  const cleanedIntentData: { [key: string]: any } = {};
  // Iterate over entries using forEach and add non-empty key-value pairs to the new object
  Object.entries(data).forEach(([key, value]) => {
    if (value && value !== "") {
      if (typeof value === "object" && !Array.isArray(value)) {
        // Validate the same in nested objects
        if (Object.keys(value).length > 0) {
          const cleanedValues = removeEmptyValues(value);
          if (cleanedValues && Object.keys(cleanedValues).length > 0) {
            cleanedIntentData[key] = cleanedValues;
          }
        }
      } else {
        cleanedIntentData[key] = value;
      }
    }
  });
  return cleanedIntentData;
};
