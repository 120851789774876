
        import {css} from 'lit-element';   
        export default css`.aid-value {
  margin-left: 8px;
  width: 204px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*# sourceURL=/Users/tachen2/wxcc-desktop/packages/agentx-mfe-wc-based/src/components/TeamPerformanceWidget/components/AidValues.scss */
/*# sourceURL=AidValues.scss */
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9Vc2Vycy90YWNoZW4yL3d4Y2MtZGVza3RvcC9wYWNrYWdlcy9hZ2VudHgtbWZlLXdjLWJhc2VkL3NyYy9jb21wb25lbnRzL1RlYW1QZXJmb3JtYW5jZVdpZGdldC9jb21wb25lbnRzL0FpZFZhbHVlcy5zY3NzIiwiQWlkVmFsdWVzLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxnQkFBQTtFQUNBLFlBQUE7RUFDQSw0QkFBQTtFQUNBLG9CQUFBO0VBQ0EscUJBQUE7RUFDQSxnQkFBQTtFQUNBLHVCQUFBO0FDQ0YiLCJmaWxlIjoiQWlkVmFsdWVzLnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYWlkLXZhbHVlIHtcbiAgbWFyZ2luLWxlZnQ6IDhweDtcbiAgd2lkdGg6IDIwNHB4O1xuICAtd2Via2l0LWJveC1vcmllbnQ6IHZlcnRpY2FsO1xuICBkaXNwbGF5OiAtd2Via2l0LWJveDtcbiAgLXdlYmtpdC1saW5lLWNsYW1wOiAzO1xuICBvdmVyZmxvdzogaGlkZGVuO1xuICB0ZXh0LW92ZXJmbG93OiBlbGxpcHNpcztcbn1cbiIsIi5haWQtdmFsdWUge1xuICBtYXJnaW4tbGVmdDogOHB4O1xuICB3aWR0aDogMjA0cHg7XG4gIC13ZWJraXQtYm94LW9yaWVudDogdmVydGljYWw7XG4gIGRpc3BsYXk6IC13ZWJraXQtYm94O1xuICAtd2Via2l0LWxpbmUtY2xhbXA6IDM7XG4gIG92ZXJmbG93OiBoaWRkZW47XG4gIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xufSJdfQ== */`;
    