/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { SERVICE } from "@agentx/agentx-services";
import "@momentum-ui/web-components";
import { customElement, html, LitElement, property } from "lit-element";
import style from "./NotesHeaderWidget.scss";

export namespace NotesHeaderWidget {
  /**
   * @element agentx-wc-notes-header-widget
   */
  @customElement("agentx-wc-notes-header-widget")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: String, attribute: "agent-id" }) agentId = "";

    private handleMenuOpened() {
      SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.NOTES_WIDGET_OPENED);
    }

    static get styles() {
      return style;
    }

    render() {
      return html`
        <md-menu-overlay size="large" @menu-overlay-open=${this.handleMenuOpened}>
          <md-tooltip slot="menu-trigger" placement="bottom" message="${t("app:notes.notes")}">
            <md-button class="notes-btn" ariaLabel="${t("app:notes.notes")}" variant="secondary" size="40" circle
              ><md-icon name="note_20"></md-icon
            ></md-button>
          </md-tooltip>
          <div class="notes-widget-container">
            <agentx-wc-notes-widget .agentId=${this.agentId}></agentx-wc-notes-widget>
          </div>
        </md-menu-overlay>
      `;
    }
  }
}
