import { t } from "@/mixins/i18nMixin";
import { SERVICE, Service } from "@agentx/agentx-services";
import { customElement, html, LitElement, property } from "lit-element";
import { classMap } from "lit-html/directives/class-map";
import style from "./CollapseToggler.scss";

export namespace CollapseToggler {
  export type EToggle = {
    collapsed: boolean;
  };

  export enum Event {
    Toggle = "toggle"
  }

  export type EHover = {
    hovered: boolean;
  };

  export enum HoverEvent {
    Hover = "collapseHover"
  }

  export enum Direction {
    Left = "left",
    Right = "right"
  }

  /**
   * @element agentx-wc-collapse-toggler
   * @fires toggle
   */
  @customElement("agentx-wc-collapse-toggler")
  export class Element extends LitElement {
    @property({ type: Boolean }) collapsed = false;
    @property({ type: String, reflect: true }) direction = Direction.Left;
    @property({ type: Boolean }) visible = true;
    @property({ type: Boolean }) hovered = false;
    private stopListen: any;
    toggle() {
      SERVICE.telemetry.timeEvent(SERVICE.telemetry.MIX_EVENT.TASK_PANE_TOGGLE);
      this.dispatchEvent(
        new CustomEvent<CollapseToggler.EToggle>(CollapseToggler.Event.Toggle, {
          detail: {
            collapsed: !this.collapsed
          }
        })
      );
    }

    handleHoverEvent(e: any) {
      if (e.type === "mouseover") {
        this.hovered = true;
      } else {
        this.hovered = false;
      }
      this.dispatchEvent(
        new CustomEvent(CollapseToggler.HoverEvent.Hover, {
          bubbles: true,
          composed: true,
          detail: { hovered: this.hovered }
        })
      );
    }

    handleShortcutKeyEvent() {
      const { stopListen } = SERVICE.shortcut.event.listenKeyPress((event: Service.shortcut.EKeyInfo) => {
        const { data } = event;
        if (
          data &&
          data.key === SERVICE.shortcut.REGISTERED_KEYS.EXPAND_COLLAPSE_TASK_LIST_PANEL_KEY &&
          data.modifierKeys === SERVICE.shortcut.MODIFIERS.CTRL_SHIFT
        ) {
          this.toggle();
        }
      });
      this.stopListen = stopListen;
    }
    async connectedCallback() {
      super.connectedCallback();
      this.handleShortcutKeyEvent();
    }
    async disconnectedCallback() {
      super.connectedCallback();
      this.stopListen();
    }

    static get styles() {
      return style;
    }

    get iconName() {
      const ICON_NAME_LEFT = "icon-arrow-left-optical_8";
      const ICON_NAME_RIGHT = "icon-arrow-right-optical_8";

      if (this.collapsed) {
        if (this.direction === Direction.Left) {
          return ICON_NAME_RIGHT;
        } else {
          return ICON_NAME_LEFT;
        }
      } else {
        if (this.direction === Direction.Left) {
          return ICON_NAME_LEFT;
        } else {
          return ICON_NAME_RIGHT;
        }
      }
    }

    render() {
      return html`
        <button
          class="toggler ${classMap({ visible: this.visible || this.collapsed })}"
          @click="${() => this.toggle()}"
          @mouseover="${this.handleHoverEvent}"
          @mouseleave="${this.handleHoverEvent}"
          type="button"
          role="button"
          aria-label=${this.collapsed ? t("app:common.agentTaskPanelExpand") : t("app:common.agentTaskPanelCollapsed")}
          tab-index="0"
        >
          <md-icon name="${this.iconName}"></md-icon>
        </button>
      `;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "agentx-wc-collapse-toggler": CollapseToggler.Element;
  }
}
