
        import {css} from 'lit-element';   
        export default css`:host .screen-pop {
  width: 100%;
  height: 100vh;
  border: 0;
}

.screen-pop-tab {
  overflow: hidden;
}
/*# sourceURL=/Users/tachen2/wxcc-desktop/packages/agentx-mfe-wc-based/src/components/ScreenPop/ScreenPop.scss */
/*# sourceURL=ScreenPop.scss */
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9Vc2Vycy90YWNoZW4yL3d4Y2MtZGVza3RvcC9wYWNrYWdlcy9hZ2VudHgtbWZlLXdjLWJhc2VkL3NyYy9jb21wb25lbnRzL1NjcmVlblBvcC9TY3JlZW5Qb3Auc2NzcyIsIlNjcmVlblBvcC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0UsV0FBQTtFQUNBLGFBQUE7RUFDQSxTQUFBO0FDQUo7O0FESUE7RUFDRSxnQkFBQTtBQ0RGIiwiZmlsZSI6IlNjcmVlblBvcC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAuc2NyZWVuLXBvcCB7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgaGVpZ2h0OiAxMDB2aDtcbiAgICBib3JkZXI6IDA7XG4gIH1cbn1cblxuLnNjcmVlbi1wb3AtdGFiIHtcbiAgb3ZlcmZsb3c6IGhpZGRlbjtcbn1cbiIsIjpob3N0IC5zY3JlZW4tcG9wIHtcbiAgd2lkdGg6IDEwMCU7XG4gIGhlaWdodDogMTAwdmg7XG4gIGJvcmRlcjogMDtcbn1cblxuLnNjcmVlbi1wb3AtdGFiIHtcbiAgb3ZlcmZsb3c6IGhpZGRlbjtcbn0iXX0= */`;
    