/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin } from "@/mixins/i18nMixin";
import { logger } from "@/sdk";
import { Service, SERVICE } from "@agentx/agentx-services";
import "@momentum-ui/web-components";
import { Decorator } from "@uuip/unified-ui-platform-sdk";
import { customElement, html, internalProperty, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import "../Common/WidgetError/WidgetError";
import "../Common/WidgetLoader/WidgetLoader";
import "./components/IntentWrapper/IntentWrapper";
import { IntentWrapper } from "./components/IntentWrapper/IntentWrapper";
import "./components/TranscriptWrapper/TranscriptWrapper";
import style from "./IvrTranscripts.scss";
import { showIntent } from "./utils/utils";

export const DEFAULT_TRANSCRIPT_COLOR = "default";
const CONVERSATION_API_TIMEOUT = 5;

export namespace IvrTranscripts {
  /**
   * @element agentx-wc-ivr-transcripts
   * @fires save-ivr-conversation
   */
  @customElement("agentx-wc-ivr-transcripts")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Number }) selectedIntent = -1;
    @property({ type: Array }) ivrConversations: Service.MediaStorage.IvrConversations | undefined = undefined;
    @property({ type: String }) orgId = "";
    @property({ type: String }) interactionId = "";

    @internalProperty() private isLoading = false;
    @internalProperty() private trackingId = "";

    static get styles() {
      return style;
    }

    async connectedCallback() {
      super.connectedCallback();
      if (!this.ivrConversations) {
        this.fetchIvrConversations();
      }
    }

    @Decorator.Handle<Element>({
      "Service.mediaStorage.metaData": ({ ctx, details }) => {
        ctx.isLoading = false;
        ctx.trackingId = details.axiosError?.headers?.trackingid;
        logger.error(`[IvrTranscript] Failed to fetch Transcript Meta data  ${JSON.stringify(details)}`);
        SERVICE.telemetry.MIX_util.trackInitialCallEvents({
          eventName: SERVICE.telemetry.MIX_EVENT.IVR_TRANSCRIPT,
          interactionId: ctx.trackingId,
          contactDirection: "INBOUND",
          mediaType: "telephony",
          status: SERVICE.telemetry.MIX_EVENT.STATUS.FAILED,
          reasonCode: "TRANSCRIPT_FETCH_FAIL"
        });
      },
      "Service.mediaStorage.conversation": ({ ctx, details }) => {
        ctx.isLoading = false;
        ctx.trackingId = details.axiosError?.headers?.trackingid;
        logger.error(`[IvrTranscript] Failed to fetch Conversation Meta data  ${JSON.stringify(details)}`);
        SERVICE.telemetry.MIX_util.trackInitialCallEvents({
          eventName: SERVICE.telemetry.MIX_EVENT.IVR_TRANSCRIPT,
          interactionId: ctx.trackingId,
          contactDirection: "INBOUND",
          mediaType: "telephony",
          status: SERVICE.telemetry.MIX_EVENT.STATUS.FAILED,
          reasonCode: "CONVERSATION_FETCH_FAIL"
        });
      }
    })
    private async fetchIvrConversations() {
      this.isLoading = true;
      this.ivrConversations = await SERVICE.mediaStorage.fetchIVRTranscript(
        this.orgId,
        this.interactionId,
        CONVERSATION_API_TIMEOUT
      );
      SERVICE.telemetry.MIX_util.trackInitialCallEvents({
        eventName: SERVICE.telemetry.MIX_EVENT.IVR_TRANSCRIPT,
        interactionId: this.interactionId,
        contactDirection: "INBOUND",
        mediaType: "telephony",
        status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS,
        ivrConversation: JSON.stringify(this.ivrConversations)
      });
      this.dispatchEvent(
        new CustomEvent<ESaveTranscript["detail"]>("save-ivr-conversation", {
          detail: {
            interactionId: this.interactionId,
            ivrConversation: this.ivrConversations
          }
        })
      );
      this.isLoading = false;
    }

    private readonly setSelectedIntent = (index: number) => {
      this.selectedIntent = index;
    };

    private readonly reFetchTranscriptData = () => {
      logger.info("[IvrTranscript] re-fetching ivr transcript data");
      this.fetchIvrConversations();
    };

    render() {
      return this.ivrConversations
        ? html`
            <div class="ivr-transcripts">
              ${showIntent(this.ivrConversations)
                ? html`
                    <agentx-wc-ivr-intent-wrapper
                      .transcriptList=${this.ivrConversations}
                      @on-intent-select="${(e: CustomEvent<IntentWrapper.ESelectedIntent>) =>
                        this.setSelectedIntent(e.detail.index)}"
                    ></agentx-wc-ivr-intent-wrapper>
                  `
                : nothing}
              <agentx-wc-ivr-transcript-wrapper
                class="transcript-wrapper"
                .transcriptList=${this.ivrConversations}
                selectedIntent=${this.selectedIntent}
              ></agentx-wc-ivr-transcript-wrapper>
            </div>
          `
        : this.isLoading
        ? html`
            <agentx-wc-widget-loader></agentx-wc-widget-loader>
          `
        : html`
            <agentx-wc-widget-error @retry="${() => this.reFetchTranscriptData()}"></agentx-wc-widget-error>
          `;
    }
  }

  export type TranscriptColor =
    | "blue"
    | "purple"
    | "orange"
    | "cyan"
    | "violet"
    | "green"
    | "mint"
    | "yellow"
    | "default"
    | "red";

  export type ESaveTranscript = {
    detail: {
      interactionId: string;
      ivrConversation: Service.MediaStorage.IvrConversations;
    };
  };
}
