import { ICellRendererParams } from "ag-grid-enterprise";
import { QMW_ACTIONS_BTN_CLICK } from "../constants";

export default class ActionsRenderer {
  private eGui: HTMLElement = document.createElement("div");

  renderActionsBtn(params: ICellRendererParams): void {
    const playRecordingMsg = params.colDef?.cellEditorParams.i18n.playRecording;
    this.eGui.classList.add("qmw-actions-cell");

    this.eGui.innerHTML = `
    <md-tooltip message="${playRecordingMsg}" placement="bottom">
      <button class="md-button--none qmw-actions-btn" aria-label="${playRecordingMsg}">
        <i class="md-icon icon icon-play_16"></i>
      </button>
    </md-tooltip>
    `;

    const btn = this.eGui.querySelector(".qmw-actions-btn");

    const handleButtonClick = () => {
      btn?.dispatchEvent(
        new CustomEvent(QMW_ACTIONS_BTN_CLICK, {
          bubbles: true,
          composed: true,
          detail: {
            sessionId: params.data?.id,
            ani: params.data?.origin,
            agentName: params.data?.owner.name
          }
        })
      );
    };

    const handleKeyDown = (event: { [key: string]: any }) => {
      if (event.key === "Enter") {
        handleButtonClick();
      }
    };
    btn?.addEventListener("click", handleButtonClick);
    btn?.addEventListener("keydown", handleKeyDown);
  }

  init(params: ICellRendererParams): void {
    this.renderActionsBtn(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.renderActionsBtn(params);
    return true;
  }

  getGui(): HTMLElement {
    return this.eGui;
  }

  destroy(): void {
    this.eGui.remove();
  }
}
