import { customElement, html, LitElement } from "lit-element";
import commonStyles from "../../assets/styles/common.scss";
import styles from "./AppLoadingScreen.scss";
import appLogo from "./src/logo";

@customElement("agentx-wc-loading-screen")
export class AgentXLoadingScreen extends LitElement {
  static get styles() {
    return [styles, commonStyles];
  }

  render() {
    return html`
      <div class="loading-screen">
        <span class="loading-logo" aria-hidden="true">${appLogo(150)}</span>
        <md-loading class="md-loading" aria-hidden="true"></md-loading>
        <span class="sr-only" role="status">Loading</span>
      </div>
    `;
  }
}
