const GRID_CELL_CLASSNAME = "ag-cell";
const COLUMN_WIDTH_RESIZE = 2;
let rowDetails: any;

export function getAllFocusableElementsOf(actionCell: any): any {
  const focusableElements: any = [];
  if (actionCell) {
    const buttons = actionCell.querySelectorAll(".aid-button");
    if (buttons && buttons.length > 0) {
      buttons.forEach((element: any) => {
        if (!element.disabled) {
          focusableElements.push(element);
        }
      });
    }
  }
  return focusableElements;
}

function getEventPath(event: any) {
  const path = [];
  let currentTarget = event.target;

  while (currentTarget) {
    path.push(currentTarget);
    currentTarget = currentTarget.parentElement;
  }

  return path;
}

export function getDeepActiveElement() {
  let host = document.activeElement || document.body;
  while (host && host.shadowRoot && host.shadowRoot.activeElement) {
    host = host.shadowRoot.activeElement;
  }
  return host;
}

export function checkForwardTabbing(
  event: any,
  isTabForward: boolean,
  isTabBackward: boolean,
  focusableChildrenElements: any,
  actionCell: any,
  eGridCell: any
): boolean {
  let suppressEvent = false;
  const lastCellChildEl = focusableChildrenElements[focusableChildrenElements.length - 1] as HTMLElement;
  const firstCellChildEl = focusableChildrenElements[0];
  const activeElement = getDeepActiveElement();
  // Suppress keyboard event if tabbing forward within the cell and the current focused element is not the last child
  if (isTabForward) {
    const isLastChildFocused = lastCellChildEl && activeElement === lastCellChildEl;
    if (!isLastChildFocused) {
      suppressEvent = true;
    } else {
      rowDetails = null;
    }
  }
  // Suppress keyboard event if tabbing backwards within the cell, and the current focused element is not the first child
  else if (isTabBackward) {
    const cellHasFocusedChildren = eGridCell !== activeElement;
    // Manually set focus to the last child element if cell doesn't have focused children
    if (!cellHasFocusedChildren) {
      lastCellChildEl?.focus();
      // Cancel keyboard press, so that it doesn't focus on the last child and then pass through the keyboard press to
      // move to the 2nd last child element
      event.preventDefault();
    } else {
      const getFocusableElements = getAllFocusableElementsOf(actionCell);
      let indexToFocus = -1; // Default index
      getFocusableElements.some((element: any, index: number) => {
        if (activeElement === element) {
          indexToFocus = index - 1;
          return true;
        }
        return false;
      });
      if (indexToFocus >= 0) {
        getFocusableElements[indexToFocus]?.focus();
      }
      event.preventDefault();
    }

    const isFirstChildFocused = firstCellChildEl && activeElement === firstCellChildEl;
    if (!isFirstChildFocused) {
      suppressEvent = true;
    } else {
      rowDetails = null;
    }
  }
  return suppressEvent;
}

export const suppressHeaderKeyboardEvent = ({ event, column, columnApi, api }: any) => {
  const { key, altKey, shiftKey } = event;
  const { actualWidth } = column;
  const isTabForward = key === "Tab" && shiftKey === false;
  if (isTabForward && column.colId === "actions") {
    const actionCol = columnApi.getAllDisplayedColumns().find((o: { colId: string }) => o.colId === "actions");
    api.ensureColumnVisible(actionCol);
    // sets focus into the first grid cell
    setTimeout(() => {
      api.setFocusedCell(0, actionCol);
    }, 10);
  }
  if (altKey && (key === "ArrowLeft" || key === "ArrowRight")) {
    event.preventDefault();
    columnApi.setColumnWidth(column, actualWidth + (key === "ArrowLeft" ? -COLUMN_WIDTH_RESIZE : COLUMN_WIDTH_RESIZE));
    return true;
  }
  return false;
};

export const getRowDetails = (nodeRowIndex: number, api: any) => {
  const lastRowIndex = api.getDisplayedRowAtIndex(api.getLastDisplayedRow()).rowIndex;
  return {
    currentRowIndex: nodeRowIndex,
    previousRowIndex: api.getDisplayedRowAtIndex(nodeRowIndex - 1)?.rowIndex,
    nextRowIndex: api.getDisplayedRowAtIndex(nodeRowIndex + 1)?.rowIndex,
    lastRowIndex
  };
};

export function suppressKeyboardEvent(eventObj: any): boolean {
  const { event, node, api } = eventObj;
  const { key, shiftKey } = event;
  const path = getEventPath(event);
  const isTabForward = key === "Tab" && shiftKey === false;
  const isTabBackward = key === "Tab" && shiftKey === true;

  let suppressEvent = false;
  rowDetails = getRowDetails(node.rowIndex, api);

  // Handle cell children tabbing
  if (isTabForward || isTabBackward) {
    const eGridCell = path.find(el => {
      if (el.classList === undefined) {
        return false;
      }
      return el.classList.contains(GRID_CELL_CLASSNAME);
    });

    if (!eGridCell) {
      return suppressEvent;
    }
    const actionCell = eGridCell.querySelector(".ActionCell");
    const focusableChildrenElements = getAllFocusableElementsOf(actionCell);
    if (focusableChildrenElements.length > 0) {
      suppressEvent = checkForwardTabbing(
        event,
        isTabForward,
        isTabBackward,
        focusableChildrenElements,
        actionCell,
        eGridCell
      );
    }

    if (actionCell && focusableChildrenElements.length === 0) {
      rowDetails = null;
    }
  }

  return suppressEvent;
}

export const onTabToNextCell = (params: any) => {
  const { columnApi, previousCellPosition, nextCellPosition, backwards, api } = params;
  if (nextCellPosition) {
    const previousCell = previousCellPosition;
    const lastRowIndex = previousCell.rowIndex;
    let nextRowIndex = backwards ? lastRowIndex - 1 : lastRowIndex + 1;
    const renderedRowCount = api.getModel().getRowCount();

    if (nextRowIndex < 0) {
      nextRowIndex = -1;
    }
    if (nextRowIndex >= renderedRowCount) {
      nextRowIndex = renderedRowCount - 1;
    }

    const actionCol = columnApi.getAllDisplayedColumns().find((o: { colId: string }) => o.colId === "actions");

    return {
      rowIndex: rowDetails ? rowDetails.currentRowIndex : nextRowIndex,
      column: actionCol
    };
  } else {
    return null;
  }
};
