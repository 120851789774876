import { COLUMN_MENU_FOCUS, Key } from "@/constants";
import { IDoesFilterPassParams } from "ag-grid-enterprise";
import { checkIfFilterModelIsEmpty } from "../TeamPerformanceUtils";
import { TPW } from "../Utils";

export default class ColumnMenuRenderer {
  private params: TPW.ColumnParams = {};
  private valueGetter!: (params: IDoesFilterPassParams | any) => any;
  private eGui: HTMLElement;
  private columnMenuElement: any;
  private filterModel: Record<string, boolean> = {};

  constructor() {
    this.eGui = document.createElement("div");
  }

  listener = (event: any) => {
    if (event.key === Key.Escape) {
      this.params.api.hidePopupMenu();
      document.dispatchEvent(new CustomEvent(COLUMN_MENU_FOCUS, { detail: this.params?.column?.colId }));
    }
  };

  init(params: TPW.ColumnParams) {
    this.valueGetter = params.valueGetter;
    this.params = params;
    this.setupGui(params);
    this.eGui.addEventListener("keydown", this.listener);
  }

  destroy() {
    this.eGui.removeEventListener("keydown", this.listener);
  }

  setupGui(params: TPW.ColumnParams) {
    this.eGui.innerHTML = `
        <agentx-wc-tpw-column-menu></agentx-wc-tpw-column-menu>
    `;

    this.columnMenuElement = this.eGui.querySelector("agentx-wc-tpw-column-menu");
    this.columnMenuElement.columnParams = params;
    this.columnMenuElement.columnFilterModel = this.filterModel;
  }

  getGui() {
    return this.eGui;
  }

  doesFilterPass(params: IDoesFilterPassParams) {
    let passed = true;
    Object.keys(this.filterModel).forEach((filterWord: any) => {
      const rowValue = this.valueGetter(params);
      if (rowValue === filterWord) {
        passed = this.filterModel[filterWord];
      }
    });
    return passed;
  }

  isFilterActive() {
    return typeof this.filterModel !== "undefined" && this.filterModel !== null
      ? !checkIfFilterModelIsEmpty(this.filterModel)
      : false;
  }

  getModel() {
    return this.filterModel;
  }

  setModel(newFilterModel: Record<string, boolean>) {
    this.filterModel = newFilterModel;
    if (this.columnMenuElement) {
      this.columnMenuElement.columnFilterModel = this.filterModel;
    }
    this.params?.filterChangedCallback();
  }
}
