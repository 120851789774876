/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin } from "@/mixins/i18nMixin";
import { Service } from "@agentx/agentx-services";
import "@momentum-ui/web-components";
import { customElement, html, LitElement, property, query, TemplateResult } from "lit-element";
import { nothing } from "lit-html";
import { ifDefined } from "lit-html/directives/if-defined";
import { repeat } from "lit-html/directives/repeat";
import { DEFAULT_TRANSCRIPT_COLOR, IvrTranscripts } from "../../IvrTranscripts";
import { getColorByIndex, hasParamData } from "../../utils/utils";
import "../TranscriptItem/TranscriptItem";
import style from "./TranscriptWrapper.scss";

export namespace TranscriptWrapper {
  /**
   * @element agentx-wc-ivr-transcript-wrapper
   * @fires item-selected
   */
  @customElement("agentx-wc-ivr-transcript-wrapper")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Array }) transcriptList: Service.MediaStorage.IvrConversations | null = null;
    @property({ type: Number }) selectedIntent = -1;
    @query("#scroll-container") private readonly scrollContainer!: Element;

    static get styles() {
      return style;
    }

    updated() {
      if (this.selectedIntent >= 0) {
        const customerSelectedTemplateId = `#customer-transcript-${this.selectedIntent}`;
        const botSelectedTemplateId = `#bot-transcript-${this.selectedIntent}`;
        const selectedTemplate = this.shadowRoot?.querySelector(customerSelectedTemplateId)
          ? this.shadowRoot?.querySelector(customerSelectedTemplateId)
          : this.shadowRoot?.querySelector(botSelectedTemplateId);
        if (selectedTemplate instanceof HTMLElement && this.scrollContainer) {
          this.scrollContainer.scrollTop = selectedTemplate.offsetTop - this.scrollContainer.clientHeight / 4;
        }
      }
    }

    renderCustomerTranscript = (
      transcript: Service.MediaStorage.Transcript,
      color: IvrTranscripts.TranscriptColor,
      selected: boolean,
      index: number
    ): TemplateResult | typeof nothing => {
      const templateId = `customer-transcript-${index}`;
      return transcript.customer
        ? html`
            <agentx-wc-ivr-transcript-item
              type="customer"
              .sentiment=${transcript.customer.sentiment}
              content=${transcript.customer.query}
              .params=${transcript.bot && transcript.bot.parameters ? transcript.bot.parameters : {}}
              color=${color}
              .timestamp=${transcript.customer.timestamp}
              .selected=${selected}
              id=${templateId}
            ></agentx-wc-ivr-transcript-item>
          `
        : nothing;
    };

    renderBotTranscript = (
      transcript: Service.MediaStorage.Transcript,
      color: IvrTranscripts.TranscriptColor,
      selected: boolean,
      index: number
    ): TemplateResult | typeof nothing => {
      const templateId = `bot-transcript-${index}`;
      return transcript.bot
        ? html`
            <agentx-wc-ivr-transcript-item
              type="bot"
              content=${transcript.bot.reply}
              .timestamp=${transcript.bot.timestamp}
              .confidence=${transcript.bot.confidence}
              intentName=${ifDefined(transcript.bot.intentName)}
              color=${color}
              .selected=${selected}
              botName=${ifDefined(transcript.bot.botName)}
              id=${templateId}
            ></agentx-wc-ivr-transcript-item>
          `
        : nothing;
    };

    renderTranscript = (
      transcript: Service.MediaStorage.Transcript,
      color: IvrTranscripts.TranscriptColor,
      selected: boolean,
      index: number
    ): TemplateResult => {
      return html`
        ${this.renderCustomerTranscript(transcript, color, selected, index)}
        ${this.renderBotTranscript(transcript, color, selected, index)}
      `;
    };

    renderTranscriptList = (transcriptList: Service.MediaStorage.IvrConversations) => {
      let colorIndex = -1;
      return repeat(transcriptList, (transcript, index) => {
        let color: IvrTranscripts.TranscriptColor = DEFAULT_TRANSCRIPT_COLOR;
        const selected = this.selectedIntent === index || this.selectedIntent === -1;
        if (transcript.bot?.parameters && hasParamData(transcript.bot)) {
          colorIndex++;
          color = getColorByIndex(colorIndex);
        }
        return html`
          ${this.renderTranscript(transcript, color, selected, index)}
        `;
      });
    };

    render() {
      return (
        this.transcriptList &&
        html`
          <div class="scroll-content" id="scroll-container">
            <div class="transcript-wrapper">
              ${this.renderTranscriptList(this.transcriptList)}
            </div>
          </div>
        `
      );
    }
  }
}
