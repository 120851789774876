import { i18nMixin, t } from "@/mixins/i18nMixin";
import { debounce } from "@/utils/helpers";
import { ColDef, GridOptions } from "ag-grid-enterprise";
import { LitElement, customElement, html, internalProperty, property } from "lit-element";
import { fixedColumns, getIsCheckboxChecked } from "../../Utils";
import style from "../GridHeader.scss";

export namespace ShowHideCustomization {
  /**
   * @element agentx-wc-tpw-show-hide
   */
  @customElement("agentx-wc-tpw-show-hide")
  export class Element extends i18nMixin(LitElement) {
    @property({ reflect: true }) gridOptions: GridOptions = {};
    @property({ reflect: true }) visibleColumns: Record<string, boolean> = {};

    @internalProperty() showHideSearchTerm = "";

    static get styles() {
      return style;
    }

    private readonly isSelectedAll = () => {
      return Object.values(this.visibleColumns).every(Boolean);
    };

    private readonly handleSearch = debounce((event: CustomEvent) => {
      const searchTerm = event?.detail?.value?.toLowerCase();
      this.showHideSearchTerm = searchTerm;
      setTimeout(() => {
        this.handleFocus(event);
      }, 100);
      this.requestUpdate();
    }, 300);

    private handleFocus(event: CustomEvent) {
      const searchInput = this.shadowRoot?.querySelector<any>(".show-hide-header-wrapper md-input");
      const input = searchInput?.shadowRoot?.querySelector("input");
      if (searchInput && input) {
        input.focus({ focusVisible: true });
      }
      event.stopPropagation();
    }

    private getColumnsHeaderName() {
      return this.gridOptions?.columnDefs?.filter((column: ColDef) => {
        return column.headerName?.toLocaleLowerCase().includes(this.showHideSearchTerm);
      });
    }
    disconnectedCallback(): void {
      super.disconnectedCallback();
      this.gridOptions = {};
      this.visibleColumns = {};
    }

    private dispatchVisibleColumnsUpdateEvent() {
      this.dispatchEvent(
        new CustomEvent("tpw-update-visible-columns", {
          bubbles: true,
          composed: true,
          detail: this.visibleColumns
        })
      );
    }

    private handleShowHideColumn(event: CustomEvent, columnDetail?: ColDef) {
      if (event) {
        const isCheckboxChecked = getIsCheckboxChecked(event);
        if (columnDetail && columnDetail.field) {
          this.visibleColumns[columnDetail.field] = isCheckboxChecked;
          this.gridOptions.columnApi?.setColumnVisible(columnDetail.field, isCheckboxChecked);
        } else {
          Object.keys(this.visibleColumns).forEach(column => (this.visibleColumns[column] = isCheckboxChecked));
          this.gridOptions.columnApi?.setColumnsVisible(Object.keys(this.visibleColumns), isCheckboxChecked);
        }
        this.gridOptions.api?.sizeColumnsToFit();
        this.dispatchVisibleColumnsUpdateEvent();
        this.requestUpdate();
      }
    }

    renderMenuTriggerButton() {
      return html`
        <div class="menu-trigger-button" slot="menu-trigger">
          <span>${t("app:tpw.showHide")}</span>
          <div><md-icon slot="icon" name="icon-arrow-right_12" /></div>
        </div>
      `;
    }

    render() {
      return html`
        <md-menu-overlay max-height="344px" placement="right-start" size="small" @menu-overlay-open=${(
          event: CustomEvent
        ) => this.handleFocus(event)}>
            ${this.renderMenuTriggerButton()}
            <div class="overlay-container">
                <div class="show-hide-header-wrapper">
                    <md-input
                        clear
                        clearAriaLabel="${t("app:common:clear")}"
                        htmlId="pillSearchInput"
                        name="pillSearchInput"
                        shape="pill"
                        placeholder=${t("app:tpw.search")}
                        ariaLabel=${t("app:tpw.search")}
                        searchable
                        @input-change=${(event: CustomEvent) => this.handleSearch(event)}
                        />
                    </md-input>
                    <md-checkbox class="checkbox select-all-checkbox" ?checked=${this.isSelectedAll()} @checkbox-change=${(
        event: CustomEvent
      ) => this.handleShowHideColumn(event)}>${t("app:tpw.selectAll")}</md-checkbox>
                </div>
                <div class="checkbox-container-showhide-column">
                    <md-checkboxgroup group-label="column_titles">
                    ${this.getColumnsHeaderName()?.map((column: ColDef) => {
                      return html`
                        <md-checkbox
                          class="checkbox"
                          slot="checkbox"
                          ?disabled=${column.field && fixedColumns[column.field]}
                          ?checked=${column.field && (this.visibleColumns[column.field] || fixedColumns[column.field])}
                          @checkbox-change=${(event: CustomEvent) => this.handleShowHideColumn(event, column)}
                          >${column.headerName}</md-checkbox
                        >
                      `;
                    })}
                    </md-checkboxgroup>
                </div>
            </div>
        </md-menu-overlay>
      `;
    }
  }
}
