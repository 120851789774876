
        import {css} from 'lit-element';   
        export default css`md-menu-overlay::part(overlay-content) {
  overflow: visible;
}

.notes-widget-container {
  width: 100%;
  height: 500px;
}

.notes-btn::part(button) {
  background-color: rgba(0, 0, 0, 0);
}

.notes-btn::part(button):active {
  background-color: var(--button-white-pressed-bg-color);
}

.notes-btn::part(button):hover {
  background-color: var(--button-white-hover-bg-color);
}
/*# sourceURL=/Users/tachen2/wxcc-desktop/packages/agentx-mfe-wc-based/src/components/NotesHeaderWidget/NotesHeaderWidget.scss */
/*# sourceURL=NotesHeaderWidget.scss */
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9Vc2Vycy90YWNoZW4yL3d4Y2MtZGVza3RvcC9wYWNrYWdlcy9hZ2VudHgtbWZlLXdjLWJhc2VkL3NyYy9jb21wb25lbnRzL05vdGVzSGVhZGVyV2lkZ2V0L05vdGVzSGVhZGVyV2lkZ2V0LnNjc3MiLCJOb3Rlc0hlYWRlcldpZGdldC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsaUJBQUE7QUNDRjs7QURFQTtFQUNJLFdBQUE7RUFDQSxhQUFBO0FDQ0o7O0FERUE7RUFDRSxrQ0FBQTtBQ0NGOztBREVBO0VBQ0Usc0RBQUE7QUNDRjs7QURFQTtFQUNFLG9EQUFBO0FDQ0YiLCJmaWxlIjoiTm90ZXNIZWFkZXJXaWRnZXQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIm1kLW1lbnUtb3ZlcmxheTo6cGFydChvdmVybGF5LWNvbnRlbnQpIHtcbiAgb3ZlcmZsb3c6IHZpc2libGU7XG59XG5cbi5ub3Rlcy13aWRnZXQtY29udGFpbmVyIHtcbiAgICB3aWR0aDogMTAwJTsgXG4gICAgaGVpZ2h0OiA1MDBweDtcbn1cblxuLm5vdGVzLWJ0bjo6cGFydChidXR0b24pIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogcmdiYSgwLCAwLCAwLCAwKTtcbn1cblxuLm5vdGVzLWJ0bjo6cGFydChidXR0b24pOmFjdGl2ZSB7XG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWJ1dHRvbi13aGl0ZS1wcmVzc2VkLWJnLWNvbG9yKTtcbn1cblxuLm5vdGVzLWJ0bjo6cGFydChidXR0b24pOmhvdmVyIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tYnV0dG9uLXdoaXRlLWhvdmVyLWJnLWNvbG9yKTtcbn1cbiIsIm1kLW1lbnUtb3ZlcmxheTo6cGFydChvdmVybGF5LWNvbnRlbnQpIHtcbiAgb3ZlcmZsb3c6IHZpc2libGU7XG59XG5cbi5ub3Rlcy13aWRnZXQtY29udGFpbmVyIHtcbiAgd2lkdGg6IDEwMCU7XG4gIGhlaWdodDogNTAwcHg7XG59XG5cbi5ub3Rlcy1idG46OnBhcnQoYnV0dG9uKSB7XG4gIGJhY2tncm91bmQtY29sb3I6IHJnYmEoMCwgMCwgMCwgMCk7XG59XG5cbi5ub3Rlcy1idG46OnBhcnQoYnV0dG9uKTphY3RpdmUge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1idXR0b24td2hpdGUtcHJlc3NlZC1iZy1jb2xvcik7XG59XG5cbi5ub3Rlcy1idG46OnBhcnQoYnV0dG9uKTpob3ZlciB7XG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWJ1dHRvbi13aGl0ZS1ob3Zlci1iZy1jb2xvcik7XG59Il19 */`;
    