import { i18nMixin, t } from "@/mixins/i18nMixin";
import { LitElement, customElement, html, internalProperty, property } from "lit-element";
import { nothing } from "lit-html";
import { TPW } from "../Utils";
import style from "./ColumnMenu.scss";
import "./FilterColumnMenu";
import "./PinColumnMenu";

export namespace ColumnMenu {
  /**
   * @element agentx-wc-tpw-column-menu
   */
  @customElement("agentx-wc-tpw-column-menu")
  export class Element extends i18nMixin(LitElement) {
    private _columnParams = {};
    @property({ type: Object })
    get columnParams() {
      return this._columnParams;
    }
    set columnParams(value: TPW.ColumnParams) {
      const oldValue = this._columnParams;
      this._columnParams = value;
      this.requestUpdate("columnParams", oldValue);
    }

    private _columnFilterModel = {};
    @property({ type: Object })
    get columnFilterModel() {
      return this._columnFilterModel;
    }
    set columnFilterModel(value: Record<string, boolean>) {
      const oldValue = this._columnFilterModel;
      this._columnFilterModel = value;
      this.requestUpdate("columnFilterModel", oldValue);
    }

    @internalProperty() selectedTab = 0;

    static get styles() {
      return style;
    }

    connectedCallback(): void {
      super.connectedCallback();
      this.handleTabFocus(this.selectedTab);
    }

    firstUpdated(changedProperties: any) {
      super.firstUpdated(changedProperties);
      this.handleTabFocus(this.selectedTab);
    }

    handleTabFocus = async (index = 0): Promise<void> => {
      await this.updateComplete;
      setTimeout(() => {
        const element = this.shadowRoot?.querySelector("md-tabs")?.shadowRoot?.querySelectorAll("md-tab");
        if (element && element[index]) {
          element[index]?.focus();
        }
      }, 50);
    };

    handleTabSelection = (e: CustomEvent) => {
      // selected-changed
      this.selectedTab = e.detail.value;
      this.handleTabFocus(e.detail.value);
    };

    handleTabPanelFocus = (e: KeyboardEvent) => {
      if (e.code === "Tab") {
        e.stopPropagation();
        // Check active focus element
        setTimeout(() => {
          const activeElement = this.shadowRoot?.activeElement;
          if (!activeElement) {
            // If focus elements are not there in Table panel, Focus to the selected tab header
            this.handleTabFocus(this.selectedTab);
          }
        }, 50); //delay for modal to load
      }
    };

    handleTabKeyDown(e: CustomEvent) {
      if (e.detail?.key === "Tab") {
        setTimeout(() => {
          // Focus to the tab panel - selected element.
          const columnMenuEl = this.shadowRoot?.querySelector(
            "md-tabs md-tab-panel[selected] filter-column-menu"
          ) as HTMLElement;

          const searchInputWrapper = columnMenuEl?.shadowRoot?.querySelector<HTMLElement>("md-input");
          const searchInput = searchInputWrapper?.shadowRoot?.querySelector<HTMLElement>("input");
          searchInput?.focus();
        }, 50); //delay for modal to load
      }
    }

    render() {
      return html`
        <div class="column-menu-wrapper">
          <md-tabs
            .selected="${this.selectedTab}"
            justified
            @selected-changed="${(e: CustomEvent) => {
              this.handleTabSelection(e);
            }}"
            roving-prevent-focus
            @tab-keydown="${(e: CustomEvent) => {
              this.handleTabKeyDown(e);
            }}"
          >
            ${!this.columnParams.suppressFilterTab
              ? html`
                  <md-tab slot="tab" name="Filter" aria-label=${t("app:tpw.filter")}>
                    <md-icon name="icon-filter_16"></md-icon>
                  </md-tab>
                  <md-tab-panel
                    slot="panel"
                    .focusablePanel="${false}"
                    @keydown="${(e: KeyboardEvent) => {
                      this.handleTabPanelFocus(e);
                    }}"
                  >
                    <filter-column-menu
                      .columnParams=${this.columnParams}
                      .columnFilterModel=${this.columnFilterModel}
                    ></filter-column-menu>
                  </md-tab-panel>
                `
              : nothing}
            <md-tab slot="tab" name="Pin" aria-label=${t("app:tpw.pin")}>
              <md-icon name="icon-pin_16"></md-icon>
            </md-tab>
            <md-tab-panel
              slot="panel"
              @keydown="${(e: KeyboardEvent) => {
                this.handleTabPanelFocus(e);
              }}"
            >
              <pin-column-menu .columnParams=${this.columnParams}></pin-column-menu>
            </md-tab-panel>
          </md-tabs>
        </div>
      `;
    }
  }
}
