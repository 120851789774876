/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin, t } from "@/mixins/i18nMixin";
import "@momentum-ui/web-components";
import { customElement, html, LitElement, property } from "lit-element";
import { DEFAULT_TRANSCRIPT_COLOR, IvrTranscripts } from "../../IvrTranscripts";
import { getRoundedValue } from "../../utils/utils";
import style from "./Confidence.scss";

export namespace ConfidenceBadge {
  /**
   * @element agentx-wc-confidence-badge
   */
  @customElement("agentx-wc-confidence-badge")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Number }) confidence: number | undefined = 1;
    @property({ type: String }) intentName: string | undefined = undefined;
    @property({ type: String }) color: IvrTranscripts.TranscriptColor = DEFAULT_TRANSCRIPT_COLOR;

    static get styles() {
      return style;
    }

    render() {
      return html`
        <md-badge split color=${this.color} textColor="var(--md-primary-text-color)">
          <span slot="split-left" title=${this.intentName ? this.intentName : ""} class="badge-left-split"
            >${this.intentName}</span
          >
          <span slot="split-right"
            >${t("app:ivrTranscripts.confidence")}: ${this.confidence ? getRoundedValue(this.confidence) : 0}</span
          >
        </md-badge>
      `;
    }
  }
}
