/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { Service } from "@agentx/agentx-services";
import "@momentum-ui/web-components";
import { customElement, html, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import { ifDefined } from "lit-html/directives/if-defined";
import { DateTime } from "luxon";
import { DEFAULT_TRANSCRIPT_COLOR, IvrTranscripts } from "../../IvrTranscripts";
import { getDate, getDateTime, getHighlightedTranscriptContent, getRoundedValue, getTime } from "../../utils/utils";
import style from "./TranscriptItem.scss";

const MIN_SENTIMENT = 0;

export namespace TranscriptItem {
  /**
   * @element agentx-wc-ivr-transcript-item
   * @fires item-selected
   */
  @customElement("agentx-wc-ivr-transcript-item")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: String }) type: "customer" | "bot" = "customer";
    @property({ type: Number }) sentiment: number | undefined = undefined;
    @property({ type: Number }) confidence: number | undefined = undefined;
    @property({ type: String }) intentName: string | undefined = undefined;
    @property({ type: String }) content = "";
    @property({ type: Number }) timestamp = new Date().getTime();
    @property({ type: String }) color: IvrTranscripts.TranscriptColor = DEFAULT_TRANSCRIPT_COLOR;
    @property({ type: Object }) params: Service.MediaStorage.BotTranscript["parameters"] = {};
    @property({ type: Boolean }) selected = true;
    @property({ type: String }) botName = "";

    private readonly getLocalisedCustomerTitle = () => {
      return t(`app:ivrTranscripts.customer`);
    };
    private readonly getLocalisedBotTitle = () => {
      return this.botName ? this.botName : t(`app:ivrTranscripts.bot`);
    };

    static get styles() {
      return style;
    }

    private readonly isBot = () => {
      return this.type === "bot";
    };

    private readonly isCustomer = () => {
      return this.type === "customer";
    };

    getTitle = () => {
      return this.isCustomer() ? this.getLocalisedCustomerTitle() : this.getLocalisedBotTitle();
    };

    getFormattedTime = () => {
      return getDate(DateTime.local().toMillis()) !== getDate(this.timestamp)
        ? getDateTime(this.timestamp)
        : getTime(this.timestamp);
    };

    renderAvatar = () => {
      return this.isCustomer()
        ? html`
            <md-avatar
              aria-label=${this.getLocalisedCustomerTitle()}
              title=${this.getLocalisedCustomerTitle()}
              size=${28}
            ></md-avatar>
          `
        : html`
            <md-avatar
              aria-label=${this.getLocalisedBotTitle()}
              title=${this.getLocalisedBotTitle()}
              size=${28}
              icon-name="icon-bot_12"
            ></md-avatar>
          `;
    };

    renderSentimentBadgeText = () => {
      return `${t("app:ivrTranscripts.sentiment")}: ${getRoundedValue(this.sentiment ? this.sentiment : 0)}`;
    };

    renderBadge = () => {
      return this.isCustomer() && (this.sentiment || this.sentiment === 0)
        ? html`
            <md-badge
              color=${this.sentiment >= MIN_SENTIMENT ? "default" : "red"}
              textColor="var(--md-primary-text-color)"
              aria-label=${this.renderSentimentBadgeText()}
            >
              ${this.renderSentimentBadgeText()}
            </md-badge>
          `
        : this.isBot() && (this.confidence || this.confidence === 0) && this.intentName
        ? html`
            <agentx-wc-confidence-badge
              intentName=${ifDefined(this.intentName)}
              confidence=${ifDefined(this.confidence)}
              color=${this.color}
            ></agentx-wc-confidence-badge>
          `
        : nothing;
    };

    render() {
      return html`
        <div class="transcript-item ${this.selected ? nothing : "blur-content"} ">
          <div class="transcript-avatar">
            ${this.renderAvatar()}
          </div>
          <div class="transcript-content">
            <div class="transcript-head">
              <span class="transcript-header">
                ${this.getTitle()}
              </span>
              <span class="transcript-timer">
                ${this.getFormattedTime()}
              </span>
            </div>
            <div class="transcript-text">
              ${getHighlightedTranscriptContent(this.params, this.content, this.color)}
            </div>
            ${this.renderBadge()}
          </div>
        </div>
      `;
    }
  }
}
