/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { logger } from "@/sdk";
import { SERVICE, Service } from "@agentx/agentx-services";
import "@momentum-ui/web-components";
import "@uuip/unified-ui-platform-common-components";
import { LitElement, PropertyValues, customElement, html, property, query } from "lit-element";
import { repeat } from "lit-html/directives/repeat";
import { Key } from "./../../constants";
import { debounce, isTaskItemCardVisible, updateTimerAriaLabel } from "./../../utils/helpers";
import style from "./TaskList.scss";
import "./comp/TaskListItem";

export namespace TaskList {
  /**
   * @element agentx-wc-task-list
   * @fires task-selected
   * @fires task-removed
   * @fires handle-new-replies
   */
  @customElement("agentx-wc-task-list")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: String }) selectedId: string | null = null;
    @property({ type: String }) agentId = "";
    @property({ type: Array }) taskList: TaskList.Task[] = [];
    @property({ type: Object }) unreadMessages: Record<string, UnreadChatMessage> = {};
    @property({ type: Boolean }) hasOfferContact = false;
    @property({ type: Boolean }) isInteractionModelOpen = false;
    @property({ type: Boolean }) clickEvent = false;
    @property({ type: Object }) newUnreadTaskMessage: TaskList.UnreadMessage = {};
    @property({ type: Boolean }) isDarkMode = false;
    @property({ type: Boolean }) isProgressiveCampaignEnabled = false;
    @property({ type: Boolean }) isProgressiveCampaign1NEnabled = false;
    @property({ type: String }) monitoringOwnerName = "";
    @property({ type: String }) timerAriaLabel = "";

    private stopListen: any;
    private resizerObserver: any;

    @query(".ax-activity-list-wrapper") public taskListElement!: HTMLElement;
    private readonly newReplyList: Map<string, { value: string }> = new Map();
    handleNewReplies = "handle-new-replies";
    connectedCallback() {
      super.connectedCallback();
      this.handleShortcutKeyEvent();
      if (this.parentElement) {
        if (window.ResizeObserver) {
          this.resizerObserver = new window.ResizeObserver(this.onScrollHadler);
          this.resizerObserver.observe(this.parentElement);
        }
        this.parentElement.addEventListener("scroll", this.onScrollHadler);
      }
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      this.stopListen();
      if (this.parentElement && window.ResizeObserver && this.resizerObserver) {
        this.resizerObserver.unobserve(this.parentElement);
        this.parentElement.removeEventListener("scroll", this.onScrollHadler);
      }
    }

    handleShortcutKeyEvent() {
      const { stopListen } = SERVICE.shortcut.event.listenKeyPress((event: Service.shortcut.EKeyInfo) => {
        const { data } = event;
        if (
          data &&
          data.key === SERVICE.shortcut.REGISTERED_KEYS.SWITCH_TASK_KEY &&
          data.modifierKeys === SERVICE.shortcut.MODIFIERS.CTRL_SHIFT
        ) {
          this.switchTask();
        }
      });
      this.stopListen = stopListen;
    }

    update(changedProperties: PropertyValues) {
      super.update(changedProperties);
      if (changedProperties.has("clickEvent")) {
        this.highlightNewReplies();
      }
      if (
        changedProperties.has("newUnreadTaskMessage") &&
        this.newUnreadTaskMessage?.interactionId &&
        !this.newReplyList.has(this.newUnreadTaskMessage.interactionId)
      ) {
        this.updateNewReplyList(this.newUnreadTaskMessage.interactionId);
        const isShowUp: boolean = !!this.newReplyList.size && [...this.newReplyList][0][1].value === "top";
        this.dispatchEvent(
          new CustomEvent(this.handleNewReplies, {
            bubbles: true,
            cancelable: false,
            detail: { show: !!this.newReplyList.size, isShowUp }
          })
        );
      }
    }

    highlightNewReplies() {
      if (this.taskListElement) {
        const element: HTMLElement | null = this.taskListElement.querySelector(
          `#task-${[...this.newReplyList.keys()][0]}`
        );
        if (element) {
          element.focus();
        }
      }

      const index = [...this.newReplyList.keys()];
      this.newReplyList.delete(index[0]);

      const isShowUp: boolean = !!this.newReplyList.size && [...this.newReplyList][0][1].value === "top";
      this.dispatchEvent(
        new CustomEvent(this.handleNewReplies, {
          bubbles: true,
          cancelable: false,
          detail: { show: !!this.newReplyList.size, isShowUp }
        })
      );
    }

    checkVisibilityScroll = () => {
      Array.from(this.newReplyList.keys()).forEach(key => {
        this.updateNewReplyList(key);
      });
      const isShowUp: boolean = !!this.newReplyList.size && [...this.newReplyList][0][1].value === "top";
      this.dispatchEvent(
        new CustomEvent(this.handleNewReplies, {
          bubbles: true,
          cancelable: false,
          detail: { show: !!this.newReplyList.size, isShowUp }
        })
      );
    };

    updateNewReplyList(key: string) {
      const element: HTMLElement | null = this.taskListElement?.querySelector(`#task-${key}`);
      if (element && this.parentElement) {
        const { isVisible, arrowDir } = { ...isTaskItemCardVisible(element, this.parentElement) };
        if (!isVisible) {
          this.newReplyList.set(key, { value: arrowDir });
        } else {
          this.newReplyList.delete(key);
        }
      }
    }

    updated(changedProperties: PropertyValues) {
      super.updated(changedProperties);
    }

    switchTask() {
      if (this.taskList.length > 0) {
        const index = this.taskList.findIndex(task => task.interactionId === this.selectedId);
        if (index < 0 || index === this.taskList.length - 1) {
          this.selectedTask(this.taskList[0].interactionId);
        } else {
          this.selectedTask(this.taskList[index + 1].interactionId);
        }
      }
    }

    handleTaskItemKeyDown(event: KeyboardEvent, taskId: string) {
      if (event.code === Key.Enter || event.code === Key.Space) {
        this.selectedTask(taskId);
      }
    }

    selectedTask(taskId: string) {
      if (this.isInteractionModelOpen) {
        return;
      }
      this.evt<ETaskSelected>("task-selected", { taskId });
    }

    // ROST: create decorator fot custom event
    private evt<T extends { detail: any }>(name: string, detail: T["detail"]) {
      this.dispatchEvent(new CustomEvent(name, { bubbles: true, composed: true, detail }));
      logger.info("[TaskList] Dispatching event", name, detail);
    }

    private readonly onScrollHadler = debounce(this.checkVisibilityScroll, 300);

    get selectedListItem() {
      return this.taskList.findIndex(task => task.interactionId === this.selectedId);
    }

    static get styles() {
      return style;
    }

    renderEmptyImage() {
      return html`
        <div class="empty-state">
          <uuip-wc-empty-state
            illustration-src="    /images/illustrations/horns-success-192.svg  "
            illustration-alt="empty-state"
          >
            <p class="empty-text" role="tooltip" aria-label=${t("app:taskBar.emptyStateMessage")} tabindex="0">
              ${t("app:taskBar.emptyStateMessageNew")}
            </p>
          </uuip-wc-empty-state>
        </div>
      `;
    }

    render() {
      return this.taskList.length > 0
        ? html`
            <md-list
              label="${t("app:taskBar.taskList", "Agent Task List with {{count}} tasks", {
                count: this.taskList.length
              })}"
              class="ax-activity-list-wrapper"
              .activated=${this.selectedListItem}
              role="listbox"
            >
              ${repeat(this.taskList, task => {
                return html`
                  <md-list-item
                    slot="list-item"
                    id="task-${task.interactionId}"
                    @click=${() => this.selectedTask(task.interactionId)}
                    @keydown=${(event: KeyboardEvent) => this.handleTaskItemKeyDown(event, task.interactionId)}
                    class="ax-activity-list-item"
                    role="option"
                    @focus=${() => {
                      this.timerAriaLabel = updateTimerAriaLabel(task.timeStamp);
                    }}
                  >
                    <agentx-wc-task-list-item
                      .task=${task}
                      .isProgressiveCampaignEnabled=${this.isProgressiveCampaignEnabled}
                      .isProgressiveCampaign1NEnabled=${this.isProgressiveCampaign1NEnabled}
                      ?selected=${task.interactionId === this.selectedId}
                      .unreadMessage="${this.unreadMessages[task.interactionId]
                        ? this.unreadMessages[task.interactionId]
                        : {}}"
                      .isInteractionModelOpen=${this.isInteractionModelOpen}
                      .isDarkMode=${this.isDarkMode}
                      .monitoringOwnerName=${this.monitoringOwnerName}
                      .timerAriaLabel=${this.timerAriaLabel}
                    ></agentx-wc-task-list-item>
                  </md-list-item>
                `;
              })}
            </md-list>
          `
        : !this.hasOfferContact
        ? this.renderEmptyImage()
        : null;
    }
  }

  export interface UnreadChatMessage {
    lastUnreadMessage?: string;
    unreadMessageCount?: number;
  }

  export interface UnreadMessage {
    interactionId?: string;
    unreadMessageCount?: number;
  }

  export type ETaskSelected = {
    detail: {
      taskId: string;
    };
  };

  export interface Task extends BaseTask {
    timeStamp: number; // unix timestamp
    consultTimeStamp: number; // unix timestamp
  }

  export type BaseTask = {
    customerName: string;
    mediaType: Service.Aqm.Contact.AgentContact["data"]["interaction"]["mediaType"];
    mediaChannel: Service.Aqm.Contact.AgentContact["data"]["interaction"]["mediaChannel"];
    ani: string;
    displayAni: string;
    interactionId: string;
    consultHold: boolean;
    isConsulted: boolean;
    state: string;
    dnis: string;
    virtualTeamName: string;
    appUser: string;
    isConferencing: boolean;
    contactDirection: string;
    outboundType?: string;
    isHold: boolean;
    ownerName?: string;
    monitoringStartTime?: number;
    type?: string;
  };
}
