import {
  REVIEW_BUTTON_DARK,
  REVIEW_BUTTON_DARK_DISABLED,
  REVIEW_BUTTON_LIGHT,
  REVIEW_BUTTON_LIGHT_DISABLED,
  TPW_EMPTY_COLUMN_VALUE
} from "@/constants";
import { ICellRendererParams } from "ag-grid-enterprise";
import { getPersistedError } from "../utils/WebexChat.utils";
import BaseComponent from "./BaseComponent";
import { t } from "@/mixins/i18nMixin";

// Component names
export const SEND_MESSAGE = "SEND_MESSAGE";
export const AGENT_STATE_CHANGE = "AGENT_STATE_CHANGE";
export const REVIEW_AND_MONITOR = "REVIEW_AND_MONITOR";

type ActionComponents = typeof SEND_MESSAGE | typeof AGENT_STATE_CHANGE;

const ACTION_ELEMENT_DEFAULT_WIDTH = 40; // 40px
const DEFINED_ACTION_CELL_PADDING_SIZE = 10; // 10px (including margin and padding)
const SHOW_TOOLTIP = "data-show-tooltip";
const ARIA_LABEL = "aria-label";
const TWP_CUSTOM_TOOLTIP = "tpw-custom-tooltip";
const DATA_MESSAGE = "data-message";

export default class ActionsRenderer extends BaseComponent {
  private eGui: HTMLElement;
  private actionCellConfigs: any = {};
  private paramsData: any = {};
  private localization: any = {};
  private contextMenuList: any = [];

  constructor() {
    super();
    this.eGui = document.createElement("div");
    // section-one - Is Used to show the monitor svg image and monitoring status text
    // section-two - Is used to show the chat, kebab, additional icons.
    this.eGui.innerHTML = `<div class="section-one"></div><div class="section-one"></div>`;
    this.eGui.classList.add("ActionCell");
  }

  selectedModeClassName = (): string => {
    return this.actionCellConfigs.isDarkMode ? "dark-mode" : "light-mode";
  };

  get isWrapUp(): boolean | undefined {
    return this.actionCellConfigs.isMPCorPersistEnabled && this.actionCellConfigs.isWrapUp;
  }

  get isWrapUpAssistance(): boolean | undefined {
    return this.actionCellConfigs.isMPCorPersistEnabled && this.actionCellConfigs.isWrapUpAssistance;
  }

  getActionCellValues = () => {
    return {
      ...this.paramsData,
      agentId: this.actionCellConfigs.agentId,
      onActiveCall: this.actionCellConfigs.onActiveCall
    };
  };

  getIsMonitoring() {
    return (
      this.actionCellConfigs.monitoredBy &&
      this.actionCellConfigs.monitoredBy !== "" &&
      this.actionCellConfigs.monitoredBy !== TPW_EMPTY_COLUMN_VALUE
    );
  }

  getMonitorIconPath(isDisabled: boolean): string {
    if (this.actionCellConfigs.isDarkMode) {
      return isDisabled ? REVIEW_BUTTON_DARK_DISABLED : REVIEW_BUTTON_DARK;
    } else {
      return isDisabled ? REVIEW_BUTTON_LIGHT_DISABLED : REVIEW_BUTTON_LIGHT;
    }
  }

  getReviewMonitorIcon(disabled = false) {
    const imageElement = document.createElement("img");
    imageElement.classList.add("aid-icon", this.selectedModeClassName());

    if (this.actionCellConfigs.isMCMEnabled) {
      imageElement.alt = this.localization.reviewAndMonitor;
    } else {
      imageElement.alt = this.localization.review;
    }

    imageElement.src = this.getMonitorIconPath(disabled);
    return imageElement;
  }

  isReviewMonitorDisabled() {
    /*monitoredBy here is ensuring that the monitoring is successfully started,
     isSupervisorMonitoring can only ensure If same supervisor has started monitoring or not(not the successful landing
    isSuprvisorMonitoring is required to disable start monitoring button in AID for the phase of clicking start monitoring to successful monitoring  on supervisor desktop*/
    return (
      (this.actionCellConfigs.isSupervisorMonitoring && this.actionCellConfigs.monitoredBy) ||
      !this.actionCellConfigs.isReviewAndMonitorEnabled ||
      this.actionCellConfigs.onActiveCall ||
      this.actionCellConfigs.isConsultCall ||
      this.actionCellConfigs.voiceChannelCount === 0
    );
  }

  getTooltipMessage = (): string => {
    if (this.actionCellConfigs.voiceChannelCount === 0) {
      return this.localization.notHaveChannels;
    } else if (this.actionCellConfigs.isConsultCall) {
      return this.localization.cannotMonitorConsult;
    } else if (this.isWrapUp) {
      return this.localization.cannotMonitorWrapUp;
    } else if (this.isWrapUpAssistance) {
      return this.localization.cannotMonitorWrapUpAssistance;
    } else if (this.actionCellConfigs.isMCMEnabled) {
      return this.localization.reviewAndMonitor;
    } else {
      return this.localization.review;
    }
  };

  handleOpenReviewAndMonitorModal = () => {
    if (
      !!this.actionCellConfigs.isReviewAndMonitorEnabled &&
      !this.actionCellConfigs.onActiveCall &&
      this.actionCellConfigs?.handleAIDModal
    ) {
      this.actionCellConfigs.handleAIDModal(this.actionCellConfigs.agentId);
    }
  };

  renderReviewAndMonitorIcon(): HTMLButtonElement {
    const monitorDisabled = this.isReviewMonitorDisabled();
    const toolTipMessage = this.getTooltipMessage();
    const customClassNames = ["review-monitor-icon", `review-monitor-${this.actionCellConfigs.agentId}`];

    const button = this.createButtonElement(customClassNames, monitorDisabled);
    if (this.actionCellConfigs.voiceChannelCount === 0 || this.actionCellConfigs.isConsultCall) {
      button.setAttribute(SHOW_TOOLTIP, "true");
    }
    // Append the Review Monitor SVG Icon.
    const childElement = this.getReviewMonitorIcon(monitorDisabled);
    button.appendChild(childElement);
    button.onclick = () => {
      this.handleOpenReviewAndMonitorModal();
    };

    button.setAttribute(ARIA_LABEL, `${toolTipMessage}`);
    button.classList.add(TWP_CUSTOM_TOOLTIP);
    button.setAttribute(DATA_MESSAGE, toolTipMessage);
    return button;
  }

  stateChangeError() {
    return this.actionCellConfigs.agentStateChangeError;
  }

  getStateChangeTooltip() {
    if (this.actionCellConfigs.agentStateChanging) {
      return this.localization.stateChange.progress;
    } else if (this.actionCellConfigs.agentStateChangeError) {
      return this.localization.stateChange.failed;
    } else {
      return this.localization.stateChange.default;
    }
  }

  isAgentStateChangeDisabled() {
    return this.actionCellConfigs.agentId === this.actionCellConfigs.supervisorId;
  }

  renderStateChangeIcon(): HTMLElement {
    const customClassNames = ["state-change-action", `state-change-${this.actionCellConfigs.agentId}`];
    const stateChangeDisabled = this.isAgentStateChangeDisabled();
    const button = this.createButtonElement(customClassNames, stateChangeDisabled);

    if (this.actionCellConfigs.agentStateChanging) {
      const iconElement = this.createIconElement(["md-spinner", "md-spinner--14"]);
      button.appendChild(iconElement);
    } else {
      const iconElement = this.createIconElement(["icon-recurring_14"]);
      button.appendChild(iconElement);

      if (this.stateChangeError()) {
        const errorElement = document.createElement("div");
        errorElement.classList.add("error-icon");

        const errorIcon = this.createIconElement(["icon-priority-active-two_12"]);
        errorElement.appendChild(errorIcon);

        button.appendChild(errorElement);
      }
    }

    button.onclick = e => {
      e.stopPropagation();
      if (!stateChangeDisabled) {
        const actionCellValues = this.getActionCellValues();
        this.actionCellConfigs.handleStateChangeSelector(actionCellValues, button);
      }
    };

    const toolTipMessage = this.getStateChangeTooltip();
    button.setAttribute(ARIA_LABEL, `${toolTipMessage}`);
    button.classList.add(TWP_CUSTOM_TOOLTIP);
    button.setAttribute(DATA_MESSAGE, toolTipMessage);
    return button;
  }

  getMonitorLabel() {
    const paraElement = document.createElement("p");
    paraElement.classList.add("monitoring-label");
    paraElement.setAttribute(ARIA_LABEL, "");
    paraElement.setAttribute("role", "status");
    let toolTipMessage = "";
    if (this.actionCellConfigs.isSupervisorMonitoring) {
      if (this.actionCellConfigs.isBargeInEnabled && this.actionCellConfigs.isBarged) {
        paraElement.innerText = this.localization.bargedIn;
        paraElement.setAttribute(ARIA_LABEL, `${this.localization.bargedIn} ${this.localization.alreadyBargedIn}`);
        toolTipMessage = this.localization.alreadyBargedIn;
      } else {
        paraElement.innerText = this.localization.monitoring;
        paraElement.setAttribute(ARIA_LABEL, `${this.localization.monitoring} ${this.localization.ownMonitoring}`);
        toolTipMessage = this.localization.ownMonitoring;
      }
    } else {
      if (this.actionCellConfigs.isBargeInEnabled && this.actionCellConfigs.isBarged) {
        paraElement.innerText = this.localization.bargedIn;
        paraElement.setAttribute(
          ARIA_LABEL,
          `${this.localization.bargedIn} ${this.actionCellConfigs.monitoredBy} ${this.localization.supervisorBargedIn}`
        );
        toolTipMessage = `${this.actionCellConfigs.monitoredBy} ${this.localization.supervisorBargedIn}`;
      } else {
        paraElement.innerText = this.localization.beingMonitored;
        paraElement.setAttribute(
          ARIA_LABEL,
          `${this.localization.beingMonitored} ${this.actionCellConfigs.monitoredBy} ${this.localization.supervisorMonitoring}`
        );
        toolTipMessage = `${this.actionCellConfigs.monitoredBy} ${this.localization.supervisorMonitoring}`;
      }
    }
    const toolTipWrapper = this.toolTipElement(toolTipMessage);
    toolTipWrapper.appendChild(paraElement);
    return toolTipWrapper;
  }

  callMonitoringLabel() {
    const divElement = this.getMonitorLabel();
    divElement.classList.add("monitoring-container");
    divElement.style.width = `${this.actionCellConfigs.actionCellWidth - DEFINED_ACTION_CELL_PADDING_SIZE}px`;
    return divElement;
  }

  renderKebabIcon() {
    const customClassNames = ["kebab-action", `more-action-${this.actionCellConfigs.agentId}`];
    const button = this.createButtonElement(customClassNames);
    button.title = this.localization?.moreAction;
    button.ariaLabel = this.localization?.moreAction;

    const iconElement = this.createIconElement(["icon-more-android_16", "kebab-menu-icon"]);
    button.appendChild(iconElement);

    button.onclick = e => {
      e.stopPropagation();
      const actionCellValues = this.getActionCellValues();
      this.actionCellConfigs.handleContextMenu(actionCellValues, button, this.contextMenuList);
    };

    const element = document.createElement("div");
    element.classList.add("more-actions");
    element.appendChild(button);

    return element;
  }

  renderKebabWrapper() {
    const element = document.createElement("div");
    element.classList.add("more-actions");
    const button = this.renderKebabIcon();
    element.appendChild(button);
    return element;
  }

  isWebexChatDisabled(): boolean {
    if (this.actionCellConfigs.isSupervisorySendMessageEnabled) {
      return (
        this.actionCellConfigs.agentId === this.actionCellConfigs.supervisorId ||
        this.actionCellConfigs.agentEmail === ""
      );
    }
    // Disable webex chat
    return true;
  }

  webExMessageError() {
    return (
      this.actionCellConfigs.isWebExSendingMessageError ||
      getPersistedError(this.actionCellConfigs.agentName, this.actionCellConfigs.agentEmail)
    );
  }

  getWebexToolTip = () => {
    if (this.actionCellConfigs.isWebExSendingMessage) {
      return this.localization.webexChatSendingMessage;
    } else if (this.actionCellConfigs.isWebExSendingMessageError) {
      return this.localization.webexChatTPWErrorMessage;
    } else {
      return this.localization.webexChatSendMessage;
    }
  };

  actionComponents(): Array<ActionComponents> {
    // This method will return the list of components required
    const actionComponents: Array<ActionComponents> = [];

    if (this.actionCellConfigs.webexEnabled) {
      // Webex Component is enabled.
      actionComponents.push(SEND_MESSAGE);
    }

    if (this.actionCellConfigs.isStateChangeBySupervisorEnabled) {
      // Agent state change Component is enabled.
      actionComponents.push(AGENT_STATE_CHANGE);
    }

    return actionComponents;
  }

  renderActionElement(componentName: ActionComponents): HTMLDivElement | Element | null {
    switch (componentName) {
      case SEND_MESSAGE:
        return this.renderChatIcon();
      case AGENT_STATE_CHANGE:
        return this.renderStateChangeIcon();
      default:
        return null;
    }
  }

  getReviewMonitorIconWidth() {
    return ACTION_ELEMENT_DEFAULT_WIDTH - DEFINED_ACTION_CELL_PADDING_SIZE;
    // return reviewAndMonitorIconWidth;
  }

  showKebabMenu(actionComponents: Array<ActionComponents>) {
    const reviewAndMonitorIconWidth = this.getReviewMonitorIconWidth();
    return (
      reviewAndMonitorIconWidth +
        (ACTION_ELEMENT_DEFAULT_WIDTH * actionComponents.length - DEFINED_ACTION_CELL_PADDING_SIZE) >=
      this.actionCellConfigs.actionCellWidth
    );
  }

  getActionCellElements(actionComponents: Array<ActionComponents>) {
    const elements: Array<HTMLDivElement | Element> = []; // Stores the array of action cell components
    let contextMenuList: Array<ActionComponents> = [];
    const reviewAndMonitorIconWidth = this.getReviewMonitorIconWidth();

    const showKebabMenu = this.showKebabMenu(actionComponents);

    if (showKebabMenu) {
      const sectionTwoWidth = this.actionCellConfigs.actionCellWidth - reviewAndMonitorIconWidth;
      const noOfPossibleElements = Math.round(sectionTwoWidth / ACTION_ELEMENT_DEFAULT_WIDTH);

      if (noOfPossibleElements > 1) {
        const renderComponents: Array<ActionComponents> = [];
        // Append the list of possible component to render this width.
        for (let i = 1; i < noOfPossibleElements; i++) {
          const index = i - 1;
          const componentName = actionComponents[index];
          renderComponents.push(componentName);
          const element = this.renderActionElement(componentName);
          if (element) {
            elements.push(element);
          }
        }

        const kebabElements = actionComponents.filter(
          (componentName: ActionComponents) => !renderComponents.includes(componentName)
        );

        contextMenuList = kebabElements;
      } else {
        contextMenuList = actionComponents;
      }

      // Append the kebab Icon
      const kebabIcon = this.renderKebabWrapper();
      elements.push(kebabIcon);
    } else {
      // Render all the action items in action cell, context menu list is not required
      actionComponents.forEach((componentName: ActionComponents) => {
        const element = this.renderActionElement(componentName);
        if (element) {
          elements.push(element);
        }
      });
    }
    return [elements, contextMenuList];
  }

  renderChatIcon(): HTMLButtonElement {
    const customClassNames = ["aid-chat", `agentx-webex-chat-${this.actionCellConfigs.agentId}`];
    const isDisabled = this.isWebexChatDisabled();
    const button = this.createButtonElement(customClassNames, isDisabled);

    if (this.actionCellConfigs.isWebExSendingMessage) {
      const iconElement = this.createIconElement(["md-spinner", "md-spinner--14"]);
      button.appendChild(iconElement);
    } else {
      const iconElement = this.createIconElement(["icon-chat_14"]);
      button.appendChild(iconElement);

      if (this.webExMessageError()) {
        const errorElement = document.createElement("div");
        errorElement.classList.add("error-icon");

        const webexErrorIcon = this.createIconElement(["icon-priority-active-two_12"]);
        errorElement.appendChild(webexErrorIcon);

        button.appendChild(errorElement);
      }
    }

    button.onclick = e => {
      e.stopPropagation();
      if (!isDisabled) {
        const actionCellValues = this.getActionCellValues();
        this.actionCellConfigs.handleWebexChat(actionCellValues, button);
      }
    };

    const toolTipMessage = this.getWebexToolTip();
    button.setAttribute("aria-label", `${toolTipMessage} ${t("app:common.to")} ${this.actionCellConfigs.agentName}`);
    button.classList.add(TWP_CUSTOM_TOOLTIP);
    button.setAttribute(DATA_MESSAGE, toolTipMessage);
    return button;
  }

  renderElement() {
    const element = document.createElement("div");
    element.classList.add("ActionCell");

    const section1 = document.createElement("div");
    section1.classList.add("section-one");

    const section2 = document.createElement("div");
    section2.classList.add("section-two");

    // SECTION ONE
    const isMonitoring = this.getIsMonitoring();
    if (!isMonitoring) {
      // There is no monitoring in progress.
      // Add the review and monitor icon
      const reviewAndMonitorButtonElement = this.renderReviewAndMonitorIcon();
      section1.appendChild(reviewAndMonitorButtonElement);
    } else {
      // Call monitoring in progress.
      // Show the monitoring label text.
      const labelElement = this.callMonitoringLabel();
      section1.appendChild(labelElement);
    }

    // SECTION TWO
    // Check whether we need to render the kebab icon or chat icon based on the action cell width
    const actionComponents: Array<ActionComponents> = this.actionComponents();
    if ((this.actionCellConfigs.isAIDEnabled && isMonitoring) || actionComponents.length > 0) {
      if (this.actionCellConfigs.isAIDEnabled && isMonitoring) {
        // All the action components will added to the context menu list
        this.contextMenuList = actionComponents;
        const kebabWrapper = this.renderKebabWrapper();
        section2?.appendChild(kebabWrapper);
      } else {
        // Render action cell components
        const [elements, contextMenuList] = this.getActionCellElements(actionComponents);
        elements.forEach((element: any) => {
          section2?.appendChild(element);
        });
        this.contextMenuList = contextMenuList;
      }
    } else {
      this.contextMenuList = [];
    }

    element.appendChild(section1);
    element.appendChild(section2);

    this.eGui = element;
  }

  init(params: ICellRendererParams) {
    this.paramsData = params.value;
    this.setActionCellConfigs(params);
    this.renderElement();
  }

  getGui(): HTMLElement {
    return this.eGui;
  }

  removeAllChildNodes = (parent: Element) => {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  };

  validateDarkToLightModeTransition = (element: Element): boolean => {
    const firstChild = element?.firstChild as Element;
    if (firstChild instanceof Element) {
      const response = firstChild?.classList?.contains(this.selectedModeClassName());
      if (!response) {
        // On true, Update the class name list.
        firstChild?.classList?.remove("dark-mode", "light-mode"); // Remove the class name
        firstChild?.classList?.add(this.selectedModeClassName());
      }
      return !response;
    }
    return false;
  };

  refreshElement() {
    // This method will execute when refresh function is called.
    const section1: any = this.eGui.querySelector(".section-one");
    const isMonitoring = this.getIsMonitoring();
    if (!isMonitoring) {
      // There is no monitoring call in progress.
      // Update the monitor icon
      const reviewAndMonitorIcon: any = this.eGui.getElementsByClassName("review-monitor-icon");
      if (reviewAndMonitorIcon && reviewAndMonitorIcon.length > 0) {
        // Update the Icon...
        const reviewMonitorStatus = this.isReviewMonitorDisabled();
        const reviewMonitorSelectedIcon = reviewAndMonitorIcon[0];

        if (
          reviewMonitorStatus !== reviewMonitorSelectedIcon?.classList.contains("disabled") ||
          this.validateDarkToLightModeTransition(reviewMonitorSelectedIcon)
        ) {
          if (reviewMonitorStatus) {
            reviewMonitorSelectedIcon.classList.add("disabled");
            reviewMonitorSelectedIcon.setAttribute("aria-disabled", "true");
          } else {
            reviewMonitorSelectedIcon.classList.remove("disabled");
            reviewMonitorSelectedIcon.removeAttribute("aria-disabled");
          }
          reviewMonitorSelectedIcon.firstChild.src = this.getMonitorIconPath(reviewMonitorStatus);
        }

        const toolTipMessage = this.getTooltipMessage();
        const ToolTipElement = section1.querySelector(`.${TWP_CUSTOM_TOOLTIP}`);
        if (ToolTipElement) {
          ToolTipElement.setAttribute(DATA_MESSAGE, toolTipMessage);
        }

        if (
          this.actionCellConfigs.isConsultCall ||
          this.isWrapUp ||
          this.isWrapUpAssistance ||
          this.actionCellConfigs.voiceChannelCount === 0
        ) {
          reviewAndMonitorIcon[0].setAttribute(SHOW_TOOLTIP, "true");
        } else {
          reviewMonitorSelectedIcon.removeAttribute(SHOW_TOOLTIP);
        }
      } else {
        // Render the monitor icon.'
        const reviewAndMonitorButtonElement = this.renderReviewAndMonitorIcon();
        section1?.replaceChildren(reviewAndMonitorButtonElement);
      }
    } else {
      // Call monitoring in progress, Render the monitoring label text
      const labelElement = this.callMonitoringLabel();
      section1?.replaceChildren(labelElement);
    }

    // Check whether we need to render the kebab icon or chat icon based on the action cell width
    const section2: any = this.eGui.querySelector(".section-two");
    const actionComponents: Array<ActionComponents> = this.actionComponents();

    if ((this.actionCellConfigs.isAIDEnabled && isMonitoring) || actionComponents.length > 0) {
      if (this.actionCellConfigs.isAIDEnabled && isMonitoring) {
        // All the action components will added to the context menu list
        this.contextMenuList = actionComponents;
        const kebabWrapper = this.renderKebabWrapper();
        section2?.replaceChildren(kebabWrapper);
      } else {
        // Render action cell components
        const [elements, contextMenuList] = this.getActionCellElements(actionComponents);
        section2?.replaceChildren(...elements);
        this.contextMenuList = contextMenuList;
      }
    } else {
      // Remove the child elements.
      // section2.removeChildren
      this.removeAllChildNodes(section2);
    }
  }

  setActionCellConfigs(params: ICellRendererParams) {
    const cellEditorParams = params?.colDef?.cellEditorParams();

    const actionCellValues =
      params?.data?.actions && Object.keys(params?.data?.actions).length > 0 ? params?.data?.actions : {};
    this.actionCellConfigs = {
      ...actionCellValues,
      isDarkMode: cellEditorParams?.isDarkMode ?? false,
      webexEnabled: cellEditorParams?.webexEnabled ?? false,
      isMCMEnabled: cellEditorParams?.isMCMEnabled ?? false,
      isAIDEnabled: cellEditorParams?.isAIDEnabled ?? false,
      isBargeInEnabled: cellEditorParams?.isBargeInEnabled ?? false,
      isMPCorPersistEnabled: cellEditorParams?.isMPCorPersistEnabled ?? false,
      isStateChangeBySupervisorEnabled: cellEditorParams?.isStateChangeBySupervisorEnabled ?? false,
      isSupervisorySendMessageEnabled: cellEditorParams?.isSupervisorySendMessageEnabled ?? false,
      voiceChannelCount: cellEditorParams?.voiceChannelCount ?? 0,
      onActiveCall: cellEditorParams?.onActiveCall ?? false,
      handleAIDModal: cellEditorParams?.handleAIDModal,
      handleContextMenu: cellEditorParams?.handleContextMenu,
      handleStateChangeSelector: cellEditorParams?.handleStateChangeSelector,
      handleWebexChat: cellEditorParams?.handleWebexChat,
      actionCellWidth: cellEditorParams?.actionCellWidth
    };

    // i18nMixin was not extended in this function,
    // All the required localization text are received as a property from cellEditorParams
    this.localization = cellEditorParams?.actionCellLocalizationValues;
  }

  refresh(params: ICellRendererParams) {
    // Update the params
    this.paramsData = params.value;
    this.setActionCellConfigs(params);
    this.refreshElement();
    return true;
  }

  destroy() {
    this.eGui.remove();
  }
}
