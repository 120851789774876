import {
  ACCESS_IDLE_CODES,
  AGENT_STATE_CHANGE_FAILED,
  AVAILABLE,
  AVAILABLE_ID,
  IDLE,
  STATE_CHANGE_OVERLAY_CLOSE_EVENT,
  SUPERVISOR,
  UPDATE_ACTION_CELL_VALUE_EVENT
} from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { logger } from "@/sdk";
import { WINDOW_LISTENERS_HUB } from "@/utils/WindowListenersHub";
import { RTDTypes, SERVICE, Service, getTrackingIdFromErrorObject } from "@agentx/agentx-services";
import { LitElement, customElement, html, internalProperty, property, query } from "lit-element";
import { nothing } from "lit-html";
import { repeat } from "lit-html/directives/repeat";
import { idleCodes } from "../../TeamPerformance";
import "./list";
import style from "./scss/main.scss";
import { ChangeStateSelectorTypes } from "./types";

export namespace AgentChangeStateSelector {
  /**
   * @element agentx-wc-tpw-agent-state-selector
   */
  @customElement("agentx-wc-tpw-agent-state-selector")
  export class Element extends i18nMixin(LitElement) {
    @property({ reflect: true }) agentId = "";
    @property({ reflect: true }) orgId = "";
    @property({ type: Object }) selectedAgentDetails: RTDTypes.AgentDetails | undefined;
    @property({ reflect: true, type: Boolean }) isOpen = false;
    @property({ type: Array }) orgIdleCodes: idleCodes = { isFetchingSuccess: false, data: [] };

    @internalProperty() searchTerm = "";
    @internalProperty() isHighlightedItem = -1;
    @internalProperty() selectedState = "";
    @internalProperty() selectedIndex = 0;
    @internalProperty() isLoading = true;
    @internalProperty() isFetchError = false;
    @internalProperty() list: ChangeStateSelectorTypes.AgentStateOption[] = [];
    @internalProperty() filteredStateList: ChangeStateSelectorTypes.AgentStateOption[] = [];
    @query("md-list") listElement!: Element;

    static get styles() {
      return style;
    }

    getSelectedAgentId(filteredStateList: any) {
      this.selectedIndex = filteredStateList.findIndex(
        (stateOption: { name: string }) => stateOption.name === this.selectedAgentDetails?.agentState
      );

      this.selectedIndex = this.selectedIndex === -1 ? 0 : this.selectedIndex;
    }

    protected updated(changedProperties: Map<string | number | symbol, unknown>): void {
      super.updated(changedProperties);
      if ((changedProperties.has("agentId") || changedProperties.has("isOpen")) && this.isOpen) {
        this.stateSelectorOpened();
      }
      if (changedProperties.has("isOpen") && this.isOpen === false) {
        this.searchTerm = "";
        this.filteredStateList = [];
        this.isHighlightedItem = -1;
        this.selectedState = "";
        this.selectedIndex = 0;
        this.list = [];
        this.isLoading = false;
      }
    }

    fetchOrganizationIdleCodes = async () => {
      try {
        const response = await SERVICE.abs.fetchIdleCodes({ orgId: this.orgId, accessType: "ALL" });
        const events = new CustomEvent("update-org-idle-codes", {
          detail: { isFetchingSuccess: true, response },
          bubbles: true,
          composed: true
        });

        WINDOW_LISTENERS_HUB.dispatch(events);
        logger.info(
          `event=fetchOrganizationIdleCodes | Successfully fetched organization idle codes on Team Performance Widget`
        );
        return response;
      } catch (error) {
        logger.error(
          "event=fetchOrganizationIdleCodes | Error on fetching organization idle codes on Team Performance Widget Agent State Change Module",
          error
        );
        throw error;
      }
    };

    async fetchAgentIdleCodes(orgId = "", agentId = ""): Promise<Record<string, string[] | string>> {
      const response: Service.Cms.AgentIdleCodes = await SERVICE.abs.fetchAgentIdleCodes({ orgId, agentId });
      return { idleCodes: response.idleCodes, accessIdleCode: response.accessIdleCode };
    }

    stateSelectorOpened = async () => {
      this.isLoading = true;
      try {
        let idleCodes: any = [];
        if (!this.orgIdleCodes.isFetchingSuccess) {
          // Fetch the org idle codes in this component.
          idleCodes = await this.fetchOrganizationIdleCodes();
        } else {
          idleCodes = this.orgIdleCodes.data ?? [];
        }

        const { idleCodes: agentIdleCodes, accessIdleCode } = await this.fetchAgentIdleCodes(this.orgId, this.agentId);

        const defaultAvailableIdleCode = [
          {
            name: t("app:tpw.stateChange.available"),
            id: AVAILABLE_ID,
            isDefault: false,
            isSystem: false
          }
        ];
        let visibleStates = []; // Variable for update the idle code list from the API.
        if (agentIdleCodes.length === 0 && accessIdleCode === ACCESS_IDLE_CODES.ALL) {
          visibleStates.push(
            ...idleCodes.filter(
              (agentStateOption: ChangeStateSelectorTypes.AgentStateOption) => agentStateOption.isSystem === false
            )
          );
        } else {
          for (const code of agentIdleCodes) {
            const agentIdleCode = idleCodes.find(
              (agentStateOption: ChangeStateSelectorTypes.AgentStateOption) => agentStateOption.id === code
            );
            if (agentIdleCode && agentIdleCode.isSystem === false) {
              visibleStates.push(agentIdleCode);
            }
          }
        }
        if (visibleStates.length > 0) {
          // sorting the list
          visibleStates = visibleStates.sort(
            (a: ChangeStateSelectorTypes.AgentStateOption, b: ChangeStateSelectorTypes.AgentStateOption) =>
              a.name.localeCompare(b.name)
          );
        }
        // Keep the "Available" state at the top of the list after the sorting API response.
        const idleCodesList = [...defaultAvailableIdleCode, ...visibleStates];
        this.list = idleCodesList;
        this.filteredStateList = idleCodesList;
        this.getSelectedAgentId(this.filteredStateList);
        this.isFetchError = false;
        setTimeout(() => {
          this.focusInputField();
        }, 0);
      } catch (e) {
        this.isFetchError = true;
        setTimeout(() => {
          this.focusRetryButton();
        }, 0);
      } finally {
        this.isLoading = false;
      }
    };

    focusInputField = () => {
      const inputElement = this.shadowRoot?.querySelector("md-input")?.shadowRoot?.querySelector("input");
      inputElement?.focus();
    };
    focusRetryButton = () => {
      const buttonElement = this.shadowRoot?.getElementById("reload-btn")?.shadowRoot?.querySelector("button");
      buttonElement?.focus();
    };

    connectedCallback(): void {
      super.connectedCallback();
      if (!this.orgIdleCodes.isFetchingSuccess) {
        this.fetchOrganizationIdleCodes();
      }
    }

    // The function is used to attach tab press event to the clear input button
    focusWhenClearInputButtonTabPress = () => {
      const clearButton = this.shadowRoot?.querySelector("md-input")?.shadowRoot?.querySelector("md-button");
      const inputElement = this.shadowRoot?.querySelector("md-input")?.shadowRoot?.querySelector("input");
      const listItem: any = this.listElement.querySelector("md-list-item");

      // Determine the element that should receive focus
      const focusElement = listItem || inputElement;

      clearButton?.addEventListener("keydown", (event: any) => {
        if (event.key === "Tab") {
          setTimeout(() => {
            focusElement?.focus();
          }, 0);
        }
      });
    };

    setFilteredStateList = () => {
      if (this.searchTerm === "") {
        this.filteredStateList = this.list;
        // No item should be highlighted when it shows all states
        this.isHighlightedItem = -1;
        this.getSelectedAgentId(this.filteredStateList);
      } else {
        const filterStateList = this.list.filter((status: ChangeStateSelectorTypes.AgentStateOption) =>
          status.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
        const isSelectedReasonPresent = filterStateList?.filter(
          (status: ChangeStateSelectorTypes.AgentStateOption) => this.selectedState === status.name
        );
        if (isSelectedReasonPresent?.length < 1) {
          this.selectedIndex = -1;
        }
        this.filteredStateList = filterStateList;
        // As soon as list is filtered, first item should be highlighted
        this.isHighlightedItem = 0;
      }
    };

    handleSearch = (event: any): void => {
      this.searchTerm = event.target.value;
      this.setFilteredStateList();
      // The function is used to attach tab press event to the clear input button when search is done
      setTimeout(() => {
        this.focusWhenClearInputButtonTabPress();
      }, 0);
    };

    private readonly updateActionCellValue = (values: Record<string, any>) => {
      this.dispatchEvent(
        new CustomEvent(UPDATE_ACTION_CELL_VALUE_EVENT, {
          composed: true,
          bubbles: true,
          detail: {
            ...values
          }
        })
      );
    };

    private readonly closeOverlay = () => {
      this.dispatchEvent(
        new CustomEvent(STATE_CHANGE_OVERLAY_CLOSE_EVENT, {
          composed: true,
          bubbles: true
        })
      );
    };

    handleSelectValue = async (event: any) => {
      // agentStateChanging Flag is used to show the spinner on action cell component
      const agentId = this.agentId;
      this.updateActionCellValue({ agentStateChanging: true, agentId });
      this.closeOverlay();
      try {
        const selectedValue = this.filteredStateList[event.detail.selected];
        if (this.agentId) {
          const payload = {
            state: selectedValue.id === AVAILABLE_ID ? AVAILABLE : IDLE,
            auxCodeId: selectedValue.id === AVAILABLE_ID ? " " : selectedValue.id,
            lastStateChangeReason: "",
            agentId: this.agentId
          };

          await SERVICE.aqm.supervisor.changeAgentState({ orgId: this.orgId, data: payload });
          SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.AGENT_STATE_CHANGE, {
            ChangedBy: SUPERVISOR,
            CurrentState: selectedValue.name,
            Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS
          });

          this.updateActionCellValue({
            agentId,
            agentStateChanging: false,
            agentStateChangeError: false
          });
        }
      } catch (error) {
        logger.error(
          `event=agentStateChangeFailed | Tracking ID: ${
            getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : ""
          } | Updating agent state from team performance widget is failed : ${error}`,
          error
        );
        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.AGENT_STATE_CHANGE, {
          ChangedBy: SUPERVISOR,
          Status: SERVICE.telemetry.MIX_EVENT.STATUS.FAILED
        });
        this.updateActionCellValue({
          agentId,
          agentStateChanging: false,
          agentStateChangeError: true
        });

        // Dispatch the event to show the error notification for agent state change failures.
        const event = new CustomEvent(AGENT_STATE_CHANGE_FAILED, {
          detail: { trackingId: getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : null },
          bubbles: true,
          composed: true
        });

        WINDOW_LISTENERS_HUB.dispatch(event);
      }
    };

    renderStatesList = () => {
      return html`
        <md-list
          class="menu-list state-selector-list"
          role="listbox"
          selected="${this.selectedIndex}"
          activated="${this.selectedIndex}"
          roving-prevent-focus="false"
          @list-item-change="${this.handleSelectValue}"
          @keydown="${this.handleListkeyDown}"
          label=${t("app:tpw.agentStateSelector")}
        >
          ${repeat(
            this.filteredStateList,
            (agentStateOption: ChangeStateSelectorTypes.AgentStateOption, index: number) => html`
              <md-list-item
                slot="list-item"
                role="option"
                class="status-menu-item ${this.isHighlightedItem === index ? "state-item-highlight" : ""}"
                aria-label="${agentStateOption.name}"
                aria-selected="${this.selectedIndex === index}"
              >
                <agent-state-selector-list
                  .isOpen=${this.isOpen}
                  .agentStateOption=${agentStateOption}
                  .searchTerm="${this.searchTerm}"
                ></agent-state-selector-list>
              </md-list-item>
            `
          )}
        </md-list>
      `;
    };

    handleListkeyDown = (event: any) => {
      event?.preventDefault();
      if (event?.key === "Tab") {
        this.focusInputField();
      }
    };

    handleInputFocus = () => {
      if (this.listElement) {
        this.listElement.setAttribute("roving-prevent-focus", "false");
      }
    };

    handleInputBlur = () => {
      if (this.listElement) {
        this.listElement.removeAttribute("roving-prevent-focus");
      }
    };

    handleInputKeyDown = (event: CustomEvent) => {
      if (event.detail.srcEvent) {
        const { srcEvent } = event.detail;
        if (srcEvent.key === "Tab" && srcEvent.shiftKey) {
          event.detail.srcEvent.preventDefault();
          // Focus to the md-list
          const currentSelectedItem: any = this.listElement.querySelector("md-list-item[selected]");
          if (currentSelectedItem) {
            currentSelectedItem?.focus();
          }
        }
      }
    };

    render() {
      return html`
        <div class="tpw-agent-status ${!this.isLoading ? "suppress-loading" : ""}">
          ${!this.isLoading && this.list.length > 0
            ? html`
                <div class="state-input-wrapper">
                  <md-input
                    id="pillSearchInput"
                    searchable
                    shape="pill"
                    placeholder=${t("app:tpw.search")}
                    clear
                    clearAriaLabel="${t("app:common:clear")}"
                    containerSize="medium-10"
                    htmlId="pillSearchInput"
                    name="pillSearchInput"
                    value="${this.searchTerm}"
                    type="text"
                    autoFocus
                    @input-change="${this.handleSearch}"
                    @input-focus="${this.handleInputFocus}"
                    @input-blur="${this.handleInputBlur}"
                    @input-keydown="${this.handleInputKeyDown}"
                  ></md-input>
                </div>
                ${this.renderStatesList()}
              `
            : nothing}
          ${this.isLoading
            ? html`
                <div class="state-loading"><md-spinner size="16"></md-spinner></div>
              `
            : nothing}
          ${!this.isLoading && (!this.filteredStateList || this.filteredStateList.length === 0) && this.list.length > 0
            ? html`
                <div class="state-no-match">
                  <agentx-wc-empty-state-wrapper
                    class="empty-state-wrapper"
                    illustration-type="empty-result"
                  ></agentx-wc-empty-state-wrapper>
                  <span class="empty-title">${t("app:common:nomatchfound")}</span>
                </div>
              `
            : nothing}
          ${!this.isLoading && this.isFetchError
            ? html`
                <div class="state-error">
                  <agentx-wc-empty-state-wrapper
                    class="error-cloud-wrapper"
                    illustration-type="error-cloud"
                  ></agentx-wc-empty-state-wrapper>
                  <div class="error-container">
                    <p class="error-title">${t("app:errorDetail:errorTitle")}</p>
                  </div>
                  <md-button @click=${() => this.stateSelectorOpened()} id="reload-btn" color="blue">
                    <span slot="text">${t("app:errorDetail:reloadButton")}</span>
                  </md-button>
                </div>
              `
            : nothing}
        </div>
      `;
    }
  }
}
