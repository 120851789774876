import { customElement, html, LitElement, property } from "lit-element";
import { DateTime, Duration } from "luxon";

const formatDuration = (diff: Duration): string => {
  if (diff.shiftTo("hours", "minute").get("hours") < 1) {
    return diff.toFormat("mm:ss");
  }
  return diff.toFormat("hh:mm:ss");
};

@customElement("agentx-wc-task-list-timer")
export class TaskTimer extends LitElement {
  @property({ type: Number }) startTimestamp = 0;

  private intervalId?: number;

  public connectedCallback(): void {
    super.connectedCallback();
    this.intervalId = window.setInterval(() => this.requestUpdate(), 100);
  }

  public disconnectedCallback(): void {
    super.disconnectedCallback();
    clearInterval(this.intervalId);
  }

  render() {
    const start = DateTime.fromMillis(this.startTimestamp);
    const now = DateTime.utc();
    return html`
      <div role="timer" class="ax-activity-timer">
        ${formatDuration(now.diff(start))}
      </div>
    `;
  }
}
