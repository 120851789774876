import { CONF } from "@/config";
import { AGENTX_WEBEX_MINIMIZE_RESTORE, AGENTX_WEBEX_WRAPPER_CLOSE } from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { logger } from "@/sdk";
import { SERVICE } from "@agentx/agentx-services";
import webexImg from "@img/teams-icon-color.svg";
import "@uuip/unified-ui-platform-common-components";
import axios from "axios";
import { LitElement, PropertyValues, customElement, html, internalProperty, property } from "lit-element";
import { throttle } from "./../../utils/helpers";
import style from "./WebexWrapper.scss";

type WebexThemePayload = {
  key: string;
  value: "default" | "light";
  orgId: string;
};
export namespace WebexWrapper {
  /**
   * @element agentx-webex-wrapper
   * @fires agentx-webex-wrapper-close
   * @fires agentx-webex-minimize-restore
   */
  @customElement("agentx-webex-wrapper")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Boolean }) webexEnabled = false;
    @property({ type: Boolean }) showModal = false;
    @property({ type: Boolean }) darkMode = false;
    @property({ type: Boolean }) minimize = false;
    @property({ type: String }) agentId = "";
    @property({ type: String }) agentEmailId = "";
    @property({ type: String }) orgId = "";
    @property({ type: Number }) webexMeetingTimeStamp = 0;
    @property({ type: Boolean }) isWebexLogoutRequested = false;
    @internalProperty()
    private readonly params = "camera *;microphone *;audio *";
    @internalProperty() private readonly defaultOriginPosition = { x: 0, y: 0 };
    @internalProperty() private webexUrl = "";
    @internalProperty() private currentWindowWidth = window.innerWidth;
    @internalProperty() private readonly lessWidth = 734;
    @internalProperty() private readonly midWidth = 920;
    @internalProperty() private readonly defaultWidth = 900;
    @internalProperty() private initial = false;
    @internalProperty() private readonly webexWrapperTitle = t("app:common.webex");
    getLocation() {
      if (window.innerWidth < this.lessWidth) {
        return this.defaultOriginPosition;
      } else if (window.innerWidth < this.midWidth) {
        return { x: window.innerWidth - window.innerWidth * 0.8, y: -(window.innerHeight * 0.7) };
      } else {
        return { x: window.innerWidth - this.defaultWidth, y: -(window.innerHeight * 0.7) };
      }
    }

    getHeightWidth() {
      if (window.innerWidth < this.lessWidth) {
        return {
          width: window.innerWidth,
          height: window.innerHeight * 0.8
        };
      } else if (window.innerWidth < this.midWidth) {
        return {
          width: window.innerWidth * 0.8,
          height: window.innerHeight * 0.7
        };
      } else {
        return { width: this.defaultWidth, height: window.innerHeight * 0.7 };
      }
    }

    windowResizeHandler = () => {
      const isModalInFullScreen: boolean | undefined =
        this.shadowRoot?.querySelector("#webex-wrapper-modal")?.hasAttribute("full-screen") || false;
      if (this.initial && isModalInFullScreen !== true) {
        if (!this.showModal || this.minimize) {
          this.containerRect = this.getHeightWidth();
          this.location = this.getLocation();
        } else if (
          this.showModal &&
          (localStorage.getItem("location") === null ||
            window.innerWidth < this.lessWidth ||
            this.currentWindowWidth < this.lessWidth)
        ) {
          this.location = this.getLocation();
          this.containerRect = this.getHeightWidth();
        }
        this.currentWindowWidth = window.innerWidth;
        this.minLocation = this.defaultOriginPosition;
      }
      this.initial = true;
    };

    @internalProperty() private readonly defaultPosition = this.getLocation();
    private readonly windowResize = throttle(() => this.windowResizeHandler());

    @property({ type: Object }) containerRect =
      localStorage.getItem("resize") !== null
        ? JSON.parse(localStorage.getItem("resize") || "")
        : this.getHeightWidth();

    @property({ type: Object }) location =
      localStorage.getItem("location") !== null
        ? JSON.parse(localStorage.getItem("location") || "")
        : this.defaultPosition;

    @property({ type: Object }) minLocation =
      localStorage.getItem("min-location") !== null
        ? JSON.parse(localStorage.getItem("min-location") || "")
        : this.defaultOriginPosition;

    private resize(event: any) {
      this.containerRect = event?.detail?.size;
      localStorage.setItem("resize", JSON.stringify(event?.detail?.size));
    }

    private saveLocation(event: any) {
      if (this.containerRect?.y < 0) {
        // reset location if modal header is out of viewPort.
        this.location = this.getLocation();
      } else {
        this.location = event?.detail?.transform;
      }
      localStorage.setItem("location", JSON.stringify(this.location));
    }

    private saveMinLocation(event: any) {
      this.minLocation = event?.detail?.transform;
      localStorage.setItem("min-location", JSON.stringify(event?.detail?.transform));
    }

    static get styles() {
      return style;
    }

    async connectedCallback() {
      super.connectedCallback();
      window.addEventListener("resize", this.windowResize);
      if (this.agentId !== "" && this.webexEnabled) {
        await this.setTheme();
      }
    }

    disconnectedCallback() {
      window.removeEventListener("resize", this.windowResize);
    }

    updated(changedProperties: PropertyValues) {
      changedProperties.forEach(async (oldValue, propName) => {
        if (propName === "showModal" && oldValue !== undefined && this.showModal) {
          SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.WEBEX_APP_OPENED);
          this.setFocus();
        } else if (
          this.webexEnabled &&
          this.agentId !== "" &&
          (propName === "darkMode" || propName === "agentId" || propName === "webexEnabled") &&
          oldValue !== undefined
        ) {
          await this.setTheme();
        }
      });
    }

    handleCloseModal() {
      const event = new CustomEvent<boolean>(AGENTX_WEBEX_WRAPPER_CLOSE, {
        detail: false,
        bubbles: true,
        composed: true
      });
      this.dispatchEvent(event);
      if (SERVICE.webex) {
        SERVICE.webex.getUserStatusForWebex(SERVICE.conf?.profile?.agentId);
      }
      SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.WEBEX_APP_CLOSED);
    }

    handleMinimizeRestore() {
      const event = new CustomEvent<boolean>(AGENTX_WEBEX_MINIMIZE_RESTORE, {
        detail: false,
        bubbles: true,
        composed: true
      });
      this.dispatchEvent(event);
      if (SERVICE.webex) {
        SERVICE.webex.getUserStatusForWebex(SERVICE.conf?.profile?.agentId);
      }
    }

    async setTheme() {
      const reqPayload: WebexThemePayload = {
        key: "web-color-theme-name",
        value: this.darkMode ? "default" : "light",
        orgId: this.orgId
      };

      const accToken = sessionStorage.getItem("accessToken");
      const url = `${CONF.WEBEX_SETTING_SERVICE}/orgsettings/users/${this.agentId}`;
      const accessToken = `Bearer ${accToken}`;

      const headers = {
        "Content-Type": "application/json",
        Authorization: accessToken
      };

      return axios
        .post(url, reqPayload, {
          headers
        })
        .then((response: any) => {
          logger.info(`[Webex] | Webex theme change success: ${response}`);
        })
        .catch((error: any) => {
          logger.error(`[Webex] | Webex Theme change failed: ${error}`);
        });
    }

    setFocus() {
      setTimeout(() => {
        const minBtn = this.shadowRoot
          ?.querySelector(".webex-wrapper-modal")
          ?.shadowRoot?.querySelector("md-button.md-floating__minimize")
          ?.shadowRoot?.querySelector("button");

        if (minBtn) {
          minBtn.focus();
        }
      }, 50); //delay for modal to load
    }

    render() {
      if (this.agentEmailId !== "" && CONF.WEBEX_APP_URL) {
        const url = this.isWebexLogoutRequested
          ? new URL(`${CONF.WEBEX_APP_URL}/signout`)
          : new URL(CONF.WEBEX_APP_URL);
        url.searchParams.set("autosignin", this.agentEmailId);
        url.searchParams.set("ccParent", "true");
        this.webexUrl = url.href;
      }

      return this.webexEnabled
        ? html`
            <md-floating-modal
              id="webex-wrapper-modal"
              class="webex-wrapper-modal"
              label=${this.webexWrapperTitle}
              resize-aria-label=${t("app:common.restoreScreenLabel")}
              maximize-aria-label=${t("app:common.maximizeScreenLabel")}
              minimize-aria-label=${t("app:common.minimizeModal")}
              close-aria-label=${t("app:keyboardShortcutKeys.closeModal")}
              ?show=${this.showModal}
              ?minimize=${this.minimize}
              .position=${this.location}
              .minPosition=${this.minLocation}
              .containerRect=${this.containerRect}
              minimizable
              @floating-modal-close=${this.handleCloseModal}
              @floating-modal-minimize=${this.handleMinimizeRestore}
              @floating-modal-minimize-location=${this.saveMinLocation}
              @floating-modal-location=${this.saveLocation}
              @floating-modal-resize=${this.resize}
            >
              <div slot="header" class="modal-header">
                <img class="webex-img" alt=${this.webexWrapperTitle} src="${webexImg}" />
                ${this.minimize && this.webexMeetingTimeStamp
                  ? html`
                      <md-icon slot="icon" class="camara-icon" name="icon-camera_20"></md-icon>
                      <agentx-wc-task-list-timer startTimestamp=${this.webexMeetingTimeStamp}>
                      </agentx-wc-task-list-timer>
                    `
                  : html`
                      ${this.webexWrapperTitle}
                    `}
              </div>
              ${this.webexUrl !== ""
                ? html`
                    <agentx-wc-iframe
                      src="${this.webexUrl}"
                      title=${this.webexWrapperTitle}
                      allowedParams="${this.params}"
                      ?is-dark-theme="${this.darkMode}"
                    >
                    </agentx-wc-iframe>
                  `
                : null}
            </md-floating-modal>
          `
        : null;
    }
  }
}
