import { AgentxMfeWcBasedConfig, setAgentxMfeWcBasedConfig } from "./config";

export { AgentPerformanceReports } from "./components/AgentPerformanceReports/AgentPerformanceReports";
export { analyzerSessionHelper } from "./components/AgentPerformanceReports/AnalyzerSessionHelper";
export { CollapseArea } from "./components/CollapseArea/CollapseArea";
export { CollapseToggler } from "./components/CollapseArea/components/CollapseToggler";
export { WidgetError } from "./components/Common/WidgetError/WidgetError";
export { CountDownTimer } from "./components/CountDownTimer/CountDownTimer";
export { IframeBase } from "./components/IframeBase/IframeBase";
export { IframeLogin } from "./components/IframeLogin/IframeLogin";
export { ImiChatWrapper } from "./components/ImiChatWrapper/ImiChatWrapper";
export { IvrTranscripts } from "./components/IvrTranscripts/IvrTranscripts";
export { MenuNotification } from "./components/MenuNotifications/MenuNotifications";
export { MoreActionsWidget } from "./components/MoreActions/components/MoreActionsContainer/MoreActionsWidget";
export { ResetTabOrderAction } from "./components/MoreActions/components/ResetTabOrderAction/ResetTabOrderAction";
export { Navigation } from "./components/Navigation/Navigation";
export { NotesHeaderWidget } from "./components/NotesHeaderWidget/NotesHeaderWidget";
export { NotesWidget } from "./components/NotesWidget/NotesWidget";
export { QualityMonitoringWidget } from "./components/QualityMonitoringWidget/QualityMonitoring";
export { ScreenPop } from "./components/ScreenPop/ScreenPop";
export { ShortcutKey } from "./components/ShortcutKey/ShortcutKeyModal";
export { AgentXLoadingScreen } from "./components/StationLogin/AppLoadingScreen";
export { ChannelFilter } from "./components/SupervisorFilters/ChannelFilter/ChannelFilter";
export { QueueFilter } from "./components/SupervisorFilters/QueueFilter/QueueFilter";
export { TeamFilter } from "./components/SupervisorFilters/TeamFilter/TeamFilter";
export { TaskList } from "./components/TaskList/TaskList";
export { TeamPerformance } from "./components/TeamPerformanceWidget/TeamPerformance";
export {
  getAgentInteractionTime,
  getParticipantsDetails,
  mapAgentContactStatus
} from "./components/TeamPerformanceWidget/TeamPerformanceUtils";
export { WebexWrapper } from "./components/WebexWrapper/WebexWrapper";
export { WxmWrapper } from "./components/WxmWrapper/WxmWrapper";
export { UserProfile } from "./components/userProfile/userProfile";
export { UserStationLogin } from "./components/userStationLogin/userStationLogin";
export { UserStationLoginTypes } from "./components/userStationLogin/userStationLoginTypes";
export { makeAlphaNumericReadable } from "./utils/helpers";

export async function initAgentxMfeWcBased(config: AgentxMfeWcBasedConfig) {
  setAgentxMfeWcBasedConfig(config);
}
