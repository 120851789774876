import { RTDTypes } from "@/../../agentx-services/dist/types";
import {
  HANDLE_AID_MODAL_EVENT,
  HANDLE_STATE_CHANGE_EVENT,
  HANDLE_WEBEX_CHAT_EVENT,
  TPW_EMPTY_COLUMN_VALUE
} from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { LitElement, customElement, html, property, query } from "lit-element";
import { nothing } from "lit-html";
import { AGENT_STATE_CHANGE, REVIEW_AND_MONITOR, SEND_MESSAGE } from "./CellRenderer/ActionRenderer";
import { ActionCellConfigs } from "./TeamPerformance";
import style from "./contextMenu.scss";
import { getPersistedError } from "./utils/WebexChat.utils";

const cannotMonitorConsult = "app:tpw.cannotMonitorConsult";
const cannotMonitorWrapUp = "app:tpw.cannotMonitorWrapUp";
const cannotMonitorWrapUpAssistance = "app:tpw.cannotMonitorWrapUpAssistance";
const reviewAndMonitor = "app:tpw.reviewAndMonitor";
const review = "app:tpw.review";

export namespace TeamPerformanceContextMenu {
  @customElement("agentx-wc-tpw-context-menu")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Object })
    actionCellConfigs!: RTDTypes.ActionCellDetails & ActionCellConfigs;

    @property({ type: Boolean }) isOpen = false;
    @property({ type: Array }) contextMenuList: any = [];
    @property({ type: Object }) listItems: any = [];
    @property({ type: Boolean }) isMCMEnabled = false;
    @property({ type: Boolean }) isMPCorPersistEnabled = false;
    @property({ type: Boolean }) isBargeInEnabled = false;
    @property({ type: Boolean }) webexEnabled = false;
    @property({ attribute: false }) handleWebexChat!: () => void;
    @property({ type: Boolean }) isSupervisorySendMessageEnabled = false;
    @property({ type: String }) agentId = "";
    @query("md-list") list!: HTMLElement;

    static get styles() {
      return style;
    }

    get isWrapUp(): boolean | undefined {
      return this.isMPCorPersistEnabled && this.actionCellConfigs.isWrapUp;
    }

    get isWrapUpAssistance(): boolean | undefined {
      return this.isMPCorPersistEnabled && this.actionCellConfigs.isWrapUpAssistance;
    }

    isSameSupervisorBarged = () => {
      return this.isBargeInEnabled && this.actionCellConfigs.isSupervisorMonitoring && this.actionCellConfigs.isBarged;
    };

    getIsMonitoring = () => {
      return (
        this.actionCellConfigs.monitoredBy &&
        this.actionCellConfigs.monitoredBy !== "" &&
        this.actionCellConfigs.monitoredBy !== TPW_EMPTY_COLUMN_VALUE
      );
    };

    getTooltipMessage = () => {
      if (this.isWrapUp) {
        return t(cannotMonitorWrapUp);
      } else if (this.isWrapUpAssistance) {
        return t(cannotMonitorWrapUpAssistance);
      } else if (this.actionCellConfigs.isConsultCall) {
        return t(cannotMonitorConsult);
      } else if (this.isSameSupervisorBarged()) {
        return t("app:tpw.alreadyBargedIn");
      } else if (this.actionCellConfigs.isSupervisorMonitoring) {
        return t("app:tpw.alreadyMonitoring");
      } else if (this.isMCMEnabled) {
        return t(reviewAndMonitor);
      } else {
        return t(review);
      }
    };

    private readonly getReviewButton = (disabled = false) => {
      return html`
        <md-button
          id=${this.actionCellConfigs.agentId}
          aria-colindex="12"
          tabIndex="-1"
          class="aid-button btn-action"
          ariaLabel="${this.isMCMEnabled ? t(reviewAndMonitor) : t(review)}"
          size="size-none"
          .disabled=${disabled}
          color="color-none"
          @click=${(event: any) => {
            if (disabled) {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
        >
          <p class="rm-text ${disabled ? "disabled" : ""}">
            ${this.isMCMEnabled ? t(reviewAndMonitor) : t(review)}
          </p>
        </md-button>
      `;
    };

    getIsReviewAndMonitorTooltipNeeded = () => {
      return (
        (!this.actionCellConfigs.isSupervisorMonitoring &&
          this.actionCellConfigs.isReviewAndMonitorEnabled &&
          !this.actionCellConfigs.onActiveCall) ||
        this.actionCellConfigs.isConsultCall ||
        this.isWrapUp ||
        this.isWrapUpAssistance ||
        this.isSameSupervisorBarged() ||
        this.actionCellConfigs.isSupervisorMonitoring
      );
    };

    renderReviewAndMonitorWrapper = () => {
      // This Tooltip scanerio need to validate...
      return html`
        <div class="${`review-monitor-wrapper list `}">
          ${this.getIsReviewAndMonitorTooltipNeeded()
            ? html`
                <md-tooltip class="aid-tooltip" message="${this.getTooltipMessage()}" placement="top">
                  ${this.getReviewButton(
                    this.actionCellConfigs.isConsultCall ||
                      this.isWrapUp ||
                      this.isWrapUpAssistance ||
                      this.isSameSupervisorBarged() ||
                      this.actionCellConfigs.isSupervisorMonitoring
                  )}
                </md-tooltip>
              `
            : this.getReviewButton(true)}
        </div>
      `;
    };

    webExMessageError = () => {
      const agentEmail = this.actionCellConfigs?.agentEmail ?? "";
      return (
        this.actionCellConfigs.isWebExSendingMessageError ||
        getPersistedError(this.actionCellConfigs.agentName, agentEmail)
      );
    };

    handleAidModal = () => {
      const selectChatElement = this.shadowRoot?.querySelector("md-list-item.review-and-monitor");
      if (selectChatElement) {
        const mdButton = selectChatElement?.querySelector("md-button");
        if (mdButton) {
          const isReviewAndMonitorDisabled = mdButton.shadowRoot?.querySelector("button")?.hasAttribute("disabled");
          if (isReviewAndMonitorDisabled) {
            return false;
          }
        }
      }

      this.dispatchEvent(
        new CustomEvent(HANDLE_AID_MODAL_EVENT, {
          composed: true,
          bubbles: true,
          detail: {
            agentId: this.actionCellConfigs.agentId
          }
        })
      );
      return true;
    };

    handleSendMessage = () => {
      const selectChatElement = this.shadowRoot?.querySelector("md-list-item.webex-send-message");
      if (selectChatElement) {
        this.dispatchEvent(
          new CustomEvent(HANDLE_WEBEX_CHAT_EVENT, {
            composed: true,
            bubbles: true,
            detail: {
              event: selectChatElement
            }
          })
        );
      }
    };

    handleAgentStateChange = () => {
      const selectChatElement = this.shadowRoot?.querySelector("md-list-item.agent-state-change");
      this.dispatchEvent(
        new CustomEvent(HANDLE_STATE_CHANGE_EVENT, {
          composed: true,
          bubbles: true,
          detail: {
            event: selectChatElement,
            agentId: this.actionCellConfigs?.agentId
          }
        })
      );
    };

    handleSelection = (selected: number) => {
      const components = this.getComponents();

      if (components[selected]) {
        if (components[selected] === REVIEW_AND_MONITOR) {
          this.handleAidModal();
        } else if (components[selected] === SEND_MESSAGE) {
          this.handleSendMessage();
        } else if (components[selected]) {
          this.handleAgentStateChange();
        }
      }
      return true;
    };
    isAgentStateDisabled = (): boolean => {
      return this.actionCellConfigs.agentId === this.actionCellConfigs.supervisorId;
    };
    isWebexChatDisabled = (): boolean => {
      if (this.isSupervisorySendMessageEnabled) {
        return (
          this.actionCellConfigs.agentId === this.actionCellConfigs.supervisorId ||
          this.actionCellConfigs.agentEmail === ""
        );
      }
      // Disable webex chat
      return true;
    };

    protected updated(_changedProperties: Map<string | number | symbol, unknown>): void {
      super.updated(_changedProperties);
      if (
        (_changedProperties.has("agentId") ||
          _changedProperties.has("isOpen") ||
          _changedProperties.has("contextMenuList")) &&
        this.isOpen &&
        this.list
      ) {
        setTimeout(() => {
          this.focusLitItem();
        }, 0);
      }
    }

    getComponents = () => {
      const isMonitoring = this.getIsMonitoring();
      const contextMenuList = Array.isArray(this.contextMenuList) ? this.contextMenuList : [];
      if (isMonitoring) {
        return [REVIEW_AND_MONITOR, ...contextMenuList];
      } else {
        return contextMenuList;
      }
    };

    focusLitItem = () => {
      const listItem = this.list?.querySelector("md-list-item:not([disabled])");
      (listItem as any)?.focus();

      // Set the selected and activated index based on the list-item
      const listItems = this.list?.querySelectorAll("md-list-item");
      const index = Array.from(listItems)?.findIndex((it: any) => !it.disabled);
      if (index >= 0) {
        this.list?.setAttribute("activated", `${index}`);
        this.list?.setAttribute("selected", `${index}`);
      }
    };

    renderSpinner = () => {
      return html`
        <md-spinner size="14"></md-spinner>
      `;
    };

    renderErrorElement = () => {
      return html`
        <div class="error-icon">
          <md-icon slot="icon" name="icon-priority-active-two_12"></md-icon>
        </div>
      `;
    };

    renderComponentName = (
      label: string,
      isDisabled: boolean,
      isLoading: boolean,
      isError: boolean,
      subMenu = false
    ) => {
      return html`
        <div class="list-type-container">
          <div class="list-text-wrapper">
            <p class="list-text ${isDisabled ? "disabled" : ""}">
              ${label}
            </p>

            ${isLoading ? this.renderSpinner() : nothing}
            ${!isLoading && isError ? this.renderErrorElement() : nothing}

          </div>
          ${
            subMenu
              ? html`
                  <md-icon class="list-arrow-icon" name="icon-arrow-right-optical_16"></md-icon>
                `
              : nothing
          }
          </div>
        </div>
      `;
    };

    handleReviewMonitorDisabled = () => {
      const { isReviewAndMonitorEnabled, isConsultCall, isSupervisorMonitoring, onActiveCall } = this.actionCellConfigs;

      if (!isReviewAndMonitorEnabled) {
        return !isConsultCall;
      } else if (isSupervisorMonitoring) {
        return false;
      } else if (onActiveCall) {
        return true;
      }

      return false;
    };

    getLabelOnReviewAndMonitor = () => {
      const defaultLabel = this.isMCMEnabled ? t(reviewAndMonitor) : t(review);
      if (this.actionCellConfigs.isConsultCall) {
        return `${defaultLabel} - ${t(cannotMonitorConsult)}`;
      }
      return defaultLabel;
    };

    isDisabledCursor = () => {
      return (
        this.isSameSupervisorBarged() ||
        this.actionCellConfigs.isSupervisorMonitoring ||
        this.actionCellConfigs.isConsultCall
      );
    };

    renderReviewAndMonitor = () => {
      return html`
        <md-list-item
          class=${`review-and-monitor  ${this.isDisabledCursor() ? "disabled-cursor" : ""} `}
          .disabled="${this.handleReviewMonitorDisabled()}"
          aria-label="${this.getLabelOnReviewAndMonitor()}"
          slot="list-item"
        >
          ${this.renderReviewAndMonitorWrapper()}
        </md-list-item>
      `;
    };

    renderSendMessage = () => {
      const isDisabled = this.isWebexChatDisabled();
      const label = t("app:notifications.webexChatSendMessage");

      return html`
        <md-list-item class="webex-send-message" .disabled="${isDisabled}" slot="list-item">
          ${this.renderComponentName(
            label,
            isDisabled,
            this.actionCellConfigs.isWebExSendingMessage || false,
            this.webExMessageError()
          )}
        </md-list-item>
      `;
    };

    renderAgentStateChange = () => {
      const isDisabled = this.isAgentStateDisabled();
      const label = t("app:tpw.stateChange.default");
      return html`
        <md-list-item class="agent-state-change" .disabled="${isDisabled}" slot="list-item">
          ${this.renderComponentName(
            label,
            false,
            this.actionCellConfigs.agentStateChanging || false,
            this.actionCellConfigs.agentStateChangeError || false,
            true
          )}
        </md-list-item>
      `;
    };

    // Render the list items
    renderElements = (componentName: string) => {
      switch (componentName) {
        case REVIEW_AND_MONITOR:
          return this.renderReviewAndMonitor();
        case SEND_MESSAGE:
          return this.renderSendMessage();
        case AGENT_STATE_CHANGE:
          return this.renderAgentStateChange();
        default:
          return nothing;
      }
    };

    render() {
      const items = this.getComponents();
      return html`
        <div class="overlay-content kebab-menu-container">
          <md-list
            label="Kebab Menu Items"
            @list-item-change="${(event: CustomEvent) => {
              this.handleSelection(event.detail.selected);
            }}"
          >
            ${items.map((componentName: string) => {
              return this.renderElements(componentName);
            })}
          </md-list>
        </div>
      `;
    }
  }
}
