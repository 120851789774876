/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin, t } from "@/mixins/i18nMixin";
import "@momentum-ui/web-components";
import { customElement, html, LitElement } from "lit-element";
import style from "../../NotesWidget.scss";
import { checkIfSpaceOrEnterKeydown } from "../../Utils";

export namespace NotesEditorClose {
  /**
   * @element agentx-wc-notes-editor-close
   * @fires close
   */
  @customElement("agentx-wc-notes-editor-close")
  export class Element extends i18nMixin(LitElement) {
    private readonly handleCloseKeyDown = (event: KeyboardEvent) => {
      if (checkIfSpaceOrEnterKeydown(event.code)) {
        this.handleNotesCloseUpdate(event);
      }
    };

    private handleNotesCloseUpdate(event: CustomEvent | KeyboardEvent) {
      event.stopPropagation();
      this.dispatchEvent(new CustomEvent("close", {}));
    }

    static get styles() {
      return style;
    }

    render() {
      return html`
        <md-tooltip message="${t("app:notes.close")}" placement="top">
          <md-icon
            ariaLabel="${t("app:notes.close")}"
            class="notes-editor-close-btn"
            tabindex="0"
            name="icon-cancel_12"
            @icon-click=${(event: CustomEvent) => this.handleNotesCloseUpdate(event)}
            @keydown=${(event: KeyboardEvent) => this.handleCloseKeyDown(event)}
          ></md-icon>
        </md-tooltip>
      `;
    }
  }
}
