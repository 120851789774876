import { i18nMixin } from "@/mixins/i18nMixin";
import { logger } from "@/sdk";
import { SERVICE, getTrackingIdFromErrorObject } from "@agentx/agentx-services";
import { GridOptions, IServerSideGetRowsParams } from "ag-grid-enterprise";
import { LitElement, customElement, html, internalProperty, property, query } from "lit-element";
import { DateTime } from "luxon";
import style from "./QualityMonitoring.scss";
import "./components/AGGrid";
import "./components/EmptyGrid";
import "./components/GridError";
import "./components/GridHeader";
import "./components/Recording";
import { getBaseGridConfigs } from "./config";
import {
  GRID,
  MAX_ROWS_IN_RESPONSE,
  QMW_ACTIONS_BTN_CLICK,
  QMW_ERR_RETRY,
  QMW_FILTERS_UPDATED,
  QMW_RECORDINGS_MODAL_CLOSE_BTN_CLK
} from "./constants";
import { errorIsRetryable, wait } from "./utils";

export namespace QualityMonitoringWidget {
  @customElement("agentx-wc-quality-monitoring-grid")
  export class Element extends i18nMixin(LitElement) {
    @query("#agentx-wc-qm-grid-container") _gridDiv!: HTMLElement;

    @property({ type: Boolean }) isDarkMode = false;
    @property({ type: Boolean }) isLoggedIntoStation = false;
    @property({ type: Boolean }) isQMWShowConsultRecordingsEnabled = false;

    @internalProperty() isGridEmpty = false;
    // filter defaults to the last 24 hour period
    @internalProperty() startTime = DateTime.local()
      .minus({ days: 1 })
      .toMillis();
    @internalProperty() endTime = DateTime.local()
      .minus({ seconds: 1 })
      .toMillis();
    @internalProperty() hasError = false;
    @internalProperty() trackingID = "";
    @internalProperty() activeSessionId = "";
    @internalProperty() activeAni = "";
    @internalProperty() activeAgentName = "";
    @internalProperty() isModalOpen = false;
    @internalProperty() modalOpenTime = 0;

    static get styles(): CSSResult {
      return style;
    }

    connectedCallback(): void {
      super.connectedCallback();
      SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.POST_INTERACTION_VISITED);
      this.addEventListener(QMW_ERR_RETRY, this.handleErrorResetTry);
      this.addEventListener(QMW_ACTIONS_BTN_CLICK, this.handleActionsBtnClick);
      this.addEventListener(QMW_RECORDINGS_MODAL_CLOSE_BTN_CLK, this.handleModalCloseClick);
      this.addEventListener(QMW_FILTERS_UPDATED, this.handleFiltersUpdated);
    }

    protected firstUpdated(changedProperties: PropertyValues): void {
      super.firstUpdated(changedProperties);
      this.getLocalFilters();
    }

    disconnectedCallback(): void {
      super.disconnectedCallback();
      this.removeEventListener(QMW_ERR_RETRY, this.handleErrorResetTry);
      this.removeEventListener(QMW_ACTIONS_BTN_CLICK, this.handleActionsBtnClick);
      this.removeEventListener(QMW_RECORDINGS_MODAL_CLOSE_BTN_CLK, this.handleModalCloseClick);
      this.removeEventListener(QMW_FILTERS_UPDATED, this.handleFiltersUpdated);
    }

    getLocalFilters(): void {
      const storedFilters = JSON.parse(localStorage.getItem("qmwFilters") || "{}");
      if (Object.keys(storedFilters).length) {
        this.startTime = storedFilters.startTime;
        this.endTime = storedFilters.endTime;
      }
    }

    setLocalFilters(): void {
      localStorage.setItem(
        "qmwFilters",
        JSON.stringify({
          startTime: this.startTime,
          endTime: this.endTime
        })
      );
    }

    getHasError(): boolean {
      return this.hasError;
    }

    setHasError(hasError: boolean): void {
      this.hasError = hasError;
    }

    getIsGridEmpty(): boolean {
      return this.isGridEmpty;
    }

    setIsGridEmpty(isGridEmpty: boolean): void {
      this.isGridEmpty = isGridEmpty;
    }

    getTrackingId(): string {
      return this.trackingID;
    }

    setTrackingID(trackingID: string): void {
      this.trackingID = trackingID;
    }

    getActiveSessionId(): string {
      return this.activeSessionId;
    }

    setActiveSessionId(sessionId: string): void {
      this.activeSessionId = sessionId;
    }

    getActiveAni(): string {
      return this.activeAni;
    }

    setActiveAni(ani: string): void {
      this.activeAni = ani;
    }

    getActiveAgentName(): string {
      return this.activeAgentName;
    }

    setActiveAgentName(agentName: string): void {
      this.activeAgentName = agentName;
    }

    getIsModalOpen(): boolean {
      return this.isModalOpen;
    }

    setIsModalOpen(isModalOpen: boolean): void {
      this.isModalOpen = isModalOpen;
      this.modalOpenTime = Date.now();
    }

    onGridReady = (params: GridOptions): void => {
      params?.api?.setServerSideDatasource(this.createDatasource());
    };

    gridOptions: GridOptions = {
      ...getBaseGridConfigs(),
      onGridReady: this.onGridReady
    };

    handleErrorResetTry(): void {
      this.setHasError(false);
      this.setTrackingID("");
    }

    handleActionsBtnClick(e: { [key: string]: any }): void {
      this.setActiveSessionId(e?.detail?.sessionId);
      this.setActiveAni(e?.detail?.ani);
      this.setActiveAgentName(e?.detail?.agentName);
      this.setIsModalOpen(true);
    }

    handleModalCloseClick(e: { [key: string]: any }): void {
      this.setActiveSessionId("");
      this.setActiveAni("");
      this.setActiveAgentName("");

      const timeSpent = (Date.now() - this.modalOpenTime) / 1000;
      this.setIsModalOpen(false);

      const numConsultRecordings = e?.detail?.numConsultRecordings;
      const consultRecordingPlayed = e?.detail?.consultRecordingPlayed;
      const mainRecordingPlayed = e?.detail?.mainRecordingPlayed;

      SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.PI_RECORDING_DETAILS_MODAL_CLOSED, {
        [SERVICE.telemetry.MIX_PROPS.PI_MAIN_RECORDING_PLAYED]: mainRecordingPlayed,
        [SERVICE.telemetry.MIX_PROPS.PI_NUM_CONSULT_RECORDINGS]: numConsultRecordings,
        [SERVICE.telemetry.MIX_PROPS.PI_CONSULT_RECORDING_PLAYED]: consultRecordingPlayed,
        [SERVICE.telemetry.MIX_PROPS.TOTAL_TIME_SPENT]: Number(timeSpent.toFixed(2))
      });
    }

    handleFiltersUpdated(e: { [key: string]: any }): void {
      this.startTime = e?.detail?.startTime;
      this.endTime = e?.detail?.endTime;
      this.setLocalFilters();
      this.gridOptions.api?.onFilterChanged();
      this.gridOptions = { ...this.gridOptions };
      const totalTimeInSeconds = (this.endTime - this.startTime) / 1000;
      SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.PI_TIME_FILTER_SELECTED, {
        [SERVICE.telemetry.MIX_PROPS.PI_TIME_FILTER_DURATION_SELECTED]: totalTimeInSeconds
      });
    }

    createDatasource = (): { getRows: (params: IServerSideGetRowsParams) => Promise<void> } => {
      const getPage = (startRow: number | undefined = 0): number => {
        if (startRow && startRow !== 0) {
          return startRow / MAX_ROWS_IN_RESPONSE;
        } else {
          return startRow;
        }
      };

      const fetchData = async (params: IServerSideGetRowsParams): Promise<void> => {
        const startTime = this.startTime;
        const endTime = this.endTime;
        const pageNumber = getPage(params.request.startRow);

        const searchData = await SERVICE.qmw.fetchTasks({ startTime, endTime, pageNumber });
        logger.info("[App:QMW] Fetched tahoe data succesfully");

        const taskData = searchData.data.task.tasks;
        const pageInfoData = searchData.data.task.pageInfo;
        this.setHasError(false);
        this.setIsGridEmpty(taskData.length ? false : true);

        if (pageInfoData.hasNextPage) {
          params.success({ rowData: taskData });
        } else {
          let rowCount = taskData.length;
          if (pageNumber !== 0) {
            const previousRowCount = pageNumber * MAX_ROWS_IN_RESPONSE;
            rowCount = rowCount + previousRowCount;
          }
          params.success({ rowData: taskData, rowCount });
        }
      };
      return {
        getRows: async (params: IServerSideGetRowsParams): Promise<void> => {
          try {
            await fetchData(params);
          } catch (err) {
            if (errorIsRetryable((err as { [key: string]: any })?.details)) {
              logger.info(`[App:QMW] Failed to fetch tahoe data due to rate limit. Retrying`);
              await wait((err as { [key: string]: any })?.details);
              await fetchData(params);
            } else {
              logger.error(`[App:QMW] Failed to fetch tahoe data`);
              params.fail();
              this.setHasError(true);
              this.setTrackingID(getTrackingIdFromErrorObject(err));
            }
          }
        }
      };
    };

    renderComponent(): TemplateResult {
      if (this.hasError) {
        return html`
          <agentx-wc-qmw-grid-error .trackingID=${this.trackingID} .errorElement=${GRID}></agentx-wc-qmw-grid-error>
        `;
      } else if (this.isGridEmpty) {
        return html`
          <agentx-wc-qmw-empty-grid .emptyElement=${GRID}></agentx-wc-qmw-empty-grid>
        `;
      } else {
        return html`
          <agentx-wc-ag-grid
            .gridOptions=${this.gridOptions}
            .isDarkMode=${this.isDarkMode}
            .isLoggedIntoStation=${this.isLoggedIntoStation}
          ></agentx-wc-ag-grid>
        `;
      }
    }

    render(): TemplateResult {
      return html`
        <div class="qm-wrapper">
          <agentx-wc-qmw-filter-header
            .startDate=${this.startTime}
            .endDate=${this.endTime}
          ></agentx-wc-qmw-filter-header>
          ${this.renderComponent()}
          <agentx-wc-qmw-recording-details
            sessionId=${this.activeSessionId}
            ani=${this.activeAni}
            agentName=${this.activeAgentName}
            .isModalOpen=${this.isModalOpen}
            .showConsultRecordings=${this.isQMWShowConsultRecordingsEnabled}
          ></agentx-wc-qmw-recording-details>
        </div>
      `;
    }
  }
}
