export const NOTE_COLORS: Record<string, string> = {
  MINT: "#30C9B0",
  CYAN: "#22C7D6",
  VIOLET: "#C7A5FA",
  PURPLE: "#F294F1",
  ORANGE: "#FF9D52",
  GOLD: "#D6B220",
  DEFAULT: "#B2B2B2"
};

export const NOTES_CONTENT_CHARACTER_LIMIT = 1000;
export const NOTES_TITLE_CHARACTER_LIMIT = 50;
export const MAX_NOTES_COUNT = 500;
export const SAVE_NOTES_DELAY = 2000; //2 sec
export const HEADER_ACTIONS_OVERLAY_WIDTH = "184px";

export const NOTES_WIDGET_LS = "agentx-notes";

export const ENTER_KEY_CODE = "Enter";
export const SPACE_KEY_CODE = "Space";
export const ESC_KEY_CODE = "Escape";
export const TAB_KEY_CODE = "Tab";

export const DELETE = "delete";
export const DOWNLOAD = "download";
export const DEFAULT = "DEFAULT";
export const NEW_NOTE = "New Note";
export const NO_CONTENT_ADDED = "No content added yet";

export const DATE = "date";
export const FAVORITES = "favorites";
export const COLORS = "colors";
export const CLEAR_FILTER = "clear-filter";

export const EMPTY_STATE_ILLUSTRATION = "Lighthouse_anding";
export const DOTTED_SEPARATOR = "........................................";
