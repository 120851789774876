/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserStationLoginTypes } from "@/index";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { logger } from "@/sdk";
import { isWebRtcSupportedBrowser, stripSpecialChar } from "@/utils/helpers";
import { formRoleNameForTelemetry, getTrackingIdFromErrorObject, SERVICE, Service } from "@agentx/agentx-services";
import "@momentum-ui/web-components";
import { Decorator, Err } from "@uuip/unified-ui-platform-sdk";
import { customElement, html, internalProperty, LitElement, property, PropertyValues } from "lit-element";
import { nothing } from "lit-html";
import {
  AGENT,
  AGENT_AND_SUPERVISOR,
  DEFAULT_DEVICE_TYPE,
  INITIATE_MS_AUTH_LOGIN,
  SUPERVISOR,
  SUPERVISOR_AUTO_LOGIN_EVENT,
  WEB_RTC_PREFIX
} from "../../constants";
import "../EmergencyNotification/EmergencyNotificationContent";
import style from "./userStationLogin.scss";
import { fetchAuxCodeValues } from "./utils";

type ErrDetails = { status: number; type: string; trackingId: string };
type ESuccess = UserStationLoginTypes.ESuccess;
type EError = UserStationLoginTypes.EError;
type ECancel = UserStationLoginTypes.ECancel;
type EStationLoginSubmit = UserStationLoginTypes.EStationLoginSubmit;

const cancelButtonLabel = "app:common.cancelButton";
const signOutButtonLabel = "app:common.signOutButton";
const EventLogoutConfirm = "logout-confirm";
const ErrId = "Service.aqm.configs.application";
const EventNameError = "login-error";
const phoneFormats: UserStationLoginTypes.PhoneFormats = { international: "International", us: "US", other: "Other" };
const AdvancedHeader = "";
export namespace UserStationLogin {
  /**
   * @element agentx-wc-user-station-login
   * @fires login-success
   * @fires @login-error
   */
  @customElement("agentx-wc-user-station-login")
  export class Element extends i18nMixin(LitElement) {
    @property({ attribute: false }) isModalOpen = false;
    @property({ type: Boolean }) isAdvancedHeader = false;
    @property({ type: Boolean }) isDesktopReasonCodeEnabled = false;
    @property() getErrorMsgForReasonCode: any = Function;
    @property({ type: String }) roleFromMe = "";
    @property() organizationId = "";
    @property() meUserId = "";

    @property({ type: Boolean }) isMonitoringEnabled = false;
    @property({ type: String }) selectedRoleName = AGENT;
    @property({ type: Boolean }) isESDStationLoginFlowEnabled = false;
    @property({ type: Boolean }) intDiallingEnabledFF = false;
    @property({ type: Boolean }) isDesktopMaxSessionLimitsEnabled = false;
    @property({ type: Boolean }) isDesktopMaerskAcqueonTeamId = false;
    @property({ type: Boolean }) isDesktopMSStateSyncEnabled = false;
    @property({ type: Boolean }) isDesktopEmergencyNotificationEnabled = false;

    @property() phoneFormat: UserStationLoginTypes.PhoneFormat =
      this.isESDStationLoginFlowEnabled || this.intDiallingEnabledFF ? phoneFormats.international : phoneFormats.us;
    @property({ type: String }) phoneNumber = "";
    @property({ type: Boolean }) isRememberMyCredentials = false;

    @property({ type: Boolean }) isWebRTCEnabled = false;
    @property({ type: String }) deviceType: Service.Aqm.Agent.DeviceType = SERVICE.constants.LoginVoiceOptions.AGENT_DN;
    @property({ type: String }) deviceId: string | null = "";
    @property({ type: String }) reasonCode: any | null = "";
    @property({ type: Boolean }) isMaerskEnabled = false; // Default FF value is turned OFF.
    @property({ type: Boolean }) isDesktopAuxCodesInfiniteScrollEnabled = false;
    @property({ type: Boolean }) isCMSConfigMigratedFF = false; // Default FF value is turned OFF.
    @property({ type: Boolean }) showEmergencyNotificationWebrtc = false;
    @property({ type: Boolean }) isEmergencyModalAlreadyDisplayed = false;

    @internalProperty() private isInitializing = true;
    @internalProperty() private isSubmitBusy = false;
    @internalProperty() private isSubmitDisabled = true;
    @internalProperty() private isSubmitClicked = false;
    @internalProperty() private teamName = "";
    @internalProperty() private teamId = "";
    @internalProperty() private isTeamsEmpty = false;
    @internalProperty() private dialNumber = "";
    @internalProperty() private deviceTypeName = "";
    @internalProperty() private isDefaultDnSet = false;
    @internalProperty() private userRole: string[] = [];
    @internalProperty() private loginType = "DN"; // Used for telemetry.
    @internalProperty() private usesOtherDN = false;
    @internalProperty() private dialNumberValueForNonUS = "";
    @internalProperty() private defaultDn = "";
    @internalProperty() private isInternational = true;
    @internalProperty() private isPreferenceSaved = false;
    @internalProperty() private loginVoiceOptions: Service.Conf.LoginOption[] = [];
    @internalProperty() private auxCodeConfig: {
      isError: boolean;
      fetchCompleted: boolean;
    } = {
      isError: false,
      fetchCompleted: false
    };
    @internalProperty() initialIdleCodes: Service.Aqm.Configs.agentStateIdleCodes | undefined; // Used to store the initial set of idle codes.
    @internalProperty() fetchUniqueTeamIdError = false;

    // Station Login Error Flags
    @internalProperty() private isErrUnknown = false;
    @internalProperty() private isErrDNRegister = false;
    @internalProperty() private isDNAlreadyInUse = false;
    @internalProperty() private isErrPhone = false;
    @internalProperty() private isErrPhoneExt = false;
    @internalProperty() private isExtAlreadyInUse = false;
    @internalProperty() private isErrExtRegister = false;
    @internalProperty() private isDNRegisterTimeOut = false;
    @internalProperty() private isMaximumSessionsReached = false;
    @internalProperty() private isWebRtcUnsupportedBrowser = false;
    @internalProperty() private isMicrophoneAccessDenied = false;

    // TODO: The types from Service.Conf holds good for Agent. Supervisor might not have all fields.
    profile: Service.Conf.Profile | null = null;
    teams: Service.Conf.Team[] = [];
    defaultAuxCode = "";
    extensionNumber = "";
    trackingId = "";
    isExtensionUsed = false;
    countryCodeValue = "+1"; // initial country code of US.
    countryCallingCode = "+1, United States of America, US";

    lang = localStorage.getItem("lng") || "en";

    advanceHeaderTracker(AdvancedHeader: string) {
      if (SERVICE.featureflag.isAdvancedHeaderEnabled() && this.isAdvancedHeader) {
        AdvancedHeader = SERVICE.telemetry.MIX_PROPS.ADVANCED_HEADER_ENABLED;
        return AdvancedHeader;
      } else {
        AdvancedHeader = SERVICE.telemetry.MIX_PROPS.ADVANCED_HEADER_DISABLED;
        return AdvancedHeader;
      }
    }

    // ROST: create decorator for custom event
    private evt<T extends { detail: any }>(name: string, detail: T["detail"]) {
      this.dispatchEvent(new CustomEvent(name, { bubbles: true, composed: true, detail }));
      logger.info("[StationLogin]: Dispatching event", name, detail);
    }

    // TODO: login type in case of reload might not send correct data. Check.
    private success(data: ESuccess["detail"], isReload: boolean) {
      data.loginType = this.loginType;
      data.advancedHeader = this.advanceHeaderTracker(AdvancedHeader);
      data.isReload = isReload;

      if (this.isDesktopAuxCodesInfiniteScrollEnabled) {
        data.initialIdleCodes = this.initialIdleCodes;
      }

      this.evt<ESuccess>("login-success", data);
      SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.DARK_MODE_TOGGLE);

      this.isModalOpen = false;
    }

    static get styles() {
      return style;
    }

    protected updated(changedProperties: PropertyValues) {
      super.updated(changedProperties);
      changedProperties.forEach((oldValue, name) => {
        if (name === "isModalOpen" && oldValue !== undefined) {
          if (this.isModalOpen) {
            //Adding this logs to debug StaionLogin issues CX-5123
            logger.info(`[StationLogin] StationLogin Modal Opened`);
          } else if (this.isModalOpen === false) {
            logger.info(`[StationLogin] StationLogin Modal Closed`);
          }
        }
      });
    }

    getAuxCodeParameters = () => {
      return {
        idleCodeConfig: {
          accessType: this.profile?.idleCodesAccess,
          ids: this.profile?.idleCodesList
        },
        wrapUpCodeConfig: {
          accessType: this.profile?.wrapUpData?.wrapUpProps?.wrapUpCodeAccess,
          ids: this.profile?.wrapUpData?.wrapUpProps?.wrapUpCodesList || []
        }
      };
    };

    setDefaultAuxCode() {
      if (this.profile) {
        const defaultStateOption: Service.Aqm.Configs.Entity | undefined = this.profile.idleCodes?.find(
          (option: any) => option.isDefault
        );
        if (defaultStateOption) {
          this.defaultAuxCode = defaultStateOption.id.toString();
        }
      }
    }

    updateAuxCodes(idleCodes: Service.Aqm.Configs.Entity[], wrapUpCodes: Service.Aqm.Configs.Entity[]) {
      if (this.profile) {
        SERVICE.conf.updateAuxCodeConfig(idleCodes, wrapUpCodes);
        this.profile = SERVICE.conf.profile; // Update the profile values after fetching the aux codes.
        if (!this.isDesktopAuxCodesInfiniteScrollEnabled) {
          this.setDefaultAuxCode();
        }
      }
    }

    async fetchTeamDataById(selectTeamDbId: string) {
      if (!this.profile) {
        return false;
      }
      try {
        const filterPayload = { dbId: selectTeamDbId };
        const team: Service.Cms.TeamResponseData[] = await SERVICE.cms.fetchAllTeams(this.profile.orgId, filterPayload);
        logger.info(
          `event=fetchTeamsById for acqueon success:  ${JSON.stringify(team)} for orgId ${
            this.profile.orgId
          } and teamId: ${selectTeamDbId}`
        );
        if (Array.isArray(team) && team.length > 0) {
          return team[0].id;
        } else {
          this.isErrUnknown = true;
          this.fetchUniqueTeamIdError = true;
          return false;
        }
      } catch (error) {
        logger.error(
          `event=fetchTeamsById for acqueon error:  ${JSON.stringify(error)} for orgId ${
            this.profile.orgId
          } and teamId: ${selectTeamDbId}`
        );
        this.isErrUnknown = true;
        this.fetchUniqueTeamIdError = true;
        return false;
      }
    }

    getDefaultWorkTypeCode = (
      auxCodesList: Service.Aqm.Configs.DefaultAuxCodes[],
      workTypeCode: Service.Aqm.Configs.AuxCodeType
    ) => {
      const response = auxCodesList.find(auxCode => auxCode.workTypeCode === workTypeCode);
      return {
        id: response?.id ?? "",
        name: response?.name ?? ""
      };
    };

    setDefaultAuxCodes(
      currentDefaultAuxCodes: Service.Aqm.Configs.DefaultAuxCodes[] | undefined,
      agentStateIdleCodes: Service.Aqm.Configs.agentStateIdleCodes
    ): void {
      this.initialIdleCodes = agentStateIdleCodes;

      if (currentDefaultAuxCodes && currentDefaultAuxCodes?.length > 0) {
        // Set default idle code to conf-service
        const defaultIdleCode = this.getDefaultWorkTypeCode(currentDefaultAuxCodes, "IDLE_CODE");
        if (defaultIdleCode) {
          this.defaultAuxCode = defaultIdleCode?.id.toString() ?? "";
          SERVICE.conf.setDefaultIdleCode(defaultIdleCode);
        } else {
          logger.warn("event=fetchAuxCodes | Default Idle code not found");
        }

        // Set default wrap up code to conf-service
        const defaultWrapUpCode = this.getDefaultWorkTypeCode(currentDefaultAuxCodes, "WRAP_UP_CODE");
        if (defaultWrapUpCode) {
          SERVICE.conf.setDefaultWrapUpCode(defaultWrapUpCode);
        } else {
          logger.warn("event=fetchAuxCodes | Default WrapUp code not found");
        }
      }
    }

    async fetchAuxCodes(shouldFetchDefaultIdleCode = false): Promise<boolean> {
      try {
        const { idleCodeConfig, wrapUpCodeConfig } = this.getAuxCodeParameters();
        const response = await fetchAuxCodeValues(
          this.organizationId,
          idleCodeConfig,
          wrapUpCodeConfig,
          this.isDesktopAuxCodesInfiniteScrollEnabled,
          shouldFetchDefaultIdleCode
        );

        const [idleCodes, wrapUpCodes, defaultAuxCodes] = response;
        const allWrapUpCodes = wrapUpCodes as Service.Aqm.Configs.Entity[];
        if (this.isDesktopAuxCodesInfiniteScrollEnabled) {
          const currentDefaultAuxCodes = defaultAuxCodes as Service.Aqm.Configs.DefaultAuxCodes[] | undefined;
          const agentStateIdleCodes = idleCodes as Service.Aqm.Configs.agentStateIdleCodes;
          this.setDefaultAuxCodes(currentDefaultAuxCodes, agentStateIdleCodes);
          // TODO: remove updateAuxCodes from here once wrapup infinitescroll is implemented
          this.updateAuxCodes(agentStateIdleCodes.data, allWrapUpCodes);
        } else {
          const allIdleCodes = idleCodes as Service.Aqm.Configs.Entity[];
          this.updateAuxCodes(allIdleCodes, allWrapUpCodes);
        }

        this.auxCodeConfig.fetchCompleted = true;
        this.auxCodeConfig.isError = false;
        return true;
      } catch (error) {
        this.trackingId = getTrackingIdFromErrorObject(error);
        this.auxCodeConfig.fetchCompleted = false;
        this.auxCodeConfig.isError = true;
        this.isErrUnknown = true;
        return false;
      }
    }

    connectedCallback() {
      super.connectedCallback();
      this.init();
      window.addEventListener(SUPERVISOR_AUTO_LOGIN_EVENT, this.autologin.bind(this));
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      window.removeEventListener(SUPERVISOR_AUTO_LOGIN_EVENT, this.autologin.bind(this));
    }

    private getTrackingID(trackingIdValue: string) {
      return trackingIdValue.split("_").length > 1 ? trackingIdValue.split("_")[1] : trackingIdValue.split("_")[0];
    }

    private getIfSupervisorRole(): boolean {
      return this.roleFromMe.toLocaleLowerCase() === SUPERVISOR.toLocaleLowerCase();
    }

    isOnlySupervisor = (): boolean => {
      return this.getIfSupervisorRole() && this.isTeamsEmpty;
    };

    private getDeviceType(
      deviceType: Service.Aqm.Agent.DeviceType | undefined,
      isExtension: boolean
    ): Service.Aqm.Agent.DeviceType {
      if (deviceType) {
        return this.isWebRTCEnabled ? deviceType?.toUpperCase() : deviceType;
      }
      return isExtension ? SERVICE.constants.LoginVoiceOptions.EXTENSION : SERVICE.constants.LoginVoiceOptions.AGENT_DN;
    }

    private getLoginType(isExtension: boolean, roles: string[] | undefined) {
      const mode = isExtension ? "EXT" : "DN";
      // Agent & Agent + Supervisor: DN and Team should be present.
      // Only Supervisor: If monitoring enabled DN/EXT should be present.
      const isDnExtEnabled = roles?.includes(AGENT) || this.isMonitoringEnabled;
      return isDnExtEnabled ? mode : "NA";
    }

    /**
     * Determine userRole and update STORE.agent.userRole.
     * userRole possible values: ["agent"], ["supervisor"], and ["agent", "supervisor"]
     * Order ["agent", "supervisor"] is important for comparison done later.
     */
    private updateUserRole() {
      let newUserRole: string[];

      if (this.getIfSupervisorRole() && this.isESDStationLoginFlowEnabled) {
        newUserRole = this.isTeamsEmpty ? [SUPERVISOR] : [AGENT, SUPERVISOR];
      } else {
        newUserRole = [AGENT];
      }

      this.userRole = newUserRole;
    }

    dispatchLoginError = (ctx: Element, details: ErrDetails, errorID: Err.Id) => {
      ctx.evt<EError>(EventNameError, {
        id: errorID,
        message:
          details.status && details.status === 400 && details.type === "Unauthorized"
            ? "Token Unauthorized"
            : "Config Failed",
        trackingId: this.getTrackingID(details.trackingId)
      });
    };

    // Error Handlers For SERVICE.conf.fetchProfile()
    @Decorator.Handle<Element>({
      "Service.aqm.configs.application": ({ ctx, details }) => {
        logger.error(`event=agentConfigError | [StationLogin] Failed application request  `, details);
        ctx.dispatchLoginError(ctx, details, ErrId);
      },
      "Service.abs.fetchCmsInitConfig": ({ ctx, details }) => {
        logger.error(`event=agentConfigError | [StationLogin] Failed agent-config proxy request `, details);
        ctx.dispatchLoginError(ctx, details, "Service.abs.fetchCmsInitConfig");
      },
      "Service.aqm.reqs.TimeoutEvent": ({ ctx, details }) => {
        logger.error(`event=agentConfigError | [StationLogin] Update agent profile event timed out: `, details);
        const errorDetails = {
          type: "Timeout",
          status: 408, // request timeout. In this case notifs response timed out
          trackingId: "" // To do to fetch tracking id on timeout of updateAgentProfile
        };
        ctx.dispatchLoginError(ctx, errorDetails, ErrId);
      },
      "Service.aqm.reqs.Timeout": ({ ctx, details }) => {
        logger.error(`event=agentConfigError | [StationLogin] Agent Property Changed event timed out: `, details?.key);
        const errorDetails = {
          type: "Timeout",
          status: 408,
          trackingId: getTrackingIdFromErrorObject(details)
        };
        ctx.dispatchLoginError(ctx, errorDetails, ErrId);
      }
    })
    private async init() {
      logger.info("event=stationLoginInit | [StationLogin] Requesting initial configs");
      const cmsConfigFlagEnabled = this.isCMSConfigMigratedFF;
      this.profile = SERVICE.conf.profile
        ? SERVICE.conf.profile
        : await SERVICE.conf.fetchProfile(
            cmsConfigFlagEnabled,
            this.organizationId,
            this.meUserId,
            this.getIfSupervisorRole()
          );

      if (this.isWebRTCEnabled && this.profile.loginVoiceOptions?.length === 0) {
        this.evt<ECancel>("no-voice-options-error", {
          header: "",
          message: t("app:stationLogin.noLoginVoiceOptionsError"),
          noExitOnEsc: true,
          buttons: [{ type: "ok", label: t(signOutButtonLabel), onClick: this.logout.bind(this) }]
        });
        return;
      }

      this.loginVoiceOptions = this.profile.loginVoiceOptions || DEFAULT_DEVICE_TYPE;
      this.evt<{ detail: { analyzerId: string } }>("update-analyser-id", {
        analyzerId: this.profile.analyserUserId ?? this.profile.agentDbId
      });

      SERVICE.telemetry.setUserProfile(this.profile.agentId, {
        AgentId: this.profile.agentId,
        OrgId: this.profile.orgId,
        SiteId: this.profile.siteId,
        TenantId: this.profile.enterpriseId
      });

      SERVICE.telemetry.register({
        TenantId: this.profile.enterpriseId,
        SiteId: this.profile.siteId,
        OrgId: this.profile.orgId,
        Locale: navigator.language,
        [SERVICE.telemetry.MIX_PROPS.PLATFORM_TYPE]: window.matchMedia("(display-mode: standalone)").matches
          ? "App"
          : "Browser"
      });

      this.profile.teams.sort((t1, t2) => {
        return t1.teamName.localeCompare(t2.teamName);
      });
      this.teams = this.profile.teams;
      this.isTeamsEmpty = !(this.teams && this.teams.length > 0 && this.teams[0]?.teamId);
      const defaultTeam = this.teams && this.teams.length > 0 ? this.teams[0].teamName : "";
      this.teamName = this.isTeamsEmpty ? "" : defaultTeam;
      this.teamId = this.isTeamsEmpty ? "" : this.teams[0].teamId;
      this.defaultDn = this.profile.defaultDn || "";
      this.dialNumber = this.dialNumberValueForNonUS = this.extensionNumber = this.phoneNumber = this.defaultDn;
      this.isDefaultDnSet = this.profile.defaultDn?.length > 0 ? this.profile.forceDefaultDnForAgent : false;
      this.isMonitoringEnabled = this.profile.isCallMonitoringEnabled || false;
      this.isInternational = this.profile.defaultDn?.length > 0 ? false : true;
      if (
        !this.isOnlySupervisor() &&
        (this.profile?.microsoftConfig?.showUserDetailsMS ||
          (this.isDesktopMSStateSyncEnabled && this.profile?.microsoftConfig?.stateSynchronizationMS))
      ) {
        // Show user details in MS
        logger.info("[Microsoft Auth]: Dispatching initiate-microsoft-auth-flow event");
        this.evt(INITIATE_MS_AUTH_LOGIN, { orgId: this.profile.orgId, microsoftConfig: this.profile?.microsoftConfig });
      }

      if (!cmsConfigFlagEnabled || !this.isMaerskEnabled) {
        this.setDefaultAuxCode();
      }
      if (this.isESDStationLoginFlowEnabled || this.intDiallingEnabledFF) {
        this.phoneFormat =
          this.isDefaultDnSet || this.profile.defaultDn?.length > 0 ? phoneFormats.other : phoneFormats.international;
      }
      this.updateUserRole();
      this.requestUpdate();

      SERVICE.telemetry.timeEvent(SERVICE.telemetry.MIX_EVENT.STATION_LOGIN, [
        SERVICE.telemetry?.SERVICE_PROVIDERS?.mixpanel,
        SERVICE.telemetry?.SERVICE_PROVIDERS?.prometheus
      ]);
      logger.info("[StationLogin] : requesting aqm.reload");
      await this.agentReload();
      SERVICE.aqm.agent.eAgentDNRegisterFailure.listen((data: any) => {
        this.isSubmitBusy = false;
        const isNumberAlreadyInUse = data.data.reason === "DUPLICATE_LOCATION" && data.data.reasonCode === 400;

        if (this.isExtensionUsed) {
          this.isErrExtRegister = true;
          if (isNumberAlreadyInUse) {
            this.isExtAlreadyInUse = true;
          }
          this.isErrPhoneExt = false;
        } else {
          this.isErrDNRegister = true;
          if (isNumberAlreadyInUse) {
            this.isDNAlreadyInUse = true;
          }
          this.isErrPhone = false;
        }
        this.isErrUnknown = false;
        this.trackingId = this.getTrackingID(data.data.trackingId);
      });
    }

    private async fetchSavedPreferences() {
      try {
        const userPreferences = await SERVICE.userPrefs.fetchUserPreferences({
          orgId: this.organizationId,
          userId: this.meUserId
        });
        logger.info("event=userPreferencesFetchSuccess | fetching User Preferences Success : ", userPreferences);
        if (userPreferences) {
          this.isPreferenceSaved = true;
          const desktopPreference = JSON.parse(userPreferences.desktopPreference);
          this.evt<{ detail: Service.userPrefs.savedUserPreferencesResponse }>(
            "update-user-preference-storage",
            userPreferences
          );
          if (desktopPreference) {
            this.selectedRoleName = desktopPreference.selectedRoleName;
            this.teamId = desktopPreference.teamId;
            const teamName = this.teams.find(team => team.teamId === this.teamId)?.teamName;
            if (teamName) {
              this.teamName = teamName;
            }
            this.extensionNumber = this.isDefaultDnSet
              ? this.profile?.defaultDn
              : desktopPreference.extensionNumber || this.profile?.defaultDn;
            this.dialNumberValueForNonUS = this.isDefaultDnSet
              ? this.profile?.defaultDn
              : desktopPreference.dialNumberValueForNonUS || this.profile?.defaultDn;
            this.isExtensionUsed = desktopPreference.isExtension;
            this.isInternational = this.isDefaultDnSet ? false : desktopPreference.isInternational;
            this.phoneNumber = this.isInternational
              ? desktopPreference.internationalPhoneNumber
              : this.profile?.defaultDn;
            this.phoneFormat =
              !this.isDefaultDnSet && desktopPreference.isInternational
                ? phoneFormats.international
                : phoneFormats.other;
            this.countryCallingCode = desktopPreference.countryCallingCode || this.countryCallingCode;
            this.countryCodeValue = desktopPreference.countryCodeValue || this.countryCodeValue;
            this.isRememberMyCredentials = desktopPreference.isRememberMyCredentials;
            this.deviceType = this.getDeviceType(desktopPreference.deviceType, desktopPreference.isExtension);
            this.deviceId = desktopPreference.deviceId;
            this.isEmergencyModalAlreadyDisplayed = desktopPreference.isEmergencyModalAlreadyDisplayed;
          }
        } else {
          this.isPreferenceSaved = false;
        }
      } catch (error) {
        this.isPreferenceSaved = false;
        logger.error("event=userPreferencesFetchFailed | fetching User Preferences Error : ", error);
        this.evt<Service.userPrefs.EUserPrefsSaved>("save-update-user-prefs-failed", {
          isPrefSaved: this.isPreferenceSaved
        });
      }
      // Updating this flag here to know if preferences are already saved or not.
      // Using this flag to perform save/update action when called from user station login & user profile settings
      this.evt<Service.userPrefs.EUserPrefsSaved>("update-flag-user-prefs-saved", {
        isPrefSaved: this.isPreferenceSaved
      });
    }

    autologin() {
      logger.info("[StationLogin] | Auto Login for supervisor only user initiated");
      const stationLoginData: UserStationLoginTypes.OnSubmitProfileDetails = {
        dnNumber: "",
        isExtension: false,
        teamId: null,
        teamName: null,
        roles: ["supervisor"],
        usesOtherDN: false,
        extensionNumber: "",
        dialNumberValueForNonUS: "",
        defaultDn: "",
        isDefaultDnSet: false,
        isInternational: false,
        phoneNumber: "",
        countryCallingCode: "",
        countryCodeValue: "",
        isRememberMyCredentials: false,
        deviceType: null,
        deviceId: ""
      };
      this.handleStationLoginSubmit({ profileDetail: stationLoginData, loggedInRole: this.userRole?.join("_") }, true);
    }
    getSpecificIdleCode = async (auxCodeId: string) => {
      try {
        const response: any = await SERVICE.abs.fetchIdleCodes({
          orgId: this.organizationId,
          accessType: "SPECIFIC",
          ids: [auxCodeId]
        });

        logger.info(`event=fetchIdleCodes | Successfully fetched idle code for subStatus`);
        return response;
      } catch (error) {
        logger.error("event=fetchIdleCodes | Error on fetching idle code for subStatus", error);
        throw error;
      }
    };

    // Error Handlers For SERVICE.aqm.agent.reload()
    @Decorator.Handle<Element>(async function() {
      if (this.userRole.join("_") === AGENT && this.isTeamsEmpty) {
        this.evt<EError>(EventNameError, {
          id: "Service.aqm.configs.teams",
          message: t("app:stationLogin.initialProfileTeamFailed"),
          trackingId: ""
        });
      } else if (!(this.userRole.join("_") === SUPERVISOR && !this.isMonitoringEnabled)) {
        if (this.isESDStationLoginFlowEnabled || this.intDiallingEnabledFF) {
          await this.fetchSavedPreferences();
          this.requestUpdate();
        }
        this.isModalOpen = true;
        this.isInitializing = false;
      } else {
        this.isModalOpen = false;
        this.autologin();
      }
    })
    private async agentReload() {
      if (!this.profile) {
        return;
      }
      const reload = await SERVICE.aqm.agent.reload();
      const shouldFetchAuxCodes = await this.shouldFetchAuxCodes();
      if (shouldFetchAuxCodes && !this.auxCodeConfig.fetchCompleted && !this.isOnlySupervisor()) {
        // For pure supervisor aux codes are not required.
        const isAuxCodeUpdated = await this.fetchAuxCodes(false);
        if (!isAuxCodeUpdated) {
          // Prevent the station login, Show the error message.
          this.evt("aux-code-error", {});
          return;
        }
      }

      const {
        dn,
        agentId,
        teamId,
        auxCodeId,
        lastStateChangeTimestamp,
        lastIdleCodeChangeTimestamp,
        lastStateChangeReason,
        profileType,
        channelsMap,
        interactionIds,
        isExtension,
        roles,
        deviceType,
        deviceId
      } = reload.data;
      this.loginType = this.getLoginType(isExtension, undefined);

      // Load the team unique id, when isDesktopMaerskAcqueonTeamId FF is on.
      // This ID is required to use the acquon team id.
      let teamUniqueId: string | boolean = "";
      if (this.isDesktopMaerskAcqueonTeamId && teamId) {
        teamUniqueId = await this.fetchTeamDataById(teamId);
        if (!teamUniqueId) {
          // On Reload, Prevent the station login, Show the error message.
          this.evt("acqueon-fetch-team-id-error", {});
          return;
        }
      }

      let subStatus = "Available";
      if (auxCodeId !== "0") {
        if (this.isDesktopAuxCodesInfiniteScrollEnabled) {
          const idleCodeStatus = this.initialIdleCodes?.data?.find(c => c.id.toString() === auxCodeId)?.name ?? "";
          if (!idleCodeStatus) {
            const response = await this.getSpecificIdleCode(auxCodeId);
            if (response && response?.length > 0) {
              subStatus = response[0].name;
            }
          } else {
            subStatus = idleCodeStatus;
          }
        } else {
          subStatus = this.profile.idleCodes.find(c => c.id.toString() === auxCodeId)?.name ?? "";
        }
      }
      const teamName = this.profile.teams.find(team => team.teamId === teamId)?.teamName || "";
      if (!teamName && !this.getIfSupervisorRole()) {
        this.evt<ECancel>(EventLogoutConfirm, {
          header: t("app:errorModal.header"),
          message: t("app:stationLogin.teamDeletedError"),
          noExitOnEsc: true,
          buttons: [{ type: "ok", label: t(signOutButtonLabel), onClick: this.logout.bind(this) }]
        });
        return;
      }
      this.success(
        {
          dn,
          agentId,
          usesOtherDN: this.usesOtherDN,
          isExtension,
          teamId,
          teamUniqueId,
          teamName,
          subStatus,
          lastStateChangeTimestamp,
          lastIdleCodeChangeTimestamp,
          lastStateChangeReason: lastStateChangeReason ? lastStateChangeReason : "",
          profileType,
          interactionIds,
          mmProfile: {
            voiceCount: channelsMap["telephony"]?.length,
            chatCount: channelsMap["chat"]?.length,
            emailCount: channelsMap["email"]?.length,
            socialCount: channelsMap["social"]?.length
          },
          defaultDn: this.defaultDn,
          isDefaultDnSet: this.isDefaultDnSet,
          userRole: this.userRole,
          deviceType,
          deviceId,
          selectedRoleName: roles ? roles.join("_") : ""
        },
        true
      );

      if (
        this.isWebRTCEnabled &&
        deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER &&
        !isWebRtcSupportedBrowser()
      ) {
        this.evt<ECancel>(EventLogoutConfirm, {
          header: t("app:errorModal.header"),
          message: t("app:stationLogin:browserUnsupportedError"),
          noExitOnEsc: true,
          buttons: [{ type: "ok", label: t(signOutButtonLabel), onClick: this.logout.bind(this) }]
        });
        return;
      }
      this.isInitializing = false;
    }

    private showEmergencyModal() {
      return (
        this.isDesktopEmergencyNotificationEnabled &&
        this.deviceTypeName === SERVICE.constants.LoginVoiceOptions.BROWSER &&
        !this.isEmergencyModalAlreadyDisplayed
      );
    }

    /** Inform UserProfile to start station login validation. */
    private onSubmit() {
      if (!this.profile) {
        logger.warn("[StationLogin] Fired onSubmit | No Config profile after StationLogin");
        return;
      }
      /** On click of 'Continue' button on the emergency modal after 1st login, isEmergencyModalAlreadyDisplayed to be set to true. 
      If true, the modal is NOT to be displayed again. If false, modal to be displayed again*/
      if (this.showEmergencyModal()) {
        this.isEmergencyModalAlreadyDisplayed = true;
      }
      this.isSubmitBusy = true;
      this.isSubmitClicked = !this.isSubmitClicked;
    }

    addTelemetryOnRoleSwitch(roleName: string, loggedInRole: string) {
      if (loggedInRole && loggedInRole !== roleName) {
        SERVICE.telemetry.track(
          SERVICE.telemetry.MIX_EVENT.SWITCH_USER_ROLE,
          {
            status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS,
            LastUserRole: loggedInRole === AGENT_AND_SUPERVISOR ? formRoleNameForTelemetry(loggedInRole) : loggedInRole,
            SwitchedRole: roleName === AGENT_AND_SUPERVISOR ? formRoleNameForTelemetry(roleName) : roleName,
            Reason: "[Station Login] User switched Role",
            AdvancedHeader: this.advanceHeaderTracker(AdvancedHeader)
          },
          [SERVICE.telemetry?.SERVICE_PROVIDERS?.mixpanel, SERVICE.telemetry?.SERVICE_PROVIDERS?.prometheus]
        );
      }
    }

    setErrorFlags() {
      this.isErrUnknown = true;
      this.isErrDNRegister = false;
      this.isDNAlreadyInUse = false;
      this.isErrPhone = false;
      this.isErrPhoneExt = false;
      this.isExtAlreadyInUse = false;
      this.isErrExtRegister = false;
      this.isSubmitBusy = false;
      this.isMaximumSessionsReached = false;
      this.isWebRtcUnsupportedBrowser = false;
      this.isMicrophoneAccessDenied = false;
    }

    private isUsesOtherFormat() {
      if (!this.isESDStationLoginFlowEnabled && this.intDiallingEnabledFF) {
        return this.phoneFormat === phoneFormats.other || this.countryCodeValue !== "+1";
      }
      return this.phoneFormat === phoneFormats.other;
    }

    private handleUnSupportedBrowserError() {
      logger.info("[WebRtc] : Unsupported Browser error");
      setTimeout(() => {
        this.isSubmitBusy = false;
        this.isErrUnknown = true;
        this.isWebRtcUnsupportedBrowser = true;
        this.trackingId = "";
      }, 200); // need a delay to update the attributes.
    }

    private handleMicrophoneAccessDenied() {
      logger.info("[WebRtc] : Microphone access denied error");
      this.isSubmitBusy = false;
      this.isErrUnknown = true;
      this.isMicrophoneAccessDenied = true;
      this.trackingId = "";
    }

    private dispatchWebCallingRegister(deviceId: string) {
      this.evt<{ detail: { deviceId: string } }>("register-web-calling", {
        deviceId
      });
    }
    private shouldFetchAuxCodes(): boolean {
      if (this.isCMSConfigMigratedFF && this.isDesktopAuxCodesInfiniteScrollEnabled) {
        return true;
      } else {
        return this.isCMSConfigMigratedFF && this.isMaerskEnabled;
      }
    }

    // Error Handlers For SERVICE.aqm.agent.stationLogin()
    @Decorator.Exec<Element>(({ ctx, isExec }) => (ctx.isSubmitBusy = isExec), false)
    @Decorator.Handle<Element>(({ ctx, id, message }) => {
      ctx.isErrUnknown = true;
      ctx.isErrDNRegister = false;
      ctx.isDNAlreadyInUse = false;
      ctx.isErrPhone = false;
      ctx.isErrExtRegister = false;
      ctx.isExtAlreadyInUse = false;
      ctx.isErrPhoneExt = false;
      ctx.isSubmitBusy = false;
      ctx.evt<EError>(EventNameError, { id, message, trackingId: "" });
    })
    @Decorator.Handle<Element>({
      "Service.aqm.agent.stationLogin": ({ ctx, details }) => {
        ctx.setErrorFlags();
        ctx.isSubmitBusy = false;
        logger.error("[StationLogin] | event=stationLoginError | Station Login Error:", details);
        ctx.trackingId = ctx.getTrackingID(details.trackingId);

        if (ctx.isModalOpen) {
          ctx.evt<EError>(EventNameError, {
            id: "Service.aqm.agent.stationLogin",
            message: "Station Login API failed",
            trackingId: ctx.trackingId
          });
        } else {
          ctx.evt<EError>(EventNameError, {
            id: "Service.aqm.supervisor.stationLogin",
            message: "Supervisor Station Login API failed",
            trackingId: ctx.trackingId
          });
        }
        SERVICE.telemetry.track(
          SERVICE.telemetry.MIX_EVENT.STATION_LOGIN,
          {
            LoginType: ctx.loginType,
            Status: SERVICE.telemetry.MIX_EVENT.STATUS.FAILED,
            [SERVICE.telemetry.MIX_PROPS.SIGN_IN_FAILURE_REASON]: "Station Login API failed",
            Reason: "Station Login API failed",
            AdvancedHeader: ctx.advanceHeaderTracker(AdvancedHeader)
          },
          [SERVICE.telemetry?.SERVICE_PROVIDERS?.mixpanel, SERVICE.telemetry?.SERVICE_PROVIDERS?.prometheus]
        );
      },
      "Service.aqm.agent.stationLoginFailed": ({ ctx, details }) => {
        ctx.isSubmitBusy = false;
        ctx.setErrorFlags();
        ctx.reasonCode = details?.data?.reasonCode;
        logger.error("[StationLogin] Station Login Notifs Failed Error:", details);
        if (details?.data?.reasonCode === 41) {
          ctx.isDNRegisterTimeOut = true;
        }

        if (details?.data?.reasonCode === 1019) {
          ctx.isMaximumSessionsReached = true;
        }

        ctx.trackingId = details.data?.trackingId;
        if (ctx.isModalOpen) {
          ctx.evt<EError>(EventNameError, {
            id: "Service.aqm.agent.stationLoginFailed",
            message: "Station Login Notifs failed",
            trackingId: ctx.trackingId,
            reasonCode: details.data?.reasonCode
          });
        } else {
          ctx.evt<EError>(EventNameError, {
            id: "Service.aqm.supervisor.stationLoginFailed",
            message: "Station Login Notifs failed",
            trackingId: ctx.trackingId,
            reasonCode: details.data?.reasonCode
          });
        }
        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.STATION_LOGIN, {
          LoginType: ctx.loginType,
          Status: SERVICE.telemetry.MIX_EVENT.STATUS.FAILED,
          [SERVICE.telemetry.MIX_PROPS.SIGN_IN_FAILURE_REASON]: "Station Login Notifs failed",
          AdvancedHeader: ctx.advanceHeaderTracker(AdvancedHeader)
        });
      },
      "Service.aqm.reqs.Timeout": ({ ctx, details }) => {
        ctx.setErrorFlags();
        logger.error("[StationLogin] | event=stationLoginError | Station Login Timeout Error:", details);
        ctx.trackingId = ctx.getTrackingID(details?.resAxios?.headers?.trackingid);
        ctx.evt<EError>(EventNameError, {
          id: "Service.aqm.reqs.Timeout",
          message: "Station Login failed",
          trackingId: ctx.trackingId
        });
        SERVICE.telemetry.track(
          SERVICE.telemetry.MIX_EVENT.STATION_LOGIN,
          {
            LoginType: ctx.loginType,
            Status: SERVICE.telemetry.MIX_EVENT.STATUS.FAILED,
            [SERVICE.telemetry.MIX_PROPS.SIGN_IN_FAILURE_REASON]: "Station Login failed",
            Reason: "Station Login failed",
            AdvancedHeader: ctx.advanceHeaderTracker(AdvancedHeader)
          },
          [SERVICE.telemetry?.SERVICE_PROVIDERS?.mixpanel, SERVICE.telemetry?.SERVICE_PROVIDERS?.prometheus]
        );
      }
    })
    private async handleStationLoginSubmit(stationLoginDetails: EStationLoginSubmit["detail"], autologin = false) {
      if (!this.profile) {
        this.isSubmitBusy = false;
        logger.warn("[StationLogin] Fired onSubmit | No Config profile after StationLogin");
        return;
      }
      this.isSubmitBusy = true;
      this.isErrUnknown = false;
      this.auxCodeConfig.isError = false;
      this.fetchUniqueTeamIdError = false;
      const shouldFetchAuxCodes = await this.shouldFetchAuxCodes();

      if (shouldFetchAuxCodes && !this.auxCodeConfig.fetchCompleted && !this.isOnlySupervisor()) {
        // For pure supervisor aux codes are not required.
        const isAuxCodeUpdated = await this.fetchAuxCodes(true);
        if (!isAuxCodeUpdated) {
          return;
        }
      }

      const { profileDetail, loggedInRole } = stationLoginDetails;

      const {
        isExtension,
        dnNumber,
        usesOtherDN,
        teamId: teamSelectedId,
        teamName: teamSelectedName,
        roles,
        extensionNumber,
        dialNumberValueForNonUS,
        defaultDn,
        isDefaultDnSet,
        isInternational,
        phoneNumber,
        countryCallingCode,
        countryCodeValue,
        isRememberMyCredentials,
        deviceType
      } = profileDetail;

      // Load the team unique id, when isDesktopMaerskAcqueonTeamId FF is on.
      // This ID is required to use the acquon team id.
      let teamUniqueId: string | boolean = "";
      if (this.isDesktopMaerskAcqueonTeamId && teamSelectedId) {
        teamUniqueId = await this.fetchTeamDataById(teamSelectedId);
        if (!teamUniqueId) {
          // Prevent the station login, Show the error message.
          return;
        }
      }

      this.loginType = this.getLoginType(isExtension, roles);

      SERVICE.telemetry.register({ TeamId: teamSelectedId, TeamName: teamSelectedName, OrgId: this.organizationId });

      const ifSupervisorOnly = roles.join("_") === "supervisor";

      const stationLoginData: Service.Aqm.Agent.UserStationLogin = {
        ...(this.isESDStationLoginFlowEnabled
          ? { dialNumber: dnNumber?.replace(/ /g, "") }
          : { dn: dnNumber?.replace(/ /g, "") }), // changing dn to dialNumber as per the contract for new Core API
        usesOtherDN,
        teamId: ifSupervisorOnly ? null : teamSelectedId || "",
        teamName: ifSupervisorOnly ? null : teamSelectedName || "",
        siteId: this.profile.siteId,
        auxCodeId: this.defaultAuxCode.toString(),
        isExtension,
        ...(this.isESDStationLoginFlowEnabled && { roles })
      };

      let deviceId = dnNumber?.replace(/ /g, "");
      if (this.isWebRTCEnabled) {
        if (deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER) {
          // check BrowserCompatible for webRtc
          if (!isWebRtcSupportedBrowser()) {
            this.handleUnSupportedBrowserError();
            return;
          }
          // ask Media microphone access / check access allowed
          try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            if (!mediaStream) {
              this.handleMicrophoneAccessDenied();
              return;
            } else {
              mediaStream.getTracks().forEach((track: any) => track.stop());
            }
          } catch (error) {
            logger.error("[WebRtc] micrpophone access error", error);
            this.handleMicrophoneAccessDenied();
            return;
          }

          deviceId = `${WEB_RTC_PREFIX}${this.profile.agentId}`;
        }
        stationLoginData.deviceId = deviceId;
        stationLoginData.deviceType = this.getDeviceType(deviceType, isExtension);
        stationLoginData.dialNumber = deviceId; // keep this for backward compatible. remove while FF cleanup.
      }

      SERVICE.telemetry.track(
        SERVICE.telemetry.MIX_EVENT.STATION_LOGIN_CLICKED,
        {
          LoginType: this.loginType,
          DeviceType: this.deviceType
        },
        [SERVICE.telemetry?.SERVICE_PROVIDERS?.mixpanel]
      );

      SERVICE.telemetry.timeEvent(SERVICE.telemetry.MIX_EVENT.STATION_LOGIN, [
        SERVICE.telemetry?.SERVICE_PROVIDERS?.mixpanel,
        SERVICE.telemetry?.SERVICE_PROVIDERS?.prometheus
      ]);

      if (
        this.profile.skillProfileId &&
        this.profile.skillProfileId !== "0" &&
        this.profile.skillProfileId !== "null"
      ) {
        stationLoginData.skillProfileId = this.profile.skillProfileId;
      }

      // TODO: API Dependency (1) Send Role (2) Empty DN/Team (3) Supervisor Only.
      const login = await SERVICE.aqm.agent.stationLogin({
        data: stationLoginData
      });

      if ((this.isESDStationLoginFlowEnabled || this.intDiallingEnabledFF) && !autologin) {
        // Save Preferences only if Remember me checkbox is checked and its not an autologin
        /* Autologin scenario occurs when:
         * Role is Supervisor only
         * No teams are assigned
         * Call Monitoring is disabled
         */
        if (this.isRememberMyCredentials) {
          const saveUpdateUserPref = {
            ...(this.isESDStationLoginFlowEnabled && { selectedRoleName: stationLoginData?.roles?.join("_") }),
            teamName: teamSelectedName,
            teamId: teamSelectedId,
            dialNumber: this.isESDStationLoginFlowEnabled ? stationLoginData.dialNumber : stationLoginData.dn,
            isExtension: stationLoginData.isExtension || false,
            extensionNumber,
            dialNumberValueForNonUS: dialNumberValueForNonUS?.replace(/ /g, ""),
            isInternational: isInternational ? true : false,
            internationalPhoneNumber: isInternational ? stripSpecialChar(phoneNumber) : "",
            countryCallingCode: isInternational ? countryCallingCode : "",
            countryCodeValue: isInternational ? countryCodeValue : "",
            isRememberMyCredentials,
            deviceType,
            deviceId
          };
          if (this.isDesktopEmergencyNotificationEnabled) {
            const saveUpdateUserPrefEmergency = {
              ...saveUpdateUserPref,
              isEmergencyModalAlreadyDisplayed: this.isEmergencyModalAlreadyDisplayed
            };
            this.evt<Service.userPrefs.EUserPrefs>("save-update-user-prefs", saveUpdateUserPrefEmergency);
          } else {
            this.evt<Service.userPrefs.EUserPrefs>("save-update-user-prefs", saveUpdateUserPref);
          }
        } else {
          // If remember me button is not checked save only isEmergencyModalAlreadyDisplayed flag
          if (this.isDesktopEmergencyNotificationEnabled) {
            const saveUpdateUserPref = { isEmergencyModalAlreadyDisplayed: this.isEmergencyModalAlreadyDisplayed };
            this.evt<Service.userPrefs.EUserIsEmergency>("save-update-user-prefs", saveUpdateUserPref);
            this.evt("delete-user-preference-storage", {});
          } else {
            // delete prefs
            this.evt("delete-user-prefs", {});
            this.evt("delete-user-preference-storage", {});
          }
        }
      }

      // to capture event only when role switch is in between supervisor or agent+supervisor
      if (loggedInRole === AGENT_AND_SUPERVISOR || loggedInRole === SUPERVISOR) {
        this.addTelemetryOnRoleSwitch(roles.join("_"), loggedInRole);
      }

      const {
        teamId,
        lastStateChangeTimestamp,
        lastIdleCodeChangeTimestamp,
        profileType,
        channelsMap,
        agentId,
        interactionIds
      } = login.data;

      const teamNameProfile = this.profile.teams.find(t => t.teamId === teamSelectedId)?.teamName || "";

      this.success(
        {
          dn: dnNumber?.replace(/ /g, ""),
          agentId,
          usesOtherDN: this.isUsesOtherFormat(),
          isExtension,
          teamId,
          teamUniqueId,
          teamName: teamNameProfile,
          subStatus: this.profile.defaultIdleName,
          profileType,
          defaultDn,
          isDefaultDnSet,
          mmProfile: {
            voiceCount: channelsMap["telephony"]?.length,
            chatCount: channelsMap["chat"]?.length,
            emailCount: channelsMap["email"]?.length,
            socialCount: channelsMap["social"]?.length
          },
          lastIdleCodeChangeTimestamp,
          lastStateChangeTimestamp,
          interactionIds,
          userRole: this.userRole,
          deviceType,
          deviceId,
          selectedRoleName: roles ? roles.join("_") : ""
        },
        false
      );
      this.evt("remove-state-change-banner", {});
    }

    private requestCILogout() {
      this.evt<UserStationLoginTypes.ELogout>("ci-logout", {
        userLogout: "station"
      });
    }

    private cancelStationLogin() {
      this.isModalOpen = false;
      this.isSubmitBusy = false;
      // Trigger confirmation modal for sign out
      this.evt<ECancel>(EventLogoutConfirm, {
        header: t("app:stationLogin.signoutConfirmHeader"),
        message: t("app:stationLogin.signoutConfirmMsg"),
        noExitOnEsc: true,
        buttons: [
          {
            type: "cancel",
            label: t(cancelButtonLabel),
            color: "var(--md-background-color--gray-05)",
            onClick: this.cancelLogout.bind(this)
          },
          { type: "ok", label: t(signOutButtonLabel), onClick: this.requestCILogout.bind(this) }
        ]
      });
    }

    private stationLoginSubmitClick() {
      if (this.showEmergencyModal()) {
        this.setEmergencyNotificationAlert(true);
      } else {
        this.onSubmit();
      }
    }

    private cancelLogout() {
      this.isModalOpen = true;
    }

    handleRememberMeCheckBoxChange() {
      this.isRememberMyCredentials = !this.isRememberMyCredentials;
    }

    private logout() {
      const logoutResponse = SERVICE.aqm.agent.logout({ data: { logoutReason: "User requested logout" } });
      logoutResponse
        .then(async () => {
          logger.info("event=AQMLogoutSuccess");
          if (this.isWebRTCEnabled && this.deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER) {
            logger.info("[WebRtc] : deregistering webcalling");
            SERVICE.webCalling.deregisterWebCalling();
          }
        })
        .catch(err => {
          logger.error(`event=AQMLogoutFailure : ${err}`, err);
        })
        .finally(() => {
          this.requestCILogout();
        });
    }

    updateContinueButtonDisabledState(isDisabled: boolean) {
      const button = this.shadowRoot?.getElementById("continueButton") as HTMLButtonElement | null;
      if (button) {
        button.disabled = isDisabled;
      }
      // On checkbox change, update the focusable elements
      this.resetFocusableElements();
    }

    setEmergencyNotificationAlert(show: boolean) {
      this.showEmergencyNotificationWebrtc = show;
      this.resetFocusableElements();
    }

    resetFocusableElements() {
      document.dispatchEvent(new CustomEvent("on-widget-update"));
    }

    private renderEmergencyNotificationFooter() {
      return html`
        <div class="footer-buttons" slot="footer">
          <md-button class="footer-btn" @button-click="${() => this.setEmergencyNotificationAlert(false)}">
            ${t("app:common.cancelButton")}
          </md-button>
          <md-button
            @button-click="${() => this.onSubmit()}"
            id="continueButton"
            class="footer-btn"
            variant="primary"
            ?disabled=${true}
          >
            ${this.isSubmitBusy
              ? html`
                  <md-loading></md-loading>
                  ${this.updateContinueButtonDisabledState(true)}
                `
              : t("app:common.continueButton")}
          </md-button>
        </div>
      `;
    }

    private renderStationLoginFooter() {
      return html`
        <div class="sl-footer" slot="footer">
          ${this.isESDStationLoginFlowEnabled || this.intDiallingEnabledFF
            ? html`
                <div class="remember-me-checkbox-wrapper">
                  <md-checkbox
                    slot="checkbox"
                    ?checked=${this.isRememberMyCredentials}
                    label="${t("app:stationLogin.rememberMe")}"
                    class="remember-me-checkbox"
                    @checkbox-change=${() => this.handleRememberMeCheckBoxChange()}
                  >
                    ${t("app:stationLogin.rememberMe")}
                  </md-checkbox>
                </div>
              `
            : nothing}
          <div class="footer-buttons">
            <md-button class="footer-btn" @click="${this.cancelStationLogin}" ariaLabel="${t(cancelButtonLabel)}">
              ${t(cancelButtonLabel)}
            </md-button>
            <md-button
              class="footer-btn"
              @button-click="${this.stationLoginSubmitClick}"
              variant="primary"
              ariaLabel="${t("app:stationLogin.submit")}"
              ?disabled=${this.isSubmitDisabled || this.isSubmitBusy}
            >
              ${this.isSubmitBusy
                ? html`
                    <md-loading></md-loading>
                  `
                : t("app:stationLogin.submit")}
            </md-button>
          </div>
        </div>
      `;
    }

    private renderFooter() {
      return this.showEmergencyNotificationWebrtc && !this.isEmergencyModalAlreadyDisplayed
        ? this.renderEmergencyNotificationFooter()
        : this.renderStationLoginFooter();
    }

    private getStationModalLayout() {
      return html`
        <md-modal
          lang=${this.lang}
          .show=${this.isModalOpen}
          size="small"
          .hideFooter=${true}
          .hideHeader=${true}
          noExitOnEsc
          disableInitialFocus
          aria-label=${t("app:stationLogin.stationCredentials")}
        >
          <div slot="header">
            <h2 class="sl-form-label">
              ${this.showEmergencyNotificationWebrtc && !this.isEmergencyModalAlreadyDisplayed
                ? t("app:emergencyServiceNotification.emergencyServiceNotification")
                : this.isESDStationLoginFlowEnabled || this.intDiallingEnabledFF
                ? t("app:stationLogin.stationCredentials")
                : t("app:stationLogin.title")}
            </h2>
          </div>
          <agentx-wc-user-profile
            .isSubmit="${this.isSubmitClicked}"
            .reasonCode="${this.reasonCode}"
            .isDesktopReasonCodeEnabled="${this.isDesktopReasonCodeEnabled}"
            .getErrorMsgForReasonCode="${this.getErrorMsgForReasonCode}"
            .userRole="${this.userRole.join("_")}"
            .isExtensionUsed="${this.isExtensionUsed}"
            ?isMonitoringEnabled="${this.isMonitoringEnabled}"
            selectedRoleName="${this.selectedRoleName}"
            .dialNumber="${this.dialNumber}"
            ?isDefaultDnSet="${this.isDefaultDnSet}"
            .teams="${this.teams}"
            teamName="${this.teamName}"
            teamId="${this.teamId}"
            .usesOtherDN="${this.usesOtherDN}"
            defaultDn="${this.defaultDn}"
            extensionNumber="${this.extensionNumber}"
            .dialNumberValueForNonUS="${this.dialNumberValueForNonUS}"
            ?isInternational="${this.isInternational}"
            countryCallingCode="${this.countryCallingCode}"
            countryCodeValue="${this.countryCodeValue}"
            ?isRememberMyCredentials="${this.isRememberMyCredentials}"
            ?isErrUnknown="${this.isErrUnknown}"
            ?auxCodeError="${this.auxCodeConfig.isError}"
            ?fetchUniqueTeamIdError="${this.fetchUniqueTeamIdError}"
            ?isErrDNRegister="${this.isErrDNRegister}"
            ?isDNAlreadyInUse="${this.isDNAlreadyInUse}"
            ?isErrPhone="${this.isErrPhone}"
            ?isErrPhoneExt="${this.isErrPhoneExt}"
            ?isExtAlreadyInUse="${this.isExtAlreadyInUse}"
            ?isErrExtRegister="${this.isErrExtRegister}"
            ?isDNRegisterTimeOut="${this.isDNRegisterTimeOut}"
            ?isMaximumSessionsReached="${this.isDesktopMaxSessionLimitsEnabled && this.isMaximumSessionsReached}"
            ?isTeamsEmpty="${this.isTeamsEmpty}"
            trackingId="${this.trackingId}"
            ?isStationlogin=${true}
            ?intDiallingEnabledFF=${this.intDiallingEnabledFF}
            ?isESDStationLoginFlowEnabled=${this.isESDStationLoginFlowEnabled}
            phoneFormat="${this.phoneFormat}"
            phoneNumber="${this.phoneNumber}"
            deviceType=${this.getDeviceType(this.deviceType, this.isExtensionUsed)}
            deviceId=${this.deviceId}
            .loginVoiceOptions=${this.loginVoiceOptions}
            ?isWebRTCEnabled=${this.isWebRTCEnabled}
            ?isWebRtcUnsupportedBrowser=${this.isWebRTCEnabled && this.isWebRtcUnsupportedBrowser}
            ?isMicrophoneAccessDenied=${this.isWebRTCEnabled && this.isMicrophoneAccessDenied}
            ?showEmergencyNotificationWebrtc=${this.showEmergencyNotificationWebrtc}
            ?isEmergencyModalAlreadyDisplayed=${this.isEmergencyModalAlreadyDisplayed}
            @submit-busy="${(e: UserStationLoginTypes.ESubmitButton) => {
              this.isSubmitBusy = e.detail.submitProperty;
            }}"
            @on-component-update="${(e: UserStationLoginTypes.ESubmitButtonProfileSettings) => {
              this.isSubmitDisabled = e.detail.submitProperty;
              this.deviceTypeName = e.detail.profileDetail.deviceType!;
            }}"
            @login-start="${(e: EStationLoginSubmit) => this.handleStationLoginSubmit(e.detail)}"
            @checkbox-state-changed="${(e: any) => this.updateContinueButtonDisabledState(!e.detail.checked)}"
          ></agentx-wc-user-profile>

          <!-- Footer -->
          ${this.renderFooter()}
        </md-modal>
      `;
    }

    private getLoadingScreen() {
      return html`
        <agentx-wc-loading-screen></agentx-wc-loading-screen>
      `;
    }

    render() {
      return html`
        ${this.isInitializing ? this.getLoadingScreen() : this.getStationModalLayout()}
      `;
    }
  }
}
