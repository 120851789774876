/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin, t } from "@/mixins/i18nMixin";
import "@momentum-ui/web-components";
import { customElement, html, LitElement, property } from "lit-element";
import style from "../../styles.scss";

export namespace ResetTabOrderAction {
  /**
   * @element agentx-wc-reset-tab-order
   */
  @customElement("agentx-wc-reset-tab-order")
  export class Element extends i18nMixin(LitElement) {
    @property({ attribute: "tabs-unique-id" }) tabsUniqueId = "";

    private handleResetTabs() {
      this.dispatchEvent(
        new CustomEvent("clear-tab-order-prefs", {
          detail: {
            compUniqueId: this.tabsUniqueId
          },
          composed: true,
          bubbles: true
        })
      );
    }

    static get styles() {
      return style;
    }

    render() {
      return html`
        <md-button hasRemoveStyle class="reset-action-button" @click=${() => this.handleResetTabs()}>
          <md-icon slot="icon" aria-label=${t("app:panelTwo.resetTabOrder")} name="icon-refresh_16"></md-icon
          ><span slot="text">${t("app:panelTwo.resetTabOrder")}</span>
        </md-button>
      `;
    }
  }
}
