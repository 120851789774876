import { TPW_EMPTY_COLUMN_VALUE } from "@/constants";
import { ICellRendererParams } from "ag-grid-enterprise";
import { Duration } from "luxon";

class DurationRenderer {
  private oldTimestamp: number | undefined;
  private intervalId: any | undefined;
  private display: HTMLElement | undefined;

  init(params: ICellRendererParams): void {
    this.oldTimestamp =
      typeof params.value === "string" && params.value !== TPW_EMPTY_COLUMN_VALUE ? Number(params.value) : 0;
    this.display = document.createElement("div");
    this.display.classList.add("timer-display");
    this.getGui();
  }

  initiateInterval() {
    this.intervalId = setInterval(() => {
      if (this.oldTimestamp && this.display) {
        const diff =
          Date.now() >= this.oldTimestamp
            ? Duration.fromMillis(Date.now() - this.oldTimestamp)
            : Duration.fromMillis(0);
        this.display.textContent = diff.toFormat("hh:mm:ss");
      }
    }, 1000);
  }

  getGui(): HTMLElement {
    const container = document.createElement("div");
    container.classList.add("timer-container");
    if (this.display) {
      container.appendChild(this.display);
    }
    if (this.oldTimestamp !== 0) {
      this.initiateInterval();
    } else if (this.display) {
      this.display.textContent = TPW_EMPTY_COLUMN_VALUE;
    }

    return container;
  }

  refresh(params: ICellRendererParams): boolean {
    if ((params.value === TPW_EMPTY_COLUMN_VALUE || params.value === "0") && this.display) {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
      this.display.textContent = TPW_EMPTY_COLUMN_VALUE;
    } else {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
      this.initiateInterval();
    }
    this.oldTimestamp =
      typeof params.value === "string" && params.value !== TPW_EMPTY_COLUMN_VALUE ? Number(params.value) : 0;

    return true;
  }

  destroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    if (this.display) {
      this.display?.remove();
    }
  }
}

export default DurationRenderer;
