import { i18nMixin, t } from "@/mixins/i18nMixin";
import { logger } from "@/sdk";
import { SERVICE, getTrackingIdFromErrorObject } from "@agentx/agentx-services";
import { LitElement, customElement, html, internalProperty, property } from "lit-element";
import { TemplateResult, nothing } from "lit-html";
import { QMW_ERR_RETRY, QMW_RECORDINGS_MODAL_CLOSE_BTN_CLK, RECORDING } from "../constants";
import { dateFormatterShortWithSeconds, errorIsRetryable, relativeTimeFormatter, wait } from "../utils";
import style from "./Recording.scss";

export namespace RecordingDetails {
  export type audioSrcInfo = {
    audioSrc: string;
    absoluteStartTime: number;
    absoluteEndTime: number;
  };

  @customElement("agentx-wc-qmw-recording-details")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: String }) sessionId: string | undefined;
    @property({ type: String }) ani = "";
    @property({ type: String }) agentName: string | undefined;
    @property({ type: Boolean }) isModalOpen = false;
    @property({ type: Boolean }) showConsultRecordings = false;
    @internalProperty() isLoading = false;
    @internalProperty() mainAudioSource: audioSrcInfo | undefined;
    @internalProperty() numAvailableConsultRecordings = 0;
    @internalProperty() mainRecordingPlayed = false;
    @internalProperty() consultRecordingPlayed = false;
    @internalProperty() consultAudioSources: audioSrcInfo[] = [];
    @internalProperty() hasDataFetchError = false;
    @internalProperty() hasRecordingPlaybackError = false;
    @internalProperty() trackingID = "";
    @internalProperty() isEmpty = false;

    audioLabels: { [key: string]: any } = {
      playBtn: {
        ariaLabel: t("app:qmw.audioPlayBtn"),
        tooltipText: t("app:qmw.audioPlayBtn")
      },
      pauseBtn: {
        ariaLabel: t("app:qmw.audioPauseBtn"),
        tooltipText: t("app:qmw.audioPauseBtn")
      },
      duration: {
        ariaLabel: t("app:qmw.audioDuration"),
        tooltipText: t("app:qmw.audioDuration")
      },
      timeline: {
        ariaLabel: t("app:qmw.audioTimeline"),
        tooltipText: t("app:qmw.audioTimeline")
      },
      volumeBtn: {
        ariaLabel: t("app:qmw.audioVolumeBtn"),
        tooltipText: t("app:qmw.audioVolumeBtn")
      },
      volumeSlider: {
        ariaLabel: t("app:qmw.audioVolumeSlider"),
        tooltipText: t("app:qmw.audioVolumeSlider")
      },
      playbackSpeedBtn: {
        ariaLabel: t("app:qmw.audioPlaybackSpeed"),
        tooltipText: t("app:qmw.audioPlaybackSpeed")
      }
    };
    static get styles(): CSSResult {
      return style;
    }

    async fetchData(): Promise<void> {
      if (this.sessionId) {
        this.consultAudioSources = [];
        this.mainAudioSource = undefined;
        this.isEmpty = true;
        this.numAvailableConsultRecordings = 0;
        this.mainRecordingPlayed = false;
        this.consultRecordingPlayed = false;

        const { data } = await SERVICE.qmw.fetchCapture({ taskId: this.sessionId as string });
        // captures API takes a list of task IDs and returns an array in data for each task, we are only passing in 1 task id and thus taking the first obj in the array
        const capture = data[0];
        const { recording } = capture;
        if (recording.length) {
          const CALL_TYPE_MAIN = "main";
          const CALL_TYPE_CONSULT = "consult";
          recording.forEach(rec => {
            if (rec.attributes.callType === CALL_TYPE_MAIN && rec.segment === false) {
              this.mainAudioSource = {
                audioSrc: rec.attributes.filePath,
                absoluteStartTime: rec.attributes.startTime,
                absoluteEndTime: rec.attributes.stopTime
              };
            } else if (rec.attributes.callType === CALL_TYPE_CONSULT) {
              this.numAvailableConsultRecordings++;
              if (this.showConsultRecordings) {
                this.consultAudioSources.push({
                  audioSrc: rec.attributes.filePath,
                  absoluteStartTime: rec.attributes.startTime,
                  absoluteEndTime: rec.attributes.stopTime
                });
              }
            }
          });
          this.consultAudioSources.sort((a, b) => a.absoluteStartTime - b.absoluteStartTime);
          this.isEmpty = false;
          logger.info("[App:QMW] Fetched capture data successfully");
        } else {
          logger.info("[App:QMW] Fetched capture data successfully but there was no recording");
        }
      }
    }

    handleCloseModal(): void {
      this.setHasRecordingPlaybackError(false);
      this.dispatchEvent(
        new CustomEvent(QMW_RECORDINGS_MODAL_CLOSE_BTN_CLK, {
          bubbles: true,
          composed: true,
          detail: {
            mainRecordingPlayed: this.mainRecordingPlayed,
            numConsultRecordings: this.numAvailableConsultRecordings,
            consultRecordingPlayed: this.consultRecordingPlayed
          }
        })
      );
    }

    handleRecordingPlaybackError(): void {
      this.setHasRecordingPlaybackError(true);
    }

    handleDataFetchErrorRetry(): void {
      this.setHasDataFetchError(false);
      this.setTrackingID("");
    }

    connectedCallback(): void {
      super.connectedCallback();
      this.addEventListener("playback-error", this.handleRecordingPlaybackError);
      this.addEventListener(QMW_ERR_RETRY, this.handleDataFetchErrorRetry);
    }

    disconnectedCallback(): void {
      super.disconnectedCallback();
      this.removeEventListener("playback-error", this.handleRecordingPlaybackError);
      this.removeEventListener(QMW_ERR_RETRY, this.handleDataFetchErrorRetry);
    }

    async updated(changedProperties: PropertyValues): Promise<void> {
      super.updated(changedProperties);

      if (
        changedProperties.has("sessionId") ||
        (changedProperties.has("hasDataFetchError") && changedProperties.get("hasDataFetchError"))
      ) {
        this.setIsLoading(true);
        try {
          await this.fetchData();
          this.setHasDataFetchError(false);
        } catch (err) {
          if (errorIsRetryable((err as { [key: string]: any })?.details)) {
            logger.info(`[App:QMW] Failed to fetch capture data due to rate limit. Retrying`);
            await wait((err as { [key: string]: any })?.details);
            await this.fetchData();
          } else {
            logger.error(`[App:QMW] Failed to fetch capture data`);
            this.setHasDataFetchError(true);
            this.setTrackingID(getTrackingIdFromErrorObject(err));
          }
        } finally {
          this.setIsLoading(false);
        }
      }
    }

    getHasDataFetchError(): boolean {
      return this.hasDataFetchError;
    }

    setHasDataFetchError(hasError: boolean): void {
      this.hasDataFetchError = hasError;
    }

    getIsLoading(): boolean {
      return this.isLoading;
    }

    setIsLoading(isLoading: boolean): void {
      this.isLoading = isLoading;
    }

    getTrackingId(): string {
      return this.trackingID;
    }

    setTrackingID(trackingID: string): void {
      this.trackingID = trackingID;
    }

    getHasRecordingPlaybackError(): boolean {
      return this.hasRecordingPlaybackError;
    }

    setHasRecordingPlaybackError(hasRecordingPlaybackError: boolean): void {
      this.hasRecordingPlaybackError = hasRecordingPlaybackError;
    }

    getRelativeTimesText(audioSource: audioSrcInfo): TemplateResult {
      if (audioSource && this.mainAudioSource) {
        const startTimeDiff = audioSource.absoluteStartTime - this.mainAudioSource.absoluteStartTime;
        const endTimeDiff = audioSource.absoluteEndTime - this.mainAudioSource.absoluteStartTime;
        const mainOverOneHour = this.mainAudioSource.absoluteEndTime - this.mainAudioSource.absoluteStartTime > 3600000;

        const startTimeFormatted = relativeTimeFormatter(startTimeDiff, mainOverOneHour);
        const endTimeFormatted = relativeTimeFormatter(endTimeDiff, mainOverOneHour);

        return html`
          ${t("app:qmw.startTime")} ${startTimeFormatted} <span></span>${t("app:qmw.endTime")} ${endTimeFormatted}
        `;
      }
      return html``;
    }

    getAudioSourceComponent(): TemplateResult {
      return html`
        ${this.mainAudioSource
          ? html`
              <md-audio-player
                class="audioplayer"
                @click=${() => (this.mainRecordingPlayed = true)}
                src=${this.mainAudioSource.audioSrc}
                .labelMap=${this.audioLabels}
              ></md-audio-player>
              ${this.getConsultRecordingsComponent()}
            `
          : nothing}
      `;
    }

    getConsultRecordingsComponent(): TemplateResult {
      return html`
        ${this.consultAudioSources.length > 0
          ? html`
              <div id="consult-recordings">
                <hr />
                <p>${t("app:qmw.consultRecordings")} ${this.consultAudioSources.length}</p>
                ${this.consultAudioSources.slice(0).map(
                  audioSource => html`
                    <dl class="agentx-wc-qmw-recording-modal-consult-container">
                      <div class="agentx-wc-qmw-recording-modal-consult-info">
                        <dt>${t("app:qmw.consult")} ${this.consultAudioSources.indexOf(audioSource) + 1}</dt>
                        <dd>${this.getRelativeTimesText(audioSource)}</dd>
                      </div>
                    </dl>
                    <md-audio-player
                      class="audioplayer"
                      @click=${() => (this.consultRecordingPlayed = true)}
                      src=${audioSource.audioSrc}
                      .labelMap=${this.audioLabels}
                    ></md-audio-player>
                  `
                )}
              </div>
            `
          : nothing}
      `;
    }

    renderComponent(): TemplateResult {
      if (this.hasDataFetchError) {
        return html`
          <agentx-wc-qmw-grid-error
            .trackingID=${this.trackingID}
            .errorElement=${RECORDING}
          ></agentx-wc-qmw-grid-error>
        `;
      } else if (this.isLoading) {
        return html`
          <agentx-wc-widget-loader></agentx-wc-widget-loader>
        `;
      } else if (this.isEmpty) {
        return html`
          <agentx-wc-qmw-empty-grid .emptyElement=${RECORDING}></agentx-wc-qmw-empty-grid>
        `;
      } else {
        return html`
          <div id="scrollable-content">
            <md-alert-banner .show=${this.hasRecordingPlaybackError} type="error">
              <p class="agentx-wc-qmw-recording-modal-playback-error-text">
                ${t("app:qmw.recordingPlaybackError")}
              </p>
            </md-alert-banner>

            <dl class="agentx-wc-qmw-recording-modal-properties-container">
              <div class="agentx-wc-qmw-recording-modal-properties">
                <dt>${t("app:qmw.sessionId")}</dt>
                <dd>${this.sessionId}</dd>
              </div>

              <div class="agentx-wc-qmw-recording-modal-properties">
                <dt>${t("app:qmw.ani")}</dt>
                <dd>${this.ani}</dd>
              </div>

              <div class="agentx-wc-qmw-recording-modal-properties">
                <dt>${t("app:qmw.startTime")}</dt>
                <dd>${dateFormatterShortWithSeconds(this.mainAudioSource?.absoluteStartTime || 0)}</dd>
              </div>

              <div class="agentx-wc-qmw-recording-modal-properties">
                <dt>${t("app:qmw.endTime")}</dt>
                <dd>${dateFormatterShortWithSeconds(this.mainAudioSource?.absoluteEndTime || 0)}</dd>
              </div>

              <div class="agentx-wc-qmw-recording-modal-properties">
                <dt>${t("app:qmw.agent")}</dt>
                <dd>${this.agentName}</dd>
              </div>

              ${this.getAudioSourceComponent()}
            </dl>
          </div>
        `;
      }
    }

    render(): TemplateResult {
      return html`
        <md-modal
          .show=${this.isModalOpen}
          size="small"
          .hideFooter=${true}
          .showCloseButton=${true}
          ariaLabelClose=${t("app:qmw.close")}
          .backdropClickExit=${true}
          headerLabel=${t("app:qmw.recordingPlayback")}
          @close-modal=${() => this.handleCloseModal()}
          class="agentx-wc-qmw-recording-modal"
          .disableInitialFocus=${true}
        >
          ${this.renderComponent()}
          <section slot="footer">
            <md-button @click=${() => (this.isModalOpen = false)} ariaLabel=${t("app:qmw.close")}>
              ${t("app:qmw.close")}
            </md-button>
          </section>
        </md-modal>
      `;
    }
  }
}
