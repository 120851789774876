import { TIMEOUT_TEN_MS } from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { SERVICE, Service } from "@agentx/agentx-services";
import "@momentum-ui/web-components";
import "@uuip/unified-ui-platform-common-components";
import { ToasterNotificationItemContent } from "@uuip/unified-ui-platform-common-components";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
import { customElement, html, internalProperty, LitElement, property, PropertyValues, query } from "lit-element";
import { nothing } from "lit-html";
import { repeat } from "lit-html/directives/repeat";
import { styleMap } from "lit-html/directives/style-map";
import { DateTime } from "luxon";
import commonStyles from "../../assets/styles/common.scss";
import "../Common/EmptyStateWrapper/EmptyStateWrapper.ts";
import style from "./MenuNotifications.scss";

const webexNotificationType = "webex_message";

export namespace MenuNotification {
  export type EClearedNotifications = { notifications: Notifications.Item[] };

  const MAX_UNREAD_NOTIFICATIONS_NON_DISPLAY = 99; // Count
  const TIME_FROM_UPDATE_INTERVAL = 5000; // ms

  /**
   * @element agentx-wc-menu-notification
   * @fires clear-notifications
   */
  @customElement("agentx-wc-menu-notification")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Object }) notificationsService!: Notifications.Service;

    @query("#menu-notification") private readonly refMenuElem!: HTMLElement;

    @query("#live-region") liveRegion!: any;

    @internalProperty() isModalOpen = false;

    @internalProperty() private unreadNotifications: Notifications.Item[] = [];
    private unreadNotificationsHash: Record<Notifications.Item["timestamp"], Notifications.Item> = {};

    @internalProperty() private notificationsTimesFrom: Record<Notifications.Item["timestamp"], number> = {};
    private notificationTimeUpdateRef: undefined | number = undefined;

    static get styles() {
      return [style, commonStyles];
    }

    disconnectedCallback() {
      super.disconnectedCallback();

      this.unbindServiceEvents();

      this.stopTimeupdate();
    }

    protected firstUpdated(changedProperties: PropertyValues) {
      super.firstUpdated(changedProperties);

      this.updateCollections();

      this.bindServiceEvents();
    }

    private bindServiceEvents() {
      // Listening for all service status events
      Notifications.ServiceMeta.STATUS_EVENTS.forEach(eventName =>
        this.notificationsService.addEventListener(eventName, this.updateCollections)
      );
    }

    private unbindServiceEvents() {
      // Stop Listening for all service status events
      Notifications.ServiceMeta.STATUS_EVENTS.forEach(eventName =>
        this.notificationsService.removeEventListener(eventName, this.updateCollections)
      );
    }

    private startTimeupdate() {
      this.stopTimeupdate();

      this.notificationsTimesFrom = this.unreadNotifications.reduce((acc, n) => {
        acc[n.timestamp] = Math.round(DateTime.local().diff(DateTime.fromISO(n.timestamp), "minutes").minutes);
        return acc;
      }, {} as Record<Notifications.Item["timestamp"], number>);

      this.notificationTimeUpdateRef = window.setTimeout(() => this.startTimeupdate(), TIME_FROM_UPDATE_INTERVAL);
    }

    private stopTimeupdate() {
      window.clearTimeout(this.notificationTimeUpdateRef);
      this.notificationTimeUpdateRef = undefined;
    }

    private readonly updateCollections = () => {
      this.unreadNotifications = [...this.notificationsService.pended, ...this.notificationsService.activated].sort(
        (n1, n2) => {
          // Sorting from new to old
          return n1.timestamp > n2.timestamp ? -1 : n1.timestamp < n2.timestamp ? 1 : 0;
        }
      );
      this.unreadNotifications = this.unreadNotifications.filter(notification => {
        return (notification.data as ToasterNotificationItemContent.DataController).type !== webexNotificationType;
      });
      this.unreadNotificationsHash = this.unreadNotifications.reduce((acc, n) => {
        acc[n.timestamp] = n;
        return acc;
      }, {} as Record<Notifications.Item["timestamp"], Notifications.Item>);

      if (this.unreadNotifications.length) {
        this.startTimeupdate();
      } else {
        this.stopTimeupdate();
      }
    };

    private get unreadNotificationsCount() {
      return this.unreadNotifications.length;
    }

    private executeOpenMenuNotification(key: Service.shortcut.EKeyInfo) {
      if (
        key.data &&
        key.data.modifierKeys === SERVICE.shortcut.MODIFIERS.CTRL_ALT &&
        key.data.key === SERVICE.shortcut.REGISTERED_KEYS.OPEN_NOTIFICATION_CENTER_KEY &&
        this.refMenuElem &&
        this.refMenuElem.firstElementChild
      ) {
        (this.refMenuElem.firstElementChild as HTMLElement).click();
      }
    }

    connectedCallback() {
      super.connectedCallback();
      SERVICE.shortcut.event.listenKeyPress(key => this.executeOpenMenuNotification(key));
    }

    handleClearAll() {
      // Deactivating => clear
      const deactivatedNotifications = this.notificationsService.deactivateAll(
        Notifications.ItemMeta.DeactivateEventReason.UserNegative
      );

      // Notify outer
      this.dispatchEvent(
        new CustomEvent<EClearedNotifications>("clear-notifications", {
          detail: {
            notifications: deactivatedNotifications
          }
        })
      );
      setTimeout(() => {
        if (this.liveRegion) {
          this.liveRegion.textContent = t("app:menuNotifications.notificationMenuEmptyStateNew");
        }
      }, TIMEOUT_TEN_MS);
    }

    // like 10 or 99+
    private get unreadNotificationsCountText() {
      return this.unreadNotificationsCount > 0
        ? this.unreadNotificationsCount > MAX_UNREAD_NOTIFICATIONS_NON_DISPLAY
          ? `${MAX_UNREAD_NOTIFICATIONS_NON_DISPLAY}+`
          : this.unreadNotificationsCount
        : null;
    }

    renderCount() {
      return this.unreadNotificationsCountText
        ? html`
            <md-badge class="wc-menu-notification_count" color="darkred" small>
              <span>
                ${this.unreadNotificationsCountText}
              </span>
            </md-badge>
          `
        : nothing;
    }

    render() {
      const unreadNotificationsCountText = `(${this.unreadNotificationsCount})`;
      return html`
        <md-menu-overlay
          id="menu-notification"
          @menu-overlay-open=${() => {
            this.isModalOpen = true;
          }}
          @menu-overlay-close=${() => {
            this.isModalOpen = false;
            if (this.liveRegion) {
              this.liveRegion.textContent = "";
            }
          }}
        >
          <span slot="menu-trigger" class="wc-menu-notification-icon_wrap">
            <md-tooltip slot="menu-trigger" placement="bottom" message=${t("app:notifications.notificationLable")}>
              <md-button
                class="notification-btn"
                variant="secondary"
                size="40"
                circle
                ariaLabel=${t("app:notifications.notificationAriaLabel", "Notification Center", {
                  count: this.unreadNotificationsCount
                })}
                ariaExpanded=${this.isModalOpen}
                ariaHaspopup
                ><md-icon name="alert_20"></md-icon
              ></md-button>
            </md-tooltip>

            ${this.renderCount()}
          </span>
          <div class="wc-menu-notification-wrap">
            ${this.unreadNotificationsCount > 0
              ? html`
                  <div class="wc-full-menu-notification">
                    <h2 class="wc-menu-notification_title">
                      ${`${t("app:menuNotifications.notificationMenuTitle")} ${unreadNotificationsCountText}`}
                    </h2>
                    <md-list
                      class="wc-menu-notification_list"
                      label=${t("app:menuNotifications.notifications")}
                      role="list"
                    >
                      ${repeat(
                        this.unreadNotifications,
                        n => n.timestamp,
                        n => html`
                          <md-list-item
                            class="wc-menu-notification-item"
                            slot="list-item"
                            role="listitem"
                            aria-label="${n.title}, ${this.notificationsTimesFrom[n.timestamp]} ${t(
                              "app:common.minutes"
                            )},  ${typeof n.data == "object" ? n.data.text : n.data} "
                          >
                            <uuip-wc-toaster-notification-item-content .notification=${n}>
                            </uuip-wc-toaster-notification-item-content>
                            <span class="wc-notification-datediff"
                              >${this.notificationsTimesFrom[n.timestamp]}
                              ${t("app:menuNotifications.notificationMenuTime")}</span
                            >
                          </md-list-item>
                        `
                      )}
                    </md-list>
                    <div class="wc-menu-cleaner">
                      <md-button
                        color="color-none"
                        @click=${() => this.handleClearAll()}
                        ariaLabel="${t("app:menuNotifications.notificationMenuClearBtn")}"
                      >
                        <md-icon
                          slot="icon"
                          name="check_16"
                          style=${styleMap({ color: "var(--md-alert-info-text-color)" })}
                        ></md-icon>
                        <span slot="text">${t("app:menuNotifications.notificationMenuClearBtn")}</span>
                      </md-button>
                    </div>
                  </div>
                `
              : html`
                  <div class="wc-empty-menu-notification">
                    <agentx-wc-empty-state-wrapper illustration-type="empty-state">
                      <div id="live-region" class="sr-only" aria-live="polite"></div>
                      <p
                        class="empty-text"
                        aria-label=${t("app:menuNotifications.notificationMenuEmptyStateNew")}
                        tabindex="0"
                      >
                        ${t("app:menuNotifications.notificationMenuEmptyStateNew")}
                      </p>
                    </agentx-wc-empty-state-wrapper>
                  </div>
                `}
          </div>
        </md-menu-overlay>
      `;
    }
  }
}
