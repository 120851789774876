import {
  REVIEW_BUTTON_DARK,
  REVIEW_BUTTON_DARK_DISABLED,
  REVIEW_BUTTON_LIGHT,
  REVIEW_BUTTON_LIGHT_DISABLED
} from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { LitElement, customElement, html, property } from "lit-element";
import { nothing } from "lit-html";
import style from "./ReviewAndMonitorWrapper.scss";
const reviewAndMonitor = "app:tpw.reviewAndMonitor";
const review = "app:tpw.review";

export namespace ReviewAndMonitorWrapper {
  /**
   * @element agentx-wc-tpw-review-monitor-wrapper
   */
  @customElement("agentx-wc-tpw-review-monitor-wrapper")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: String }) agentId = "";
    @property({ type: Boolean }) isMCMEnabled = false;
    @property({ type: Boolean }) isList = false;
    @property({ type: Boolean }) isConsultCall = false;
    @property({ type: Boolean }) isDarkMode = false;
    @property({ type: Boolean }) isReviewMonitorEnabled = false;

    static get styles() {
      return style;
    }

    connectedCallback() {
      super.connectedCallback();
    }
    disconnectedCallback() {
      super.disconnectedCallback();
    }

    private getReviewButton(disabled = false) {
      return html`
        <md-button
          id=${this.agentId}
          aria-colindex="12"
          tabIndex="0"
          class="aid-button btn-action"
          ariaLabel="${this.isMCMEnabled ? t(reviewAndMonitor) : t(review)}"
          size="size-none"
          .disabled=${disabled}
          color="color-none"
        >
          ${!disabled && !this.isList
            ? html`
                <img
                  class="aid-icon"
                  alt="${this.isMCMEnabled ? t(reviewAndMonitor) : t(review)}"
                  src="${this.isDarkMode ? REVIEW_BUTTON_DARK : REVIEW_BUTTON_LIGHT}"
                />
              `
            : disabled && !this.isList
            ? html`
                <img
                  class="aid-icon"
                  alt="${this.isMCMEnabled ? t(reviewAndMonitor) : t(review)}"
                  src="${this.isDarkMode ? REVIEW_BUTTON_DARK_DISABLED : REVIEW_BUTTON_LIGHT_DISABLED}"
                />
              `
            : this.isList
            ? html`
                <p class="rm-text ${disabled ? "disabled" : ""}">
                  ${this.isMCMEnabled ? t(reviewAndMonitor) : t(review)}
                </p>
              `
            : nothing}
        </md-button>
      `;
    }

    getTooltipMessage() {
      if (this.isConsultCall) {
        return t("app:tpw.cannotMonitorConsult");
      } else if (this.isMCMEnabled) {
        return t(reviewAndMonitor);
      } else {
        return t(review);
      }
    }

    render() {
      return html`
        <div class="${`review-monitor-wrapper ${this.isList ? "list" : ""}`}">
          ${this.isReviewMonitorEnabled || this.isConsultCall
            ? html`
                <md-tooltip class="aid-tooltip" message="${this.getTooltipMessage()}" placement="top">
                  ${this.isConsultCall ? this.getReviewButton(true) : this.getReviewButton(false)}
                </md-tooltip>
              `
            : this.getReviewButton(true)}
        </div>
      `;
    }
  }
}
