import { logger } from "@/sdk";
import { customElement, html, internalProperty, LitElement, property, PropertyValues } from "lit-element";
import { nothing } from "lit-html";
import { repeat } from "lit-html/directives/repeat";
import isEmail from "validator/lib/isEmail";
import { isRegistered } from "../../utils/helpers";
import styles from "./WxmWrapper.scss";

export namespace WxmWrapper {
  export type ResponseData = {
    ".expires": string | undefined;
    ".issued": string | undefined;
    access_token: string;
    email: string;
    expires_in: number;
    hash: string;
    managedBy: string;
    primaryRole: string;
    station: string;
    token_type: string;
    userName: string;
    isCIToken?: boolean | string;
  };
  /**
   * @element agentx-wc-cloudcherry-widget
   * @fires agentx-settings-set
   */
  @customElement("agentx-wc-cloudcherry-widget")
  export class ResponsesWidget extends LitElement {
    @property({ type: Boolean }) metrics = false;
    @property({ type: String }) spaceId = "";
    @property({ type: String }) metricsId = "";
    @property({ type: String }) ani = "";
    @property({ type: String }) teamId = "";
    @property({ type: String }) agentId = "";
    @property({ type: Boolean, attribute: "is-dark-mode" }) isDarkMode = false;

    @internalProperty() styleList: NodeListOf<HTMLStyleElement> | null = null;
    @internalProperty() addedStyleList: Node[] = [];
    @property({ type: Object }) userModel: ResponseData | null = null;
    @internalProperty() customerEmail: string | undefined = undefined;
    @internalProperty() customerPhones: string | undefined = undefined;

    updated(changedProperties: PropertyValues) {
      super.updated(changedProperties);
      changedProperties.forEach((oldValue, name) => {
        if ((name === "spaceId" || name === "metricsId") && (this.spaceId.length > 0 || this.metricsId.length > 0)) {
          this.evt("agentx-settings-set", { settingsSet: true, setting: name, value: this[name] });
        } else if (name === "ani") {
          if (isEmail(this.ani?.trim())) {
            this.customerEmail = this.ani;
            this.customerPhones = undefined;
          } else {
            this.customerPhones = this.ani;
            this.customerEmail = undefined;
          }
        }
      });
    }

    private evt<T extends { detail: any }>(name: string, detail: T["detail"]) {
      this.dispatchEvent(new CustomEvent(name, { bubbles: true, composed: true, detail }));
      logger.info("[WxmWrapper] Dispatching", name, detail);
    }

    private readonly mutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          const newNode = node.cloneNode(true);
          if (newNode.nodeName !== "#text") {
            // Fix for page title getting added to Wxm widget
            this.addedStyleList.push(newNode);
            this.requestUpdate();
          }
        });
      });
    });

    constructor() {
      super();

      window.customElements.whenDefined("cloudcherry-responses-widget").then(() => {
        this.fetchStyles();
        this.mutationObserver.observe(document.getElementsByTagName("head")[0], {
          attributes: true,
          characterData: true,
          childList: true,
          subtree: true,
          attributeOldValue: true,
          characterDataOldValue: true
        });
      });
    }

    connectedCallback() {
      super.connectedCallback();
      logger.info(
        `[WxMWrapper: Render] => rendering WxM with`,
        this.metrics,
        this.userModel,
        this.spaceId,
        this.metricsId,
        this.agentId,
        this.ani
      );
      logger.info(
        `[WxMWrapper: Render] => Is cloudcherry-metric-widget defined`,
        isRegistered("cloudcherry-metric-widget")
      );
      logger.info(
        `[WxMWrapper: Render] => Is cloudcherry-responses-widget defined`,
        isRegistered("cloudcherry-responses-widget")
      );
    }

    static get styles() {
      return styles;
    }

    fetchStyles() {
      this.styleList = document.querySelectorAll("style");
    }

    render() {
      return this.userModel
        ? html`
            ${this.styleList &&
              repeat(this.styleList, style => {
                const node = style.cloneNode(true);
                return html`
                  ${node}
                `;
              })}
            ${this.addedStyleList.length > 0
              ? repeat(this.addedStyleList, style => {
                  return html`
                    ${style}
                  `;
                })
              : nothing}
            <div class="cloudcherry-responses-widget">
              ${this.metrics
                ? html`
                    <cloudcherry-metric-widget
                      .userModel=${this.userModel}
                      .spaceId=${this.spaceId}
                      .metricId=${this.metricsId}
                      .isDarkMode=${this.isDarkMode}
                      .agentId=${this.agentId}
                    ></cloudcherry-metric-widget>
                  `
                : html`
                    <cloudcherry-responses-widget
                      .userModel=${this.userModel}
                      .spaceId=${this.spaceId}
                      .customerEmail=${this.customerEmail}
                      .customerPhones=${this.customerPhones ? [this.customerPhones] : undefined}
                      .isDarkMode=${this.isDarkMode}
                    ></cloudcherry-responses-widget>
                  `}
            </div>
          `
        : nothing;
    }
  }

  export type ESettings = {
    settingsSet: boolean;
  };
}
