import { TPW_EMPTY_COLUMN_VALUE } from "@/constants";
import { t } from "@/mixins/i18nMixin";
import { ICellRendererParams } from "ag-grid-enterprise";

export default class NameRenderer {
  private eGui: HTMLElement = document.createElement("div");

  renderNameRenderer(params: any): void {
    const title = this.prettyTitle(params?.data?.agentName);
    const type = this.avatarType(params?.data?.agentState);

    const agentId = params?.data?.agentId;
    const agentName = params?.data?.agentName || TPW_EMPTY_COLUMN_VALUE;
    const id = `avatar-renderer-${agentId}`;
    const className = `md-avatar md-avatar--28 md-avatar--${type}`;

    const avatarDiv = `<div id="${id}" class="${className}" alt="${agentName}" aria-label="${agentName}" title="${agentName}">
                          <span class="md-avatar__letter">${title}</span>
                        </div>`;

    const agentNameSpan = `<span class="name-span-renderer">${agentName}</span>`;

    this.eGui.innerHTML = avatarDiv + agentNameSpan;
  }

  prettyTitle = (agentName: string) => {
    if (agentName && agentName.length) {
      const fullName = agentName.trim().split(" ");
      if (fullName.length === 1) {
        return fullName[0].charAt(0);
      } else {
        return `${fullName[0].charAt(0)}${fullName[fullName.length - 1].charAt(0)}`;
      }
    }
    return "";
  };

  avatarType = (agentState: string) => {
    return agentState === t("app:tpw.stateChange.available") ? "active" : "idle";
  };

  init(params: ICellRendererParams): void {
    this.eGui.classList.add("avatar-center");
    this.renderNameRenderer(params);
  }

  refresh(params: ICellRendererParams) {
    this.renderNameRenderer(params);
    return true;
  }

  getGui(): HTMLElement {
    return this.eGui;
  }
}
