import { DateTime, Duration } from "luxon";

export const dateFormatterShortWithSeconds = (dateInMilliSeconds: number): string => {
  return DateTime.fromMillis(dateInMilliSeconds).toFormat("D tt");
};

export const relativeTimeFormatter = (dateInMilliSeconds: number, showHours: boolean): string => {
  if (showHours) {
    return Duration.fromMillis(dateInMilliSeconds).toFormat("hh:mm:ss");
  }
  return Duration.fromMillis(dateInMilliSeconds).toFormat("mm:ss");
};

export const errorIsRetryable = (errorDetails: { [key: string]: any }): boolean => {
  const { statusCode, retryAfter } = errorDetails;
  return statusCode === 429 && !!retryAfter;
};

export const wait = (errorDetails: { [key: string]: any }): Promise<void> => {
  const { retryAfter } = errorDetails;
  return new Promise(resolve => setTimeout(resolve, retryAfter));
};
