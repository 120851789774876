import { i18nMixin, t } from "@/mixins/i18nMixin";
import "@momentum-ui/web-components";
import { customElement, html, internalProperty, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import { COPY_CLICK_TIMEOUT, GRID, QMW_ERR_RETRY, RECORDING } from "../constants";
import style from "./GridError.scss";
export namespace GridError {
  @customElement("agentx-wc-qmw-grid-error")
  export class Element extends i18nMixin(LitElement) {
    static get styles(): CSSResult {
      return style;
    }

    @property({ type: String }) trackingID = "";
    @property({ type: String }) errorElement = GRID;

    @internalProperty() shouldKnowMore = false;
    @internalProperty() isCopied = false;

    getIsCopied(): boolean {
      return this.isCopied;
    }

    setIsCopied(isCopied: boolean): void {
      this.isCopied = isCopied;
    }

    getShouldKnowMore(): boolean {
      return this.shouldKnowMore;
    }

    setShouldKnowMore(shouldKnowMore: boolean): void {
      this.shouldKnowMore = shouldKnowMore;
    }

    handleShowMoreBtnClick = (): void => {
      this.setShouldKnowMore(!this.getShouldKnowMore());
    };

    handleCopyClick = (): void => {
      const clipboard = window.navigator.clipboard;
      clipboard.writeText(this.trackingID);

      this.setIsCopied(true);
      setTimeout(() => {
        this.setIsCopied(false);
      }, COPY_CLICK_TIMEOUT);
    };

    handleRetryBtnClick = (): void => {
      this.dispatchEvent(
        new CustomEvent(QMW_ERR_RETRY, {
          bubbles: true,
          composed: true,
          detail: true
        })
      );
    };

    render(): TemplateResult {
      return html`
        <section class=${this.errorElement !== RECORDING ? "agentx-wc-qmw-error grid-height" : "agentx-wc-qmw-error"}>
          <div class="agentx-wc-qmw-error-content">
            <span class=${"agentx-wc-qmw-error-icon"}>
              <md-icon .name=${"icon-warning_32"} .color=${"red"}></md-icon>
            </span>
            <h1 class="agentx-wc-qmw-error-heading">${t("app:commonWebComp.loadFail")}</h1>
            <md-button @click=${this.handleRetryBtnClick} .color=${"blue"} .ariaLabel=${t("app:commonWebComp.tryAgain")}
              >${t("app:commonWebComp.tryAgain")}</md-button
            >

            ${this.trackingID
              ? html`
            <md-button @click=${
              this.handleShowMoreBtnClick
            } .color=${"color-none"} class=${"agentx-wc-qmw-error-toggle"}
            .ariaLabel= ${
              !this.shouldKnowMore ? t("app:errorDetail.errorMoreButton") : t("app:errorDetail.errorLessButton")
            }>
              <span class=${"agentx-wc-qmw-error-toggle-text"}>
              ${!this.shouldKnowMore ? t("app:errorDetail.errorMoreButton") : t("app:errorDetail.errorLessButton")}
              <span>
            </md-button>
            `
              : nothing}
            ${this.trackingID && this.shouldKnowMore
              ? html`
                  <div>
                    <p class="agentx-wc-qmw-error-detail">${t("app:qmw.pleaseShare")}</p>
                    <div class="agentx-wc-qmw-error-tracking-id-wrapper">
                      <p>${t("app:errorModal.trackingIdLabel")}: ${this.trackingID}</p>
                      <md-button
                        @click=${this.handleCopyClick}
                        .color=${"color-none"}
                        .ariaLabel=${t("app:errorModal.copy")}
                      >
                        <md-icon .name=${!this.isCopied ? "copy_14" : "icon-check_12"}></md-icon>
                      </md-button>
                    </div>
                  </div>
                `
              : nothing}
          </div>
        </section>
      `;
    }
  }
}
