import { i18nMixin } from "@/mixins/i18nMixin";
import "@momentum-ui/web-components";
import { customElement, html, LitElement, property } from "lit-element";
import style from "./style.scss";

const AgentState = { Available: "Available", Idle: "Idle" };
export namespace StateSelector {
  /**
   * @element agentx-wc-tpw-state-selector
   */
  @customElement("agentx-wc-tpw-state-selector")
  export class Element extends i18nMixin(LitElement) {
    @property({ reflect: true }) agentState = "";

    static get styles() {
      return style;
    }

    private getAgentStateDotClass() {
      switch (this.agentState) {
        case AgentState.Available:
          return "agent-available-dot";
        case AgentState.Idle:
          return "agent-idle-dot";
        default:
          return "agent-idle-dot";
      }
    }

    render() {
      return html`
        <md-chip
          class=${this.agentState === "Available" ? "agent-state-active" : "agent-state-idle"}
          value=${this.agentState}
        >
          <span slot="custom-left-content" class=${`agent-state-dot ${this.getAgentStateDotClass()}`}></span>
          <md-icon name="icon-arrow-down_16" slot="custom-right-content"></md-icon>
        </md-chip>
      `;
    }
  }
}
