import { logger } from "@/sdk";
import { customElement, html, LitElement, property, PropertyValues } from "lit-element";
import styles from "./ImiChatWrapper.scss";
import { nothing } from "lit-html";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { classMap } from "lit-html/directives/class-map";

export namespace ImiChatWrapper {
  export type EImiInitState = {
    detail: boolean;
  };
  /**
   * @element imi-chat-wrapper
   * @fires update-imi-init-status
   *
   */
  @customElement("imi-chat-wrapper")
  export class ImiChatWrapperWidget extends i18nMixin(LitElement) {
    @property({ type: String }) convId = "";
    @property({ type: Boolean, attribute: "is-dark-mode" }) isDarkMode = false;
    @property({ type: Boolean }) isLoading = true;

    update(changedProperties: PropertyValues) {
      super.update(changedProperties);
      changedProperties.forEach((oldValue, name) => {
        if (name === "convId") {
          this.evt("update-imi-init-status", true);
        }
      });
    }
    private evt<T extends { detail: any }>(name: string, detail: T["detail"]) {
      this.dispatchEvent(new CustomEvent(name, { bubbles: true, composed: true, detail }));
      logger.info("[WxmWrapper] Dispatching", name, detail);
    }

    connectedCallback() {
      super.connectedCallback();
      logger.info(`[IMIWrapper: Render] => Is imi-chat-widget defined`);
    }

    static get styles() {
      return styles;
    }

    render() {
      return html`
        <div class="imi-chat ${classMap({ hidden: this.isLoading })}">
          <imi-engage conversationid="${this.convId}" theme="${this.isDarkMode ? "DARK" : "LIGHT"}" lang="en-US">
          </imi-engage>
        </div>
        ${this.isLoading
          ? html`
              <div class="widget-loader">
                <div class="widget-loader-wrapper">
                  <md-spinner aria-label=${t("app:commonWebComp.loading")} size="56"></md-spinner>
                </div>
              </div>
            `
          : nothing}
      `;
    }
  }
}
