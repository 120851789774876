import { logger } from "@/sdk";
import { SERVICE, Service, getTrackingIdFromErrorObject } from "@agentx/agentx-services";
export const IDLE_CODE = "IDLE_CODE";

export const fetchIdleCodes = async (
  orgId: string,
  idleCodeConfig: any,
  isDesktopAuxCodesInfiniteScrollEnabled: boolean
) => {
  try {
    let params: any = { orgId, accessType: idleCodeConfig.accessType, ids: idleCodeConfig.ids };
    if (isDesktopAuxCodesInfiniteScrollEnabled) {
      // This block will execute when "desktop-aux-codes-infinite-scrolling" feature flag is ON.
      params = { ...params, page: 0, pageSize: 50 };
      const response = (await SERVICE.abs.fetchIdleCodes(params)) as Service.Aqm.Configs.agentStateIdleCodes;
      logger.info(
        `event=fetchIdleCodes Successfully fetched ${response.data.length} idle codes for page: ${params.page} `
      );
      return response;
    } else {
      // This block will execute when "desktop-maersk-CX-11355" feature flag is ON and "desktop-aux-codes-infinite-scrolling" is OFF.
      const response: any = (await SERVICE.abs.fetchIdleCodes(params)) as Service.Aqm.Configs.Entity[];
      logger.info(`event=fetchIdleCodes Successfully fetched ${response.length} idle codes`);
      return Array.isArray(response) ? response : [];
    }
  } catch (error) {
    logger.error(
      `event=fetchIdleCodes | Tracking ID: ${
        getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : ""
      } | Fetching idle codes from aux code is failed : ${error}`,
      error
    );
    throw error;
  }
};

export const fetchWrapUpCodes = async (orgId: string, wrapUpCodeConfig: any) => {
  try {
    const params = { orgId, accessType: wrapUpCodeConfig.accessType, ids: wrapUpCodeConfig.ids };
    const response = await SERVICE.abs.fetchWrapUpCodes(params);
    logger.info(`event=fetchWrapUpCodes Successfully fetched ${response.length} wrap up codes`);
    return Array.isArray(response) ? response : [];
  } catch (error) {
    logger.error(
      `event=fetchWrapUpCodes | Tracking ID: ${
        getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : ""
      } | Fetching wrap up codes from aux code is failed : ${error}`,
      error
    );
    throw error;
  }
};

const fetchDefaultAuxCodes = async (orgId: string): Promise<Service.Aqm.Configs.DefaultAuxCodes[]> => {
  try {
    const params = { orgId };
    const response = await SERVICE.abs.fetchDefaultAuxCodes(params);
    logger.info(`event=fetchDefaultAuxCodes : Successfully fetched ${response.length} default aux codes`);
    return response;
  } catch (error) {
    logger.error(
      `event=fetchDefaultIdleCodes | Tracking ID: ${
        getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : ""
      } | Fetching default aux code is failed : ${error}`,
      error
    );
    throw error;
  }
};

export const fetchAuxCodeValues = async (
  orgId: string,
  idleCodeConfig: any,
  wrapUpCodeConfig: any,
  isDesktopAuxCodesInfiniteScrollEnabled: boolean,
  shouldFetchDefaultIdleCode: boolean
) => {
  try {
    const apiCalls: any = [
      fetchIdleCodes(orgId, idleCodeConfig, isDesktopAuxCodesInfiniteScrollEnabled),
      fetchWrapUpCodes(orgId, wrapUpCodeConfig)
    ];
    if (isDesktopAuxCodesInfiniteScrollEnabled && shouldFetchDefaultIdleCode) {
      // fetch default aux code from api incase of infinite scroll
      apiCalls.push(fetchDefaultAuxCodes(orgId));
    }
    const [idleCodes, wrapUpCodes, defaultAuxCodes] = await Promise.all(apiCalls);
    return [idleCodes, wrapUpCodes, defaultAuxCodes];
  } catch (error) {
    logger.error(
      `event=fetchAuxCodeValues | Tracking ID: ${
        getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : ""
      } | Fetching fetchAuxCodeValues is failed : ${error}`,
      error
    );
    throw error;
  }
};
