import { FILTER_CHANGE_DELAY } from "@/constants";
import { t } from "@/mixins/i18nMixin";
import { Service } from "@agentx/agentx-services";
import { customElement, html, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import { ifDefined } from "lit-html/directives/if-defined";
import { styleMap } from "lit-html/directives/style-map";
import { debounce } from "./../../../utils/helpers";
import style from "./../index.scss";

@customElement("agentx-wc-queue-filter")
export class QueueFilter extends LitElement {
  @property({ type: Array, reflect: true }) selectedQueueList: Service.Conf.Queue[] = [];
  @property({ type: Array, reflect: true }) managedQueues: Service.Conf.Queue[] = [];
  @property({ type: Boolean, reflect: true }) isLoading = true;
  @property({ type: Boolean, reflect: true }) isError = false;

  languageResources = "/i18n/{{lng}}/{{ns}}.json";
  static get styles() {
    return style;
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener("reload", this.fetchQueue);
  }

  changeHandler = debounce((e: CustomEvent) => {
    sessionStorage.setItem("selected-queue-list", JSON.stringify(e.detail.selected));
    window.dispatchEvent(
      new CustomEvent("queue-filter-selected", {
        detail: e.detail.selected,
        bubbles: true,
        composed: true
      })
    );
  }, FILTER_CHANGE_DELAY);

  fetchQueue() {
    window.dispatchEvent(
      new CustomEvent("fetch-managed-queue", {
        detail: "",
        bubbles: true,
        composed: true
      })
    );
  }

  getFetchErrorMessage() {
    return JSON.stringify({
      header: t("app:errorDetail:errorTitle"),
      message: t("app:errorModal:trackingIdDescription"),
      trackingId: this.isError
    });
  }

  render() {
    return html`
      <div
        class="filter-container"
        style=${styleMap({
          width: `${this.style.width || "250px"}`
        })}
      >
        <md-combobox
          .options=${this.managedQueues}
          option-id="queueId"
          option-value="queueName"
          .value=${this.selectedQueueList}
          use-virtual-scroll
          shape=${"pill"}
          is-multi
          show-selected-count
          no-clear-icon
          allow-select-all
          placeholder=${t("app:supervisorProfile.selectQueue")}
          all-i18n=${t("app:common.all")}
          selected-all-i18n=${t("app:common.selected")}
          select-all-i18n=${t("app:tpw.selectAll")}
          @change-selected=${this.changeHandler}
          @combobox-on-expand=${this.fetchQueue}
          show-custom-error=${ifDefined(this.isError ? true : undefined)}
          show-loader=${ifDefined(this.isLoading ? true : undefined)}
          ariaLabel=${t("app:pageSubHeader.queueName")}
          comboboxId="inp-queueName"
        >
          ${this.isLoading
            ? html`
                <div slot="custom-loader" part="custom-loader">
                  <agentx-wc-widget-loader></agentx-wc-widget-loader>
                </div>
              `
            : nothing}
          ${this.isError
            ? html`
                <div slot="custom-error" part="custom-error">
                  <agentx-wc-widget-error detail="${this.getFetchErrorMessage()}"></agentx-wc-widget-error>
                </div>
              `
            : nothing}
        </md-combobox>
      </div>
    `;
  }
}
