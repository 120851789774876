/* eslint-disable sonarjs/no-duplicate-string */
import { Service } from "@agentx/agentx-services";
export const CONFERENCE = "conference";
export const CONSULTING = "consulting";
export const Play = "play";

export enum Key {
  End = "End",
  Home = "Home",
  Enter = "Enter",
  Tab = "Tab",
  ArrowDown = "ArrowDown",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  ArrowUp = "ArrowUp",
  Delete = "Delete",
  Space = "Space",
  Escape = "Escape"
}

export const MEDIA_TYPES = {
  CHAT: "chat",
  EMAIL: "email",
  VOICE: "telephony",
  SOCIAL: "social",
  SMS: "sms",
  FACEBOOK: "facebook",
  WHATSAPP: "whatsapp",
  MIDCALL_MONITORING: "midcall telephony",
  APPLE_BUSINESS_CHAT: "appleMessages",
  GOOGLE_BUSINESS_CHAT: "googleMessages",
  OUTBOUND_AGENT_INITIATED_EMAIL: "outboundEmail",
  OUTBOUND_AGENT_INITIATED_SMS: "outboundSms"
};

export const DEFAULT_DEVICE_TYPE: Service.Conf.LoginOption[] = ["AGENT_DN", "EXTENSION"];
export const WEB_RTC_PREFIX = "webrtc-";

export const TASKLIST_ITEMSTATUS = {
  OUTBOUND_ICON: "outbound telephony",
  CALLBACK_ICON: "callback",
  CAMPAIGN_ICON: "progressive_campaign"
};

export const CONTACT_DIRECTION = {
  INBOUND: "INBOUND",
  OUTBOUND: "OUTBOUND"
};

export const OUTBOUND_TYPE = {
  CAMPAIGN: "CAMPAIGN",
  COURTESY_CALLBACK: "COURTESY_CALLBACK",
  CALLBACK: "CALLBACK",
  PROGRESSIVE_CAMPAIGN: "PROGRESSIVE_CAMPAIGN",
  CAMPAIGN_OUTDIAL_CUSTOMER: "CAMPAIGN_OUTDIAL_CUSTOMER",
  CAMPAIGN_PREDICTIVE_OUTDIAL_CUSTOMER: "CAMPAIGN_PREDICTIVE_OUTDIAL_CUSTOMER",
  OUTDIAL_RESERVATION: "OUTDIAL_RESERVATION"
};
export const SUPERVISOR = "supervisor";
export const AGENT = "agent";
export const SUPERVISOR_AND_AGENT = "supervisor and agent";
export const AGENT_AND_SUPERVISOR = "agent_supervisor";
export const channelList: Service.Aqm.Configs.ChannelList = [
  { name: "Voice", value: "telephony" },
  { name: "Chat", value: "chat" },
  { name: "Email", value: "email" },
  { name: "Social", value: "social" }
];
export const LOGGED_OUT = "LoggedOut";
export const SIGNED_OUT = "Signed Out";
export const AVAILABLE = "Available";
export const AVAILABLE_ID = "0";
export const IDLE = "Idle";
export const DELETE = "DELETE";
export const TELEPHONY = "telephony";

export const TIMER_FIELDS = ["contactStatusTime", "totalContactDuration", "agentInteractionTime"];

//tpw custom events
export const TPW_FILTER_DATA_UPDATE = "tpw-filter-data-update";
export const TPW_FILTER_UPDATED = "tpw-filter-updated";
export const TPW_MONITOR_MODAL = "tpw-monitor-modal";
export const TPW_EMPTY_COLUMN_VALUE = "-";
export const TPW_CONTACT_UPDATE = "tpw-contact-update";
export const TPW_FILTER_MODAL_UPDATED = "tpw-applied-filters-updated";
export const ACTIONS = "actions";
export const FLOW_ACTIVITY = "flow-activity";
export const MONITORING_STARTED = "monitoring-started";
export const MONITORING_ENDED = "monitoring-ended";
export const MONITORING_REQUESTED = "monitoring-requested";
export const MONITORING_HOLD = "monitoring-hold";
export const MONITORING_UNHOLD = "monitoring-unhold";
export const MONITORING_ERROR = "monitoring-error";
export const CONTACT_COMPLETED = "contact-completed";
export const CONTACT_STATS_HISTORICAL_EVENTS = [CONTACT_COMPLETED, "wrapup-completed", "treatment-completed", "parked"];
export const INBOUND = "inbound";
export const CONFERENCE_REQUESTED = "conference-requested";
export const CONFERENCE_STARTED = "conference-started";
export const HOLD_COMPLETED = "hold-completed";
export const HOLD_COMPLETED_CONFERENCE = "hold-completed-conference";
export const HOLD_COMPLETED_CONSULT = "hold-completed-consult";
export const HOLD_STARTED_CONFERENCE = "hold-started-conference";
export const CONSULT_REQUESTED = "consult-requested";
export const CONSULT_STARTED = "consult-started";
export const JOIN_CONFERENCE = "join-conference";
export const EXIT_CONFERENCE = "exit-conference";
export const PRIMARY_OWNER_CHANGED = "primary-owner-changed";
export const TRANSFER_COMPLETED = "transfer-completed";
export const QUEUE_TRANSFER_COMPLETED = "queueTransfer-completed";
export const EP_TRANSFER_COMPLETED = "epTransfer-complete";
export const BLIND_TRANSFER_COMPLETED = "blindTransfer-completed";
export const WRAP_UP = "wrapup";
export const WRAP_UP_ASSISTANCE = "wrapup-assistance";
export const CALL_ENDED = "call-ended";
export const OPEN_REVIEW_AND_MONITOR_MODAL = "open-review-and-monitor-modal";
export const OPEN_SEND_MESSAGE_MODAL = "open-send-message-modal";
export const FILTER_CHANGE_DELAY = 500;
export const REALTIME_FEED = "RealtimeFeed";
export const SUBSCRIPTION_RESPONSE = "SubscriptionResponse";
export const CONNECTED_CONSULTING = "connected-consulting";
export const IDLE_CONSULTING = "idle-consulting";
export const AVAILABLE_CONSULTING = "available-consulting";
export const CONNECTED = "connected";
export const ON_HOLD = "on-hold";
export const CONFERENCING = "conferencing";
export const CONSULT_DONE = "consult-done";
export const CONSULT_ERROR = "consult-error";
export const CAPTURE_STARTED = "capture-started";
export const HOLD_STARTED = "hold-started";
export const HOLD_STARTED_CONSULT = "hold-completed-consult";
export const WRAP_UP_COMPLETED = "wraup-completed";
export const CONSULT_COMPLETED = "consult-completed";
export const CONFERENCE_COMPLETED = "conference-completed";
export const AGENTCONNECT_COMPLETED = "agentConnect-completed";

export const PROFILE_SETTING_INVALID_DATA_EVENT = "profile-settings-invalid-data";
export const HANDLE_AID_MODAL_EVENT = "handle-aid-modal";
export const HANDLE_WEBEX_CHAT_EVENT = "handle-webex-chat";
export const HANDLE_WEBEX_CHAT_CLOSE_EVENT = "webexchat-overlay-close";
export const HANDLE_STATE_CHANGE_EVENT = "handle-state-change";
export const UPDATE_ACTION_CELL_VALUE_EVENT = "update-actions-cell-value";
export const STATE_CHANGE_OVERLAY_CLOSE_EVENT = "state-change-overlay-close";

export const REVIEW_BUTTON_LIGHT = "/images/illustrations/Monitoring-regular-light.svg";
export const REVIEW_BUTTON_DARK = "/images/illustrations/Monitoring-regular-dark.svg";

export const REVIEW_BUTTON_LIGHT_DISABLED = "/images/illustrations/LightMode-Monitoring-Disabled.svg";
export const REVIEW_BUTTON_DARK_DISABLED = "/images/illustrations/DarkMode-Monitoring-Disabled.svg";
export const SUPERVISOR_AUTO_LOGIN_EVENT = "supervisor-auto-login";
export const GRID_ROW_MODAL = {
  GROUP: "group"
};

export const CONTACT_TRANSFERRED_EVENTS = [
  TRANSFER_COMPLETED,
  QUEUE_TRANSFER_COMPLETED,
  EP_TRANSFER_COMPLETED,
  BLIND_TRANSFER_COMPLETED
];

// List of Contact status events, that define the interaction is active.
export const CONTACT_ACTIVE_EVENTS = [
  "connected",
  "consulting",
  "on-hold",
  "hold-done",
  "consult-done",
  "consult",
  "conferencing",
  "available-consulting",
  "connected-consulting",
  "idle-consulting"
];

export const AGENTX_WEBEX_WRAPPER_CLOSE = "agentx-webex-wrapper-close";
export const AGENTX_WEBEX_MINIMIZE_RESTORE = "agentx-webex-minimize-restore";
export const AGENT_STATE_CHANGE_FAILED = "agent-state-change-failed";

export const HELP_LINK = {
  AGENT_HELP: "https://help.webex.com/en-us/landing/ld-sxjj9p-WebexContactCenter/Agent",
  SUPERVISOR_HELP: "https://help.webex.com/en-us/landing/ld-sxjj9p-WebexContactCenter/Supervisor"
};

export const WEB_RTC_STATUS = {
  WEB_RTC_CONNECTED: "webRtcConnected",
  WEB_RTC_REGISTERING: "webRtcRegistering",
  WEB_RTC_DISCONNECTED: "webRtcDisconnected"
};
export const ACCESS_IDLE_CODES = {
  ALL: "ALL",
  SPECIFIC: "SPECIFIC"
};

export const CHANGED_BY_ROLE = {
  SELF: "SELF",
  SUPERVISOR: "SUPERVISOR",
  ADMIN: "ADMIN",
  SYSTEM: "SYSTEM"
};

export const DESKTOP = "Desktop";
export const APPLE_MESSAGE_DARK_SVG = "/images/illustrations/apple-messages-dark.svg";
export const APPLE_MESSAGE_LIGHT_SVG = "/images/illustrations/apple-messages-light.svg";
export const MONITORING_REGULAR_DARK = "/images/illustrations/Monitoring-regular-dark.svg";
export const MONITORING_REGULAR_LIGHT = "/images/illustrations/Monitoring-regular-light.svg";
export const TIMEOUT_THOUSAND_MS = 1000;
export const MIN_WIDTH = "227px";
export const INITIATE_MS_AUTH_LOGIN = "initiate-microsoft-auth-flow";
export const TPW_DATA_WAIT_TIME = 20000;
export const PRESENCE_SYNC = "PRESENCE_SYNC";
export const emergency911HelpLink = "https://help.webex.com/mwjlgk";

export const WEBRTC_SHORTCUT_KEYS: Service.shortcut.KeyInfo[] = [
  {
    widgetElement: "agentx-react-interaction-control",
    group: "app:keyboardShortcutKeys.interactionControl.componentName",
    action: "app:keyboardShortcutKeys.interactionControl.muteOrUnmuteWebCall",
    modifierKeys: "ctrlKey_shiftKey",
    key: "n",
    role: "agent",
    deviceType: "BROWSER"
  },
  {
    widgetElement: "agentx-react-interaction-control",
    group: "app:keyboardShortcutKeys.interactionControl.componentName",
    action: "app:keyboardShortcutKeys.interactionControl.openKeyboard",
    modifierKeys: "ctrlKey_altKey",
    key: "k",
    role: "agent",
    deviceType: "BROWSER"
  }
];
export const ICON_SRC = "icon src";
export const DIGITAL_OUTBOUND = "app:outboundMessage";
export const OUT_DIAL = "OUTDIAL";

export const COLUMN_MENU_FOCUS = "column-menu-focus";
export const OUTDIAL = "outdial";
export const TIMEOUT_TEN_MS = 10;
