import { t } from "@/mixins/i18nMixin";
import { SuppressKeyboardEventParams } from "ag-grid-enterprise";
import { Duration } from "luxon";
import ActionsRenderer from "./components/ActionsRenderer";
import { MAX_ROWS_IN_RESPONSE, SESSION_ID_COLUMN_WIDTH } from "./constants";
import { dateFormatterShortWithSeconds } from "./utils";
import { suppressHeaderKeyboardEvent } from "../TeamPerformanceWidget/utils/KeyboardNavigationUtils";

type QMWApiResponse = {
  data: {
    id: string;
    destination: string;
    createdTime: number;
    lastQueue: {
      name: string;
    };
    lastTeam: {
      name: string;
    };
    lastWrapupCodeName: string;
    origin: string;
    owner: {
      name: string;
    };
    connectedDuration: number;
  };
};

export const getAgent = (params: QMWApiResponse): string => {
  return params.data.owner.name;
};

export const getTeam = (params: QMWApiResponse): string => {
  return params.data.lastTeam.name;
};

export const getQueue = (params: QMWApiResponse): string => {
  return params.data.lastQueue.name;
};

export const getDuration = (params: QMWApiResponse): string => {
  return Duration.fromMillis(params.data.connectedDuration)
    .shiftTo("hours", "minutes", "seconds")
    .toFormat("hh:mm:ss");
};

export const getDate = (params: QMWApiResponse): string => {
  return dateFormatterShortWithSeconds(params.data.createdTime);
};

export const suppressKeyboardEvent = (params: SuppressKeyboardEventParams): boolean => {
  const { event } = params;

  if (event.code === "Tab" || event.key === "Tab") {
    const cellElement: HTMLElement | null =
      event.target instanceof HTMLElement ? event.target.closest(".ag-cell") : null;

    if (!cellElement) {
      return false; // Cell element not found, do not suppress
    }

    const focusableChildElements: NodeListOf<HTMLElement> = cellElement.querySelectorAll<HTMLElement>(
      'button, [href], input:not(.ag-hidden), select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    const forwardTab = !event.shiftKey;
    const reverseTab = event.shiftKey;
    const lastFocusableElement = focusableChildElements[focusableChildElements.length - 1];
    const firstFocusableElement = focusableChildElements[0];

    if (
      focusableChildElements.length === 0 ||
      (forwardTab && event.target === lastFocusableElement) ||
      (reverseTab && event.target === firstFocusableElement)
    ) {
      return false; // Do not suppress
    } else if (reverseTab && event.target === cellElement) {
      event.preventDefault();
      firstFocusableElement.focus(); // Set focus on the first focusable child element
      return true; // Suppress
    }
    return true; // Suppress
  } else {
    return false; // Do not suppress by default
  }
};

export const getBaseGridConfigs = (): { [key: string]: any } => {
  return {
    components: {
      actionsRenderer: ActionsRenderer
    },
    columnDefs: [
      {
        field: "id",
        headerName: `${t("app:qmw.sessionId")}`,
        suppressMenu: true,
        width: SESSION_ID_COLUMN_WIDTH
      },
      {
        headerName: `${t("app:qmw.queue")}`,
        suppressMenu: true,
        valueGetter: getQueue
      },
      {
        headerName: `${t("app:qmw.team")}`,
        suppressMenu: true,
        valueGetter: getTeam
      },
      {
        headerName: `${t("app:qmw.agent")}`,
        suppressMenu: true,
        valueGetter: getAgent
      },
      {
        field: "origin",
        headerName: `${t("app:qmw.ani")}`,
        suppressMenu: true
      },
      {
        field: "destination",
        headerName: `${t("app:qmw.dnis")}`,
        suppressMenu: true
      },
      {
        headerName: `${t("app:qmw.duration")}`,
        suppressMenu: true,
        valueGetter: getDuration
      },
      {
        field: "lastWrapupCodeName",
        headerName: `${t("app:qmw.wrapupCodes")}`,
        suppressMenu: true
      },
      {
        headerName: `${t("app:qmw.date")}`,
        suppressMenu: true,
        valueGetter: getDate
      },
      {
        headerName: `${t("app:qmw.actions")}`,
        suppressMenu: true,
        pinned: "right",
        lockPinned: "true",
        cellRenderer: "actionsRenderer",
        field: "actions",
        width: 117,
        cellEditorParams: {
          i18n: {
            playRecording: t("app:qmw.playRecording")
          }
        }
      }
    ],
    defaultColDef: {
      suppressKeyboardEvent,
      suppressHeaderKeyboardEvent,
      resizable: true
    },
    rowModelType: "serverSide",
    cacheBlockSize: MAX_ROWS_IN_RESPONSE,
    maxBlocksInCache: 2,
    suppressContextMenu: true,
    blockLoadDebounceMillis: 1000,
    localeText: {
      loadingOoo: t("app:qmw.loading")
    }
  };
};
