/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin } from "@/mixins/i18nMixin";
import { Service } from "@agentx/agentx-services";
import "@momentum-ui/web-components";
import { customElement, html, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import { ifDefined } from "lit-html/directives/if-defined";
import { repeat } from "lit-html/directives/repeat";
import { getColorByIndex, hasParamData } from "../../utils/utils";
import "../Intent/Intent";
import style from "./IntentWrapper.scss";

export namespace IntentWrapper {
  /**
   * @element agentx-wc-ivr-intent-wrapper
   * @fires on-intent-select
   */
  @customElement("agentx-wc-ivr-intent-wrapper")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Array }) transcriptList: Service.MediaStorage.IvrConversations | null = null;

    static get styles() {
      return style;
    }

    private readonly toggleIntentSelection = (index: number) => {
      this.dispatchEvent(
        new CustomEvent<ESelectedIntent>("on-intent-select", {
          detail: {
            index
          }
        })
      );
    };

    renderIntentList = (transcriptList: Service.MediaStorage.IvrConversations) => {
      let colorIndex = -1;
      return repeat(transcriptList, (transcript, index) => {
        if (transcript.bot?.parameters && hasParamData(transcript.bot)) {
          colorIndex++;
        }
        return transcript.bot?.parameters && hasParamData(transcript.bot)
          ? html`
              <agentx-wc-ivr-intent
                confidence=${transcript.bot.confidence}
                intentName=${ifDefined(transcript.bot.intentName)}
                color=${getColorByIndex(colorIndex)}
                timestamp=${transcript.bot.timestamp}
                .params=${transcript.bot.parameters}
                tabindex=${0}
                class="ivr-intent-item"
                @focus="${() => this.toggleIntentSelection(index)}"
                @blur="${() => this.toggleIntentSelection(-1)}"
              ></agentx-wc-ivr-intent>
            `
          : nothing;
      });
    };

    render() {
      return (
        this.transcriptList &&
        html`
          <div class="scroll-content">
            <div class="ivr-intent-wrapper">
              ${this.renderIntentList(this.transcriptList)}
            </div>
          </div>
        `
      );
    }
  }

  export type ESelectedIntent = { index: number };
}
