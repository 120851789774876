/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin, t } from "@/mixins/i18nMixin";
import "@momentum-ui/web-components";
import { LitElement, customElement, html, property } from "lit-element";
import { nothing } from "lit-html";
import style from "../../styles.scss";

export namespace MoreActionsWidget {
  /**
   * @element agentx-wc-more-actions-widget
   */
  @customElement("agentx-wc-more-actions-widget")
  export class Element extends i18nMixin(LitElement) {
    @property({ attribute: "tabs-unique-id" }) tabsUniqueId = "";
    @property({ type: Boolean }) isResetTabOrderEnabled = false;
    @property({ type: Boolean }) isMenuOpen = false;

    static get styles() {
      return style;
    }

    render() {
      return html`
        <md-menu-overlay
          custom-width="226px"
          size="small"
          @menu-overlay-open="${() => {
            this.isMenuOpen = true;
            document.dispatchEvent(new CustomEvent("agentx-more-actions-opened"));
          }}"
          @menu-overlay-close="${() => {
            this.isMenuOpen = false;
          }}"
          class="more-actions-menu-overlay"
        >
          <md-tooltip placement="top" message="${t("app:notes.moreActions")}" slot="menu-trigger">
            <md-button
              circle
              type="button"
              ariaLabel="${t("app:notes.moreActions")}"
              class="menu-trigger-button more-actions-header-button"
              ariaExpanded="${this.isMenuOpen ? "true" : "false"}"
            >
              <md-icon name="icon-more-adr_16"></md-icon>
            </md-button>
          </md-tooltip>
          <div class="show-more-actions-list">
            ${this.isResetTabOrderEnabled
              ? html`
                  <agentx-wc-reset-tab-order tabs-unique-id=${this.tabsUniqueId}></agentx-wc-reset-tab-order>
                `
              : nothing}
            <slot name="custom-action"></slot>
          </div>
        </md-menu-overlay>
      `;
    }
  }
}
