/* eslint-disable @typescript-eslint/no-explicit-any */
import { CONF } from "@/config";
import { MIN_WIDTH, TIMEOUT_THOUSAND_MS } from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import "@momentum-ui/web-components";
import "@uuip/unified-ui-platform-common-components";
import { LitElement, customElement, html, property } from "lit-element";
import { repeat } from "lit-html/directives/repeat";
import style from "./AgentPerformanceReports.scss";
import { analyzerSessionHelper } from "./AnalyzerSessionHelper";
import { agentReportList } from "./agentReportList";

export namespace AgentPerformanceReports {
  /**
   * @element agentx-wc-agent-performance-reports
   */
  @customElement("agentx-wc-agent-performance-reports")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Boolean, attribute: "is-dark-theme" }) isDarkTheme = false;
    @property({ type: Array }) agentReportList: ReportTab[] = agentReportList;
    @property({ attribute: false }) isAnalyzerSessionSet = false;
    static get styles() {
      return style;
    }

    async connectedCallback() {
      super.connectedCallback();
      this.isAnalyzerSessionSet = await analyzerSessionHelper.create();
      this.addEventListener("selected-changed", this.handleTabClick as EventListener);
      agentReportList.forEach((data, index) => {
        this.postApsMessage(data.tabId, data.reportId, t(`app:apsReports.${data.tabId}`), `report-${index}`);
      });
      this.setMoreActionWidth();
    }

    setMoreActionWidth = async () => {
      await this.updateComplete;
      setTimeout(() => {
        const overlayContent = this.shadowRoot
          ?.querySelector("md-tabs")
          ?.shadowRoot?.querySelector("md-menu-overlay")
          ?.shadowRoot?.querySelector(".overlay-content");
        if (overlayContent) {
          // Default custom width for menu-overlay is 227px.
          overlayContent.style.minWidth = MIN_WIDTH;
          overlayContent.style.width = "max-content";
        }
      }, TIMEOUT_THOUSAND_MS);
    };

    handleTabClick(event: any) {
      const tabTitle: string = t(`app:apsReports.${agentReportList[event.detail.value].tabId}`);
      const id = `report-${event.detail.value}`;
      this.postApsMessage(
        agentReportList[event.detail.value].tabId,
        agentReportList[event.detail.value].reportId,
        tabTitle,
        id
      );
    }

    postApsMessage(tabId: string, reportId: string, tabTitle: string, id: string) {
      const message = {
        event: "onReportClick",
        data: { reportId, reportName: tabTitle, tabId }
      };
      const frameElem: HTMLIFrameElement | null | undefined = this.shadowRoot?.querySelector(`#${id}`);
      if (frameElem?.contentWindow) {
        frameElem.contentWindow.postMessage(JSON.stringify(message), "*");
      }
    }

    render() {
      return this.isAnalyzerSessionSet
        ? html`
            <div class="agent-performance-reports-container">
              <md-tabs
                id="agent-performance-reports-tabs"
                persist-selection=${true}
                overflowLabel=${t(`app:common.moreTabs`)}
                tabs-id="aps-tabs"
              >
                ${repeat(this.agentReportList, ({ tabId, reportId, isDashboard }: ReportTab, index: number) => {
                  const tabTitle: string = t(`app:apsReports.${tabId}`);
                  const id = `report-${index}`;
                  return html`
                    <md-tab slot="tab" label="${tabTitle}" aria-label="${tabTitle}">${tabTitle}</md-tab>
                    <md-tab-panel slot="panel" class="aps-tab-panel">
                      <iframe
                        src="${CONF.ANALYZER_HOST}/analyzer/view/${isDashboard
                          ? "embed"
                          : "iframe"}?rId=${reportId}&is_dark_theme=${this.isDarkTheme}"
                        title="${tabTitle}"
                        id="${id}"
                        frameborder="0"
                        class="report-frame"
                      ></iframe>
                    </md-tab-panel>
                  `;
                })}
              </md-tabs>
            </div>
          `
        : html`
            <uuip-wc-content-loading></uuip-wc-content-loading>
          `;
    }
  }

  export type ReportTab = {
    reportId: string;
    isDashboard: boolean;
    tabId: string;
  };
}
