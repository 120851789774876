import { Navigation } from "@/components/Navigation/Navigation";
import {
  CONTACT_DIRECTION,
  MEDIA_TYPES,
  MONITORING_REGULAR_DARK,
  MONITORING_REGULAR_LIGHT,
  OUT_DIAL,
  WEBRTC_SHORTCUT_KEYS
} from "@/constants";
import { SERVICE } from "@agentx/agentx-services";
import { DateTime } from "luxon";

export const throttle = (fn: (...args: any[]) => unknown, delay = 1000 / 60) => {
  let lastTimeoutRef: number | undefined = undefined;
  let last = Date.now();

  // Throttling
  const next = (...args: any[]) => {
    const now = Date.now();
    window.clearTimeout(lastTimeoutRef);

    if (!last || now - last >= delay) {
      last = now;
      fn.call(fn, ...args);
    } else {
      lastTimeoutRef = window.setTimeout(() => next(...args), delay - (now - last));
    }
  };

  return (...args: any[]) => next(...args);
};

export const isRegistered = (name: string) => {
  return document.createElement(name).constructor !== HTMLElement;
};

export const isTaskItemCardVisible = (
  cardElement: HTMLElement,
  cardContainerElement: HTMLElement
): { arrowDir: string; isVisible: boolean } => {
  const data = {
    arrowDir: "",
    isVisible: false
  };
  if (cardElement.getBoundingClientRect().top < 65 && cardContainerElement instanceof HTMLElement) {
    data.isVisible = false;
    data.arrowDir = "top";
  } else if (cardElement.getBoundingClientRect().top > cardContainerElement.clientHeight) {
    data.isVisible = false;
    data.arrowDir = "down";
  } else {
    data.isVisible = true;
  }
  return data;
};

/**
 * Debounce method to avoid repetative  call
 */
export const debounce = (fn: any, time: number) => {
  let timer: number;
  return function(...args: any[]) {
    const func = () => fn.call({}, args[0]);
    clearTimeout(timer);
    timer = window.setTimeout(func, time);
  };
};

export const checkForMixPanelTracking = (navigateTo: Navigation.Item["navigateTo"]): void => {
  SERVICE.telemetry.setValueInCache(navigateTo, new Date().getTime());
};

export const stripSpecialChar = (str: string) => {
  /*eslint no-useless-escape: "off"*/
  return str.replace(/[^\+?\d]/gi, "");
};

export const isWebRtcSupportedBrowser = () => {
  return (navigator?.userAgent?.includes("Chrome") && !navigator?.userAgent?.includes("Edg")) ?? false;
};

export const registerWebRTCShortcuts = () => {
  SERVICE.shortcut.register(WEBRTC_SHORTCUT_KEYS);
};

export const removeWebRTCShortcuts = () => {
  const shortcutKeyMap = Array.from(SERVICE.shortcut.getRegisteredKeys().values());
  SERVICE.shortcut.unregisterAllKeys();
  SERVICE.shortcut.register(shortcutKeyMap.filter(data => !data.deviceType || data.deviceType !== "BROWSER"));
};

export const isOutboundContact = (direction: string, outboundType: string | undefined) => {
  return direction === CONTACT_DIRECTION.OUTBOUND && outboundType === OUT_DIAL;
};

export const getMediaChannelForTaskList = (
  mediaChannel: string | undefined,
  direction: string,
  outboundType: string | undefined
) => {
  if (isOutboundContact(direction, outboundType ?? "")) {
    if (mediaChannel && mediaChannel === MEDIA_TYPES.EMAIL) {
      return MEDIA_TYPES.OUTBOUND_AGENT_INITIATED_EMAIL;
    }
    if (mediaChannel && mediaChannel === MEDIA_TYPES.SMS) {
      return MEDIA_TYPES.OUTBOUND_AGENT_INITIATED_SMS;
    }
    return mediaChannel;
  }
  return mediaChannel;
};

/**
 *
 * @param mediaType
 * @param mediaChannel
 * @returns
 */

export const getImageSrcTaskList = (
  mediaType: string,
  mediaChannel: string | undefined,
  isDarkMode: boolean,
  selected = false,
  contactDirection: string,
  outboundType: string | undefined
) => {
  let imgSrc;
  const derivedMediaChannel = getMediaChannelForTaskList(mediaChannel, contactDirection, outboundType);
  if (mediaType === MEDIA_TYPES.MIDCALL_MONITORING) {
    imgSrc = isDarkMode ? MONITORING_REGULAR_DARK : MONITORING_REGULAR_LIGHT;
  } else if (
    derivedMediaChannel &&
    SERVICE.constants.MEDIA_SUBCHANNEL_TYPES[
      derivedMediaChannel as keyof typeof SERVICE.constants.MEDIA_SUBCHANNEL_TYPES
    ]
  ) {
    if (selected) {
      imgSrc = isDarkMode
        ? SERVICE.constants.MEDIA_SUBCHANNEL_TYPES[
            derivedMediaChannel as keyof typeof SERVICE.constants.MEDIA_SUBCHANNEL_TYPES
          ]["normal"].dark
        : SERVICE.constants.MEDIA_SUBCHANNEL_TYPES[
            derivedMediaChannel as keyof typeof SERVICE.constants.MEDIA_SUBCHANNEL_TYPES
          ]["normal"].light;
    } else {
      imgSrc = isDarkMode
        ? SERVICE.constants.MEDIA_SUBCHANNEL_TYPES[
            derivedMediaChannel as keyof typeof SERVICE.constants.MEDIA_SUBCHANNEL_TYPES
          ]["unselected"].dark
        : SERVICE.constants.MEDIA_SUBCHANNEL_TYPES[
            derivedMediaChannel as keyof typeof SERVICE.constants.MEDIA_SUBCHANNEL_TYPES
          ]["unselected"].light;
    }
  }
  return imgSrc;
};

export const isDigitalOutboundCase = (mediaChannel: string, contactDirection: string, outboundType: string) => {
  return (
    contactDirection === CONTACT_DIRECTION.OUTBOUND &&
    outboundType === OUT_DIAL &&
    (mediaChannel === MEDIA_TYPES.EMAIL || mediaChannel === MEDIA_TYPES.SMS)
  );
};

/**
 *
 * @param mediaType
 * @param mediaChannel
 * @returns
 */
export const isIconSrc = (
  mediaType: string,
  mediaChannel: string | undefined,
  contactDirection: string,
  outboundType: string
) => {
  return (
    mediaType === MEDIA_TYPES.MIDCALL_MONITORING ||
    isDigitalOutboundCase(mediaChannel ?? "", contactDirection, outboundType) ||
    (mediaChannel &&
    SERVICE.constants.MEDIA_SUBCHANNEL_TYPES[mediaChannel as keyof typeof SERVICE.constants.MEDIA_SUBCHANNEL_TYPES]
      ? true
      : false)
  );
};

export const getTimerLabel = (timer: string): string => {
  const timeUnits = ["hour", "minute", "second"];
  const parts = timer
    .split(":")
    .map(Number)
    .map((value, i) => (value > 0 ? `${value} ${timeUnits[i]}${value !== 1 ? "s" : ""}` : ""))
    .filter(Boolean);
  return `${parts.join(", ")}`;
};

export const makeTelephonyReadable = (num?: string): string => {
  return num
    ? num
        .split("")
        .join(" ")
        .replace(/ {2}/g, " ")
    : "";
};

export const updateTimerAriaLabel = (timeStamp: number): string => {
  const now = DateTime.utc();
  const taskTime = DateTime.fromMillis(timeStamp);
  const diff = now.diff(taskTime);
  const formattedDifference = diff.toFormat("hh:mm:ss");
  return getTimerLabel(formattedDifference);
};

export const makeAlphaNumericReadable = (inputString: string | undefined): string => {
  if (!inputString) {
    return "";
  }
  const isDigit = (char: string) => /\d/.test(char);
  const modifiedStr = inputString
    .split("")
    .map(char => {
      if (isDigit(char)) {
        return ` ${char} `;
      }
      return char;
    })
    .join("");
  return modifiedStr.replace(/\s+/g, " ").trim();
};

/**
 * Validates the use-case for digital outbound contacts for auto-accept.
 * @param mediaChannel - i.e. email, sms etc.
 * @param contactDirection - i.e. INBOUND, OUTBOUND etc.
 * @param outboundType - i.e. OUTDIAL etc.
 * Returns a boolean.
 */
