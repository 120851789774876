import { i18nMixin, t } from "@/mixins/i18nMixin";
import { customElement, html, internalProperty, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import { DateTime } from "luxon";
import { QMW_FILTERS_UPDATED } from "../constants";
import { dateFormatterShortWithSeconds } from "../utils";
import style from "./GridHeader.scss";
export namespace QualityMonitoringGridHeader {
  /**
   * @element agentx-wc-qmw-filter-header
   */
  @customElement("agentx-wc-qmw-filter-header")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Number }) startDate = DateTime.local()
      .minus({ days: 1 })
      .toMillis();
    @property({ type: Number }) endDate = DateTime.local()
      .minus({ seconds: 1 })
      .toMillis();
    @internalProperty() activeFilters = [
      {
        filterName: "date-filter",
        displayIcon: "calendar-month",
        displayText: "",
        tooltipTranslateLink: "app:qmw:dateRangeFilterTooltip"
      }
    ];
    @internalProperty() filterPanelShow = false;
    @internalProperty() startDatePickerValue = "";
    @internalProperty() endDatePickerValue = "";
    @internalProperty() maxStartDate = "";
    @internalProperty() maxEndDate = "";
    @internalProperty() minStartDate = "";
    @internalProperty() minEndDate = "";
    @internalProperty() boundHandleKeyDown: any = {};
    @internalProperty() isFormInvalid = false;

    static get styles(): CSSResult {
      return style;
    }

    connectedCallback(): void {
      super.connectedCallback();
      this.setInitialDatePickerBounds();
      this.boundHandleKeyDown = this.handleKeyDown.bind(this);
      document.addEventListener("keydown", this.boundHandleKeyDown);
      document.addEventListener("click", this.handleOutsideFilterPanelClick);
    }

    firstUpdated(changedProperties: PropertyValues): void {
      super.firstUpdated(changedProperties);
      this.updateDateFilterPillText();
      this.updateDatePickerValues();
    }

    update(changedProperties: PropertyValues): void {
      super.update(changedProperties);
    }

    disconnectedCallback(): void {
      super.disconnectedCallback();
      document.removeEventListener("keydown", this.boundHandleKeyDown);
      document.removeEventListener("click", this.handleOutsideFilterPanelClick);
    }

    dateTimePickerBoundsFormat(date: number): string {
      return DateTime.fromMillis(date).toISODate() || "";
    }

    updateDateFilterPillText(): void {
      const dateFilterIndex = this.activeFilters.map(filter => filter.filterName).indexOf("date-filter");
      this.activeFilters[dateFilterIndex].displayText = `${dateFormatterShortWithSeconds(
        this.startDate
      )} - ${dateFormatterShortWithSeconds(this.endDate)}`;
    }

    updateDatePickerValues(): void {
      this.startDatePickerValue =
        DateTime.fromMillis(this.startDate)
          .startOf("second")
          .toISO({ suppressMilliseconds: true }) || "";
      this.endDatePickerValue =
        DateTime.fromMillis(this.endDate)
          .startOf("second")
          .toISO({ suppressMilliseconds: true }) || "";
    }

    toggleFilterPanel(): void {
      this.filterPanelShow = !this.filterPanelShow;
      const defaultStartDate = DateTime.local()
        .minus({ days: 1 })
        .toMillis();
      const defaultEndDate = DateTime.local()
        .minus({ days: 1 })
        .toMillis();
      // If a user wants to pick a new date range they shouldn't be restricted
      // within a month of the previous range
      if (this.filterPanelShow) {
        this.isFormInvalid = false;
        if (this.startDate !== defaultStartDate || this.endDate !== defaultEndDate) {
          // If a previously applied filter is used, make sure its valid
          // This will not cause an error state in the component because it is immediately followed
          // by setting the 13 month bounds. Filter clearing and an error message for the user
          // would make this better in the future
          this.setDatePickerBounds("start", this.startDate);
          this.setDatePickerBounds("end", this.endDate);
        }
        this.setInitialDatePickerBounds();
      } else {
        // If a user changed the dates but didn't apply them before closing,
        // reset dates to last applied
        this.startDate = DateTime.fromISO(this.startDatePickerValue).toMillis();
        this.endDate = DateTime.fromISO(this.endDatePickerValue).toMillis();
      }
    }

    handleOutsideFilterPanelClick = (event: MouseEvent): void => {
      const path = event.composedPath();
      if (path.length) {
        const insideSelfClick = !!path.find(element => (element as HTMLElement).id === "qmw-filter-panel");
        const btnClick = !!path.find(element => (element as HTMLElement).id === "toggle-filter-button");
        if (!btnClick && !insideSelfClick && this.filterPanelShow) {
          this.toggleFilterPanel();
        }
      }
    };

    handleApplyButton(): void {
      this.updateDateFilterPillText();
      this.updateDatePickerValues();
      this.toggleFilterPanel();
      this.dispatchEvent(
        new CustomEvent(QMW_FILTERS_UPDATED, {
          bubbles: true,
          composed: true,
          detail: {
            startTime: this.startDate,
            endTime: this.endDate
          }
        })
      );
    }

    handleKeyDown(event: { [key: string]: any }): void {
      if (this.filterPanelShow && event.code === "Escape") {
        this.toggleFilterPanel();
      }
    }

    setInitialDatePickerBounds(): void {
      const minDateObject = DateTime.local()
        .minus({ months: 13 })
        .toMillis();
      this.minEndDate = this.dateTimePickerBoundsFormat(minDateObject);
      this.minStartDate = this.minEndDate;
      this.maxStartDate = this.dateTimePickerBoundsFormat(DateTime.local().toMillis());
      this.maxEndDate = this.dateTimePickerBoundsFormat(DateTime.local().toMillis());
    }

    setDatePickerBounds(datePicked: string, newDate: number): void {
      if (datePicked === "start") {
        const today = DateTime.local()
          .endOf("day")
          .toMillis();
        // check if 30 days after new start date is after today
        const monthAfterStart = DateTime.fromMillis(newDate)
          .plus({ days: 30 })
          .endOf("day")
          .toMillis();
        this.maxEndDate =
          today < monthAfterStart
            ? this.dateTimePickerBoundsFormat(today)
            : this.dateTimePickerBoundsFormat(monthAfterStart);
        // set minimum end date to day after new start date
        this.minEndDate = this.dateTimePickerBoundsFormat(
          DateTime.fromMillis(newDate)
            .plus({ minutes: 1 })
            .startOf("day")
            .toMillis()
        );
      } else if (datePicked === "end") {
        const minDate = DateTime.local()
          .minus({ months: 13 })
          .startOf("day")
          .toMillis();
        // check if 30 days before new end date is before 13 months ago
        const monthBeforeEnd = DateTime.fromMillis(newDate)
          .minus({ days: 30 })
          .startOf("day")
          .toMillis();
        this.minStartDate =
          minDate > monthBeforeEnd
            ? this.dateTimePickerBoundsFormat(minDate)
            : this.dateTimePickerBoundsFormat(monthBeforeEnd);
        // set maximum start date to day before new end date
        this.maxStartDate = this.dateTimePickerBoundsFormat(
          DateTime.fromMillis(newDate)
            .minus({ minutes: 1 })
            .endOf("day")
            .toMillis()
        );
      }
      this.dateValidator(this.startDate, this.endDate);
    }

    dateValidator(startDate: number, endDate: number): boolean {
      const minStartDate = DateTime.fromISO(this.minStartDate)
        .startOf("day")
        .toMillis();
      const maxStartDate = DateTime.fromISO(this.maxStartDate)
        .endOf("day")
        .toMillis();
      const minEndDate = DateTime.fromISO(this.minEndDate)
        .startOf("day")
        .toMillis();
      const maxEndDate = DateTime.fromISO(this.maxEndDate)
        .endOf("day")
        .toMillis();
      this.isFormInvalid =
        startDate >= endDate ||
        !(minStartDate <= startDate && startDate <= maxStartDate) ||
        !(minEndDate <= endDate && endDate <= maxEndDate);
      return this.isFormInvalid;
    }

    handleStartDate(e: { [key: string]: any }): void {
      this.startDate = e.detail?.dateTime.toMillis();
      this.setDatePickerBounds("start", this.startDate);
    }

    handleEndDate(e: { [key: string]: any }): void {
      this.endDate = e.detail?.dateTime.toMillis();
      this.setDatePickerBounds("end", this.endDate);
    }

    renderActiveFilters(): TemplateResult {
      return html`
        <div class="active-filter-container">
          ${this.activeFilters.map(filter => {
            return html`
              <md-tooltip placement="bottom" message="${t(filter.tooltipTranslateLink)}">
                <md-badge color="grey" id="${filter.filterName}">
                  <md-icon name="${filter.displayIcon}_12"></md-icon>
                  ${filter.displayText}
                </md-badge>
              </md-tooltip>
            `;
          })}
        </div>
      `;
    }

    renderFilterPanel(): TemplateResult {
      return html`
        <div id="qmw-filter-panel" class="filters-panel custom-menu-overlay overlay-container" role="dialog">
          <div class="filters-panel-container overlay-content" part="overlay-content">
            <div class="filters-menu-header-wrapper">
              <span>${t("app:qmw.filters")}</span>
            </div>
            <div class="date-range-container">
              <div class="date-range-label">
                <span>${t("app:qmw.dateRange")}</span>
                <md-tooltip placement="bottom" message="${t("app:qmw:dateRangeLabelTooltip")}">
                  <md-icon name="info_16"><md-icon>
                </md-tooltip>
              </div>
              <div class="date-time-wrapper">
                <div class="date-time-container">
                  <label>${t("app:qmw.startDate")}</label>
                  <md-date-time-picker id="start-date" maxDate=${this.maxStartDate} minDate=${
        this.minStartDate
      } value=${this.startDatePickerValue} @date-time-change=${this.handleStartDate}></md-date-time-picker>
                </div>
                <div class="date-time-container">
                  <label>${t("app:qmw.endDate")}</label>
                  <md-date-time-picker id="end-date" maxDate=${this.maxEndDate} minDate=${this.minEndDate} value=${
        this.endDatePickerValue
      } @date-time-change=${this.handleEndDate}></md-date-time-picker>
                </div>
              </div>
            </div>
            <div class="filters-footer-container">
              <md-button class="panel-button" @button-click=${this.toggleFilterPanel}>${t("app:qmw.close")}</md-button>
              <md-button
                class="panel-button"
                color="blue"
                ?disabled=${this.isFormInvalid}
                @button-click=${this.handleApplyButton}
                >${t("app:qmw.apply")}</md-button>
            </div>
          </div>
        </div>
      `;
    }

    render(): TemplateResult {
      return html`
        <div class="grid-header-container">
          ${this.renderActiveFilters()}
          <div class="grid-header-action-container">
            <md-button
              slot="menu-trigger"
              class="filter-button"
              color="color-none"
              id="toggle-filter-button"
              @click=${() => this.toggleFilterPanel()}
            >
              <md-icon slot="icon" name="filter_16"></md-icon>
              <span slot="text">${t("app:qmw.filters")}</span>
            </md-button>
          </div>
        </div>
        ${this.filterPanelShow ? this.renderFilterPanel() : nothing}
      `;
    }
  }
}
