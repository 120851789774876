import { UserStationLoginTypes } from "@/index";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { logger } from "@/sdk";
import {
  isWebRtcSupportedBrowser,
  registerWebRTCShortcuts,
  removeWebRTCShortcuts,
  stripSpecialChar
} from "@/utils/helpers";
import { SERVICE, Service } from "@agentx/agentx-services";
import "@momentum-ui/web-components";
import { customElement, html, internalProperty, LitElement, property, query } from "lit-element";
import { nothing } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { repeat } from "lit-html/directives/repeat";
import {
  AGENT,
  AGENT_AND_SUPERVISOR,
  emergency911HelpLink,
  Key,
  PROFILE_SETTING_INVALID_DATA_EVENT,
  SUPERVISOR,
  SUPERVISOR_AND_AGENT
} from "../../constants";
import style from "./userProfile.scss";

type ESubmitButton = UserStationLoginTypes.ESubmitButton;
type EInvalidData = UserStationLoginTypes.EInvalidData;
type modeOptions = UserStationLoginTypes.modeOptions;

const trackingIdLabel = "app:errorModal:trackingIdLabel";
const extensionPlaceHolder = "app:stationLogin:extensionPlaceHolder";
const clearAriaLabel = "app:stationLogin.clear";
const dnStationLogin = "app:stationLogin.stationDnLabel";
const extensionLabel = "app:stationLogin:extension";
const phoneInput = "md-phone-input";
const hiddenBtn = "#hiddenBtn";
const extensionInfo = "app:stationLogin.extensionInfo";
const modeOptionslabel = "app:stationLogin:modeOptionsLabel";
const phoneFormats: UserStationLoginTypes.PhoneFormats = { international: "International", us: "US", other: "Other" };

export namespace UserProfile {
  /**
   * @element agentx-wc-user-profile
   * @fires selected-user-role
   * @fires login-start
   * @fires submit-busy
   * @fires on-component-update
   * @fires modal-initialized
   */
  @customElement("agentx-wc-user-profile")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: String }) userRole = "";
    @property({ type: Boolean }) isMonitoringEnabled = false;
    @property({ type: Boolean }) isSubmit = false;
    @property({ type: String }) selectedRoleName = AGENT;
    @property({ type: String }) teamName = "";
    @property({ type: String }) teamId = "";
    @property({ type: String }) dialNumber = "";
    @property({ type: Boolean }) isExtensionUsed = false;
    @property({ type: Boolean }) isDefaultDnSet = false;
    @property({ type: String }) extensionNumber = "";
    @property({ type: String }) dialNumberValueForNonUS = "";
    @property({ type: String }) defaultDn = "";
    @property({ type: Boolean }) isDefaultDnSetFromProfile = false;
    @property({ type: Boolean }) isInternational = false;
    @property({ type: Boolean }) isInternationalFromProfile = false;
    @property({ type: String }) countryCallingCode = "";
    @property({ type: String }) countryCodeValue = "";
    @property({ type: Boolean }) isRememberMyCredentials = false;
    @property({ type: Boolean }) auxCodeError = false;
    @property({ type: Boolean }) fetchUniqueTeamIdError = false;

    @property({ type: String }) isSubmitProfile = "";
    @property({ type: Array }) teams: Service.Conf.Team[] = []; // From User Station Login.
    @property({ type: String }) filteredTeamList = ""; // From Menu Component for Profile Settings.
    @property({ type: Boolean }) isErrUnknown = false;
    @property({ type: Boolean }) isErrDNRegister = false;
    @property({ type: String }) reasonCode = "";
    @property({ type: Boolean }) isDesktopReasonCodeEnabled = false;
    @property({ type: Function }) getErrorMsgForReasonCode = Function;
    @property({ type: Boolean }) isDNAlreadyInUse = false;
    @property({ type: Boolean }) isErrPhone = false;
    @property({ type: Boolean }) isErrPhoneExt = false;
    @property({ type: Boolean }) isExtAlreadyInUse = false;
    @property({ type: Boolean }) isErrExtRegister = false;
    @property({ type: Boolean }) isDNRegisterTimeOut = false;
    @property({ type: Boolean }) isTeamsEmpty = false;
    @property({ type: Boolean }) usesOtherDN = false;
    @property({ type: String }) trackingId = "";
    @property({ type: Boolean }) isStationlogin = false;
    @property({ type: Boolean }) isExtensionUsedFromProfile = false;
    @property({ type: Boolean }) usesOtherDNFromProfile = false;
    @property({ type: Boolean }) isMonitoringEnabledFromProfile = false;
    @property({ type: String }) phoneFormat: UserStationLoginTypes.PhoneFormat = phoneFormats.other;
    @property({ type: String }) phoneNumber = "";
    @property({ type: Boolean }) intDiallingEnabledFF = false;
    @property({ type: Boolean }) isESDStationLoginFlowEnabled = false;
    @property({ type: Boolean }) isMaximumSessionsReached = false;
    @property({ type: Array }) loginVoiceOptions: Service.Conf.LoginOption[] = [];
    @property({ type: String }) deviceType: Service.Aqm.Agent.DeviceType = SERVICE.constants.LoginVoiceOptions.AGENT_DN;
    @property({ type: String }) deviceId: string | null = "";
    @property({ type: Boolean }) isWebRTCEnabled = false;
    @property({ type: Boolean }) isWebRtcUnsupportedBrowser = false;
    @property({ type: Boolean }) isMicrophoneAccessDenied = false;
    @property({ type: Boolean }) showEmergencyNotificationWebrtc = false;
    @property({ type: Boolean }) isEmergencyModalAlreadyDisplayed = false;

    @internalProperty() private isErrTeam = false;
    @internalProperty() private mode: modeOptions = "DN";
    @internalProperty() private isErrRole = false;
    @internalProperty() private displayRoleDropdown = true;
    @internalProperty() private displayTeamsDropdown = true;
    @internalProperty() private displayDNExtension = true;
    @internalProperty() private radioSelection = 0;
    @internalProperty() private isExtensionTooltipOpened = false;
    @internalProperty() private isIntenationalTooltipOpened = false;
    @internalProperty() private roleInfo: Service.Conf.Role | null = null;

    @query(".form-class") form!: any;
    @query("#international-form") internationalForm!: any;
    @query("#ext-form") extForm!: any;
    @query(".footer-buttons") continueButton!: any;

    @query(".dn-info-icon-button") dnInfoIconButton!: any;
    @query(".roles-dropdown") rolesDropDown!: any;
    @query(".station-login-radio-group") telephonyRadio!: any;
    /**
     * Inserting hidden button inside the form so that autocomplete works
     * for firefox
     */
    private hiddenAutoFillBtnDN: HTMLButtonElement = document.createElement("button");

    team: Service.Conf.Team | null = null;

    profile: Service.Conf.Profile | null = null;

    // TODO: this should come from i18N.
    roles: Service.Conf.Role[] = [
      { roleId: "1", roleName: SUPERVISOR, roleDisplay: t("app:stationLogin:supervisorRole") },
      { roleId: "2", roleName: AGENT_AND_SUPERVISOR, roleDisplay: t("app:stationLogin:supervisorAndAgentRole") }
    ];
    defaultAuxCode = "";
    insertFromPaste = "insertFromPaste";

    teamsArray: (string | Service.Conf.OptionMember)[] = [];
    defaultCountryCallingCode = "+1, United States of America, US";
    defaultCountryCodeValue = "+1";
    isValidISD = false;

    connectedCallback() {
      super.connectedCallback();
      this.init();
      this.attachHiddenAttributesButton();
    }

    init() {
      this.teams = this.filteredTeamList ? JSON.parse(this.filteredTeamList) : this.teams;
      this.teamsArray = this.teams?.map(team => team.teamName);
      const defaultTeam = this.teams && this.teams.length > 0 ? this.teams[0].teamName : "";
      this.teamName = this.teamName ? this.teamName : defaultTeam;
      this.setValuesFromProfile();
      this.displayControlsBasedOnRole();
      if (this.isWebRTCEnabled) {
        // remove toUpperCase check when webrtc ff is GA
        if (
          this.deviceType === null ||
          this.loginVoiceOptions.indexOf(this.deviceType.toUpperCase() as Service.Conf.LoginOption) === -1 ||
          !this.isRememberMyCredentials
        ) {
          this.deviceType = this.loginVoiceOptions[0];
        }
        this.setDeviceType();
      } else {
        this.setRadioButtonSelection();
      }
      this.getSelectedRole();
      // Adding a timeout as phone input takes some time to load and then the values will be populated from Preferences, focus needs to be done after that
      setTimeout(() => {
        this.handleInitialFocus();
        this.getRoleSelection();
      }, 10);
      this.dispatchEventWhenComponentUpdates();
    }

    /**
     * As profile is React component, boolean values doesn't apply correctly when passed from there
     * thus as a workaround setting it as string here
     */
    private setValuesFromProfile() {
      if (!this.isStationlogin) {
        this.usesOtherDN = this.usesOtherDNFromProfile;
        this.isExtensionUsed = this.isExtensionUsedFromProfile;
        this.isMonitoringEnabled = this.isMonitoringEnabledFromProfile;
        this.isDefaultDnSet = this.isDefaultDnSetFromProfile;
        this.isInternational = this.isInternationalFromProfile;
      }
    }
    private getDn() {
      if (this.isExtensionUsed) {
        return this.extensionNumber;
      }
      let countryCodeValueVar = "";
      if (this.phoneNumber !== "") {
        // send dn as empty if phonenumber is empty instead of +1 so that DN register failure doesnt happen
        countryCodeValueVar = this.countryCodeValue || this.defaultCountryCodeValue;
      }

      return this.ifInternationalFormat()
        ? `${countryCodeValueVar}${stripSpecialChar(this.phoneNumber)}`
        : this.dialNumberValueForNonUS;
    }

    private isUsesOtherFormat() {
      if (this.intDiallingEnabledFF) {
        return this.phoneFormat === phoneFormats.other || this.countryCodeValue !== "+1";
      }
      return this.phoneFormat === phoneFormats.other;
    }

    private setRadioButtonSelection() {
      this.radioSelection = this.isExtensionUsed ? 1 : 0;
      const format = this.isExtensionUsed ? "Extension" : "DN";
      this.setValuesOnRadioChange(format);
    }

    private setDeviceType() {
      // remove toUpperCase check when webrtc ff is GA
      if (this.deviceType !== null) {
        this.radioSelection = this.loginVoiceOptions.indexOf(this.deviceType.toUpperCase() as Service.Conf.LoginOption);
      }
      this.isExtensionUsed = this.deviceType === SERVICE.constants.LoginVoiceOptions.EXTENSION;
      this.isErrUnknown = false;
    }

    private setValuesOnRadioChange(format: modeOptions) {
      this.mode = format;
      this.isExtensionUsed = format === "Extension";
      this.radioSelection = this.isExtensionUsed ? 1 : 0;
      this.isErrUnknown = false;
      this.isErrPhoneExt = false;
      this.isErrPhone = false;
    }

    private displayControlsBasedOnRole() {
      const userRoleString = this.userRole;
      switch (userRoleString) {
        case AGENT: {
          this.displayRoleDropdown = false;
          this.displayTeamsDropdown = true;
          this.displayDNExtension = true;
          break;
        }
        case SUPERVISOR: {
          this.displayDNExtension = this.isMonitoringEnabled;
          this.displayRoleDropdown = false;
          this.displayTeamsDropdown = false;
          break;
        }
        // TODO: remove SUPERVISOR_AND_AGENT as UI determines the user role.
        case SUPERVISOR_AND_AGENT.replace(/\s/g, "").toLocaleLowerCase():
        case AGENT_AND_SUPERVISOR: {
          if (this.selectedRoleName) {
            this.handleDisplayOnRoleChange(this.selectedRoleName.toLocaleLowerCase());
          } else {
            this.displayRoleDropdown = true;
            this.displayTeamsDropdown = false;
            this.displayDNExtension = false;
          }
          break;
        }
      }
    }

    /**
     * Toggles the display of UI Elements based upon Role coming from STORE and
     * when user changes role from the dropdown
     * @param selectedRoleName
     */
    handleDisplayOnRoleChange(selectedRoleName: string) {
      if (selectedRoleName === SUPERVISOR) {
        this.displayTeamsDropdown = false;
        this.displayRoleDropdown = true;
        this.displayDNExtension = this.isMonitoringEnabled;
      } else if (selectedRoleName === AGENT_AND_SUPERVISOR) {
        this.displayRoleDropdown = true;
        this.displayTeamsDropdown = true;
        this.displayDNExtension = true;
      }
    }

    private attachHiddenAttributesButton() {
      this.hiddenAutoFillBtnDN.id = "hiddenBtn";
      this.hiddenAutoFillBtnDN.type = "submit";
      this.hiddenAutoFillBtnDN.onclick = e => {
        e.preventDefault();
        logger.info("hiddenAutoFillBtnDN clicked");
      };
      this.hiddenAutoFillBtnDN.style.display = "none";
    }

    private attachAutoCompleteConditionally() {
      return this.ifInternationalFormat() ? "tel-national" : "tel";
    }

    protected updated(changedProperties: PropertyValues) {
      super.updated(changedProperties);
      changedProperties.forEach((oldValue, name) => {
        if (name === "isSubmit" && oldValue !== undefined) {
          this.submitFromStationLogin();
        }
        if (name === "isSubmitProfile" && oldValue !== undefined) {
          this.submitFromProfileSettings();
        }
      });
      this.setRovingPreventFocus();

      if (!this.isESDStationLoginFlowEnabled && !this.intDiallingEnabledFF) {
        let inp;
        if (this.isExtensionUsed) {
          inp = this.extForm?.querySelector("md-input");
        } else {
          inp = this.form?.querySelector("md-input");
        }
        if (!inp?.shadowRoot?.querySelector(hiddenBtn)) {
          const form = inp?.shadowRoot.querySelector("form");
          if (form) {
            form.append(this.hiddenAutoFillBtnDN);
          }
        }
      } else {
        let inp;
        setTimeout(() => {
          // setTimeout required as md-phone-input does not render
          if (this.isExtensionUsed || !this.ifInternationalFormat()) {
            inp = this.form?.querySelector("md-input");
          } else {
            inp = this.internationalForm?.querySelector(phoneInput).shadowRoot.querySelector("md-input");
            if (inp && !inp?.shadowRoot.querySelector("form")) {
              // create form element and append to input element to support auto suggestion for md-phone-input component
              const input = inp.shadowRoot.querySelector("input") as HTMLInputElement;
              if (input) {
                input.name = input.id = "input-name";
                input.autocomplete = this.attachAutoCompleteConditionally();

                const inputParent = input.parentElement;
                if (inputParent) {
                  const formElement = document.createElement("form");
                  inputParent.insertBefore(formElement, input);
                  formElement.append(input);
                }
              }
            }
          }
          if (!inp?.shadowRoot?.querySelector(hiddenBtn)) {
            const form = inp?.shadowRoot.querySelector("form");
            if (form) {
              const input = inp.shadowRoot.querySelector("input");
              input.autocomplete = this.attachAutoCompleteConditionally();
              form.append(this.hiddenAutoFillBtnDN);
            }
          }
        }, 50); // delay required for md-phone-input to render
      }
    }

    setRovingPreventFocus() {
      if (this.telephonyRadio && !this.rolesDropDown) {
        this.telephonyRadio.rovingPreventFocus = true;
      }
    }

    handleRememberMeCheckBoxChange() {
      this.isRememberMyCredentials = !this.isRememberMyCredentials;
      this.dispatchEventWhenComponentUpdates();
    }

    handleSelectedRole(e: CustomEvent) {
      if (e.detail.option) {
        this.roleInfo = e.detail.option;
      } else {
        this.roleInfo = null;
      }
      const selectedRoleName = this.roleInfo?.roleName;
      selectedRoleName && this.handleDisplayOnRoleChange(selectedRoleName.toLocaleLowerCase());
      this.isErrUnknown = false;
      setTimeout(() => {
        this.handleInputFocus();
      }, 10);

      this.dispatchEventWhenComponentUpdates();
    }

    handleSelectedTeam(e: CustomEvent) {
      if (e.detail.value) {
        const selectedTeam = this.teams.find(teamObj => teamObj.teamName === e.detail.value);
        if (selectedTeam) {
          this.team = selectedTeam;
        }
      } else {
        this.team = null;
        this.teamName = "";
      }
      this.dispatchEventWhenComponentUpdates();
    }

    setDialNumber(e: any) {
      if (e?.detail?.srcEvent?.inputType === this.insertFromPaste) {
        e.target.value = stripSpecialChar(e.target.value);
      }
      this.dialNumberValueForNonUS = e.target.value;
      this.dispatchEventWhenComponentUpdates();
    }

    onDialNumberOrExtBlur() {
      this.dispatchEventWhenComponentUpdates();
    }

    setExtensionNumber(e: any) {
      if (e?.detail?.srcEvent?.inputType === this.insertFromPaste) {
        e.target.value = stripSpecialChar(e.target.value);
      }
      this.extensionNumber = e.target.value;
      this.dispatchEventWhenComponentUpdates();
    }

    private handleInputFocus() {
      const inputField = this.shadowRoot?.querySelector("md-input")?.shadowRoot?.querySelector("input");
      const phoneField = this.shadowRoot
        ?.querySelector(phoneInput)
        ?.shadowRoot?.querySelector("md-input")
        ?.shadowRoot?.querySelector("input");
      setTimeout(() => {
        if (inputField) {
          inputField.focus();
        } else {
          phoneField?.focus();
        }
      }, 1);
    }

    private handleInitialFocus() {
      setTimeout(() => {
        if (this.displayRoleDropdown) {
          this.rolesDropDown?.shadowRoot?.querySelector("label")?.focus();
        } else {
          this.dnInfoIconButton?.shadowRoot?.querySelector("button")?.focus();
        }
      }, 1);
    }

    private submitLogin() {
      if (this.isStationlogin) {
        this.evt<ESubmitButton>("submit-busy", { submitProperty: true });
        this.submitFromStationLogin();
      } else {
        this.submitFromProfileSettings();
      }
    }

    private handleEnterKeypress(e: KeyboardEvent) {
      if (e.key === Key.Enter) {
        this.submitLogin();
      }
    }

    private handlePhoneInputKeydown(e: CustomEvent) {
      const keyboardEvent = e.detail.srcEvent;
      if (keyboardEvent.key === Key.Enter) {
        this.submitLogin();
      }
    }

    private handleUnSupportedBrowserError() {
      this.isErrUnknown = true;
      this.isWebRtcUnsupportedBrowser = true;
      this.trackingId = "";
      this.evt<EInvalidData>(PROFILE_SETTING_INVALID_DATA_EVENT, { isValid: false });
    }

    private handleMicrophoneAccessDenied() {
      this.isErrUnknown = true;
      this.isMicrophoneAccessDenied = true;
      this.trackingId = "";
      this.evt<EInvalidData>(PROFILE_SETTING_INVALID_DATA_EVENT, { isValid: false });
    }

    /***
     * When Updating User Profile Settings
     */
    async submitFromProfileSettings() {
      if ((this.displayRoleDropdown && !this.roleInfo) || (this.displayTeamsDropdown && !this.team)) {
        return;
      }
      let selectedRoleName = this.roleInfo?.roleName;
      if (selectedRoleName) {
        selectedRoleName = selectedRoleName === "agent_supervisor" ? "supervisorAgent" : selectedRoleName;
        localStorage.setItem("userRole", selectedRoleName);
      }

      if (this.isWebRTCEnabled && this.deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER) {
        if (!this.isEmergencyModalAlreadyDisplayed && SERVICE.featureflag.isDesktopEmergencyNotificationEnabled()) {
          this.renderEmergencyNotificationBody();
        }

        this.setErrorFlags();
        this.isErrUnknown = false;
        logger.info("[WebRtc] : Station Login Request from profile");
        if (!isWebRtcSupportedBrowser()) {
          logger.error("[WebRtc] Unsupported Browser error");
          this.handleUnSupportedBrowserError();
          return;
        }

        try {
          const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
          if (!mediaStream) {
            this.handleMicrophoneAccessDenied();
            return;
          } else {
            mediaStream.getTracks().forEach((track: any) => track.stop());
          }
        } catch (error) {
          logger.error("[WebRtc] micrpophone access error", error);
          this.handleMicrophoneAccessDenied();
          return;
        }
      } else {
        if (this.displayDNExtension && !this.valid()) {
          const inputField =
            this.isExtensionUsed && !this.ifInternationalFormat()
              ? this.form?.shadowRoot?.querySelector("md-input")?.shadowRoot?.querySelector("input")
              : this.form?.shadowRoot
                  ?.querySelector(phoneInput)
                  ?.shadowRoot?.querySelector("md-input")
                  ?.shadowRoot?.querySelector("input");
          setTimeout(() => inputField?.focus(), 1);
          this.evt<EInvalidData>(PROFILE_SETTING_INVALID_DATA_EVENT, { isValid: false });
          return;
        }
      }

      this.evt<EInvalidData>(PROFILE_SETTING_INVALID_DATA_EVENT, { isValid: true });
      SERVICE.shortcut.updateRegisterKeys();
      this.isWebRTCEnabled && this.deviceType === "BROWSER" ? registerWebRTCShortcuts() : removeWebRTCShortcuts();
      this.dispatchEventWhenComponentUpdates();

      // TODO: Save to cache on successful save.
      // this.updateSupervisorCacheData();
    }
    setInternationalPhNumber(e: CustomEvent) {
      this.phoneNumber = e.detail.phoneNumber;
      this.isValidISD = e.detail.phoneNumber && e.detail.isValid;
      this.dispatchEventWhenComponentUpdates();
    }

    submitFromStationLogin() {
      //storing original role here as to compare for role switch for telemetry
      const loggedInRole = this.selectedRoleName;
      // Update selectedRoleName in STORE.agent in case of Supervisor And Agent
      const selectedRoleName = this.roleInfo?.roleName;
      if (this.displayRoleDropdown && selectedRoleName) {
        const roleData = { selectedRoleName };
        this.evt<UserStationLoginTypes.EUserSelectedRole>("selected-user-role", roleData);
      }

      if (this.isWebRTCEnabled && this.deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER) {
        logger.info("[WebRtc] : Station Login Request from StationLogin PopUP");
      } else {
        let inp;
        if (this.isExtensionUsed) {
          inp = this.extForm?.querySelector("md-input")?.shadowRoot?.querySelector("input");
        } else {
          inp = this.ifInternationalFormat()
            ? this.form?.querySelector(phoneInput)?.shadowRoot?.querySelector("md-input")
            : this.form?.querySelector("md-input")?.shadowRoot?.querySelector("input");
        }

        if (this.displayDNExtension && !this.valid()) {
          const inputField = inp?.shadowRoot?.querySelector("input");
          setTimeout(() => inputField?.focus(), 1);
          this.evt<ESubmitButton>("submit-busy", { submitProperty: false });
          this.handleInputFocus();
          return;
        }
        if ((this.displayRoleDropdown && !this.roleInfo) || (this.displayTeamsDropdown && !this.team)) {
          this.evt<ESubmitButton>("submit-busy", { submitProperty: false });
          return;
        }

        // handle submit as form submission.
        let hiddenBtn;
        if (!this.isExtensionUsed && this.phoneFormat !== phoneFormats.other) {
          hiddenBtn = inp?.shadowRoot?.querySelector(hiddenBtn);
        } else {
          hiddenBtn = inp?.querySelector(hiddenBtn);
        }
        if (hiddenBtn) {
          hiddenBtn.click();
        }
      }

      this.evt<UserStationLoginTypes.EStationLoginSubmit>("login-start", {
        profileDetail: this.getProfileDetails(),
        loggedInRole
      });
    }

    private isValidPhone() {
      // Validate the phone number for international format - for non-ESD flow with intDiallingEnabledFF on
      if (this.ifInternationalFormat()) {
        return this.isValidISD && this.phoneNumber;
      }
      return this.dialNumberValueForNonUS;
    }

    private valid() {
      this.setErrorFlags();
      // hardcoded check for US phone - was requested
      // "this.profile.regexUS" must be used later
      const regexUS = new RegExp("^(\\+1|1)?\\d{10}$");
      const regexOther = new RegExp("([A-Za-z0-9-_]+)");
      //regex for extension number validation (2 to 13 digits only allowed)
      const regexExtension = new RegExp("^[0-9]{2,18}$");
      this.isErrTeam = this.displayTeamsDropdown ? !this.team : false;
      this.isErrRole = this.displayRoleDropdown ? !this.roleInfo : false;
      if (this.isExtensionUsed) {
        this.isErrPhoneExt = !this.extensionNumber;
        if (this.isErrPhoneExt || this.isErrTeam || this.isErrRole) {
          this.isErrUnknown = false;
          this.isExtAlreadyInUse = false;
          this.isErrExtRegister = false;
          return false;
        }
        if (!regexExtension.test(this.extensionNumber)) {
          this.isErrPhoneExt = true;
          this.isErrUnknown = false;
          this.isErrExtRegister = false;
          this.isExtAlreadyInUse = false;
          return false;
        }
      } else {
        if (this.phoneFormat === "US") {
          if (regexUS.test(this.dialNumberValueForNonUS)) {
            if (this.dialNumberValueForNonUS.length === 11) {
              // only +1 prefix allowed not just 1
              // hardcoded add "+" if 11 digits
              this.dialNumberValueForNonUS = `+${this.dialNumberValueForNonUS}`;
            } else if (
              SERVICE.featureflag.isSteeringDigitsAppendEnabled() &&
              this.dialNumberValueForNonUS.length === 10
            ) {
              // hardcoded +1 for CX-6490.
              this.dialNumberValueForNonUS = `+1${this.dialNumberValueForNonUS}`;
            }
          } else {
            this.isErrPhone = true;
            this.isErrUnknown = false;
            this.isErrDNRegister = false;
            this.isDNAlreadyInUse = false;
            return false;
          }
        }
        if (this.ifInternationalFormat()) {
          this.isErrPhone =
            this.isESDStationLoginFlowEnabled || this.intDiallingEnabledFF ? !this.isValidPhone() : !this.phoneNumber;
        } else if (this.isUsesOtherFormat() && !regexOther.test(this.dialNumberValueForNonUS)) {
          this.isErrPhone = true;
          this.isErrUnknown = false;
          this.isErrDNRegister = false;
          this.isDNAlreadyInUse = false;
          return false;
        }
        if (this.isErrPhone || this.isErrTeam) {
          this.isErrUnknown = false;
          this.isErrDNRegister = false;
          this.isErrExtRegister = false;
          this.isDNAlreadyInUse = false;
          this.isErrPhoneExt = false;
          return false;
        }
      }

      if (this.isErrUnknown) {
        this.isErrUnknown = false;
      }

      if (this.isErrDNRegister) {
        this.isErrDNRegister = false;
      }

      if (this.isErrPhone) {
        this.isErrPhone = false;
      }
      if (this.isErrExtRegister) {
        this.isErrExtRegister = false;
      }

      if (this.isErrPhoneExt) {
        this.isErrPhoneExt = false;
      }

      return true;
    }

    onDNExtensionRadioChange(e: CustomEvent) {
      const format = e.detail.selected;
      this.deviceType = e.detail.selected;
      this.setValuesOnRadioChange(format);
      this.dispatchEventWhenComponentUpdates();
    }

    onVoiceOptionChange(e: CustomEvent) {
      this.deviceType = e.detail.selected;
      this.setDeviceType();
      this.dispatchEventWhenComponentUpdates();
    }

    handleKeyDown(e: CustomEvent) {
      e.stopImmediatePropagation();
    }

    ifInternationalFormat() {
      return this.phoneFormat === phoneFormats.international;
    }

    handleFormatChange() {
      document.dispatchEvent(new CustomEvent("on-widget-update"));
      this.setErrorFlags();
      this.isErrUnknown = false;
      this.phoneFormat = this.ifInternationalFormat() ? phoneFormats.other : phoneFormats.international;
      this.isInternational = this.ifInternationalFormat();
      this.dispatchEventWhenComponentUpdates();
    }

    handleCountryChange(e: CustomEvent) {
      const event = e.detail.srcEvent;
      if (event && event.detail) {
        if (!event.detail.value || !event.detail.value.id) {
          this.countryCodeValue = "+1";
          this.countryCallingCode = "";
          return;
        }
        this.countryCodeValue = event.detail.selected[0]?.value;
        this.countryCallingCode = event.detail.value.id;
        this.isValidISD = e.detail.isValid;
        if (e.detail.isValid) {
          this.setErrorFlags();
          this.isErrUnknown = false;
        }
        this.dispatchEventWhenComponentUpdates();
      }
    }

    getErrorMessage() {
      if (this.isErrPhone) {
        return `${t("app:stationLogin:stationLoginInvalidNumber")}`;
      } else if (this.isErrDNRegister) {
        if (this.isDNAlreadyInUse) {
          return `${t("app:stationLogin:dnAlreadyInUse")} (${t(trackingIdLabel)}: ${this.trackingId})`;
        }
        return `${t("app:stationLogin:dnRegisterFailure")} (${t(trackingIdLabel)}: ${this.trackingId})`;
      }
      return `${t("app:stationLogin:stationLoginInvalidNumber")}`;
    }

    radioChange(e: CustomEvent) {
      const format = e.detail.selected;
      this.deviceType = e.detail.selected;
      this.phoneFormat = format;
      this.dispatchEventWhenComponentUpdates();
    }

    setErrorFlags() {
      this.isErrUnknown = true;
      this.isErrDNRegister = false;
      this.isDNAlreadyInUse = false;
      this.isErrPhone = false;
      this.isErrPhoneExt = false;
      this.isExtAlreadyInUse = false;
      this.isErrExtRegister = false;
      this.isWebRtcUnsupportedBrowser = false;
      this.isMicrophoneAccessDenied = false;
    }

    // ROST: create decorator for custom event
    private evt<T extends { detail: any }>(name: string, detail: T["detail"]) {
      this.dispatchEvent(new CustomEvent(name, { bubbles: true, composed: true, detail }));
      logger.info("[User Profile]: Dispatching event", name, detail);
    }

    static get styles() {
      return style;
    }

    getSelectedRole() {
      if (!this.roleInfo) {
        const selectedRoleOption = this.roles.filter(
          role => role.roleName.toLocaleLowerCase() === this.selectedRoleName.toLocaleLowerCase()
        );
        this.roleInfo = selectedRoleOption[0];
      }

      return this.roleInfo;
    }

    getRoleSelection() {
      let focusRoleDropdown;
      if (this.selectedRoleName !== "agent" && this.selectedRoleName !== "" && this.isMonitoringEnabled === true) {
        focusRoleDropdown = false;
      } else {
        focusRoleDropdown = true;
      }
      return focusRoleDropdown;
    }
    getProfileDetails() {
      return {
        roles: this.displayRoleDropdown
          ? this.roleInfo?.roleName?.split("_") || this.userRole?.split("_")
          : this.userRole?.split("_"),
        teamName: this.team?.teamName ? this.team.teamName : this.teamName,
        teamId: this.team?.teamId ? this.team.teamId : this.teamId,
        dnNumber: this.getDn(),
        isExtension: this.isExtensionUsed,
        usesOtherDN: this.isUsesOtherFormat() || this.countryCodeValue !== "+1",
        extensionNumber: this.extensionNumber || this.defaultDn || "",
        dialNumberValueForNonUS: this.dialNumberValueForNonUS,
        defaultDn: this.defaultDn,
        isDefaultDnSet: this.isDefaultDnSet,
        isInternational: this.ifInternationalFormat(),
        phoneNumber: this.isInternational ? this.phoneNumber : this.defaultDn || "",
        countryCallingCode: this.isInternational ? this.countryCallingCode || this.defaultCountryCallingCode : "",
        countryCodeValue: this.isInternational ? this.countryCodeValue || this.defaultCountryCodeValue : "",
        isRememberMyCredentials: this.isRememberMyCredentials,
        deviceType: this.deviceType,
        deviceId: this.deviceId,
        isEmergencyModalAlreadyDisplayed: this.isEmergencyModalAlreadyDisplayed
      };
    }

    /**
     * On any input field change dispatch event and handle in profile settings
     */
    dispatchEventWhenComponentUpdates(): void {
      const checkRole = this.isESDStationLoginFlowEnabled && this.displayRoleDropdown ? this.roleInfo : true;
      const dnInputValue = this.getDn();
      const checkDN = this.displayDNExtension ? dnInputValue : true;
      const checkTeam = this.displayTeamsDropdown ? this.team : true;
      const isDeviceTypeBrowser =
        this.isWebRTCEnabled && this.deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER;
      const flg = checkRole && (checkDN || isDeviceTypeBrowser) && checkTeam ? false : true;

      const profileDetail = this.getProfileDetails();

      this.evt<UserStationLoginTypes.ESubmitButtonProfileSettings>("on-component-update", {
        submitProperty: flg,
        profileDetail
      });
    }

    errorCodesCheck() {
      const errMsg = this.getErrorMsgForReasonCode(this.reasonCode);

      if (this.isDesktopReasonCodeEnabled && this.reasonCode && errMsg) {
        return [
          {
            type: "error",
            message: `${errMsg}`
          }
        ];
      }

      if (this.isErrPhone) {
        return [
          {
            type: "error",
            message: `${t("app:stationLogin:stationLoginInvalidNumber")}`
          }
        ];
      }

      if (this.isErrDNRegister) {
        if (this.isDNAlreadyInUse) {
          return [
            {
              type: "error",
              message: `${t("app:stationLogin:dnAlreadyInUse")} (${t(trackingIdLabel)}: ${this.trackingId})`
            }
          ];
        }
        return [
          {
            type: "error",
            message: `${t("app:stationLogin:dnRegisterFailure")} (${t(trackingIdLabel)}: ${this.trackingId})`
          }
        ];
      }

      if (this.isErrPhoneExt) {
        return [
          {
            type: "error",
            message: `${t("app:stationLogin:stationLoginInvalidExtNumber")}`
          }
        ];
      }

      if (this.isErrExtRegister) {
        if (this.isExtAlreadyInUse) {
          return [
            {
              type: "error",
              message: `${t("app:stationLogin:dnAlreadyInUse")} (${t(trackingIdLabel)}: ${this.trackingId})`
            }
          ];
        }
        return [
          {
            type: "error",
            message: `${t("app:stationLogin:dnRegisterFailure")} (${t(trackingIdLabel)}: ${this.trackingId})`
          }
        ];
      }

      return [];
    }

    renderExtensionForm() {
      return html`
        <div class="extension-format">
          <md-form class="form-class" id="ext-form">
            <md-input
              clear
              clearAriaLabel="${t("app:stationLogin.clearExtensionLabel")}"
              htmlId="dial-extension"
              ariaDescribedBy="dial-extension"
              ariaLabel="${t(extensionInfo)}"
              type="tel"
              id="ext"
              placeholder=${t(extensionPlaceHolder)}
              ?readOnly=${this.isDefaultDnSet}
              shape="pill"
              class=${classMap({ err: this.isErrPhoneExt })}
              .value=${this.extensionNumber ? this.extensionNumber : ""}
              .secondaryLabel="${t(extensionInfo)}"
              @input-change=${this.setExtensionNumber}
              @input-blur=${this.onDialNumberOrExtBlur}
              @keypress=${this.handleEnterKeypress}
              .messageArr=${this.errorCodesCheck()}
            ></md-input>
          </md-form>
        </div>
      `;
    }

    renderDnForm() {
      return html`
        <md-form class="form-class">
          <md-input
            clear
            clearAriaLabel="${t(clearAriaLabel)} ${t(dnStationLogin)}"
            type="tel"
            id="dn"
            placeholder=${t(dnStationLogin)}
            ariaLabel="${t(dnStationLogin)}"
            class=${classMap({ err: this.isErrPhone })}
            ?readOnly=${this.isDefaultDnSet}
            ?disabled=${this.isDefaultDnSet}
            .value=${this.dialNumberValueForNonUS ? this.dialNumberValueForNonUS : ""}
            shape="pill"
            @keydown=${this.handleKeyDown}
            @keypress=${this.handleEnterKeypress}
            @input-change=${this.setDialNumber}
            @input-blur=${this.onDialNumberOrExtBlur}
            .messageArr=${this.errorCodesCheck()}
          ></md-input>
        </md-form>
      `;
    }

    renderInternationalForm() {
      return html`
        <md-form class="form-class" id="international-form">
          <md-phone-input
            type="tel"
            id="international"
            name="international-value"
            pill
            .errorMessage=${this.getErrorMessage()}
            .value="${this.phoneNumber}"
            .countryCallingCode=${this.countryCallingCode}
            .numberPlaceholder=${t("app:stationLogin:stationDnPlaceholder")}
            .countryCodeAriaLabel=${t("app:stationLogin.countryCode")}
            .dialNumberAriaLabel=${t("app:stationLogin.stationDnLabelAriaLabel")}
            ariaLabel="${t(dnStationLogin)}"
            ?showErrorMessage=${this.ifInternationalFormat() &&
              (this.isErrPhone || this.isErrDNRegister || this.isDNAlreadyInUse)}
            @phoneinput-keydown="${(e: CustomEvent) => {
              e.stopImmediatePropagation();
              this.handlePhoneInputKeydown(e.detail.srcEvent);
            }}"
            @phoneinput-change="${(e: CustomEvent) => {
              e.stopImmediatePropagation();
              this.setInternationalPhNumber(e);
              this.setErrorFlags();
              this.isErrUnknown = false;
            }}"
            @phoneinput-blur="${(e: CustomEvent) => {
              e.stopImmediatePropagation();
              this.setInternationalPhNumber(e);
            }}"
            @countrycode-change="${(e: CustomEvent) => this.handleCountryChange(e)}"
            clear-icon-height="12px"
            clearAriaLabel="${t(clearAriaLabel)} ${t(dnStationLogin)}"
          ></md-phone-input>
        </md-form>
      `;
    }

    renderTeam() {
      return html`
        <div class="md-input-container sl-combobox" tabindex="-1">
          <label class="teams-label" for="teams" tabindex="-1">${t("app:stationLogin.stationLoginTeamLabel")}</label>
          ${/** retaining the check here,
           * however when no team is present its a config error and agent has to sign out
           * even before the app loads. This logic is taken care. */
          !this.isTeamsEmpty
            ? html`
                <md-combobox
                  placeholder="${t("app:stationLogin.selectTeam")}"
                  ariaLabel="${t("app:stationLogin.selectTeam")}"
                  search-result-aria-label="${t("app:stationLogin.selectTeamOnSearch")}"
                  .options=${this.teamsArray}
                  .value=${[this.teamsArray.find(teamName => teamName === this.teamName) || this.teamName]}
                  option-id="teamId"
                  option-value="teamName"
                  .visibleOptions=${3}
                  @change-selected="${this.handleSelectedTeam}"
                  no-options-i18n=${t("app:common.noOptions")}
                  no-results-i18n=${t("app:common.noResults")}
                  clear-icon-height="12px"
                  shape="pill"
                  clear-aria-label="${t("app:stationLogin.clearTeamLabel")}"
                ></md-combobox>
              `
            : nothing}
        </div>
      `;
    }

    getUnknownErrorMessage() {
      if (this.isWebRTCEnabled && this.deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER) {
        if (this.isMicrophoneAccessDenied) {
          return t("app:stationLogin:microphoneAccessDeniedError");
        }
        if (this.isWebRtcUnsupportedBrowser) {
          return t("app:stationLogin:browserUnsupportedError");
        }
        return t("app:stationLogin:stationLoginFailed");
      }
      if (this.isMaximumSessionsReached) {
        return t("app:stationLogin:maximumSessionsReachedError");
      }
      if (this.auxCodeError) {
        return t("app:stationLogin:auxiliaryCodeFailed");
      }
      if (this.fetchUniqueTeamIdError) {
        return t("app:stationLogin:fetchTeamIdFailed");
      }
      return this.isDNRegisterTimeOut
        ? this.isExtensionUsed
          ? t("app:stationLogin:extNumberRegisterTimeout")
          : t("app:stationLogin:dnRegisterTimeout")
        : t("app:stationLogin:stationLoginFailed");
    }

    renderUnknownError() {
      return html`
        <div part="message" class="error-message">
          <md-help-text .messageType=${"error"}>
            ${this.getUnknownErrorMessage()} ${this.trackingId ? `(${t(trackingIdLabel)}: ${this.trackingId})` : ""}
          </md-help-text>
        </div>
      `;
    }

    private getLabel() {
      if (this.loginVoiceOptions.length === 1) {
        return t(`app:stationLogin.${this.loginVoiceOptions[0].toLowerCase()}Label`);
      }
      return t("app:stationLogin.stationLoginVoiceLabel");
    }

    private getToolTipInfoByLoginVoiceOptions() {
      if (this.loginVoiceOptions.length === 2) {
        if (this.loginVoiceOptions[0] === SERVICE.constants.LoginVoiceOptions.AGENT_DN) {
          if (this.loginVoiceOptions[1] === SERVICE.constants.LoginVoiceOptions.EXTENSION) {
            return t(extensionInfo);
          }
          return t("app:stationLogin.loginVoiceDnBrowserInfo");
        }
        return t("app:stationLogin.loginVoiceExtBrowserInfo");
      }
      return t("app:stationLogin.loginVoiceInfo");
    }

    private getTooltipInfo() {
      if (this.isWebRTCEnabled) {
        return this.getToolTipInfoByLoginVoiceOptions();
      }
      return t(extensionInfo);
    }

    private getLabelSection() {
      return html`
        <div class="dial-number-label">
          ${html`
            <label
              tabindex="-1"
              class="${classMap({ "label-with-bottom-margin": this.loginVoiceOptions.length === 1 })}"
              >${this.getLabel()}</label
            >
          `}
          ${this.loginVoiceOptions.length > 1
            ? html`
                <md-tooltip
                  ?opened=${this.isExtensionTooltipOpened}
                  message=${this.getTooltipInfo()}
                  placement="bottom"
                >
                  <md-button
                    aria-label=${this.getTooltipInfo()}
                    circle
                    variant="white"
                    class="info-icon dn-info-icon-button"
                    role="tooltip"
                    size="20"
                    @focus=${() => (this.isExtensionTooltipOpened = true)}
                    @blur=${() => (this.isExtensionTooltipOpened = false)}
                  >
                    <md-icon name="info_16"></md-icon>
                  </md-button>
                </md-tooltip>
              `
            : nothing}
        </div>
      `;
    }

    handleKeyDownRadio(e: any) {
      if (this.telephonyRadio) {
        this.telephonyRadio.rovingPreventFocus = false;
      }
      this.handleEnterKeypress(e);
    }

    private getRadioList() {
      return html`
        <md-radiogroup
          class="station-login-radio-group"
          group-label="${t(modeOptionslabel)}"
          alignment="horizontal"
          checked="${this.radioSelection}"
          @keydown=${this.handleKeyDownRadio}
          @radio-change=${(e: CustomEvent) => this.onVoiceOptionChange(e)}
        >
          ${repeat(
            this.loginVoiceOptions,
            option =>
              html`
                <md-radio
                  slot="radio"
                  value="${option}"
                  ariaLabel="${t(`app:stationLogin.${option.toLocaleLowerCase()}`)}"
                  title="${t(`app:stationLogin.${option.toLocaleLowerCase()}`)}"
                  >${t(`app:stationLogin.${option.toLocaleLowerCase()}`)}
                </md-radio>
              `
          )}
        </md-radiogroup>
      `;
    }

    private renderBrowserSection() {
      return html`
        <div class="browser-label-container">
          <label>${t("app:stationLogin.browserInfo")}</label>
        </div>
      `;
    }

    private renderEmergencyInfoSectionOnSecondLogin() {
      return html`
        <div>
          <div class="browser-label-container-emergency">
            <label>${t("app:stationLogin.browserInfo")}</label>
          </div>
          <div class="browser-label-container-emergency-helpText">
            <md-tooltip
              class="emergency-help-tooltip"
              placement="top"
              message=${t("app:emergencyServiceNotification.updateCurrentAddInfoOnSecondSignIn")}
            >
              <md-button
                aria-label=${this.getTooltipInfo()}
                circle
                variant="white"
                class="info-icon"
                role="tooltip"
                size="12"
                @focus=${() => (this.isExtensionTooltipOpened = true)}
                @blur=${() => (this.isExtensionTooltipOpened = false)}
              >
                <md-icon name="info_12"></md-icon>
              </md-button>
              <label
                >${t("app:emergencyServiceNotification.updateCurrentAddInfoOnSecondSignIn")}
                <md-link
                  inline
                  class="help-section-link"
                  aria-label=${t("app:emergencyServiceNotification.seeHelpSection")}
                  tag="a"
                  href=${emergency911HelpLink}
                  target="_blank"
                  >${t("app:emergencyServiceNotification.seeHelpSection")}</md-link
                >
              </label>
            </md-tooltip>
          </div>
        </div>
      `;
    }

    private getInputField() {
      switch (this.deviceType) {
        case SERVICE.constants.LoginVoiceOptions.EXTENSION: {
          return this.renderExtensionForm();
        }
        case SERVICE.constants.LoginVoiceOptions.BROWSER: {
          if (SERVICE.featureflag.isDesktopEmergencyNotificationEnabled() && this.isEmergencyModalAlreadyDisplayed) {
            return this.renderEmergencyInfoSectionOnSecondLogin();
          }
          return this.renderBrowserSection();
        }
        default:
          return this.renderDNSection();
      }
    }

    private renderVoiceOptionSection() {
      return html`
        ${this.displayDNExtension // role base
          ? html`
              <div
                class="station-login-radio 
                ${classMap({
                  "station-login-radio-min": this.loginVoiceOptions.length === 2,
                  "station-login-radio-max": this.loginVoiceOptions.length === 3
                })}"
              >
                ${this.getLabelSection()}
                ${!this.isWebRTCEnabled || this.loginVoiceOptions.length > 1 ? this.getRadioList() : nothing}
              </div>
              ${this.getInputField()}
            `
          : nothing}
      `;
    }

    private renderTabsForNonESDFlow() {
      return html`
        <md-tabs
          class="sl-tabs-container"
          justified
          @selected-changed="${(e: CustomEvent<{ value: number }>) => {
            const { value } = e.detail;
            this.isExtensionUsed = value === 1;
            this.isErrUnknown = false;
          }}"
        >
          <md-tab slot="tab" label="${t(dnStationLogin)}" aria-label="${t(dnStationLogin)}" role="tab">
            <span>${t(dnStationLogin)}</span>
          </md-tab>
          <md-tab-panel slot="panel" .focusablePanel=${"false"}>
            <div class="phone-format">
              <md-radiogroup
                group-label="phone-format"
                alignment="horizontal"
                checked="0"
                @keypress=${this.handleEnterKeypress}
                @radio-change=${(e: CustomEvent) => this.radioChange(e)}
              >
                <md-radio slot="radio" value="US" ariaLabel="${t("app:stationLogin.stationDnLabelsUSFormat")}"
                  >${t("app:stationLogin.stationDnLabelsUSFormat")}</md-radio
                >
                <md-radio slot="radio" value="Other" ariaLabel="${t("app:stationLogin.stationDnLabelsOtherFormat")}"
                  >${t("app:stationLogin.stationDnLabelsOtherFormat")}</md-radio
                >
              </md-radiogroup>
            </div>
            <!-- phone input -->
            ${this.renderDnForm()}
          </md-tab-panel>
          <md-tab slot="tab" label="${t(extensionLabel)}" aria-label="${t(extensionLabel)}" role="tab">
            <span>${t(extensionLabel)}</span>
          </md-tab>
          <md-tab-panel slot="panel" .focusablePanel=${"false"}>
            ${this.renderExtensionForm()}
          </md-tab-panel>
        </md-tabs>
      `;
    }

    private renderDNSection() {
      return html`
        <!-- International Checkbox -->
        <div class="international-checkbox-wrapper">
          <md-checkbox
            slot="checkbox"
            ?disabled="${this.isDefaultDnSet}"
            .checked="${this.ifInternationalFormat()}"
            @checkbox-change=${this.handleFormatChange}
            ariaLabel="${t("app:stationLogin.internationalDialingFormat")}"
            >${t("app:stationLogin.internationalDialingFormat")}</md-checkbox
          >
          <md-tooltip
            ?opened=${this.isIntenationalTooltipOpened}
            ?disabled="${this.isDefaultDnSet}"
            message="${t("app:stationLogin.internationalDialingFormatInfo")}"
            placement="top"
            ><md-button
              ?disabled="${this.isDefaultDnSet}"
              ariaLabel="${t("app:stationLogin.internationalDialingFormatInfo")}"
              circle
              variant="white"
              class="info-icon international-info-btn"
              role="tooltip"
              size="20"
              @focus=${() => (this.isIntenationalTooltipOpened = true)}
              @blur=${() => (this.isIntenationalTooltipOpened = false)}
            >
              <md-icon name="info_16"></md-icon>
            </md-button>
          </md-tooltip>
        </div>

        <!-- phone input -->
        ${this.ifInternationalFormat() ? this.renderInternationalForm() : this.renderDnForm()}
      `;
    }
    handleCheckboxChange(e: any) {
      const checkbox = e.target;
      this.dispatchEvent(
        new CustomEvent("checkbox-state-changed", {
          detail: { checked: checkbox.checked },
          bubbles: true,
          composed: true
        })
      );
    }

    renderEmergencyNotificationBody() {
      return html`
        <div class="emergency-notification-body">
          <agentx-wc-emergency-notification-content> </agentx-wc-emergency-notification-content>
          <div class="read-the-notification-wrapper">
            <md-checkbox
              id="readNotificationCheckbox"
              slot="checkbox"
              class="read-the-notification"
              @checkbox-change=${this.handleCheckboxChange}
            >
              ${t("app:emergencyServiceNotification.readNotification")}
            </md-checkbox>
          </div>
        </div>
      `;
    }

    renderUserProfileContent() {
      return html`
        <div class="user-profile">
          ${// Render tab based form in Station Login screen for non-esd flow, only if intDiallingEnabledFF is off
          this.isStationlogin && !this.isESDStationLoginFlowEnabled && !this.intDiallingEnabledFF
            ? html`
                ${this.renderTabsForNonESDFlow()}
              `
            : html`
                <!-- Role Select -->
                ${this.isESDStationLoginFlowEnabled && this.displayRoleDropdown
                  ? html`
                      <div class="md-input-container sl-combobox roles-combobox">
                        <label class="teams-label">${t("app:stationLogin.stationLoginRoleLabel")}</label>
                        <md-dropdown
                          class="roles-dropdown"
                          .title="${t("app:stationLogin.selectRole")}"
                          .options="${this.roles}"
                          option-id="roleId"
                          option-value="roleDisplay"
                          .visibleOptions=${2}
                          @dropdown-selected="${this.handleSelectedRole}"
                          .defaultOption=${this.getSelectedRole()}
                          custom-tab-index=${-1}
                          .autofocus=${this.getRoleSelection()}
                        ></md-dropdown>
                      </div>
                    `
                  : nothing}

                <!-- Agent_DN | Extension Section -->
                ${this.isWebRTCEnabled
                  ? this.renderVoiceOptionSection()
                  : this.displayDNExtension
                  ? html`
                      <div class="station-login-radio station-login-radio-min">
                        <div class="dial-number-label">
                          <label tabindex="-1" for="dial-number-label"
                            >${t("app:stationLogin.stationLoginDNExtension")}</label
                          >
                          <md-tooltip
                            ?opened=${this.isExtensionTooltipOpened}
                            message=${t(extensionInfo)}
                            placement="bottom"
                          >
                            <md-button
                              aria-label=${t(extensionInfo)}
                              circle
                              variant="white"
                              class="info-icon dn-info-icon-button"
                              role="tooltip"
                              size="20"
                              @focus=${() => (this.isExtensionTooltipOpened = true)}
                              @blur=${() => (this.isExtensionTooltipOpened = false)}
                              @keydown=${this.handleKeyDown}
                            >
                              <md-icon name="info_16"></md-icon>
                            </md-button>
                          </md-tooltip>
                        </div>
                        <md-radiogroup
                          class="station-login-radio-group"
                          group-label="${t("app:stationLogin.stationLoginDNExtension")}"
                          alignment="horizontal"
                          checked="${this.radioSelection}"
                          id="dial-number-label"
                          @keydown=${this.handleKeyDownRadio}
                          @radio-change=${(e: CustomEvent) => this.onDNExtensionRadioChange(e)}
                        >
                          <md-radio
                            slot="radio"
                            value="DN"
                            ariaLabel="${t(dnStationLogin)}"
                            title="${t(dnStationLogin)}"
                            >${t(dnStationLogin)}</md-radio
                          >
                          <md-radio
                            slot="radio"
                            value="Extension"
                            ariaLabel="${t("app:stationLogin.extension")}"
                            title="${t("app:stationLogin.extension")}"
                            >${t("app:stationLogin.extension")}
                          </md-radio>
                        </md-radiogroup>
                      </div>

                      ${this.mode === "Extension" ? this.renderExtensionForm() : this.renderDNSection()}
                    `
                  : nothing}
              `}
          <!-- team select -->
          ${this.displayTeamsDropdown ? this.renderTeam() : nothing}
          <!-- For React Agent Profile Component -->
          ${!this.isStationlogin
            ? html`
                <div class="remember-me-checkbox-wrapper">
                  <md-checkbox
                    slot="checkbox"
                    ?checked=${this.isRememberMyCredentials}
                    label="${t("app:stationLogin.rememberMeProfile")}"
                    class="remember-me-checkbox"
                    @checkbox-change=${() => this.handleRememberMeCheckBoxChange()}
                  >
                    ${t("app:stationLogin.rememberMeProfile")}
                  </md-checkbox>
                </div>
              `
            : nothing}
          <!-- Error Message -->
          ${this.isErrUnknown ? this.renderUnknownError() : nothing}
        </div>
      `;
    }

    render() {
      return this.showEmergencyNotificationWebrtc && !this.isEmergencyModalAlreadyDisplayed
        ? this.renderEmergencyNotificationBody()
        : this.renderUserProfileContent();
    }
  }
}
