import { CONF } from "@/config";
import { i18nMixin } from "@/mixins/i18nMixin";
import { Grid, GridOptions, LicenseManager } from "ag-grid-enterprise";
import { LitElement, customElement, html, internalProperty, property, query } from "lit-element";

import style from "./AgGrid.scss";

export namespace AGGrid {
  @customElement("agentx-wc-ag-grid")
  export class Element extends i18nMixin(LitElement) {
    @query("#agentx-wc-ag-grid-container") _gridDiv!: HTMLElement;

    @property({ type: Object }) gridOptions: GridOptions | any = {};
    @property({ type: Boolean }) isDarkMode = false;
    @property({ type: Boolean }) isLoggedIntoStation = false;

    @internalProperty() areExternalStyleSheetsLoaded: boolean[] = [];

    static get styles(): CSSResult {
      return style;
    }

    setAreExternalStyleSheetsLoaded(): void {
      this.areExternalStyleSheetsLoaded = [...this.areExternalStyleSheetsLoaded, true];
    }

    loadAGGridScript(): void {
      const agGridLink = document.createElement("link");
      const agThemeAlpineLink = document.createElement("link");

      const momentumIcons = document.createElement("link");
      momentumIcons.setAttribute("rel", "stylesheet");

      const momentumCore = document.createElement("link");
      momentumCore.setAttribute("rel", "stylesheet");

      agGridLink.setAttribute("rel", "stylesheet");
      agThemeAlpineLink.setAttribute("rel", "stylesheet");

      agGridLink.setAttribute("href", "https://cdn.jsdelivr.net/npm/ag-grid-enterprise@30.0.0/styles/ag-grid.css");
      agThemeAlpineLink.setAttribute(
        "href",
        "https://cdn.jsdelivr.net/npm/ag-grid-enterprise@30.0.0/styles/ag-theme-alpine.css"
      );

      momentumIcons.setAttribute("href", "/css/momentum-ui-icons.min.css");
      momentumCore.setAttribute("href", "/css/momentum-ui.min.css");

      const rootElement = this.shadowRoot?.querySelector(".grid-wrapper");
      rootElement?.appendChild(agGridLink);
      rootElement?.appendChild(agThemeAlpineLink);
      rootElement?.prepend(momentumIcons);
      rootElement?.prepend(momentumCore);

      momentumIcons.onload = () => {
        this.setAreExternalStyleSheetsLoaded();
      };

      momentumCore.onload = () => {
        this.setAreExternalStyleSheetsLoaded();
      };
      agGridLink.onload = () => {
        this.setAreExternalStyleSheetsLoaded();
      };
      agThemeAlpineLink.onload = () => {
        this.setAreExternalStyleSheetsLoaded();
      };
    }

    initializeGrid(): void {
      new Grid(this._gridDiv, this.gridOptions);
    }

    firstUpdated(changedProperties: PropertyValues): void {
      super.firstUpdated(changedProperties);
      this.loadAGGridScript();
      LicenseManager.setLicenseKey(CONF.AG_GRID_LICENSE_KEY ?? "");
    }

    updated(changedProperties: PropertyValues): void {
      super.updated(changedProperties);
      if (changedProperties.has("gridOptions") && this.isLoggedIntoStation) {
        if (changedProperties.get("gridOptions")) {
          this.gridOptions.api.destroy();
        }
        this.initializeGrid();
      }
    }

    render(): TemplateResult {
      return html`
        <div class=${`grid-wrapper  ${this.areExternalStyleSheetsLoaded.length === 4 ? "" : "hide"}`}>
          <div
            id="agentx-wc-ag-grid-container"
            class=${`grid-container  ${this.isDarkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"}`}
          ></div>
        </div>
      `;
    }
  }
}
