import { ACTIONS, TPW_EMPTY_COLUMN_VALUE } from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { CsvExportParams, ExcelExportParams, GridOptions } from "ag-grid-enterprise";
import { LitElement, customElement, html, internalProperty, property, query } from "lit-element";
import { nothing } from "lit-html";
import { styleMap } from "lit-html/directives/style-map";
import { getTimerValue } from "../TeamPerformanceUtils";
import { durationColumns } from "../Utils";
import { getDisplayedRowCountText } from "./../TeamPerformanceUtils";
import "./Customization/Customization";
import style from "./GridHeader.scss";

const CSV = "csv";
const EXCEL = "excel";
const excelSheetName = "Team Performance Details";

export namespace TableHeader {
  /**
   * @element agentx-wc-tpw-grid-header
   */
  @customElement("agentx-wc-tpw-grid-header")
  export class Element extends i18nMixin(LitElement) {
    @property({ reflect: true }) gridOptions: GridOptions = {};
    @property({ reflect: true, type: Number }) displayedRowCount = 0;
    @internalProperty() private isModalOpen = false;
    @query(".reset-table") closeModalButton!: HTMLElement;
    @property({ type: Boolean }) isServerSidePaginated = false;
    debounceTimer!: NodeJS.Timeout;

    static get styles() {
      return style;
    }

    private resetTable() {
      this.dispatchEvent(
        new CustomEvent("tpw-reset-table", {
          bubbles: true,
          composed: true,
          detail: { clearAllFilter: true }
        })
      );
      this.cancelModal();
    }
    private showConfirmationModal() {
      this.isModalOpen = true;
    }
    private cancelModal() {
      this.isModalOpen = false;
      this.closeModalButton?.shadowRoot!.querySelector("button")?.focus();
    }

    private handleSearch(searchTerm: string) {
      if (!this.isServerSidePaginated) {
        this.gridOptions.api?.setQuickFilter(searchTerm);
      } else {
        this.debounceTimer = setTimeout(() => {
          this.gridOptions.api?.setFilterModel({
            agentNameDetails: {
              type: "text",
              filter: searchTerm
            }
          });
        }, 500);
      }
      this.dispatchEvent(
        new CustomEvent("tpw-search-updated", {
          bubbles: true,
          composed: true,
          detail: true
        })
      );
      this.requestUpdate();
    }

    private getExportParams(): ExcelExportParams & CsvExportParams {
      return {
        fileName: `TPW_${new Date(Date.now()).toString()}`,
        allColumns: true,
        processCellCallback(params: any) {
          if (
            params.value &&
            params.value !== TPW_EMPTY_COLUMN_VALUE &&
            durationColumns.includes(params?.column?.colId)
          ) {
            return getTimerValue(params.value, true);
          }
          if (params?.column?.colId === ACTIONS) {
            return "";
          }
          if (params?.column?.colId === "agentNameDetails") {
            return params.value.agentName;
          } else {
            return params.value;
          }
        },
        processRowGroupCallback(params: any) {
          return `row group: ${params?.node?.key}`;
        },
        processHeaderCallback(params: any) {
          if (params?.column?.colId === ACTIONS) {
            return "";
          } else {
            return params.column.userProvidedColDef.headerName;
          }
        }
      };
    }

    private downloadTableData(downloadFormat: string) {
      switch (downloadFormat) {
        case CSV:
          this.gridOptions.api?.exportDataAsCsv(this.getExportParams());
          break;
        case EXCEL:
          this.gridOptions.api?.exportDataAsExcel({ ...this.getExportParams(), sheetName: excelSheetName });
          break;
      }
    }

    disconnectedCallback(): void {
      super.disconnectedCallback();
      this.gridOptions = {};
    }

    warningTooltip() {
      return html`
        <md-tooltip message=${t("app:tpw.agentWarning")} placement="bottom">
          <md-icon style="cursor: pointer" class="conflict-warning-info" name="icon-info_14"></md-icon>
        </md-tooltip>
      `;
    }

    renderIcon() {
      return html`
        <md-icon class="conflict-warning" name="icon-warning_14"></md-icon>
      `;
    }

    renderSearchRenderer() {
      const styles = { color: this.isServerSidePaginated ? "var(--md-yellow-70)" : "var(--md-secondary-text-color)" };

      return html`
        <div class="search-wrapper">
          <md-input
            htmlId="inputSearchClearPill"
            containerSize="small-12"
            placeholder=${t("app:tpw.search")}
            ariaLabel=${t("app:tpw.widgetTitle")}
            searchable
            shape="pill"
            clear
            clearAriaLabel="${t("app:common:clear")}"
            class="grid-search-input"
            @input-change=${(event: CustomEvent) => this.handleSearch(event.detail.value)}
          ></md-input>
          <span aria-live="polite" class="result-count-wrapper" style=${styleMap(styles)}>
            ${this.isServerSidePaginated ? this.renderIcon() : nothing}
            ${getDisplayedRowCountText(this.displayedRowCount)}
            ${this.isServerSidePaginated ? this.warningTooltip() : nothing}
          </span>
        </div>
      `;
    }

    renderResetTable() {
      return html`
        <md-button class="reset-table" color="color-none" @click=${() => this.showConfirmationModal()}
          ><md-icon slot="icon" name="reset_16" ariaHidden="true"></md-icon
          ><span slot="text">${t("app:tpw.resetTable")}</span></md-button
        >
        <md-modal
          applicationId="reset"
          ?show=${this.isModalOpen}
          id="actionModal"
          size="dialog"
          .hideFooter=${true}
          .hideHeader=${true}
          tabindex="${this.isModalOpen ? "0" : "-1"}"
          ?showclosebutton=${true}
          @close-modal=${this.cancelModal}
          .ariaLabelClose=${t("app:tpw.closeResetToDefaultView")}
          aria-labelled-by="reset-header"
          aria-label=""
        >
          <div slot="header" class="header-width">
            <span aria-label=${t("app:tpw.resetToDefaultView")} id="reset-header" class="reset-header"
              >${t("app:tpw.resetToDefaultView")}</span
            >
          </div>
          <div class="modal-body">
            <span>
              ${t("app:tpw.resetConfirmationText")} <br />
              ${t("app:tpw.resetConfirmationSubText")}
            </span>
          </div>
          <div slot="footer" class="reset-btn-wrapper">
            <md-button
              class="reset-submit"
              variant="primary"
              @click=${() => this.resetTable()}
              type="submit"
              ariaLabel=${t("app:tpw.resetTable")}
            >
              ${t("app:tpw.resetTable")}</md-button
            >
            <md-button class="reset-close" ariaLabel=${t("app:tpw.cancel")} type="reset" @click=${this.cancelModal}>
              ${t("app:tpw.cancel")}</md-button
            >
          </div>
        </md-modal>
      `;
    }

    renderExportRender() {
      return html`
        <md-menu-overlay size="small">
          <md-button slot="menu-trigger" color="color-none" class="download-btn"
            ><md-icon slot="icon" name="export_16" ariaHidden="true"></md-icon
            ><span slot="text">${t("app:tpw.download")}</span></md-button
          >
          <div class="export-overlay-container">
            <md-list label=${t("app:tpw.downloadTableContent")}>
              <md-list-item
                slot="list-item"
                @click=${() => this.downloadTableData(CSV)}
                @keypress=${(e: KeyboardEvent) => e.key === "Enter" && this.downloadTableData(CSV)}
                >${t("app:tpw.csv")}</md-list-item
              >
              <md-list-item
                slot="list-item"
                @click=${() => this.downloadTableData(EXCEL)}
                @keypress=${(e: KeyboardEvent) => e.key === "Enter" && this.downloadTableData(EXCEL)}
                >${t("app:tpw.microsoftExcel")}</md-list-item
              >
            </md-list>
          </div>
        </md-menu-overlay>
      `;
    }

    render() {
      return html`
        <div class="grid-header-container">
          ${this.renderSearchRenderer()}
          <div class="grid-header-action-container">
            ${this.renderResetTable()}
            <agentx-wc-tpw-customization .gridOptions=${this.gridOptions}></agentx-wc-tpw-customization>
            ${this.renderExportRender()}
          </div>
        </div>
      `;
    }
  }
}
