import { SERVICE } from "@agentx/agentx-services";
import { I18N } from "@uuip/unified-ui-platform-sdk";

export const i18nMixin = I18N.createMixin({ i18n: SERVICE.i18n });

export function t(...args: Parameters<typeof SERVICE.i18n.t>) {
  // Testing
  if (process.env.NODE_ENV === "test") {
    return (args && args.length ? (Array.isArray(args[0]) ? args[0].join("") : args[0]) : "") as string;
  }
  return SERVICE.i18n.t(...args);
}
