import { i18nMixin, t } from "@/mixins/i18nMixin";
import { debounce } from "@/utils/helpers";
import { ColDef, GridOptions } from "ag-grid-enterprise";
import { LitElement, customElement, html, internalProperty, property } from "lit-element";
import { fixedColumns, getIsCheckboxChecked, hiddenGroupColumn } from "../../Utils";
import style from "../GridHeader.scss";

export namespace GroupByCustomization {
  /**
   * @element agentx-wc-tpw-group-by
   */
  @customElement("agentx-wc-tpw-group-by")
  export class Element extends i18nMixin(LitElement) {
    @property({ reflect: true }) gridOptions: GridOptions = {};
    @property({ reflect: true }) rowGroupedColumns: string[] = [];
    @property({ reflect: true }) visibleColumns: Record<string, boolean> = {};

    @internalProperty() groupBySearchTerm = "";

    static get styles() {
      return style;
    }
    disconnectedCallback(): void {
      super.disconnectedCallback();
      this.gridOptions = {};
      this.rowGroupedColumns = [];
      this.visibleColumns = {};
    }
    handleSearch = debounce((event: CustomEvent) => {
      event.stopPropagation();
      const searchTerm = event?.detail?.value?.toLowerCase();
      this.groupBySearchTerm = searchTerm;
      setTimeout(() => {
        this.handleFocus(event);
      }, 100);
      this.requestUpdate();
    }, 300);

    private getColumnsHeaderName() {
      return this.gridOptions?.columnDefs?.filter((column: ColDef) => {
        return column.headerName?.toLocaleLowerCase().includes(this.groupBySearchTerm);
      });
    }

    private handleGroupByRowData(event: CustomEvent, columnDetail: ColDef) {
      if (event) {
        const isCheckboxChecked = getIsCheckboxChecked(event);
        if (isCheckboxChecked && columnDetail.field) {
          this.rowGroupedColumns.push(columnDetail.field);
        } else {
          this.rowGroupedColumns = this.rowGroupedColumns.filter((column: string) => {
            return column !== columnDetail.field;
          });
        }
        this.gridOptions.columnApi?.setRowGroupColumns(this.rowGroupedColumns);
        if (columnDetail.field && this.visibleColumns[columnDetail.field] === false) {
          this.gridOptions.columnApi?.setColumnVisible(columnDetail.field, false);
        }
      }
    }

    private handleFocus(event: CustomEvent) {
      const searchInput = this.shadowRoot?.querySelector<any>(".group-by-header-wrapper md-input");
      const input = searchInput?.shadowRoot?.querySelector("input");
      if (searchInput && input) {
        input.focus({ focusVisible: true });
      }
      event.stopPropagation();
    }

    renderMenuTriggerButton() {
      return html`
        <div class="menu-trigger-button" slot="menu-trigger">
          <span>${t("app:tpw.groupBy")}</span>
          <div><md-icon slot="icon" name="icon-arrow-right_12" /></div>
        </div>
      `;
    }

    render() {
      return html`
        <md-menu-overlay placement="right-start" size="small" @menu-overlay-open=${(event: CustomEvent) =>
          this.handleFocus(event)}>
          ${this.renderMenuTriggerButton()}
          <div class="overlay-container">
            <div class="group-by-header-wrapper">
                <md-input
                clear
                clearAriaLabel="${t("app:common:clear")}"
                htmlId="pillSearchInput"
                name="pillSearchInput"
                shape="pill"
                placeholder=${t("app:tpw.search")}
                ariaLabel=${t("app:tpw.search")}
                searchable
                @input-change=${(event: CustomEvent) => this.handleSearch(event)}
                />
                </md-input>
            </div>
            <div class="checkbox-container-group-column">
              <md-checkboxgroup group-label="column_titles">
                ${this.getColumnsHeaderName()?.map((column: ColDef) => {
                  if (column.field && !fixedColumns[column.field] && !hiddenGroupColumn[column.field]) {
                    return html`
                      <md-checkbox
                        class="checkbox"
                        slot="checkbox"
                        ?checked=${this.rowGroupedColumns.indexOf(column.field) !== -1}
                        @checkbox-change=${(event: CustomEvent) => this.handleGroupByRowData(event, column)}
                        >${column.headerName}</md-checkbox
                      >
                    `;
                  } else {
                    return html``;
                  }
                })}
              </md-checkboxgroup>
            </div>
          </div>
      </md-menu-overlay>
      `;
    }
  }
}
