/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin } from "@/mixins/i18nMixin";
import "@momentum-ui/web-components";
import "@uuip/unified-ui-platform-common-components";
import { customElement, html, LitElement, property } from "lit-element";
import style from "./EmptyStateWrapper.scss";

export namespace EmptyStateWrapper {
  /**
   * @element agentx-wc-empty-state-wrapper
   */
  @customElement("agentx-wc-empty-state-wrapper")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: String, attribute: "illustration-type" }) illustrationType = "";

    static get styles() {
      return style;
    }

    get imagePath() {
      switch (this.illustrationType) {
        case "empty-state":
          return "/images/illustrations/box-open-120.svg";
        case "empty-result":
          return "/images/illustrations/desert-open-results-192.svg";
        case "error-cloud":
          return "/images/illustrations/cloud-failure-192-red-pink.svg";
        default:
          return "/images/illustrations/box-open-120.svg";
      }
    }
    render() {
      return html`
        <div class="empty-state" part="container">
          <div class="empty-state__container">
            <img
              part="img"
              src="${this.imagePath}"
              alt="empty-state"
              aria-hidden="true"
              class="empty-state__illustration"
            />
            <slot></slot>
          </div>
        </div>
      `;
    }
  }
}
