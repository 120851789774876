import {
  CALL_ENDED,
  CONTACT_ACTIVE_EVENTS,
  CONTACT_COMPLETED,
  CONTACT_TRANSFERRED_EVENTS,
  TPW_EMPTY_COLUMN_VALUE,
  TPW_MONITOR_MODAL,
  WRAP_UP
} from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { logger } from "@/sdk";
import { RTDTypes, SERVICE, Service } from "@agentx/agentx-services";
import { LitElement, PropertyValues, customElement, html, internalProperty, property } from "lit-element";
import { nothing } from "lit-html";
import { ifDefined } from "lit-html/directives/if-defined";
import commonStyles from "../../../assets/styles/common.scss";
import "../../../components/CountDownTimer/CountDownTimer";
import style from "./ActiveInteraction.scss";
import "./AidValues";
export namespace ActiveInteraction {
  /**
   * @element agentx-wc-aid
   * @fires close-modal
   */
  @customElement("agentx-wc-aid")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Boolean }) isModalOpen = false;
    @property({ type: Object }) selectedAgentDetails!: RTDTypes.AgentDetails;
    @property({ type: Boolean }) isMCMEnabled = false;
    @property({ type: Boolean }) isBargeInEnabled = false;
    @property({ type: Boolean }) monitoringInProgress = false;
    @property({ type: String }) errorText = "";
    @property({ type: Boolean }) isAIDFixedVariablesEnabled = false;
    @property({ type: String }) role = "";
    @property({ type: Boolean }) isMPCorPersistEnabled = false;
    @property({ type: Boolean }) isWxccAgentInteractionEnabled = false;
    @internalProperty() shouldTrackAid = true;
    @internalProperty() startMonitoringTriggered = false;
    @internalProperty() interactionDetails = {
      isDisconnected: false, // Is Interaction is closed or not.
      interactionIsTransferred: false // Is interaction is closed by transfer or end
    };
    @internalProperty() enableWarningTooltip = false;
    @internalProperty() isStartMonitorFailed = false;
    @internalProperty() liveRegionMessage = "";

    @internalProperty() detail = {
      header: t("app:tpw.failedToLoad"),
      message: t("app:tpw.shareTrackId")
    };

    @internalProperty() isDataUpdated = false;
    MONITORING_CALL_MESSAGE = "app:tpw.currentlyMonitoringCall";
    RACE_ERROR_REASON_CODE = 53;

    @internalProperty() fixedVariables = [
      {
        variableText: "app:tpw.ContactPhoneNumber",
        variableName: "contactPhoneNumber"
      },
      {
        variableText: "app:tpw.ContactQueue",
        variableName: "contactQueueName"
      },
      {
        variableText: "app:tpw.ActiveParticipants",
        variableName: "activeParticipantsName"
      },
      {
        variableText: "app:tpw.ConsultedParticipant",
        variableName: "consultedParticipantName"
      },
      {
        variableText: "app:tpw.ContactStatus",
        variableName: "contactStatus"
      },
      {
        variableText: "app:tpw.TimeInContactStatus",
        variableName: "contactStatusTime"
      },
      {
        variableText: "app:tpw.AgentInteractionTime",
        variableName: "agentInteractionTime"
      },
      {
        variableText: "app:tpw.TotalContactDuration",
        variableName: "totalContactDuration"
      },
      {
        variableText: "app:tpw.Recording",
        variableName: "recording"
      }
    ];

    @internalProperty() multiPartyFixedVariables = [
      {
        variableText: "app:tpw.ContactPhoneNumber",
        variableName: "contactPhoneNumber"
      },
      {
        variableText: "app:tpw.ContactQueue",
        variableName: "contactQueueName"
      },
      {
        variableText: "app:tpw.Participants",
        variableName: "participants"
      },
      {
        variableText: "app:tpw.ContactStatus",
        variableName: "contactStatus"
      },
      {
        variableText: "app:tpw.TimeInContactStatus",
        variableName: "contactStatusTime"
      },
      {
        variableText: "app:tpw.AgentInteractionTime",
        variableName: "agentInteractionTime"
      },
      {
        variableText: "app:tpw.TotalContactDuration",
        variableName: "totalContactDuration"
      },
      {
        variableText: "app:tpw.Recording",
        variableName: "recording"
      }
    ];

    private readonly closeAIDModal = () => {
      this.errorText = "";
      this.dispatchEvent(
        new CustomEvent(TPW_MONITOR_MODAL, { detail: { modalIsOpen: false }, bubbles: false, composed: true })
      );
      this.isDataUpdated = false;

      this.startMonitoringTriggered = false;
      // Reset interaction details
      this.interactionDetails = {
        ...this.interactionDetails,
        isDisconnected: false, // Is Interaction is closed or not.
        interactionIsTransferred: false // Weather check interaction closed by transfer or normal close
      };
    };

    private readonly trackAIDClose = () => {
      this.closeAIDModal();
      if (this.shouldTrackAid) {
        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.AID_CLOSED, {
          SupervisorId: this.selectedAgentDetails.actions?.supervisorId ?? "",
          Role: this.role
        });
      }
    };

    private readonly closeAIDModalOnMonitoring = () => {
      this.shouldTrackAid = false;
      this.closeAIDModal();
    };

    private setErrorText = async (e: any) => {
      const { reasonCode } = e.detail;
      this.shouldTrackAid = true;
      if (this.isAIDFixedVariablesEnabled && reasonCode === this.RACE_ERROR_REASON_CODE) {
        this.errorText = t(this.MONITORING_CALL_MESSAGE);
      } else {
        this.closeAIDModal();
      }
    };

    private readonly ariaLabelForMonitoringButton = (): string => {
      const disableTooltipMessage = this.getDisableTooltipMessage();
      const startMonitoringMessage = t("app:tpw.startMonitoring");

      let message: string;
      if (disableTooltipMessage) {
        message = `${startMonitoringMessage}, ${disableTooltipMessage}`;
      } else {
        message = startMonitoringMessage;
      }

      return message;
    };

    attachEventListeners() {
      window.addEventListener("monitoring-offered", this.closeAIDModalOnMonitoring);
      window.addEventListener("monitoring-started", this.closeAIDModalOnMonitoring);
      window.addEventListener("monitoring-create-failed", this.setErrorText);
      window.addEventListener("monitoring-failed", this.setErrorText);
    }

    connectedCallback() {
      super.connectedCallback();
      this.attachEventListeners();
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      window.removeEventListener("monitoring-offered", this.closeAIDModalOnMonitoring);
      window.removeEventListener("monitoring-started", this.closeAIDModalOnMonitoring);
      window.removeEventListener("monitoring-create-failed", this.setErrorText);
      window.removeEventListener("monitoring-failed", this.setErrorText);
    }

    static get styles() {
      return [style, commonStyles];
    }

    private isInteractionDisconnected(): boolean {
      // This function will return the interaction is disconnected or not.
      return this.interactionDetails.isDisconnected;
    }

    private renderContent() {
      if (!this.isAIDFixedVariablesEnabled) {
        return html`
          <div class="aid-details-wip">
            <img src="/images/illustrations/work-organize-320.svg" alt="work-organize-320" />
            <p class="aid-details-wip-text" aria-label=${t("app:tpw.underDevelopment")} tabindex="0">
              ${t("app:tpw.underDevelopment")}
            </p>
          </div>
        `;
      }
      const agentDetails = this.selectedAgentDetails;
      let aidVariables = this.isMPCorPersistEnabled ? this.multiPartyFixedVariables : this.fixedVariables;

      if (!this.isWxccAgentInteractionEnabled) {
        aidVariables = aidVariables.filter(item => item.variableName !== "agentInteractionTime");
      }

      return html`
        <dl>
          ${aidVariables.map(fixedVariable => {
            const { variableName, variableText } = fixedVariable;
            // If interaction is disconnected, then will show the "-" only.
            const value = this.isInteractionDisconnected()
              ? TPW_EMPTY_COLUMN_VALUE
              : agentDetails[variableName as keyof typeof agentDetails] ?? TPW_EMPTY_COLUMN_VALUE;
            if (value !== TPW_EMPTY_COLUMN_VALUE) {
              return html`
                <div class="aid-details-wrapper">
                  <dt class="aid-field-ellipsis">${t(variableText)}</dt>
                  <dd class="aid-value">
                    <agentx-aid-value .variableName=${variableName} .variableValue=${value}></agentx-aid-value>
                  </dd>
                </div>
              `;
            } else {
              return undefined;
            }
          })}
        </dl>
      `;
    }

    private readonly generateUUID = (): string => {
      let d = Date.now();
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        const r = (d + Math.random() * 16) % 16 | 0; // eslint-disable-line no-bitwise
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16); // eslint-disable-line no-bitwise
      });
    };

    private startMonitoring() {
      this.liveRegionMessage = `${t("app:tpw.ringingMonitoring")} ${
        this.selectedAgentDetails?.agentNameDetails?.agentName
      }`;
      if (!this.startMonitoringTriggered) {
        this.startMonitoringTriggered = true; // Set true, Once the click the start monitoring button. This prevent multiple clicks
        const monitoringRequest: Service.Aqm.Supervisor.MonitoringRequest = {
          id: this.generateUUID(),
          monitorType: "midcall",
          trackingId: this.generateUUID(),
          taskId: this.selectedAgentDetails?.interactionId
        };
        SERVICE.aqm.supervisor
          ?.startMonitoring({ data: monitoringRequest })
          .then((data: any) => {
            logger.info("event= MonitoringRequestCreated:", data);
          })
          .catch(() => {
            logger.error("event= MonitoringRequestCreatedFailed:");
          });
      }
    }

    private retry() {
      //TODO
    }

    isConsultCall() {
      return (
        this.selectedAgentDetails.contactStatus === t("app:tpw.contactStatus.consult").toString() ||
        this.selectedAgentDetails.contactStatus === t("app:tpw.contactStatus.consulting").toString()
      );
    }

    private isStartMonitoringDisabled = (): boolean => {
      if (this.isAIDFixedVariablesEnabled) {
        // FF- EN
        return (
          (this.isInteractionDisconnected() ||
            this.errorText !== "" ||
            (this.selectedAgentDetails && this.selectedAgentDetails?.isMonitored === "true") ||
            this.monitoringInProgress ||
            this.selectedAgentDetails.actions?.isConsultCall) ??
          false
        );
      }
      return false;
    };

    ifFixedVariablesAreUpdated = (oldValues: RTDTypes.AgentDetails): boolean => {
      const newValues = this.selectedAgentDetails;
      return (
        oldValues.contactQueueName !== newValues.contactQueueName ||
        oldValues.totalContactDuration !== newValues.totalContactDuration ||
        oldValues.agentInteractionTime !== newValues.agentInteractionTime ||
        oldValues.contactStatusTime !== newValues.contactStatusTime ||
        oldValues.contactPhoneNumber !== newValues.contactPhoneNumber ||
        oldValues.activeParticipantsName !== newValues.activeParticipantsName ||
        oldValues.consultedParticipantName !== newValues.consultedParticipantName ||
        oldValues.recording !== newValues.recording ||
        oldValues.contactStatus !== newValues.contactStatus ||
        oldValues.actions !== newValues.actions
      );
    };

    private isCallTransferred() {
      // Once receive transferred eventname, we need persist this.interactionDetails.interactionIsTransferred=true
      return (
        this.interactionDetails.interactionIsTransferred ||
        (!this.interactionDetails.interactionIsTransferred &&
          CONTACT_TRANSFERRED_EVENTS.includes(this.selectedAgentDetails?.contactEventName || "") &&
          !CONTACT_ACTIVE_EVENTS.includes(this.selectedAgentDetails?.contactStatusCode || "") &&
          (this.selectedAgentDetails?.contactStatusCode === "" ||
            this.selectedAgentDetails?.contactStatusCode?.toLowerCase() === WRAP_UP))
      );
    }

    protected updated(changedProperties: PropertyValues): void {
      super.updated(changedProperties);
      const previousSelectedAgentDetails: any = changedProperties.get("selectedAgentDetails");
      if (
        changedProperties.has("selectedAgentDetails") &&
        previousSelectedAgentDetails &&
        !changedProperties.has("isModalOpen") &&
        this.isModalOpen &&
        !this.interactionDetails.isDisconnected &&
        this.isAIDFixedVariablesEnabled
      ) {
        this.isDataUpdated = this.ifFixedVariablesAreUpdated(previousSelectedAgentDetails) || this.isDataUpdated;
      }

      if (changedProperties.has("isModalOpen") && this.isModalOpen) {
        // While opening the modal reset the interaction error modal property values.
        this.interactionDetails = {
          isDisconnected: false,
          interactionIsTransferred: false
        };
      }

      if (changedProperties.has("selectedAgentDetails")) {
        if (
          previousSelectedAgentDetails &&
          Object.keys(previousSelectedAgentDetails).length > 0 &&
          this.selectedAgentDetails?.interactionId !== "" &&
          this.selectedAgentDetails?.interactionId !== previousSelectedAgentDetails?.interactionId
        ) {
          // New interaction id are updated, So disable the warnings.
          this.interactionDetails = {
            isDisconnected: false,
            interactionIsTransferred: false
          };
        } else if (this.isCallTransferred()) {
          // Interaction is Transferred.
          this.interactionDetails = {
            isDisconnected: true,
            interactionIsTransferred: true
          };
        } else if (
          this.selectedAgentDetails?.contactEventName === CONTACT_COMPLETED ||
          this.selectedAgentDetails?.contactStatusCode === WRAP_UP ||
          this.selectedAgentDetails?.contactStatusCode === CALL_ENDED ||
          this.selectedAgentDetails?.interactionId === ""
        ) {
          // Interaction is disconnected.
          this.interactionDetails = {
            isDisconnected: true,
            interactionIsTransferred: false
          };
        }
        if (
          this.errorText !== "" &&
          (!this.selectedAgentDetails.isMonitored || this.selectedAgentDetails.isMonitored === "false") &&
          (!this.selectedAgentDetails.monitorFullName ||
            this.selectedAgentDetails.monitorFullName === TPW_EMPTY_COLUMN_VALUE)
        ) {
          this.errorText = "";
          this.startMonitoringTriggered = false;
        }
      }
      this.enableWarningContentTooltip();
    }

    enableWarningContentTooltip = async (): Promise<void> => {
      await this.updateComplete;
      const element = this.shadowRoot?.querySelector(".aid-banner-text") as HTMLElement;
      if (element && this.isEllipsisActive(element)) {
        // Enable the tooptip.
        this.enableWarningTooltip = true;
      } else {
        this.enableWarningTooltip = false;
      }
    };

    isEllipsisActive = (element: HTMLElement): boolean => {
      return element.offsetHeight < element.scrollHeight;
    };

    renderStartMonitoringButton() {
      return this.isMCMEnabled
        ? html`
            <div aria-live="assertive" class="sr-only">
              ${this.liveRegionMessage}
            </div>
            <md-button
              class="aid-start-btn"
              ?disabled=${this.isStartMonitoringDisabled()}
              variant="primary"
              @click="${!this.isStartMonitoringDisabled() ? this.startMonitoring : ""}"
              type="submit"
              ariaLabel=${this.ariaLabelForMonitoringButton()}
            >
              <img
                class="aid-monitoring-icon"
                alt="${t("app:common.aidMonitoringIcon")}"
                size="28"
                src=${!this.isStartMonitoringDisabled()
                  ? "/images/illustrations/Monitoring-regular-dark.svg"
                  : "/images/illustrations/Monitoring-disable.svg"}
              />
              ${t("app:tpw.startMonitoring")}</md-button
            >
          `
        : ``;
    }

    private getDisableTooltipMessage() {
      if (this.isAIDFixedVariablesEnabled) {
        if (this.selectedAgentDetails.actions?.isSupervisorMonitoring) {
          return t("app:tpw.cannotMonitor");
        } else if (this.interactionDetails.isDisconnected) {
          // Interaction is closed, after AID modal is open
          if (this.interactionDetails.interactionIsTransferred) {
            // Interaction is Transferred.
            return t("app:tpw.interactionTransferredButton");
          }
          // Interaction is Ended.
          return t("app:tpw.interactionEndButton");
        } else if (
          this.selectedAgentDetails?.monitorFullName &&
          (this.errorText === t(this.MONITORING_CALL_MESSAGE) || this.selectedAgentDetails.isMonitored === "true")
        ) {
          if (this.isBargeInEnabled && this.selectedAgentDetails.actions?.isBarged) {
            return t("app:tpw.cannotMonitorBargedCall");
          } else {
            return this.selectedAgentDetails?.monitorFullName + t(this.MONITORING_CALL_MESSAGE);
          }
        } else if (this.selectedAgentDetails.actions?.isConsultCall) {
          return t("app:tpw.cannotMonitorConsult");
        }
      }
      return false;
    }

    renderModalFooter() {
      const toolTipMessage = this.getDisableTooltipMessage();
      return html`
        <md-tooltip
          class="aid-start"
          placement="top"
          ?disabled=${!toolTipMessage ? true : false}
          message=${toolTipMessage || ""}
        >
          ${this.renderStartMonitoringButton()}
        </md-tooltip>
        <md-button class="aid-close" ariaLabel=${t("app:tpw.cancel")} @click="${this.closeAIDModal}" type="reset">
          ${t("app:tpw.cancel")}</md-button
        >
      `;
    }

    renderWarningElement(): any {
      // getWarningContent
      if (this.isAIDFixedVariablesEnabled) {
        const { errorMessage, bannerContent } = this.getWarningContent();
        if (errorMessage) {
          return html`
            <md-tooltip class="aid-banner-content" message=${errorMessage} ?disabled=${!this.enableWarningTooltip}>
              ${bannerContent}
            </md-tooltip>
          `;
        }
      }
      return nothing;
    }

    getWarningContent(): any {
      if (
        this.errorText === t(this.MONITORING_CALL_MESSAGE) &&
        this.selectedAgentDetails?.monitorFullName &&
        (this.selectedAgentDetails.isMonitored === "false" || this.selectedAgentDetails.isMonitored === null)
      ) {
        // <!-- error banner when monitoringcreatefailed event is received -->
        const errorMessage = `${this.selectedAgentDetails?.monitorFullName} ${t(this.MONITORING_CALL_MESSAGE)}`;
        return {
          errorMessage,
          bannerContent: html`
            <md-alert-banner class="md-alert-banner-wrapper" show type="error">
              <div class="aid-banner-text-wrapper">
                <md-icon aria-hidden="true" name="icon-error_12"></md-icon>
                <div class="aid-banner-text aid-banner-text-error">
                  <span aria-label="${errorMessage}">${errorMessage}</span>
                </div>
              </div>
            </md-alert-banner>
          `
        };
      } else if (
        this.selectedAgentDetails.isMonitored === "true" &&
        !this.selectedAgentDetails.actions?.isSupervisorMonitoring
      ) {
        // <!-- warning banner to notify supervisor that call is monitored/barged by another supervisor -->
        const errorMessage =
          this.selectedAgentDetails.actions?.isBarged && this.isBargeInEnabled
            ? `${this.selectedAgentDetails?.monitorFullName} ${t("app:tpw.supervisorBargedIn")}`
            : `${this.selectedAgentDetails?.monitorFullName} ${t(this.MONITORING_CALL_MESSAGE)}`;
        return {
          errorMessage,
          bannerContent: html`
            <md-alert-banner class="md-alert-banner-wrapper" show type="warning">
              <div class="aid-banner-text-wrapper">
                <md-icon aria-hidden="true" name="icon-warning_12"></md-icon>
                <div class="aid-banner-text aid-banner-text-warning" aria-label="${errorMessage}">
                  ${errorMessage}
                </div>
              </div>
            </md-alert-banner>
          `
        };
      } else if (this.isInteractionDisconnected()) {
        // <!-- warning/error banner to notify supervisor that interaction is closed or transferred -->

        // Before click start monitoring, The agent is completed the interaction or transferred the interaction, then showing the "warning" type modal
        // While click start monitoring, simultaneously the agent is completed the interaction or transferred the interaction, then showing the "error" type modal
        const modalType = this.startMonitoringTriggered ? "error" : "warning";

        const errorMessage = this.interactionDetails.interactionIsTransferred
          ? t("app:tpw.interactionTransferred")
          : t("app:tpw.interactionEnd");
        return {
          errorMessage,
          bannerContent: html`
            <md-alert-banner class="md-alert-banner-wrapper" show type="${modalType}">
              <div class="aid-banner-text-wrapper">
                <md-icon
                  aria-hidden="true"
                  name="${modalType === "error" ? "icon-error_12" : "icon-warning_12"}"
                ></md-icon>
                <div
                  aria-label="${errorMessage}"
                  class="aid-banner-text ${modalType === "error" ? "aid-banner-text-error" : "aid-banner-text-warning"}"
                >
                  ${errorMessage}
                </div>
              </div>
            </md-alert-banner>
          `
        };
      } else if (this.monitoringInProgress && this.selectedAgentDetails.isMonitored === "true") {
        // info banner when supervisor is already monitoring this call
        const errorMessage = t("app:tpw.cannotMonitor");
        return {
          errorMessage,
          bannerContent: html`
            <md-alert-banner class="md-alert-banner-wrapper" show type="default">
              <div class="aid-banner-text-wrapper">
                <md-icon aria-hidden="true" name="icon-info_12"></md-icon>
                <div aria-label="${errorMessage}" class="aid-banner-text-info">
                  ${errorMessage}
                </div>
              </div>
            </md-alert-banner>
          `
        };
      } else {
        return nothing;
      }
    }
    render() {
      const HeaderText = this.isMCMEnabled ? t("app:tpw.reviewAndMonitor") : t("app:tpw.review");
      return html`
        <md-modal
          class="aid-modal"
          htmlId="modal-1"
          ?show=${this.isModalOpen}
          size="small"
          ariaLabelClose=${`${HeaderText} ${t("app:common.close")}`}
          headerLabel=${HeaderText}
          .hideFooter=${true}
          .hideHeader=${true}
          showCloseButton=${true}
          backdropClickExit=${true}
          @close-modal="${this.trackAIDClose}"
          >${this.selectedAgentDetails
            ? html`
                <div slot="header">
                  <h2 class="modal-header">${HeaderText}</h2>
                </div>
                <div class="aid-img-wrapper">
                  <div>
                    <md-avatar
                      alt=${ifDefined(this.selectedAgentDetails?.agentName)}
                      class="aid-img md-avatar md-avatar--medium"
                      title=${ifDefined(this.selectedAgentDetails?.agentName)}
                    >
                    </md-avatar>
                  </div>
                  <div aria-label=${ifDefined(this.selectedAgentDetails?.agentName)} class="aid-name">
                    ${ifDefined(this.selectedAgentDetails?.agentName)}
                  </div>
                </div>
                <div
                  tabindex="0"
                  role="group"
                  class=${`aid-body ${!this.isAIDFixedVariablesEnabled ? "aid-body-no-scroll" : ""}`}
                >
                  ${this.renderWarningElement()}
                  <div class=${`${this.isAIDFixedVariablesEnabled ? "aid-data-height-auto" : "aid-data"}`}>
                    ${this.selectedAgentDetails && this.renderContent()}
                  </div>
                </div>
                ${this.isAIDFixedVariablesEnabled && this.isDataUpdated
                  ? html`
                      <div class="aid-warning">
                        <md-icon class="aid-warning-icon" name="icon-warning_12"></md-icon>${t("app:tpw.isUpdated")}
                      </div>
                    `
                  : ``}
                ${this.errorText !== "" && this.errorText !== t(this.MONITORING_CALL_MESSAGE)
                  ? html`
                      <div class="aid-error">
                        <md-icon class="aid-error-icon" name="icon-error_12"></md-icon>${this.errorText}
                      </div>
                    `
                  : ``}
                <div slot="footer" class="aid-btn-wrapper">
                  ${this.renderModalFooter()}
                </div>
              `
            : html`
                <div class="aid-nodata-wrapper">
                  <agentx-wc-widget-error .detail=${this.detail} @reload=${this.retry}></agentx-wc-widget-error>
                </div>
              `}
        </md-modal>
      `;
    }
  }
}
