import { i18nMixin } from "@/mixins/i18nMixin";
import { html } from "@open-wc/testing-helpers";
import * as workerTimers from "@uuip/unified-ui-platform-sdk";
import { customElement, internalProperty, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import { DateTime } from "luxon";
import { getTimerValue } from "../TeamPerformanceWidget/TeamPerformanceUtils";
export namespace CountDownTimer {
  /**
   * @element agentx-wc-count-down-timer
   * @fires close-inactivity-modal
   * @fires countdown-start
   */
  @customElement("agentx-wc-count-down-timer")
  export class Element extends i18nMixin(LitElement) {
    @property() countdownStartTime = 0; //seconds
    @property() isDurationTimer = false;
    @property() showDefaultHours = true;
    @property() duration = "0";
    @internalProperty() futureTimeStamp = DateTime.local().plus({ seconds: this.countdownStartTime });
    @internalProperty() intervalId?: any;
    @internalProperty() durationTime: string | undefined;

    durationTimer = () => {
      this.durationTime = getTimerValue(this.duration, this.showDefaultHours);
    };

    countdownTimer = () => {
      if (parseInt(this.futureTimeStamp.diff(DateTime.local()).toFormat("ss")) > 0) {
        this.requestUpdate();
      } else {
        workerTimers.clearInterval(this.intervalId);
        this.dispatchEvent(new CustomEvent("close-inactivity-modal", { bubbles: true, detail: true }));
      }
    };

    connectedCallback(): void {
      super.connectedCallback();
      if (this.isDurationTimer) {
        if (this.duration !== "0") {
          this.intervalId = setInterval(this.durationTimer, 1000);
        }
      } else {
        this.futureTimeStamp = DateTime.local().plus({ seconds: this.countdownStartTime });
        this.intervalId = workerTimers.setInterval(this.countdownTimer, 1000);
        this.dispatchEvent(
          new CustomEvent("countdown-start", { bubbles: true, detail: { intervalId: this.intervalId } })
        );
      }
    }

    disconnectedCallback(): void {
      super.disconnectedCallback();
      clearInterval(this.intervalId);
    }

    render() {
      return !this.isDurationTimer
        ? this.countdownStartTime > 0
          ? html`
              <span class="timeperiod">
                <time role="timer">${this.futureTimeStamp.diff(DateTime.local()).toFormat("ss")}</time>
              </span>
            `
          : nothing
        : html`
            <time role="timer" datetime=${this.durationTime ?? ""}>
              ${this.durationTime && this.durationTime !== "Invalid Duration" ? this.durationTime : "-"}
            </time>
          `;
    }
  }
}
