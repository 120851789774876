import { Key, TPW_FILTER_UPDATED } from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import "@momentum-ui/web-components";
import { GridOptions } from "ag-grid-enterprise";
import { LitElement, PropertyValues, customElement, html, internalProperty, property } from "lit-element";
import { nothing } from "lit-html";
import commonStyles from "../../../assets/styles/common.scss";
import { TPW, columnHeaderMapping } from "../Utils";
import style from "./style.scss";

type UpdatedCoulmnFilter = {
  clearAllFilter?: boolean;
  field?: string;
  data?: Record<string, boolean>;
};

export namespace AppliedFilters {
  /**
   * @element agentx-wc-tpw-applied-filters
   */
  @customElement("agentx-wc-tpw-applied-filters")
  export class Element extends i18nMixin(LitElement) {
    @property({ reflect: true }) appliedFilters: TPW.FilterModel = {};
    @property({ reflect: true }) gridOptions: GridOptions = {};
    @property({ reflect: true }) appliedGroups = [];

    @internalProperty() isFilterApplied = false;
    @internalProperty() isExpanded = false;
    @internalProperty() columnWiseFilterCount: Record<string, number> = {};
    @internalProperty() columnWiseFilters: Record<string, string[]> = {};

    static get styles() {
      return [style, commonStyles];
    }

    private readonly updateFiltersAndFilterCount = () => {
      Object.keys(this.appliedFilters).forEach(columnField => {
        let columnFilterCount = 0;
        const columnFilters: string[] = [];
        Object.keys(this.appliedFilters[columnField]).forEach(columnData => {
          if (this.appliedFilters[columnField][columnData]) {
            columnFilterCount++;
            columnFilters.push(columnData);
          }
        });
        if (columnFilterCount) {
          this.isFilterApplied = true;
        }
        this.columnWiseFilterCount[columnField] = columnFilterCount;
        this.columnWiseFilters[columnField] = columnFilters;
      });
    };

    protected updated(changedProperties: PropertyValues) {
      super.updated(changedProperties);
      if (changedProperties.has("appliedFilters")) {
        this.isFilterApplied = false;
        this.updateFiltersAndFilterCount();
        this.requestUpdate();
      }
    }

    private checkKeyboardEventKey(event: MouseEvent | KeyboardEvent) {
      if (event.type === "keydown") {
        const { code } = event as KeyboardEvent;
        if (code !== Key.Space && code !== Key.Enter) {
          return false;
        }
      }
      return true;
    }

    private dispatchFilterUpdateEvent(updatedColumnFilter: UpdatedCoulmnFilter) {
      this.dispatchEvent(
        new CustomEvent(TPW_FILTER_UPDATED, {
          bubbles: true,
          composed: true,
          detail: updatedColumnFilter
        })
      );
    }

    private dispatchGroupFilterUpdateEvent(updatedColumnFilter: UpdatedCoulmnFilter) {
      this.dispatchEvent(
        new CustomEvent("tpw-group-filter-updated", {
          bubbles: true,
          composed: true,
          detail: updatedColumnFilter
        })
      );
    }

    private handleRemoveColumnAllFilters(event: MouseEvent | KeyboardEvent, columnField: string) {
      if (!this.checkKeyboardEventKey(event)) {
        return;
      }
      event.preventDefault();
      this.dispatchFilterUpdateEvent({
        field: columnField,
        data: {}
      });
    }

    private handleRemoveColumnDataFilter(event: MouseEvent | KeyboardEvent, columnField: string, columnData: string) {
      if (!this.checkKeyboardEventKey(event)) {
        return;
      }
      event.preventDefault();
      const updatedColumnFilter = {
        field: columnField,
        data: {
          ...this.appliedFilters[columnField],
          [columnData]: false
        }
      };
      this.dispatchFilterUpdateEvent(updatedColumnFilter);
    }

    private readonly handleExpandAppliedFilters = (event: MouseEvent | KeyboardEvent) => {
      if (!this.checkKeyboardEventKey(event)) {
        return;
      }
      event.preventDefault();
      this.isExpanded = !this.isExpanded;
    };

    private readonly handleClearAllFilters = (event: MouseEvent | KeyboardEvent) => {
      if (!this.checkKeyboardEventKey(event)) {
        return;
      }
      event.preventDefault();
      this.dispatchFilterUpdateEvent({
        clearAllFilter: true
      });
    };

    private renderCollapsedView() {
      return html`
        ${Object.keys(this.columnWiseFilterCount).map(columnField => {
          if (this.columnWiseFilterCount[columnField]) {
            return html`
              <md-chip
                role="button"
                aria-label="${t("app:tpw.remove")} ${t("app:tpw.filter")} ${columnHeaderMapping()[columnField]} (${this
                  .columnWiseFilterCount[columnField]}) "
                aria-describedby="filter-info-id"
                tooltipText=${this.columnWiseFilters[columnField].join(" ,")}
                tooltipPlacement="bottom"
                value="${`${columnHeaderMapping()[columnField]} (${this.columnWiseFilterCount[columnField]})`}"
                class="applied-filters-chips filter-collapsed-view"
                @chip-interaction=${(event: MouseEvent) => this.handleRemoveColumnAllFilters(event, columnField)}
                @click=${(event: MouseEvent) => this.handleRemoveColumnAllFilters(event, columnField)}
                @keydown=${(event: KeyboardEvent) => this.handleRemoveColumnAllFilters(event, columnField)}
              >
                <span class="sr-only" slot="custom-left-content" id="filter-info-id"
                  >${this.columnWiseFilters[columnField].join(" ,")}</span
                >
                <md-icon
                  name="icon-cancel_8"
                  slot="custom-right-content"
                  class="chip-cross-icon"
                  tabIndex="-1"
                ></md-icon>
              </md-chip>
            `;
          } else {
            return nothing;
          }
        })}
      `;
    }

    private renderExpandedView() {
      const appliedFiltersData = [];
      const columnsDataKeys = Object.keys(this.appliedFilters);
      for (const columnsDataKey of columnsDataKeys) {
        const columnsDataNestedKeys = this.appliedFilters[columnsDataKey];
        for (const i in columnsDataNestedKeys) {
          if (columnsDataNestedKeys[i]) {
            appliedFiltersData.push({
              columnsDataKey,
              colName: columnHeaderMapping()[columnsDataKey],
              colVal: i
            });
          }
        }
      }
      return appliedFiltersData.map((data: { colName: string; colVal: string; columnsDataKey: string }) => {
        return html`
          <md-chip
            role="button"
            aria-label="${t("app:tpw.remove")} ${t("app:tpw.filter")} ${data.colName}: ${data.colVal}"
            aria-describedby="data-filter-info"
            class="applied-filters-chips filter-expanded-view"
            tooltipText="${data.colName}: ${data.colVal}"
            tooltipPlacement="bottom"
            value="${data.colName}: ${data.colVal}"
            @chip-interaction=${(event: MouseEvent) =>
              this.handleRemoveColumnDataFilter(event, data.columnsDataKey, data.colVal)}
          >
            <span class="sr-only" slot="custom-left-content" id="data-filter-info"
              >${data.colName}: ${data.colVal}</span
            >
            <md-icon
              name="icon-cancel_8"
              slot="custom-right-content"
              class="chip-cross-icon"
              tabIndex="-1"
              @click=${(event: MouseEvent) =>
                this.handleRemoveColumnDataFilter(event, data.columnsDataKey, data.colVal)}
              @keydown=${(event: KeyboardEvent) =>
                this.handleRemoveColumnDataFilter(event, data.columnsDataKey, data.colVal)}
            ></md-icon>
          </md-chip>
        `;
      });
    }

    private renderFilterActionButtons() {
      return html`
        <md-link
          tag="span"
          ariaRole="button"
          class="applied-filters-expandCollapse-btn applied-filters-action-btn"
          @click=${(event: MouseEvent) => this.handleExpandAppliedFilters(event)}
          @keydown=${(event: KeyboardEvent) => this.handleExpandAppliedFilters(event)}
        >
          ${this.isExpanded ? t("app:tpw.collapse") : t("app:tpw.expand")}
        </md-link>
        <md-link
          tag="span"
          ariaRole="button"
          class="applied-filters-action-btn"
          @click=${(event: MouseEvent) => this.handleClearAllFilters(event)}
          @keydown=${(event: KeyboardEvent) => this.handleClearAllFilters(event)}
        >
          ${t("app:tpw.clearAll")}
        </md-link>
      `;
    }
    private getAppliedGroupFilters(columnDefs: any, appliedGroupsBy: any) {
      const appliedGroupsData = appliedGroupsBy.map((a: { colId: any }) => {
        return {
          id: a.colId,
          name: columnDefs?.find((b: { field: any }) => b.field === a.colId)?.headerName
        };
      });
      return appliedGroupsData.map((data: { name: string; id: string }) => {
        return html`
          <md-chip
            role="button"
            aria-label="${t("app:tpw.remove")} ${t("app:tpw.group")} ${data.name}"
            aria-describedby="group-filter-info"
            class="applied-groups-chips filter-expanded-view"
            tooltipText=${data.name}
            tooltipPlacement="bottom"
            value=${data.name}
            @chip-interaction=${(event: MouseEvent) => this.onGroupFilterClear(data.id, event)}
          >
            <span class="sr-only" slot="custom-left-content" id="group-filter-info">${data.name}</span>
            <md-icon
              name="icon-cancel_8"
              slot="custom-right-content"
              class="chip-cross-icon"
              tabIndex="-1"
              @click=${(event: MouseEvent) => this.onGroupFilterClear(data.id, event)}
              @keydown=${(event: KeyboardEvent) => this.onGroupFilterClear(data.id, event)}
            ></md-icon>
          </md-chip>
        `;
      });
    }

    private onGroupFilterClearAll(event: MouseEvent | KeyboardEvent) {
      if (!this.checkKeyboardEventKey(event)) {
        return;
      }
      event.preventDefault();
      this.dispatchGroupFilterUpdateEvent({
        clearAllFilter: true
      });
    }
    private onGroupFilterClear(fieldId: string, event: MouseEvent | KeyboardEvent) {
      if (!this.checkKeyboardEventKey(event)) {
        return;
      }
      event.preventDefault();
      this.dispatchGroupFilterUpdateEvent({
        field: fieldId
      });
    }

    render() {
      return html`
        <div>
          ${this.isFilterApplied
            ? html`
                <div class="applied-filters-wrapper">
                  <span class="applied-filters__label">${`${t("app:tpw.filtersApplied")}:`} </span>
                  <span class="applied-group__chip">
                    ${this.isExpanded ? this.renderExpandedView() : this.renderCollapsedView()}
                  </span>
                  ${this.renderFilterActionButtons()}
                </div>
              `
            : nothing}
          ${this.appliedGroups.length > 0
            ? html`
                <div class="applied-group-wrapper">
                  <span class="applied-group__label">${t("app:tpw.groupBy")}: </span>
                  <span class="applied-group__chip">
                    ${this.getAppliedGroupFilters(this.gridOptions.columnDefs, this.appliedGroups)}
                  </span>
                  ${this.appliedGroups.length > 1
                    ? html`
                        <md-link
                          ariaRole="button"
                          data-testid="applied-group__clear-all"
                          class="applied-group__clear-all"
                          @click=${(event: MouseEvent) => this.onGroupFilterClearAll(event)}
                          @keydown=${(event: KeyboardEvent) => this.onGroupFilterClearAll(event)}
                          >${t("app:tpw.clearAll")}
                        </md-link>
                      `
                    : nothing}
                </div>
              `
            : nothing}
        </div>
      `;
    }
  }
}
