import {
  AGENTCONNECT_COMPLETED,
  AVAILABLE_CONSULTING,
  CAPTURE_STARTED,
  CONFERENCE_COMPLETED,
  CONFERENCE_REQUESTED,
  CONFERENCE_STARTED,
  CONFERENCING,
  CONNECTED,
  CONNECTED_CONSULTING,
  CONSULTING,
  CONSULT_COMPLETED,
  CONSULT_DONE,
  CONSULT_ERROR,
  CONSULT_STARTED,
  DELETE,
  EXIT_CONFERENCE,
  GRID_ROW_MODAL,
  HOLD_COMPLETED,
  HOLD_COMPLETED_CONFERENCE,
  HOLD_COMPLETED_CONSULT,
  HOLD_STARTED,
  HOLD_STARTED_CONFERENCE,
  HOLD_STARTED_CONSULT,
  IDLE_CONSULTING,
  INBOUND,
  JOIN_CONFERENCE,
  LOGGED_OUT,
  MONITORING_ENDED,
  ON_HOLD,
  PRIMARY_OWNER_CHANGED,
  SIGNED_OUT,
  TELEPHONY,
  TPW_EMPTY_COLUMN_VALUE,
  TPW_FILTER_DATA_UPDATE,
  TPW_FILTER_MODAL_UPDATED,
  TRANSFER_COMPLETED,
  WEB_RTC_PREFIX,
  WRAP_UP,
  WRAP_UP_ASSISTANCE
} from "@/constants";
import { t } from "@/mixins/i18nMixin";
import { CMSTypes, RTDTypes } from "@agentx/agentx-services";
import { DateTime, Duration } from "luxon";
import { EMPTY_AGENT_DETAILS, disableForContactStatus, filterColumnName, localiseAvailableState } from "./Utils";

const cacheKey = (agentId: string): string => `avt-${agentId}`;

type ParticipantData = {
  newDataForParticipants: RTDTypes.AgentDetails[];
  existingDataForParticipants: RTDTypes.AgentDetails[];
};

// on filter update save the grid configs to local storage
export const disptachFilterUpdateEvent = (comp: any) => {
  comp.dispatchEvent(
    new CustomEvent(TPW_FILTER_MODAL_UPDATED, {
      bubbles: true,
      composed: true
    })
  );
};
export const disptachFilterDataUpdateEvent = (key: keyof RTDTypes.AgentDetails) => {
  window.dispatchEvent(
    new CustomEvent(TPW_FILTER_DATA_UPDATE, {
      bubbles: true,
      composed: true,
      detail: { colId: key }
    })
  );
};

/**
 * Checks if applied filters exists for that specific column key for which filter is applied
 * @param comp
 * @param key
 * @returns Boolean
 */
const checkIfFilterAppliedExists = (comp: any, key: keyof RTDTypes.AgentDetails) => {
  return (
    comp.appliedFilters &&
    Object.prototype.hasOwnProperty.call(comp.appliedFilters, key) &&
    Object.keys(comp.appliedFilters[key]).length !== 0
  );
};

const isReviewAndMonitorEnabled = (contactStatus: string | undefined): boolean => {
  return !!contactStatus && !disableForContactStatus.includes(contactStatus);
};
/**
 * Add the new row entry data into the filter model
 * @param comp
 * @param key
 * @param rowData
 */
const updateAppliedFilter = (
  comp: any,
  key: keyof RTDTypes.AgentDetails,
  rowData: RTDTypes.AgentDetails,
  oldData?: RTDTypes.AgentDetails
) => {
  if (checkIfFilterAppliedExists(comp, key)) {
    const k: any = rowData[key];
    if (oldData && oldData[key] === TPW_EMPTY_COLUMN_VALUE && k !== TPW_EMPTY_COLUMN_VALUE) {
      delete comp.appliedFilters[key][TPW_EMPTY_COLUMN_VALUE];
    }
    if (key === "agentNameDetails") {
      // agentNameDetails is a object, Contains the agentName property.
      if (!Object.prototype.hasOwnProperty.call(comp.appliedFilters[key], k.agentName)) {
        comp.appliedFilters[key][k.agentName] = false;
      }
    } else {
      if (!Object.prototype.hasOwnProperty.call(comp.appliedFilters[key], k)) {
        comp.appliedFilters[key][k] = false;
      }
    }
  }
};

export const getDateFormat = (date: number, lang = "en-US") => {
  return DateTime.fromJSDate(new Date(date))
    .setLocale(lang)
    .toLocaleString(DateTime.DATETIME_SHORT);
};
export const getAvatarUrlFromCache = (agentId: string): string | undefined => {
  const url = sessionStorage.getItem(cacheKey(agentId));
  return (typeof url === "string" && url.trim() === "") || url ? url : undefined;
};

export const updateAvatarUrlCache = (agentId: string, url: string | undefined): void => {
  sessionStorage.setItem(cacheKey(agentId), url ? url : "");
};

export const getDisplayedRowCountText = (displayedRowCount: number) => {
  return displayedRowCount === 1
    ? `${t("app:tpw.displaying")} ${displayedRowCount} ${t("app:tpw.agent")}`
    : `${t("app:tpw.displaying")} ${displayedRowCount} ${t("app:tpw.agents")}`;
};
export const formatDuration = (duration: Duration, showDefaultHours = true): string => {
  if (!showDefaultHours && duration.shiftTo("hours", "minute").get("hours") < 1) {
    return duration.toFormat("mm:ss");
  }
  return duration.toFormat("hh:mm:ss");
};

export const getFormattedDurationTime = (
  durationTime: Duration | undefined,
  showDefaultHours: boolean
): string | undefined => {
  if (durationTime) {
    if (durationTime.shiftTo("seconds").seconds < 0) {
      return undefined;
    }
    const formattedDurationTime = formatDuration(durationTime, showDefaultHours);
    if (
      formattedDurationTime &&
      formattedDurationTime !== "Invalid DateTime" &&
      formattedDurationTime.split(":")[0].length === 1
    ) {
      return `0${formattedDurationTime}`;
    }
    return formattedDurationTime;
  }
  return undefined;
};
export const getTimerValue = (duration: string, showDefaultHours: boolean): string | undefined => {
  if (duration === TPW_EMPTY_COLUMN_VALUE) {
    return "Invalid Duration";
  }
  const start = DateTime.fromMillis(Number(duration));

  return getFormattedDurationTime(DateTime.utc().diff(start), showDefaultHours);
};

export const getAgentState = (state: string | undefined, idleCodeName: string | null | undefined): string => {
  if (state) {
    if (idleCodeName) {
      return idleCodeName;
    } else if (state === LOGGED_OUT) {
      return SIGNED_OUT;
    } else {
      return state;
    }
  }
  return TPW_EMPTY_COLUMN_VALUE;
};

export const stringComparator = (valueA: string, valueB: string): number => {
  const nameA = valueA?.toLocaleLowerCase();
  const nameB = valueB?.toLocaleLowerCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};
export const agentNameComparator = (valueA: any, valueB: any): number => {
  const firstValue = typeof valueA === "object" ? valueA["agentName"] : valueA;
  const secondValue = typeof valueB === "object" ? valueB["agentName"] : valueB;
  const nameA = firstValue?.toLocaleLowerCase();
  const nameB = secondValue?.toLocaleLowerCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};
export const durationComparator = (valueA: number, valueB: number): number => {
  if (valueA < valueB) {
    return 1;
  }
  if (valueA > valueB) {
    return -1;
  }
  return 0;
};

export const dateComparator = (valueA: number | string, valueB: number | string): number => {
  if (valueA === TPW_EMPTY_COLUMN_VALUE && valueB !== TPW_EMPTY_COLUMN_VALUE) {
    return 1;
  }
  if (valueA !== TPW_EMPTY_COLUMN_VALUE && valueB === TPW_EMPTY_COLUMN_VALUE) {
    return -1;
  }
  if (valueA === TPW_EMPTY_COLUMN_VALUE && valueB === TPW_EMPTY_COLUMN_VALUE) {
    return 0;
  }
  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }
  return 0;
};

export const checkIfFilterModelIsEmpty = (filterModel: Record<string, boolean>): boolean => {
  return Object.values(filterModel).every(item => !item);
};

const getParticipatedSupervisorName = (isSameSupervisor: boolean, monitoringSupervisorName: string) => {
  return isSameSupervisor ? `, ${monitoringSupervisorName} (${t("app:tpw.you")})` : `, ${monitoringSupervisorName}`;
};
export const getParticipantsDetails = (
  agentContact: RTDTypes.AgentContactStats["data"],
  newEventName: string | undefined,
  isMPCorPersistEnabled: boolean,
  supervisorId?: string
): RTDTypes.ParticipantDetails => {
  const destinationAgent = agentContact.destinationAgentName ?? agentContact.destinationAgentDNIS;
  const bargedInSupervisor =
    agentContact.isBarged === "true" && agentContact.monitorFullName
      ? getParticipatedSupervisorName(
          !!supervisorId && supervisorId === agentContact.monitorUserId,
          agentContact.monitorFullName
        )
      : "";
  let participantDetails: RTDTypes.ParticipantDetails = {
    activeParticipantsName: TPW_EMPTY_COLUMN_VALUE,
    consultedParticipantName: TPW_EMPTY_COLUMN_VALUE,
    participants: TPW_EMPTY_COLUMN_VALUE
  };
  const participatingAgentList = agentContact.participatingAgentList
    ? JSON.parse(agentContact.participatingAgentList)
    : undefined;
  if (isMPCorPersistEnabled) {
    participantDetails.participants = participatingAgentList
      ?.reduce((acc: string[], participatingAgent: RTDTypes.ParticipatingAgent) => {
        const value = participatingAgent.type === "agent" ? participatingAgent.name : participatingAgent.dn;
        if (value) {
          acc.push(value);
        }
        return acc;
      }, [])
      .join(", ")
      .concat(bargedInSupervisor);
  } else {
    if (
      newEventName === CONFERENCE_REQUESTED ||
      newEventName === CONFERENCE_STARTED ||
      newEventName === HOLD_COMPLETED_CONFERENCE ||
      newEventName === HOLD_STARTED_CONFERENCE
    ) {
      participantDetails = {
        activeParticipantsName: destinationAgent
          ? `${agentContact.agentName}, ${destinationAgent}${bargedInSupervisor}`
          : agentContact.agentName,
        consultedParticipantName: TPW_EMPTY_COLUMN_VALUE
      };
    } else if (newEventName === TRANSFER_COMPLETED) {
      participantDetails = {
        activeParticipantsName:
          !agentContact.destinationAgentName && agentContact.destinationAgentDNIS
            ? `${agentContact.destinationAgentDNIS}${bargedInSupervisor}`
            : agentContact.agentName,
        consultedParticipantName: TPW_EMPTY_COLUMN_VALUE
      };
    } else {
      participantDetails = {
        activeParticipantsName: `${agentContact.agentName}${bargedInSupervisor}`,
        consultedParticipantName: destinationAgent ?? TPW_EMPTY_COLUMN_VALUE
      };
    }
  }
  return participantDetails;
};
export const mapAgentContactStatus = (contactStatus: string, oldContactStatus: string): string => {
  if (contactStatus) {
    switch (contactStatus.toLowerCase()) {
      case CONNECTED:
      case WRAP_UP:
      case WRAP_UP_ASSISTANCE:
      case ON_HOLD:
      case CONSULT_DONE:
      case CONFERENCING:
      case CONSULT_ERROR:
        return t(`app:tpw.contactStatus.${contactStatus.toLowerCase()}`);
      case "":
        return TPW_EMPTY_COLUMN_VALUE;
      case AVAILABLE_CONSULTING:
      case CONNECTED_CONSULTING:
      case IDLE_CONSULTING:
      case CONSULTING:
        return t(`app:tpw.contactStatus.consulting`);
      default:
        return oldContactStatus;
    }
  }
  return TPW_EMPTY_COLUMN_VALUE;
};

export const getContactStatsDetailsForAllParticipants = (
  contactData: RTDTypes.AgentContactStats,
  oldDataMap: Map<string, RTDTypes.AgentDetails> | undefined,
  newDataForPrimaryAgent: RTDTypes.AgentDetails,
  supervisorId: string
): ParticipantData => {
  const newDataForParticipants: RTDTypes.AgentDetails[] = [];
  const existingDataForParticipants: RTDTypes.AgentDetails[] = [];
  const oldParticipantsList = oldDataMap?.get(newDataForPrimaryAgent.agentId)?.participatingAgentList;
  const newParticipantsList = contactData.data.participatingAgentList
    ? (JSON.parse(contactData.data.participatingAgentList) as RTDTypes.ParticipatingAgent[])
    : undefined;

  const removedParticipants = oldParticipantsList?.filter(
    oldParticipant => !newParticipantsList?.some(newParticipant => newParticipant.id === oldParticipant.id)
  );

  newParticipantsList?.forEach(participant => {
    const existingDataForParticipant = oldDataMap?.has(participant.id) ? oldDataMap.get(participant.id) : undefined;
    const actionCellDetails: RTDTypes.ActionCellDetails = {
      ...newDataForPrimaryAgent.actions,
      agentId: participant.id,
      agentName: participant.name,
      supervisorId
    };
    const newDataForParticipant: RTDTypes.AgentDetails = {
      ...EMPTY_AGENT_DETAILS,
      ...existingDataForParticipant,
      agentId: participant.id,
      agentName: participant.name,
      interactionId: newDataForPrimaryAgent.interactionId,
      isMonitored: newDataForPrimaryAgent.isMonitored,
      isBarged: newDataForPrimaryAgent.isBarged,
      monitorFullName: newDataForPrimaryAgent.monitorFullName,
      monitorUserId: newDataForPrimaryAgent.monitorUserId,
      contactPhoneNumber: newDataForPrimaryAgent.contactPhoneNumber,
      contactQueueName: newDataForPrimaryAgent.contactQueueName,
      participatingAgentList: newDataForPrimaryAgent.participatingAgentList,
      participants: newDataForPrimaryAgent.participants,
      activeParticipantsName: newDataForPrimaryAgent.activeParticipantsName,
      consultedParticipantName: newDataForPrimaryAgent.consultedParticipantName,
      recording: newDataForPrimaryAgent.recording,
      contactEventName: newDataForPrimaryAgent.contactEventName,
      actions: actionCellDetails
    };
    newDataForParticipants.push(newDataForParticipant);
    if (existingDataForParticipant) {
      existingDataForParticipants.push(existingDataForParticipant);
    }
  });
  removedParticipants?.forEach(participant => {
    const existingDataForParticipant = oldDataMap?.has(participant.id) ? oldDataMap.get(participant.id) : undefined;
    const newDataForParticipant: RTDTypes.AgentDetails = {
      ...EMPTY_AGENT_DETAILS,
      ...existingDataForParticipant,
      agentId: participant.id,
      agentName: participant.name,
      interactionId: "",
      isMonitored: "false",
      isBarged: "false",
      monitorFullName: "",
      monitorUserId: "",
      contactPhoneNumber: TPW_EMPTY_COLUMN_VALUE,
      contactQueueName: TPW_EMPTY_COLUMN_VALUE,
      participants: TPW_EMPTY_COLUMN_VALUE,
      activeParticipantsName: TPW_EMPTY_COLUMN_VALUE,
      consultedParticipantName: TPW_EMPTY_COLUMN_VALUE,
      recording: TPW_EMPTY_COLUMN_VALUE,
      contactEventName: TPW_EMPTY_COLUMN_VALUE,
      actions: { agentId: participant.id, supervisorId } as RTDTypes.ActionCellDetails,
      participatingAgentList: newDataForPrimaryAgent.participatingAgentList
    };
    newDataForParticipants.push(newDataForParticipant);
    if (existingDataForParticipant) {
      existingDataForParticipants.push(existingDataForParticipant);
    }
  });
  return { newDataForParticipants, existingDataForParticipants };
};

export const updateContactStatsForDestinationAgent = (
  contactData: RTDTypes.AgentContactStats,
  oldDataMap: Map<string, RTDTypes.AgentDetails> | undefined,
  newDataForPrimaryAgent: RTDTypes.AgentDetails,
  supervisorId: string,
  existingDataForPrimaryAgent?: RTDTypes.AgentDetails
) => {
  let newDataForDestinationAgent: RTDTypes.AgentDetails = {} as RTDTypes.AgentDetails;
  let existingDataForDestinationAgent: RTDTypes.AgentDetails | undefined;
  if (contactData.data.destinationAgentId !== null && contactData.data.destinationAgentName !== null) {
    /** This block of code is used for populating Agent 2 details from 
    destinationAgentId present in Agent 1 details and copying only contact related information **/
    existingDataForDestinationAgent = oldDataMap?.has(contactData.data.destinationAgentId)
      ? oldDataMap.get(contactData.data.destinationAgentId)
      : undefined;

    const actionCellDetails: RTDTypes.ActionCellDetails = {
      ...newDataForPrimaryAgent.actions,
      agentId: contactData.data.destinationAgentId,
      agentName: contactData.data.destinationAgentName,
      supervisorId
    };

    newDataForDestinationAgent = {
      ...EMPTY_AGENT_DETAILS,
      ...existingDataForDestinationAgent,
      agentId: contactData.data.destinationAgentId,
      agentName: contactData.data.destinationAgentName,
      interactionId: newDataForPrimaryAgent.interactionId,
      isMonitored: newDataForPrimaryAgent.isMonitored,
      isBarged: newDataForPrimaryAgent.isBarged,
      monitorFullName: newDataForPrimaryAgent.monitorFullName,
      monitorUserId: newDataForPrimaryAgent.monitorUserId,
      contactPhoneNumber: newDataForPrimaryAgent.contactPhoneNumber,
      contactQueueName: newDataForPrimaryAgent.contactQueueName,
      activeParticipantsName: newDataForPrimaryAgent.activeParticipantsName,
      consultedParticipantName: newDataForPrimaryAgent.consultedParticipantName,
      recording: newDataForPrimaryAgent.recording,
      contactEventName: newDataForPrimaryAgent.contactEventName,
      actions: actionCellDetails
    };
  } else if (
    existingDataForPrimaryAgent &&
    existingDataForPrimaryAgent.destinationAgentId &&
    existingDataForPrimaryAgent.destinationAgentName &&
    newDataForPrimaryAgent.contactEventName !== TRANSFER_COMPLETED
  ) {
    // This block of code is used to reset the Agent2 details if consult/conference is over for Agent 2
    existingDataForDestinationAgent = oldDataMap?.has(existingDataForPrimaryAgent?.destinationAgentId)
      ? oldDataMap.get(existingDataForPrimaryAgent?.destinationAgentId)
      : undefined;
    newDataForDestinationAgent = {
      ...EMPTY_AGENT_DETAILS,
      ...existingDataForDestinationAgent,
      agentId: existingDataForPrimaryAgent.destinationAgentId,
      agentName: existingDataForPrimaryAgent.destinationAgentName,
      interactionId: "",
      isMonitored: "false",
      isBarged: "false",
      monitorFullName: "",
      monitorUserId: "",
      contactPhoneNumber: TPW_EMPTY_COLUMN_VALUE,
      contactQueueName: TPW_EMPTY_COLUMN_VALUE,
      activeParticipantsName: TPW_EMPTY_COLUMN_VALUE,
      consultedParticipantName: TPW_EMPTY_COLUMN_VALUE,
      recording: TPW_EMPTY_COLUMN_VALUE,
      contactEventName: TPW_EMPTY_COLUMN_VALUE,
      actions: { agentId: existingDataForPrimaryAgent.destinationAgentId, supervisorId } as RTDTypes.ActionCellDetails
    };
  }
  return {
    existingDataForDestinationAgent,
    newDataForDestinationAgent
  };
};

/**
 * Checks if row is removed , removes that object from the applied filters
 * @param comp
 * @param key
 * @param rowToRemove
 */
const deleteRemovedRowObjectFromFilter = (comp: any, key: any, rowToRemove: RTDTypes.AgentDetails[]) => {
  if (checkIfFilterAppliedExists(comp, key)) {
    const k: any = rowToRemove[key as any];
    if (Object.prototype.hasOwnProperty.call(comp.appliedFilters[key], k)) {
      delete comp.appliedFilters[key][k];
      disptachFilterDataUpdateEvent(key);
    }
  }
};
const refreshClientSideRowModel = (gridOptions: any) => {
  gridOptions?.api?.refreshClientSideRowModel(GRID_ROW_MODAL.GROUP);
};

const removeRowFromGrid = (
  rowToRemove: RTDTypes.AgentDetails[],
  gridOptions: any,
  comp: any,
  isServerSideGrid: boolean
) => {
  isServerSideGrid
    ? gridOptions.api?.applyServerSideTransactionAsync({ remove: rowToRemove })
    : gridOptions?.api?.applyTransactionAsync({ remove: rowToRemove }, () => refreshClientSideRowModel(gridOptions));

  //update filters of column when row deleted
  rowToRemove.forEach(data => {
    (Object.keys(data) as (keyof typeof data)[]).forEach(key => {
      if (filterColumnName.includes(key)) {
        deleteRemovedRowObjectFromFilter(comp, key, rowToRemove);
        updateAppliedFilter(comp, key, data);
        disptachFilterDataUpdateEvent(key);
      }
    });
    disptachFilterUpdateEvent(comp);
  });
};

const addRowInGrid = (newRowData: RTDTypes.AgentDetails[], gridOptions: any, comp: any, isServerSideGrid: boolean) => {
  isServerSideGrid
    ? gridOptions.api?.applyServerSideTransactionAsync({ add: newRowData })
    : gridOptions?.api?.applyTransactionAsync({ add: newRowData }, () => refreshClientSideRowModel(gridOptions));
  newRowData.forEach(newData => {
    (Object.keys(newData) as (keyof typeof newData)[]).forEach(key => {
      if (filterColumnName.includes(key)) {
        updateAppliedFilter(comp, key, newData);
        disptachFilterDataUpdateEvent(key);
      }
    });
    disptachFilterUpdateEvent(comp);
  });
};

const updateRowInGrid = (
  updatedRowData: RTDTypes.AgentDetails[],
  gridOptions: any,
  comp: any,
  isServerSideGrid: boolean
) => {
  isServerSideGrid
    ? gridOptions.api?.applyServerSideTransactionAsync({ update: updatedRowData })
    : gridOptions?.api?.applyTransactionAsync({ update: updatedRowData }, () => refreshClientSideRowModel(gridOptions));

  updatedRowData.forEach(updatedData => {
    (Object.keys(updatedData) as (keyof typeof updatedData)[]).forEach(key => {
      if (filterColumnName.includes(key)) {
        updateAppliedFilter(comp, key, updatedData);
        disptachFilterDataUpdateEvent(key);
      }
    });
    disptachFilterUpdateEvent(comp);
  });
};

const isValidContactStats = (contactData: RTDTypes.AgentContactStats["data"]) => {
  return (
    contactData.eventName === CAPTURE_STARTED ||
    contactData.eventName === HOLD_STARTED ||
    contactData.eventName === CONFERENCE_REQUESTED ||
    contactData.eventName === CONFERENCE_STARTED ||
    contactData.eventName === HOLD_COMPLETED_CONFERENCE ||
    contactData.eventName === HOLD_STARTED_CONFERENCE ||
    contactData.eventName === HOLD_COMPLETED_CONSULT ||
    contactData.eventName === HOLD_STARTED_CONSULT ||
    contactData.eventName === HOLD_COMPLETED ||
    contactData.eventName === TRANSFER_COMPLETED ||
    contactData.eventName === CONSULT_COMPLETED ||
    contactData.eventName === CONFERENCE_COMPLETED ||
    contactData.eventName === AGENTCONNECT_COMPLETED ||
    contactData.eventName === CONSULT_STARTED ||
    contactData.eventName === JOIN_CONFERENCE ||
    contactData.eventName === EXIT_CONFERENCE ||
    contactData.eventName === PRIMARY_OWNER_CHANGED ||
    contactData.monitorStatus === MONITORING_ENDED
  );
};

export const filterContactStatsData = (
  contactStatsData: RTDTypes.AgentContactStats[]
): RTDTypes.AgentContactStats[] => {
  return contactStatsData.filter(
    contactData => contactData.data.channelType === TELEPHONY && isValidContactStats(contactData.data)
  );
};
const getValidChannelDetails = (channelData: RTDTypes.AgentChannelStatus["data"]) => {
  return (
    channelData.contactStatus === CONNECTED ||
    channelData.contactStatus === WRAP_UP ||
    channelData.contactStatus === WRAP_UP_ASSISTANCE ||
    channelData.contactStatus === CONFERENCING ||
    channelData.contactStatus === "" ||
    channelData.contactStatus === ON_HOLD ||
    channelData.contactStatus === CONNECTED_CONSULTING ||
    channelData.contactStatus === IDLE_CONSULTING ||
    channelData.contactStatus === CONSULTING ||
    channelData.contactStatus === AVAILABLE_CONSULTING ||
    channelData.contactStatus === CONSULT_DONE || // remove consult-done after data changes
    channelData.contactStatus === CONSULT_ERROR
  );
};

export const filteredTelephonyChannelData = (
  channelStatusData: RTDTypes.AgentChannelStatus[]
): RTDTypes.AgentChannelStatus[] => {
  return channelStatusData.filter(
    channelData => channelData.data?.channelType === TELEPHONY && getValidChannelDetails(channelData.data)
  );
};

//getMappedDataFromGlobalStatus maps/updates the received globalstatus data with the TPW required format
const getMappedDataFromGlobalStatus = (
  newAgentData: RTDTypes.AgentGlobalStatus["data"],
  oldAgentData: RTDTypes.AgentDetails | undefined,
  browserLanguage: string | null,
  supervisorAnalyserId: string
) => {
  const actionCellDetails: RTDTypes.ActionCellDetails = {
    ...oldAgentData?.actions,
    agentEmail: newAgentData?.agentEmail,
    agentName: newAgentData?.agentName,
    agentId: newAgentData?.agentId,
    supervisorId: supervisorAnalyserId
  };
  const agentState = localiseAvailableState(getAgentState(newAgentData.globalState, newAgentData.idleCodeName));
  const agentEmail = newAgentData.agentEmail ? newAgentData.agentEmail : oldAgentData?.agentEmail;
  const changedBy = newAgentData.changedByRole ? newAgentData.changedByRole : null;
  const changedById = newAgentData.changedById ? newAgentData.changedById : null;
  const changedByName = newAgentData.changedByName ? newAgentData.changedByName : null;
  const reason = newAgentData.reason ? newAgentData.reason : null;
  const agentNameDetails = {
    ...oldAgentData?.agentNameDetails,
    agentId: newAgentData.agentId,
    agentName: newAgentData.agentName,
    agentState,
    agentEmail
  };
  return {
    ...EMPTY_AGENT_DETAILS,
    ...oldAgentData,
    agentId: newAgentData.agentId,
    agentName: newAgentData.agentName ? newAgentData.agentName : TPW_EMPTY_COLUMN_VALUE,
    agentEmail,
    orgId: newAgentData.orgId,
    agentState,
    agentStateDuration:
      newAgentData.globalStateTimestamp !== 0 ? newAgentData.globalStateTimestamp.toString() : TPW_EMPTY_COLUMN_VALUE,
    phoneNumber:
      newAgentData.agentPhone && newAgentData.agentPhone !== ""
        ? newAgentData?.agentPhone?.includes(WEB_RTC_PREFIX)
          ? `${t("app:stationLogin.browser")}`
          : newAgentData.agentPhone
        : TPW_EMPTY_COLUMN_VALUE,
    loginTime:
      newAgentData.lastLoginTime !== 0
        ? getDateFormat(newAgentData.lastLoginTime, browserLanguage ?? undefined)
        : TPW_EMPTY_COLUMN_VALUE,
    siteName: newAgentData.siteName ? newAgentData.siteName : TPW_EMPTY_COLUMN_VALUE,
    teamName: newAgentData.teamName ? newAgentData.teamName : TPW_EMPTY_COLUMN_VALUE,
    //marking channel status field as blank as they are received from different data set, will be appended nce that data set received
    channelsName: oldAgentData?.channelsName ?? TPW_EMPTY_COLUMN_VALUE,
    contactStatus: oldAgentData?.contactStatus ?? TPW_EMPTY_COLUMN_VALUE,
    contactStatusTime: oldAgentData?.contactStatusTime ?? TPW_EMPTY_COLUMN_VALUE,
    contactQueueName: oldAgentData?.contactQueueName ?? TPW_EMPTY_COLUMN_VALUE,
    totalContactDuration: oldAgentData?.totalContactDuration ?? TPW_EMPTY_COLUMN_VALUE,
    agentInteractionTime: oldAgentData?.agentInteractionTime ?? TPW_EMPTY_COLUMN_VALUE,
    interactionId: oldAgentData?.interactionId ?? "",
    actions: actionCellDetails,
    changedBy,
    changedById,
    changedByName,
    reason,
    agentNameDetails
  } as RTDTypes.AgentDetails;
};

const getMappedDataFromCmsAgentData = (agentData: CMSTypes.AgentDetails): CMSTypes.AgentDetails => {
  return {
    ...EMPTY_AGENT_DETAILS,
    agentId: agentData.agentId,
    agentName: agentData.agentName,
    agentEmail: agentData.agentEmail,
    agentState: t("app:tpw.Signedout"),
    agentNameDetails: {
      agentId: agentData.agentId,
      agentName: agentData.agentName,
      agentEmail: agentData.agentEmail,
      agentState: t("app:tpw.Signedout")
    }
  } as CMSTypes.AgentDetails;
};

export const getAgentInteractionTime = (data: RTDTypes.AgentChannelStatus["data"], contactStatus: string): string => {
  const timestamp = data?.agentInteractionTimestamp;
  return timestamp && contactStatus !== TPW_EMPTY_COLUMN_VALUE ? timestamp.toString() : TPW_EMPTY_COLUMN_VALUE;
};

export const transformChannelStatus = (
  channelData: RTDTypes.AgentChannelStatus["data"],
  supervisorAnalyserId: string
): RTDTypes.AgentDetails => {
  const contactStatus = mapAgentContactStatus(channelData.contactStatus, TPW_EMPTY_COLUMN_VALUE);

  // pass old data
  const actionCellDetails = {
    agentId: channelData.agentId,
    agentName: channelData.agentName,
    supervisorId: supervisorAnalyserId,
    isConsultCall:
      channelData.contactStatus === CONNECTED_CONSULTING ||
      channelData.contactStatus === IDLE_CONSULTING ||
      channelData.contactStatus === CONSULTING ||
      channelData.contactStatus === AVAILABLE_CONSULTING,
    isReviewAndMonitorEnabled: isReviewAndMonitorEnabled(channelData.contactStatus),
    isWrapUp: channelData.contactStatus === WRAP_UP,
    isWrapUpAssistance: channelData.contactStatus === WRAP_UP_ASSISTANCE
  };
  return {
    ...EMPTY_AGENT_DETAILS,
    agentId: channelData.agentId,
    agentName: channelData.agentName,
    interactionId: channelData.interactionId ?? "",
    teamName: channelData.teamName ?? TPW_EMPTY_COLUMN_VALUE,
    siteName: channelData.siteName ?? TPW_EMPTY_COLUMN_VALUE,
    channelsName: "Voice 1/1", // need to remove after all media is supported
    contactStatus,
    contactStatusCode: channelData.contactStatus,
    contactQueueName: channelData.contactQueueName,
    contactStatusTime:
      channelData.contactStatusTimestamp !== 0 && contactStatus !== TPW_EMPTY_COLUMN_VALUE
        ? channelData.contactStatusTimestamp.toString()
        : TPW_EMPTY_COLUMN_VALUE,
    totalContactDuration:
      channelData.contactIngestionTimestamp !== 0 && contactStatus !== TPW_EMPTY_COLUMN_VALUE
        ? channelData.contactIngestionTimestamp.toString()
        : TPW_EMPTY_COLUMN_VALUE,
    agentInteractionTime: getAgentInteractionTime(channelData, contactStatus),
    actions: actionCellDetails
  } as RTDTypes.AgentDetails;
};
//getMappedDataFromGlobalStatus maps/updates the received channelstatus data with the TPW required format
const getMappedDataFromChannelStatus = (
  newAgentData: RTDTypes.AgentChannelStatus["data"],
  oldAgentData: RTDTypes.AgentDetails | undefined,
  supervisorAnalyserId: string
) => {
  const contactStatus = mapAgentContactStatus(
    newAgentData.contactStatus,
    oldAgentData?.contactStatus ?? TPW_EMPTY_COLUMN_VALUE
  );
  const actionCellDetails = {
    ...oldAgentData?.actions,
    agentId: newAgentData.agentId,
    agentName: newAgentData.agentName,
    supervisorId: supervisorAnalyserId,
    isConsultCall:
      newAgentData.contactStatus === CONNECTED_CONSULTING ||
      newAgentData.contactStatus === IDLE_CONSULTING ||
      newAgentData.contactStatus === CONSULTING ||
      newAgentData.contactStatus === AVAILABLE_CONSULTING,
    isReviewAndMonitorEnabled: isReviewAndMonitorEnabled(newAgentData.contactStatus),
    isWrapUp: newAgentData.contactStatus === WRAP_UP,
    isWrapUpAssistance: newAgentData.contactStatus === WRAP_UP_ASSISTANCE
  };
  const agentNameDetails = {
    ...oldAgentData?.agentNameDetails,
    agentId: newAgentData.agentId,
    agentName: newAgentData.agentName
  };
  return {
    ...EMPTY_AGENT_DETAILS,
    ...oldAgentData,
    agentId: newAgentData.agentId,
    agentName: newAgentData.agentName,
    interactionId: newAgentData.interactionId,
    teamName: newAgentData.teamName,
    siteName: newAgentData.siteName,
    channelsName: "Voice 1/1", // need to remove after all media is supported
    contactStatus,
    contactStatusCode: newAgentData.contactStatus,
    contactQueueName: newAgentData.contactQueueName ? newAgentData.contactQueueName : TPW_EMPTY_COLUMN_VALUE,
    contactStatusTime:
      newAgentData.contactStatusTimestamp !== 0 && contactStatus !== TPW_EMPTY_COLUMN_VALUE
        ? newAgentData.contactStatusTimestamp.toString()
        : TPW_EMPTY_COLUMN_VALUE,
    totalContactDuration:
      newAgentData.contactIngestionTimestamp !== 0 && contactStatus !== TPW_EMPTY_COLUMN_VALUE
        ? newAgentData.contactIngestionTimestamp.toString()
        : TPW_EMPTY_COLUMN_VALUE,
    agentInteractionTime: getAgentInteractionTime(newAgentData, contactStatus),
    actions: actionCellDetails,
    agentNameDetails
  };
};
//getMappedDataFromGlobalStatus maps/updates the received contactstats data with the TPW required format
const getMappedDataFromContactStats = (
  newAgentData: RTDTypes.AgentContactStats["data"],
  oldAgentData: RTDTypes.AgentDetails | undefined,
  supervisorId: string,
  isMPCorPersistEnabled: boolean
): RTDTypes.AgentDetails => {
  const isTransferredContact = newAgentData.eventName === TRANSFER_COMPLETED;
  const isMonitored = isTransferredContact ? "false" : newAgentData.isMonitored;
  const isSupervisorMonitoring =
    !!supervisorId && !!newAgentData.monitorUserId && supervisorId === newAgentData.monitorUserId;
  const actionCellDetails: RTDTypes.ActionCellDetails = {
    ...oldAgentData?.actions,
    agentId: newAgentData.agentId,
    agentName: newAgentData.agentName,
    monitoredBy: isMonitored === "true" ? newAgentData.monitorFullName : "",
    supervisorId,
    isBarged: !!newAgentData.isBarged && newAgentData.isBarged === "true",
    isSupervisorMonitoring
  };
  const participantDetails = getParticipantsDetails(
    newAgentData,
    newAgentData.eventName,
    isMPCorPersistEnabled,
    supervisorId
  );
  return {
    ...EMPTY_AGENT_DETAILS,
    ...oldAgentData,
    agentId: newAgentData.agentId,
    agentName: newAgentData.agentName ? newAgentData.agentName : oldAgentData?.agentName ?? "",
    interactionId: newAgentData.interactionId ?? "",
    isMonitored,
    isBarged: newAgentData.isBarged,
    monitorFullName: isTransferredContact ? "" : newAgentData.monitorFullName,
    monitorUserId: isTransferredContact ? "" : newAgentData.monitorUserId,
    contactPhoneNumber:
      newAgentData.contactDirection === INBOUND
        ? newAgentData.origin
        : newAgentData.destination ?? TPW_EMPTY_COLUMN_VALUE,
    contactQueueName: newAgentData.queueName ?? TPW_EMPTY_COLUMN_VALUE,
    participatingAgentList: newAgentData.participatingAgentList ? JSON.parse(newAgentData.participatingAgentList) : [],
    activeParticipantsName: participantDetails.activeParticipantsName ?? TPW_EMPTY_COLUMN_VALUE,
    consultedParticipantName: participantDetails.consultedParticipantName ?? TPW_EMPTY_COLUMN_VALUE,
    participants: participantDetails.participants ?? TPW_EMPTY_COLUMN_VALUE,
    recording: newAgentData.recording === "true" ? "On" : "Off",
    contactStatusTime: oldAgentData?.contactStatusTime ?? TPW_EMPTY_COLUMN_VALUE,
    contactEventName: newAgentData.eventName,
    actions: actionCellDetails,
    destinationAgentId:
      newAgentData.destinationAgentId && newAgentData.destinationAgentId !== newAgentData.agentId
        ? newAgentData.destinationAgentId
        : null,
    destinationAgentName:
      newAgentData.destinationAgentName && newAgentData.destinationAgentId !== newAgentData.agentId
        ? newAgentData.destinationAgentName
        : null
  };
};

export const convertGlobalStatusArrayToMap = (
  agentGlobalStatusData: RTDTypes.AgentGlobalStatus[],
  browserLanguage: string,
  supervisorAnalyserId: string
): Map<string, RTDTypes.AgentDetails> => {
  const rowDataMap = new Map();
  agentGlobalStatusData?.forEach((agentData: RTDTypes.AgentGlobalStatus) => {
    //adding logic to filter logged out agents for now , till we receive all agents.
    if (agentData.data.globalState !== LOGGED_OUT) {
      rowDataMap.set(
        agentData.data.agentId,
        getMappedDataFromGlobalStatus(agentData.data, undefined, browserLanguage, supervisorAnalyserId)
      );
    }
  });
  return rowDataMap;
};

export const convertContactStatusArrayToMap = (
  contactStats: RTDTypes.AgentContactStats[],
  supervisorId: string,
  isMPCorPersistEnabled: boolean
): Map<string, RTDTypes.AgentDetails> => {
  const rowDataMap = new Map();
  contactStats?.forEach((contactData: RTDTypes.AgentContactStats) => {
    //adding logic to filter logged out agents for now , till we receive all agents.
    const newDataForPrimaryAgent = getMappedDataFromContactStats(
      contactData.data,
      undefined,
      supervisorId,
      isMPCorPersistEnabled
    );
    if (isMPCorPersistEnabled) {
      const destinationDetails = getContactStatsDetailsForAllParticipants(
        contactData,
        undefined,
        newDataForPrimaryAgent,
        supervisorId
      ).newDataForParticipants;
      destinationDetails.forEach(newDataForDestinationAgent => {
        if (newDataForDestinationAgent?.agentId) {
          rowDataMap.set(newDataForDestinationAgent.agentId, newDataForDestinationAgent);
        }
      });
      rowDataMap.set(contactData.data.agentId, newDataForPrimaryAgent);
    } else {
      rowDataMap.set(contactData.data.agentId, newDataForPrimaryAgent);
      const destinationDetails = updateContactStatsForDestinationAgent(
        contactData,
        undefined,
        newDataForPrimaryAgent,
        supervisorId
      );
      const newDataForDestinationAgent: RTDTypes.AgentDetails = destinationDetails.newDataForDestinationAgent;
      if (newDataForDestinationAgent?.agentId) {
        rowDataMap.set(newDataForDestinationAgent.agentId, newDataForDestinationAgent);
      }
    }
  });
  return rowDataMap;
};

export const convertCmsAgentDataArrayToMap = (
  cmsAgentData: CMSTypes.AgentDetails[]
): Map<string, RTDTypes.AgentDetails> => {
  const rowDataMap = new Map();
  cmsAgentData?.forEach((agentData: CMSTypes.AgentDetails) => {
    rowDataMap.set(agentData.agentId, getMappedDataFromCmsAgentData(agentData));
  });
  return rowDataMap;
};

export const convertChannelStatusArrayToMap = (
  agentChannelStatus: RTDTypes.AgentChannelStatus[],
  supervisorAnalyserId: string
): Map<string, RTDTypes.AgentDetails> => {
  const rowDataMap = new Map();

  agentChannelStatus?.forEach((channelData: RTDTypes.AgentChannelStatus) => {
    //adding logic to filter logged out agents for now , till we receive all agents.
    rowDataMap.set(channelData.data.agentId, transformChannelStatus(channelData.data, supervisorAnalyserId));
  });
  return rowDataMap;
};

export const updateExistingMapFromGlobalStatus = (
  agentGlobalStatusData: RTDTypes.AgentGlobalStatus[],
  oldDataMap: Map<string, RTDTypes.AgentDetails>,
  browserLanguage: string,
  gridOptions: any,
  comp: any,
  supervisorAnalyserId: string,
  isServerSideGrid: boolean
): Map<string, RTDTypes.AgentDetails> => {
  const rowDataMap = oldDataMap;
  const rowToRemove: RTDTypes.AgentDetails[] = [];
  const rowToUpdate: RTDTypes.AgentDetails[] = [];
  const rowToAdd: RTDTypes.AgentDetails[] = [];

  agentGlobalStatusData?.forEach((agentData: RTDTypes.AgentGlobalStatus) => {
    const existingData = oldDataMap.has(agentData.data.agentId) ? oldDataMap.get(agentData.data.agentId) : undefined;
    //adding logic to filter logged out agents for now , till we receive all agents.
    if (agentData.data.globalState !== LOGGED_OUT) {
      const newData = getMappedDataFromGlobalStatus(
        agentData.data,
        existingData,
        browserLanguage,
        supervisorAnalyserId
      );
      if (existingData) {
        rowToUpdate.push(newData);
      } else {
        rowToAdd.push(newData);
      }
      rowDataMap.set(agentData.data.agentId, newData);
    } else if (existingData && agentData.data.globalState === LOGGED_OUT) {
      rowToRemove.push(existingData);
      rowDataMap.delete(agentData.data.agentId);
    }
  });
  //remove old agent data that has been logged out
  if (rowToRemove.length > 0) {
    removeRowFromGrid(rowToRemove, gridOptions, comp, isServerSideGrid);
  }
  if (rowToAdd.length > 0) {
    addRowInGrid(rowToAdd, gridOptions, comp, isServerSideGrid);
  }
  if (rowToUpdate.length > 0) {
    updateRowInGrid(rowToUpdate, gridOptions, comp, isServerSideGrid);
  }
  // if (rowToAdd.length > 0 || rowToUpdate.length > 0 || rowToRemove.length > 0) {
  //   gridOptions?.api?.refreshClientSideRowModel(GRID_ROW_MODAL.GROUP);
  // }
  return rowDataMap;
};

export const updateExistingMapFromChannelStatus = (
  agentChannelStatus: RTDTypes.AgentChannelStatus[],
  oldDataMap: Map<string, RTDTypes.AgentDetails>,
  gridOptions: any,
  supervisorAnalyserId: string,
  comp: any,
  isServerSideGrid: boolean
): Map<string, RTDTypes.AgentDetails> => {
  const rowDataMap = oldDataMap;
  const rowToUpdate: RTDTypes.AgentDetails[] = [];
  const rowToAdd: RTDTypes.AgentDetails[] = [];
  agentChannelStatus?.forEach((channelData: RTDTypes.AgentChannelStatus) => {
    if (channelData.operation !== DELETE) {
      const existingData = oldDataMap.has(channelData.data.agentId)
        ? oldDataMap.get(channelData.data.agentId)
        : undefined;

      const newData = getMappedDataFromChannelStatus(channelData.data, existingData, supervisorAnalyserId);
      if (existingData) {
        rowToUpdate.push(newData);
      } else {
        rowToAdd.push(newData);
      }
      rowDataMap.set(channelData.data.agentId, newData);
    }
  });
  if (rowToAdd.length > 0) {
    addRowInGrid(rowToAdd, gridOptions, comp, isServerSideGrid);
  }
  if (rowToUpdate.length > 0) {
    updateRowInGrid(rowToUpdate, gridOptions, comp, isServerSideGrid);
  }
  return rowDataMap;
};

const updateExistingMapForMPCorPersist = (
  contactData: RTDTypes.AgentContactStats,
  oldDataMap: Map<string, RTDTypes.AgentDetails>,
  newDataForPrimaryAgent: RTDTypes.AgentDetails,
  supervisorId: string,
  rowDataMap: Map<string, RTDTypes.AgentDetails>,
  rowToUpdate: RTDTypes.AgentDetails[]
) => {
  const destinationDetails = getContactStatsDetailsForAllParticipants(
    contactData,
    oldDataMap,
    newDataForPrimaryAgent,
    supervisorId
  );
  const newDataForParticipants: RTDTypes.AgentDetails[] = destinationDetails.newDataForParticipants;
  const existingDataForParticipants: RTDTypes.AgentDetails[] = destinationDetails.existingDataForParticipants;
  if (!newDataForParticipants.length) {
    rowDataMap.set(contactData.data.agentId, newDataForPrimaryAgent);
  } else {
    newDataForParticipants.forEach(newDataForDestinationAgent => {
      if (newDataForDestinationAgent?.agentId) {
        rowDataMap.set(newDataForDestinationAgent.agentId, newDataForDestinationAgent);
      }
      if (
        existingDataForParticipants.find(
          existingDataForParticipants => existingDataForParticipants.agentId === newDataForDestinationAgent.agentId
        )
      ) {
        rowToUpdate.push(newDataForDestinationAgent);
      }
    });
  }
};

export const updateExistingMapFromContactStats = (
  agentContacts: RTDTypes.AgentContactStats[],
  oldDataMap: Map<string, RTDTypes.AgentDetails>,
  gridOptions: any,
  supervisorId: string,
  comp: any,
  isMPCorPersistEnabled: boolean,
  isServerSideGrid: boolean
): Map<string, RTDTypes.AgentDetails> => {
  const rowToUpdate: RTDTypes.AgentDetails[] = [];
  const rowToAdd: RTDTypes.AgentDetails[] = [];
  const rowDataMap = oldDataMap;
  agentContacts?.forEach((contactData: RTDTypes.AgentContactStats) => {
    const existingDataForPrimaryAgent = oldDataMap.has(contactData.data.agentId)
      ? oldDataMap.get(contactData.data.agentId)
      : undefined;

    const newDataForPrimaryAgent: RTDTypes.AgentDetails = getMappedDataFromContactStats(
      contactData.data,
      existingDataForPrimaryAgent,
      supervisorId,
      isMPCorPersistEnabled
    );
    if (isMPCorPersistEnabled) {
      updateExistingMapForMPCorPersist(
        contactData,
        oldDataMap,
        newDataForPrimaryAgent,
        supervisorId,
        rowDataMap,
        rowToUpdate
      );
    } else {
      if (existingDataForPrimaryAgent) {
        rowToUpdate.push(newDataForPrimaryAgent);
      } else {
        rowToAdd.push(newDataForPrimaryAgent);
      }
      rowDataMap.set(contactData.data.agentId, newDataForPrimaryAgent);
      const destinationDetails = updateContactStatsForDestinationAgent(
        contactData,
        oldDataMap,
        newDataForPrimaryAgent,
        supervisorId,
        existingDataForPrimaryAgent
      );
      const newDataForDestinationAgent: RTDTypes.AgentDetails = destinationDetails.newDataForDestinationAgent;
      const existingDataForDestinationAgent: RTDTypes.AgentDetails | undefined =
        destinationDetails.existingDataForDestinationAgent;
      if (newDataForDestinationAgent?.agentId) {
        if (existingDataForDestinationAgent) {
          rowToUpdate.push(newDataForDestinationAgent);
        }
        rowDataMap.set(newDataForDestinationAgent.agentId, newDataForDestinationAgent);
      }
    }
  });
  if (rowToAdd.length > 0) {
    addRowInGrid(rowToAdd, gridOptions, comp, isServerSideGrid);
  }
  if (rowToUpdate.length > 0) {
    updateRowInGrid(rowToUpdate, gridOptions, comp, isServerSideGrid);
  }
  return rowDataMap;
};
