import { i18nMixin, t } from "@/mixins/i18nMixin";
import { LitElement, customElement, html, internalProperty, property } from "lit-element";
import { TPW } from "../Utils";
import style from "./ColumnMenu.scss";

const LEFT = "left";
const RIGHT = "right";

export namespace PinColumnMenu {
  /**
   * @element pin-column-menu
   */
  @customElement("pin-column-menu")
  export class Element extends i18nMixin(LitElement) {
    private _columnParams = {};
    @property({ type: Object })
    get columnParams() {
      return this._columnParams;
    }
    set columnParams(value: TPW.ColumnParams) {
      const oldValue = this._columnParams;
      this._columnParams = value;
      this.requestUpdate("columnParams", oldValue);
    }
    @property({ type: Array }) pinnedDirection = [LEFT, RIGHT, null];

    @internalProperty() coulmnPinnedDirection = 2;

    static get styles() {
      return style;
    }

    private handlePinnedDirectionChange(pinDirection: string | null) {
      this.columnParams.columnApi?.setColumnPinned(this.columnParams.column.colId, pinDirection);
      this.columnParams.api?.hidePopupMenu();
      this.coulmnPinnedDirection = this.pinnedDirection.indexOf(this.columnParams.column?.pinned);
    }

    private getCheckBoxLabel(pinDirection: string | null) {
      switch (pinDirection) {
        case LEFT:
          return t("app:tpw.pinToLeft");
        case RIGHT:
          return t("app:tpw.pinToRight");
        default:
          return t("app:tpw.none");
      }
    }

    connectedCallback(): void {
      super.connectedCallback();
      this.coulmnPinnedDirection = this.pinnedDirection.indexOf(this.columnParams.column?.pinned);
    }

    render() {
      return html`
        <div class="pin-tab-wrapper">
          <md-radiogroup
            group-label="group_process"
            checked=${this.coulmnPinnedDirection}
            @radio-change=${(event: CustomEvent) => this.handlePinnedDirectionChange(event.detail.selected)}
          >
            ${this.pinnedDirection.map((pinDirection: string | null) => {
              return html`
                <md-radio class="pin-tab-radio-btn" slot="radio" value=${pinDirection}
                  >${this.getCheckBoxLabel(pinDirection)}</md-radio
                >
              `;
            })}
          </md-radiogroup>
        </div>
      `;
    }
  }
}
