import { AVAILABLE, TPW_FILTER_DATA_UPDATE, TPW_FILTER_UPDATED } from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { RowNode } from "ag-grid-enterprise";
import { LitElement, customElement, html, internalProperty, property, query } from "lit-element";
import { TemplateResult } from "lit-html";
import "lit-virtualizer";
import { TPW, getIsCheckboxChecked } from "../Utils";
import style from "./ColumnMenu.scss";
import commonStyles from "../../../assets/styles/common.scss";
import { SERVICE } from "@agentx/agentx-services";
export namespace FilterColumnMenu {
  /**
   * @element filter-column-menu
   */
  @customElement("filter-column-menu")
  export class Element extends i18nMixin(LitElement) {
    private _columnParams = {};
    @property({ type: Object })
    get columnParams() {
      return this._columnParams;
    }
    set columnParams(value: TPW.ColumnParams) {
      const oldValue = this._columnParams;
      this._columnParams = value;
      this.requestUpdate("columnParams", oldValue);
    }

    private _columnFilterModel = {};
    @property({ type: Object })
    get columnFilterModel() {
      return this._columnFilterModel;
    }
    set columnFilterModel(value: Record<string, boolean>) {
      const oldValue = this._columnFilterModel;
      this._columnFilterModel = value;
      this.requestUpdate("columnFilterModel", oldValue);
    }

    @internalProperty() columnRowData: string[] = [];
    @internalProperty() columnRowDataObj: Record<string, boolean> = {}; //RowData mapping to get on which data filter is applied
    @internalProperty() filteredColumnRowData: string[] = [];

    @query(".search-filter-input") searchInputElement!: any;
    @query("#live-region") liveRegion!: any;

    static get styles() {
      return [style, commonStyles];
    }

    getColumnId() {
      if (this.columnParams.column?.colId === "agentNameDetails") {
        return "agentName";
      }
      return this.columnParams.column?.colId;
    }

    private getGridRowData() {
      const currentColId = this.getColumnId();
      const rowMap: Record<string, boolean> = {};
      // to get all the row nodes without filtering
      this.columnParams?.rowModel?.forEachNode((row: RowNode) => {
        if (!row.group) {
          const key = row.data[currentColId];
          rowMap[key] = false;
        }
      });
      // to select those row nodes which are selected by user i.e this.filterModel
      for (const key in this.columnFilterModel) {
        if (this.columnFilterModel[key]) {
          rowMap[key] = true;
        }
      }
      return rowMap;
    }

    private updateColumnRowData() {
      this.columnRowDataObj = this.getGridRowData();
      this.columnRowData = Object.keys(this.columnRowDataObj);
      this.filteredColumnRowData = [...this.columnRowData];
    }

    handleFilterDataUpdate = (e: any) => {
      if (e.detail && e.detail.colId === this.columnParams.column.colId) {
        this.updateColumnRowData();
      }
    };

    connectedCallback() {
      super.connectedCallback();
      window.addEventListener(TPW_FILTER_DATA_UPDATE, this.handleFilterDataUpdate);
      this.updateColumnRowData();
      this.requestUpdate();
    }

    disconnectedCallback(): void {
      super.disconnectedCallback();
      this.columnRowData = [];
      this.columnRowDataObj = {};
      this.filteredColumnRowData = [];
      this.liveRegion.textContent = "";
      window.removeEventListener(TPW_FILTER_DATA_UPDATE, this.handleFilterDataUpdate);
    }

    private readonly handleSearchFilters = async (event: CustomEvent) => {
      const searchTerm = event.detail.value.toLowerCase();
      const updatedFilterList: string[] = [];
      this.columnRowData.forEach((data: string) => {
        if (data?.toLowerCase().includes(searchTerm)) {
          updatedFilterList.push(data);
        }
      });
      this.filteredColumnRowData = [...updatedFilterList];
    };

    private updateColumnFilterModel(event: CustomEvent, isSelectAllChecked: boolean, rowData?: string) {
      const isCheckboxChecked = getIsCheckboxChecked(event);
      if (isSelectAllChecked === false && rowData) {
        this.columnRowDataObj[rowData] = isCheckboxChecked;
        this.setLiveRegionText(isCheckboxChecked, rowData);
      } else {
        Object.keys(this.columnRowDataObj).forEach((rowData: string) => {
          this.columnRowDataObj[rowData] = isCheckboxChecked;
        });
        this.setLiveRegionText(isCheckboxChecked, t("app:common.all"));
      }
    }

    private setLiveRegionText(isChecked: boolean, count: number | string) {
      if (isChecked) {
        this.liveRegion.textContent = `${count} ${t("app:tpw.filterAppliedLabel")}`;
      } else {
        this.liveRegion.textContent = `${count} ${t("app:tpw.filterRemovedLabel")}`;
      }
    }

    private handleFilterUpdateEventDispatch() {
      this.dispatchEvent(
        new CustomEvent(TPW_FILTER_UPDATED, {
          bubbles: true,
          composed: true,
          detail: {
            field: this.columnParams.colDef?.field,
            data: this.columnRowDataObj
          }
        })
      );
    }

    private handleFiltering(event: CustomEvent, isSelectAllChecked: boolean, rowData?: string) {
      if (event) {
        this.updateColumnFilterModel(event, isSelectAllChecked, rowData || "");
        this.handleFilterUpdateEventDispatch();
        this.filteredColumnRowData = [...this.columnRowData];
        const currentRow = this.getColumnId();

        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.TPW_COLUMN_FILTERED, {
          FilteredColumnName: currentRow
        });
        this.requestUpdate();
      }
    }

    private readonly isSelectAllChecked = () => {
      let selectAll = true;
      Object.keys(this.columnRowDataObj).forEach((rowData: string) => {
        if (!this.columnRowDataObj[rowData]) {
          selectAll = false;
        }
      });
      return selectAll;
    };

    renderSearchInput() {
      return html`<md-input
      clear
      clearAriaLabel="${t("app:common:clear")}"
      htmlId="pillSearchInput"
      name="pillSearchInput"
      shape="pill"
      ariaLabel=${t("app:tpw.search")}
      placeholder=${t("app:tpw.search")}
      searchable
      class="search-filter-input"
      @input-change=${(event: CustomEvent) => this.handleSearchFilters(event)}
    />
    </md-input>`;
    }

    renderSelectAllCheckbox() {
      return html`
        <md-checkbox
          tabIndex="0"
          class="filter-tab-checkbox select-all-checkbox"
          ?checked=${this.isSelectAllChecked()}
          @checkbox-change=${(event: CustomEvent) => this.handleFiltering(event, true)}
          >${t("app:tpw.selectAll")}</md-checkbox
        >
      `;
    }

    localiseAvailableInFilterColumn = (key: string): string => {
      return this.getColumnId() === "agentState" && key === AVAILABLE ? t("app:tpw.stateChange.available") : key;
    };

    getCheckBoxLabel = (optionsList: any, rowData: string, currentIndex: number) => {
      const totalOptionsCount = Object.keys(optionsList).length;
      return `${rowData} ${t("app:tpw.optionsLabel")} ${currentIndex + 1} ${t("app:common.of")} ${totalOptionsCount}`;
    };

    renderColumnData = (rowData: string, index: number): TemplateResult => {
      const label = this.getCheckBoxLabel(this.columnRowDataObj, rowData, index);
      return html`
        <div class="filter-wrapper">
          <md-checkbox
            tabIndex="0"
            class="filter-tab-checkbox"
            slot="checkbox"
            ?checked=${this.columnRowDataObj[rowData]}
            @checkbox-change=${(event: CustomEvent) => this.handleFiltering(event, false, rowData)}
            aria-label=${label}
            >${this.localiseAvailableInFilterColumn(rowData)}</md-checkbox
          >
        </div>
      `;
    };

    renderVirtualColumnRowDataCheckbox() {
      return html`
        <lit-virtualizer
          class="virtual-scroll"
          .renderItem=${this.renderColumnData}
          .items=${this.filteredColumnRowData}
        ></lit-virtualizer>
      `;
    }

    render() {
      return html`
        <div class="filter-tab-wrapper">
          <div class="filter-tab-header">
            ${this.renderSearchInput()} ${this.renderSelectAllCheckbox()}
          </div>
          <div class="filter-tab-checkbox-group-wrapper">
            ${this.renderVirtualColumnRowDataCheckbox()}
          </div>
          <div id="live-region" class="sr-only" aria-live="polite"></div>
        </div>
      `;
    }
  }
}
