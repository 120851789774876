import { TPW_EMPTY_COLUMN_VALUE } from "@/constants";
import { t } from "@/mixins/i18nMixin";
import ActionsRenderer from "./CellRenderer/ActionRenderer";
import AgentStateRenderer from "./CellRenderer/AgentStateRenderer";
import DurationRenderer from "./CellRenderer/DurationRenderer";
import GroupRowInnerRenderer from "./CellRenderer/GroupRowInnerRenderer";
import NameRenderer from "./CellRenderer/NameRenderer";
import ColumnMenuRenderer from "./ColumnMenu/ColumnMenuRenderer";
import { agentNameComparator, dateComparator, durationComparator, stringComparator } from "./TeamPerformanceUtils";
import { localiseAvailableState } from "./Utils";
import { onTabToNextCell, suppressHeaderKeyboardEvent, suppressKeyboardEvent } from "./utils/KeyboardNavigationUtils";

type gridConfigType = {
  webexEnabled: boolean;
  isDarkMode: boolean;
  isMCMEnabled: boolean;
  isError: boolean;
  isAIDEnabled: boolean;
  supervisorId: string;
  isStateChangeBySupervisorEnabled: boolean;
  onActiveCall: boolean;
  isSupervisorySendMessageEnabled: boolean;
  actionCellLocalizationValues: any;
  actionCellWidth: number;
  isBargeInEnabled: boolean;
  voiceChannelCount: number;
  handleAIDModal: any;
  handleStateChangeSelector: any;
  handleContextMenu: any;
  handleWebexChat: any;
};

export const gridConfig = (configs: () => gridConfigType, isServerSidePaginated = false): any => {
  function template(header?: string, tooltip?: string) {
    const ariaLabel = `${header ? t(header) : ""} ${tooltip ? t(tooltip) : ""}`.trim();
    return `<div class="ag-cell-label-container" role="presentation">
      <div ref="eLabel" class="ag-header-cell-label" role="button">
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
        <span ref="eText" class="ag-header-cell-text" role="columnheader" ${
          ariaLabel ? `aria-label="${ariaLabel}"` : ""
        }></span>
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" aria-label="${t(
          "app:tpw.ascendingAriaLabel"
        )}"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" aria-label="${t(
          "app:tpw.descendingAriaLabel"
        )}"></span>
        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" aria-label="${t(
          "app:tpw.noSortAriaLabel"
        )}"></span>
      </div>
      <span ref="eFilter" class="ag-header-icon ag-filter-icon" aria-label="${t(
        "app:tpw.columnHeaderFilterIconLabel"
      )}"></span>
      <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-label="${t(
        "app:tpw.columnHeaderFilterMenuAriaLabel"
      )}" role="button"></span>
    </div>`;
  }
  return {
    components: {
      actionBtnsCellRenderer: ActionsRenderer,
      nameRenderer: NameRenderer,
      columnMenuRenderer: ColumnMenuRenderer,
      groupRowInnerRenderer: GroupRowInnerRenderer,
      durationRenderer: DurationRenderer,
      agentStateRenderer: AgentStateRenderer
    },

    groupDisplayType: "groupRows",
    groupRowRendererParams: {
      innerRenderer: "groupRowInnerRenderer",
      suppressCount: true
    },
    enableCellChangeFlash: false,
    pagination: false,
    rowBuffer: 20,
    suppressDragLeaveHidesColumns: true,
    suppressScrollOnNewData: true,
    suppressContextMenu: true,
    overlayLoadingTemplate: `<agentx-wc-widget-loader></agentx-wc-widget-loader>`,
    overlayNoRowsTemplate: `<agentx-wc-tpw-empty-grid is-error="${
      configs().isError ? "1" : "0"
    }"></agentx-wc-tpw-empty-grid>`,

    defaultColDef: {
      resizable: true,
      enablePivot: false,
      enableRowGroup: true,
      enableValue: true,
      menuTabs: ["filterMenuTab"],
      sortable: true,
      rowGroup: false,
      sort: "none",
      minWidth: 88,
      suppressKeyboardEvent,
      suppressHeaderKeyboardEvent
    },

    icons: {
      menu: '<md-icon name="icon-settings_16" class="settings-icon" />',
      filter: '<md-icon name="icon-filter-adr_16" class="filter-icon" />',
      sortAscending: ` <div class="sorted up">
    <md-icon name="icon-arrow-filled-up_12"></md-icon>
    <md-icon name="icon-arrow-filled-down_12"></md-icon>
  </div>`,
      sortDescending: ` <div class="sorted down">
    <md-icon name="icon-arrow-filled-up_12"></md-icon>
    <md-icon name="icon-arrow-filled-down_12"></md-icon>
  </div>`,
      groupContracted: '<md-icon name="icon-plus_12" class="icon-group" />',
      groupExpanded: '<md-icon name="icon-minus_12" class="icon-group" />',
      clipboardCopy: '<md-icon name="icon-copy_16" class="icon-copy_16" />'
    },
    alwaysShowHorizontalScroll: true,
    alwaysShowVerticalScroll: false,
    tabToNextCell: onTabToNextCell,
    columnDefs: [
      {
        headerName: `${t("app:tpw.AgentName")}`,
        headerComponentParams: {
          template: `${template()}`
        },
        getQuickFilterText: (params: any) => {
          return params.value ? params.value.agentName : "";
        },
        field: "agentNameDetails",
        keyCreator: (params: any) => params.value.agentName,
        resizable: true,
        cellClass: "suppress-movable-col",
        cellRenderer: "nameRenderer",
        filter: "columnMenuRenderer",
        sortable: true,
        comparator: agentNameComparator,
        filterParams: {
          suppressFilterTab: isServerSidePaginated,
          valueGetter: (params: any) => {
            return params.data.agentName;
          }
        },
        headerTooltip: `${t("app:tpw.AgentName")}`,
        tooltipComponentParams: { color: "#ececec" },
        pinned: "left",
        lockPinned: true,
        tooltipField: "agentName",
        width: 300,
        hide: false,
        suppressMovable: true,
        lockPosition: true
      },
      {
        headerName: `${t("app:tpw.AgentState")}`,
        headerTooltip: `${t("app:tpw.AgentState")}`,
        field: "agentState",
        sortable: !isServerSidePaginated,
        resizable: true,
        suppressMenuFilterPanel: true,
        filter: "columnMenuRenderer",
        filterParams: {
          suppressFilterTab: isServerSidePaginated
        },
        cellRenderer: "agentStateRenderer",
        cellEditorParams: {
          supervisorId: configs().supervisorId
        },
        comparator: stringComparator,
        tooltipValueGetter: (params: any) => {
          // CX-14895 : Available name is not showing in non-english languages when placing the cursor above the Agent Availability Status.
          return localiseAvailableState(params?.value ?? TPW_EMPTY_COLUMN_VALUE);
        },
        pinned: "none",
        width: 200,
        hide: false,
        headerComponentParams: {
          template: `${template()}`
        }
      },
      {
        headerName: `${t("app:tpw.AgentStateDuration")}`,
        headerTooltip: `${t("app:tpw.AgentStateDurationToolTip")}`,
        field: "agentStateDuration",
        resizable: true,
        sortable: !isServerSidePaginated,
        cellRenderer: "durationRenderer",
        comparator: durationComparator,
        filter: "columnMenuRenderer",
        pinned: "none",
        width: 200,
        hide: false,
        filterParams: {
          suppressFilterTab: true
        },
        getQuickFilterText: () => {
          return "";
        },
        headerComponentParams: {
          template: `${template("app:tpw.AgentStateDuration", "app:tpw.AgentStateDurationToolTip")}`
        }
      },
      {
        headerName: `${t("app:tpw.PhoneNumber")}`,
        headerTooltip: `${t("app:tpw.PhoneNumber")}`,
        field: "phoneNumber",
        sortable: !isServerSidePaginated,
        resizable: true,
        filter: "columnMenuRenderer",
        comparator: stringComparator,
        pinned: "none",
        tooltipField: "phoneNumber",
        width: 200,
        hide: false,
        headerComponentParams: {
          template: `${template()}`
        }
      },
      {
        headerName: `${t("app:tpw.Site")}`,
        headerTooltip: `${t("app:tpw.Site")}`,
        field: "siteName",
        sortable: !isServerSidePaginated,
        resizable: true,
        filter: "columnMenuRenderer",
        comparator: stringComparator,
        tooltipField: "siteName",
        pinned: "none",
        hide: false,
        width: 104,
        headerComponentParams: {
          template: `${template()}`
        }
      },
      {
        headerName: `${t("app:tpw.Team")}`,
        headerTooltip: `${t("app:tpw.Team")}`,
        field: "teamName",
        sortable: !isServerSidePaginated,
        resizable: true,
        filter: "columnMenuRenderer",
        comparator: stringComparator,
        tooltipField: "teamName",
        pinned: "none",
        hide: false,
        width: 160,
        headerComponentParams: {
          template: `${template()}`
        }
      },
      {
        headerName: `${t("app:tpw.Channels")}`,
        headerTooltip: `${t("app:tpw.Channels")}`,
        field: "channelsName",
        sortable: !isServerSidePaginated,
        resizable: true,
        filter: "columnMenuRenderer",
        filterParams: {
          suppressFilterTab: isServerSidePaginated
        },
        comparator: stringComparator,
        tooltipField: "channelsName",
        pinned: "none",
        hide: true,
        sort: "none",
        width: 130,
        headerComponentParams: {
          template: `${template()}`
        }
      },
      {
        headerName: `${t("app:tpw.ContactQueue")}`,
        headerTooltip: `${t("app:tpw.ContactQueue")}`,
        field: "contactQueueName",
        sortable: !isServerSidePaginated,
        resizable: true,
        filter: "columnMenuRenderer",
        filterParams: {
          suppressFilterTab: isServerSidePaginated
        },
        comparator: stringComparator,
        tooltipField: "contactQueueName",
        pinned: "none",
        hide: true,
        width: 130,
        headerComponentParams: {
          template: `${template()}`
        }
      },
      {
        headerName: `${t("app:tpw.ContactStatus")}`,
        headerTooltip: `${t("app:tpw.ContactStatus")}`,
        field: "contactStatus",
        sortable: !isServerSidePaginated,
        resizable: true,
        filter: "columnMenuRenderer",
        comparator: stringComparator,
        tooltipField: "contactStatus",
        pinned: "none",
        width: 224,
        hide: false,
        filterParams: {
          suppressFilterTab: isServerSidePaginated
        },
        headerComponentParams: {
          template: `${template()}`
        }
      },
      {
        headerName: `${t("app:tpw.TimeInContactStatus")}`,
        headerTooltip: `${t("app:tpw.TimeInContactStatusToolTip")}`,
        field: "contactStatusTime",
        sortable: !isServerSidePaginated,
        resizable: true,
        filter: "columnMenuRenderer",
        comparator: durationComparator,
        cellRenderer: "durationRenderer",
        rowGroup: false,
        pinned: "none",
        width: 224,
        hide: false,
        filterParams: {
          suppressFilterTab: true
        },
        getQuickFilterText: () => {
          return "";
        },
        headerComponentParams: {
          template: `${template("app:tpw.TimeInContactStatus", "app:tpw.TimeInContactStatusToolTip")}`
        }
      },
      {
        headerName: `${t("app:tpw.AgentInteractionTime")}`,
        headerTooltip: `${t("app:tpw.AgentInteractionTimeToolTip")}`,
        field: "agentInteractionTime",
        sortable: !isServerSidePaginated,
        resizable: true,
        cellRenderer: "durationRenderer",
        comparator: durationComparator,
        filter: "columnMenuRenderer",
        pinned: "none",
        width: 224,
        hide: false,
        filterParams: {
          suppressFilterTab: true
        },
        getQuickFilterText: () => {
          return "";
        },
        headerComponentParams: {
          template: `${template("app:tpw.AgentInteractionTime", "app:tpw.AgentInteractionTimeToolTip")}`
        }
      },
      {
        headerName: `${t("app:tpw.TotalContactDuration")}`,
        headerTooltip: `${t("app:tpw.TotalContactDurationToolTip")}`,
        field: "totalContactDuration",
        sortable: !isServerSidePaginated,
        resizable: true,
        cellRenderer: "durationRenderer",
        comparator: durationComparator,
        filter: "columnMenuRenderer",
        pinned: "none",
        width: 224,
        hide: false,
        filterParams: {
          suppressFilterTab: true
        },
        getQuickFilterText: () => {
          return "";
        },
        headerComponentParams: {
          template: `${template("app:tpw.TotalContactDuration", "app:tpw.TotalContactDurationToolTip")}`
        }
      },
      {
        headerName: `${t("app:tpw.SignInTime")}`,
        headerTooltip: `${t("app:tpw.SignInTime")}`,
        field: "loginTime",
        sortable: !isServerSidePaginated,
        resizable: true,
        filter: "columnMenuRenderer",
        tooltipField: "loginTime",
        comparator: dateComparator,
        pinned: "none",
        width: 200,
        hide: false,
        sort: "asc",
        filterParams: {
          suppressFilterTab: true
        },
        getQuickFilterText: () => {
          return "";
        },
        headerComponentParams: {
          template: `${template()}`
        }
      },
      {
        headerName: `${t("app:tpw.Actions")}`,
        headerTooltip: `${t("app:tpw.Actions")}`,
        field: "actions",
        sortable: false,
        resizable: true,
        cellRenderer: "actionBtnsCellRenderer",
        cellClass: "action-class",
        cellEditorParams: configs,
        pinned: "right",
        lockPinned: true,
        width: 117,
        hide: false,
        suppressMovable: true,
        filter: "columnMenuRenderer",
        filterParams: {
          suppressFilterTab: true
        },
        getQuickFilterText: () => {
          return "";
        },
        headerComponentParams: {
          template: `<div class="ag-cell-label-container" role="presentation">
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
            </div>
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" role="button"></span>
          </div>`
        }
      }
    ]
  };
};
