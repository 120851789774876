import { channelList, FILTER_CHANGE_DELAY } from "@/constants";
import { t } from "@/mixins/i18nMixin";
import { Service } from "@agentx/agentx-services";
import { customElement, html, LitElement, property } from "lit-element";
import { styleMap } from "lit-html/directives/style-map";
import { debounce } from "./../../../utils/helpers";
import style from "./../index.scss";

@customElement("agentx-wc-channel-filter")
export class ChannelFilter extends LitElement {
  languageResources = "/i18n/{{lng}}/{{ns}}.json";
  static get styles() {
    return style;
  }

  @property({ type: Array }) selectedChannelList: Service.Aqm.Configs.ChannelList = [];

  private readonly channelList: Service.Aqm.Configs.ChannelList = channelList;

  changeHandler = debounce((e: CustomEvent) => {
    sessionStorage.setItem("selected-channel-list", JSON.stringify(e.detail.selected));
    window.dispatchEvent(
      new CustomEvent("channel-filter-selected", {
        detail: e.detail.selected,
        bubbles: true,
        composed: true
      })
    );
  }, FILTER_CHANGE_DELAY);

  getLocalizedChannelList = (): Service.Aqm.Configs.ChannelList => {
    const localizedChannelList: Service.Aqm.Configs.ChannelList = [];
    this.channelList.forEach(ent => {
      localizedChannelList.push({ name: t(`app:agentProfile.${ent.name}`), value: ent.value });
    });
    return localizedChannelList;
  };

  render() {
    return html`
      <div
        class="filter-container"
        style=${styleMap({
          width: `${this.style.width || "250px"}`
        })}
      >
        <md-combobox
          .options=${this.getLocalizedChannelList()}
          option-id="value"
          option-value="name"
          shape=${"pill"}
          is-multi
          show-selected-count
          no-clear-icon
          allow-select-all
          placeholder=${t("app:supervisorProfile.selectChannel")}
          all-i18n=${t("app:common.all")}
          selected-all-i18n=${t("app:common.selected")}
          select-all-i18n=${t("app:tpw.selectAll")}
          @change-selected=${this.changeHandler}
          .value=${this.selectedChannelList}
          ariaLabel=${t("app:pageSubHeader.channelType")}
          comboboxId="inp-channelType"
        ></md-combobox>
      </div>
    `;
  }
}
