
        import {css} from 'lit-element';   
        export default css`:host .iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
/*# sourceURL=/Users/tachen2/wxcc-desktop/packages/agentx-mfe-wc-based/src/components/IframeBase/IframeBase.scss */
/*# sourceURL=IframeBase.scss */
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9Vc2Vycy90YWNoZW4yL3d4Y2MtZGVza3RvcC9wYWNrYWdlcy9hZ2VudHgtbWZlLXdjLWJhc2VkL3NyYy9jb21wb25lbnRzL0lmcmFtZUJhc2UvSWZyYW1lQmFzZS5zY3NzIiwiSWZyYW1lQmFzZS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0UsV0FBQTtFQUNBLFlBQUE7RUFDQSxTQUFBO0FDQUoiLCJmaWxlIjoiSWZyYW1lQmFzZS5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAuaWZyYW1lIHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBoZWlnaHQ6IDEwMCU7XG4gICAgYm9yZGVyOiAwO1xuICB9XG59XG4iLCI6aG9zdCAuaWZyYW1lIHtcbiAgd2lkdGg6IDEwMCU7XG4gIGhlaWdodDogMTAwJTtcbiAgYm9yZGVyOiAwO1xufSJdfQ== */`;
    