import { emergency911HelpLink } from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import "@momentum-ui/web-components";
import { customElement, html, LitElement } from "lit-element";
import style from "./EmergencyNotificationContent.scss";

export namespace EmergencyNotificationContent {
  /**
   * @element agentx-wc-emergency-notification-content
   */
  @customElement("agentx-wc-emergency-notification-content")
  export class Element extends i18nMixin(LitElement) {
    static get styles() {
      return style;
    }

    async firstUpdated() {
      const link = this.shadowRoot?.querySelector("md-link");
      if (link) {
        await link.updateComplete;
        const linkAnchor = link.shadowRoot?.querySelector("a");
        linkAnchor?.focus();
      }
    }

    render() {
      return html`
        <div class="emergency-notification-container">
          <div class="emergency-notification-content">
            <md-alert-banner class="md-alert-banner-wrapper" show type="warning">
              <div class="emergency-modal-warning-content">
                <md-icon aria-hidden="true" name="icon-warning_12"></md-icon>
                <div
                  class="emergency-modal-warning-text"
                  aria-label="${t("app:emergencyServiceNotification.updateCurrentAddressContent")}"
                >
                  ${t("app:emergencyServiceNotification.updateCurrentAddressContent")}
                  <md-link
                    inline
                    class="help-section-link"
                    aria-label=${t("app:emergencyServiceNotification.seeHelpSection")}
                    tag="a"
                    href=${emergency911HelpLink}
                    target="_blank"
                    >${t("app:emergencyServiceNotification.seeHelpSection")}</md-link
                  >
                </div>
              </div>
            </md-alert-banner>
          </div>
          <div class="emergency-notification-content">
            <div class="heading">
              ${t("app:emergencyServiceNotification.dialingEmergencyServices")}
            </div>
            <label>${t("app:emergencyServiceNotification.dialingEmergencyServicesContent")}</label>
          </div>
        </div>
      `;
    }
  }
}
