class WindowListenersHub {
  private listeners: Record<string, EventListener[]> = {};
  private listenersWrappers: Record<string, EventListener> = {};

  on(eventName: string, listener: EventListener) {
    // One window listener per eventName
    if (!this.listeners[eventName]) {
      this.listeners[eventName] = [];
      this.listenersWrappers[eventName] = (e: Event) =>
        this.listeners[eventName].forEach(listener => listener.call(listener, e));
      window.addEventListener(eventName, this.listenersWrappers[eventName]);
    }

    this.listeners[eventName].push(listener);

    return () => this.off(eventName, listener);
  }

  off(eventName: string, listener: EventListener) {
    if (this.listeners[eventName]) {
      const idx = this.listeners[eventName].findIndex(l => l === listener);
      if (idx !== -1) {
        this.listeners[eventName].splice(idx, 1);
        if (this.listeners[eventName].length < 1) {
          window.removeEventListener(eventName, this.listenersWrappers[eventName]);
          delete this.listeners[eventName];
          delete this.listenersWrappers[eventName];
        }
      }
    }
  }

  once(
    eventName: string,
    listener: EventListener,
    onceConditionChecker?: (...args: Parameters<EventListener>) => boolean
  ) {
    const _listener = (...args: Parameters<EventListener>) => {
      let res = true;
      if (typeof onceConditionChecker === "function") {
        res = onceConditionChecker(...args);
      }
      if (res) {
        listener(...args);
        this.off(eventName, _listener);
      }
    };

    this.on(eventName, _listener);

    return () => this.off(eventName, _listener);
  }

  dispatch(e: Event) {
    window.dispatchEvent(e);
  }

  allOff(eventName?: string) {
    const offEventName = (_eventName: string) => {
      if (_eventName in this.listeners) {
        let i = this.listeners[_eventName].length;
        while (i) {
          const listener = this.listeners[_eventName][i - 1];
          this.off(_eventName, listener);
          i--;
        }
      }
    };
    if (eventName) {
      offEventName(eventName);
    } else {
      Object.keys(this.listeners).forEach(eventName => offEventName(eventName));
    }
  }
}

export const WINDOW_LISTENERS_HUB = new WindowListenersHub();
