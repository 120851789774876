import { i18nMixin, t } from "@/mixins/i18nMixin";
import "@uuip/unified-ui-platform-common-components";
import { LitElement, customElement, html, internalProperty, property } from "lit-element";
import style from "./IframeBase.scss";

export namespace IframeBase {
  @customElement("agentx-wc-iframe")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: String }) src = "";
    @property({ type: Boolean, attribute: "is-dark-theme" }) isDarkTheme = false;
    @property({ type: String }) allowedParams = "";
    @property({ type: String }) title = "";
    @internalProperty() private finalUrl = this.src;

    static get styles() {
      return style;
    }
    render() {
      if (this.src) {
        const url = new URL(this.src);
        url.searchParams.set("is_dark_theme", this.isDarkTheme.toString());
        this.finalUrl = url.href;
      }
      return this.src
        ? html`
            <iframe class="iframe" allow="${this.allowedParams}" src="${this.finalUrl}" title="${this.title}"></iframe>
          `
        : html`
            <uuip-wc-content-error>
              <span>${t("app:common:badUrl")}}</span>
            </uuip-wc-content-error>
          `;
    }
  }
}
