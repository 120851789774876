import { i18nMixin, t } from "@/mixins/i18nMixin";
import { logger } from "@/sdk";
import { SERVICE, Service } from "@agentx/agentx-services";
import { customElement, html, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import "../IframeBase/IframeBase";
import style from "./ScreenPop.scss";
const HTTPS_PROTOCOL = "https://";
const HTTP_PROTOCOL = "http://";
const SCREEN_POP_CONFIG = "screenPopConfig";

export namespace ScreenPop {
  export const TARGET_INSIDE_DESKTOP = "insideDesktop";
  export const TARGET_SAME_BROWSER_TAB = "sameBrowserTab";

  const ALLOWED_PARAMS =
    "battery *;camera *;display-capture *; encrypted-media *;fullscreen *;geolocation *;gyroscope *;microphone *;speaker-selection *;web-share *;";

  export const getAlreadyOpenedScreenPopConfig = (): Array<ScreenPopConfig> | null => {
    const configStr = sessionStorage.getItem(SCREEN_POP_CONFIG);
    if (configStr) {
      return JSON.parse(configStr);
    }
    return null;
  };

  @customElement("agentx-wc-screen-pop")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Array }) screenPopUrl: Array<ScreenPopConfig> | null = null;
    @property({ type: Number }) selectedTab = 0;

    static get styles() {
      return style;
    }

    async connectedCallback() {
      super.connectedCallback();
      this.addEventListener("selected-changed", this.preserveSelectedTab as EventListener);
      this.selectedTab = this.setSelectedTab();
    }

    setSelectedTab(): number {
      if (this.screenPopUrl) {
        this.screenPopUrl.forEach((data: ScreenPopConfig, index: number) => {
          if (data.isSelected) {
            this.selectedTab = index;
          }
        });
      }
      return this.selectedTab;
    }

    mixpanelTracker(screenPopData: ScreenPopConfig): void {
      SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.SCREEN_POP_INSIDE_DESKTOP_REFERRED, {
        [SERVICE.telemetry.MIX_PROPS.SCREEN_POP_INTERACTION]: screenPopData.interactionId,
        [SERVICE.telemetry.MIX_PROPS.MEDIA_TYPE]: screenPopData.mediaType
      });
    }

    preserveSelectedTab(e: CustomEvent): void {
      if (this.screenPopUrl) {
        const interactionId = e.detail.tabsOrder[e.detail.value];
        const screenPopData = JSON.parse(JSON.stringify(this.screenPopUrl));
        const modifiedData = screenPopData.map((data: ScreenPopConfig, index: number) => {
          if (index === e.detail.value) {
            data.isSelected = true;
          } else {
            data.isSelected = false;
          }
          return data;
        });

        const existingScreenPopData = getAlreadyOpenedScreenPopConfig() || [];
        let newData = existingScreenPopData.filter(data => {
          return data.interactionId !== interactionId;
        });
        newData = [...newData, ...modifiedData];
        sessionStorage.setItem(SCREEN_POP_CONFIG, JSON.stringify(newData));
        this.dispatchEvent(
          new CustomEvent("ax-update-screen-pop", {
            bubbles: true,
            composed: true,
            detail: newData
          })
        );
        const selectedTabData = newData.filter((data: ScreenPopConfig) => data.isSelected);
        this.mixpanelTracker(selectedTabData[0]);
      }
    }

    render() {
      return this.screenPopUrl
        ? html`
            <md-tabs selected=${this.setSelectedTab()}>
              ${this.screenPopUrl.map((data: ScreenPopConfig) => {
                return html`
                  <md-tab
                    name=${data.interactionId}
                    slot="tab"
                    class="widget-pane-tab"
                    aria-label=${data.screenPopDesktopLabel}
                    role="tab"
                  >
                    <span class="tab-header-content">
                      <span>${data.screenPopDesktopLabel}</span>
                    </span>
                  </md-tab>

                  <md-tab-panel class="screen-pop-tab" slot="panel">
                    <agentx-wc-iframe
                      allowedParams=${ALLOWED_PARAMS}
                      class="screen-pop"
                      src="${data.screenPopUrl}"
                    ></agentx-wc-iframe>
                  </md-tab-panel>
                `;
              })}
            </md-tabs>
          `
        : nothing;
    }
  }

  /**;
   * Open screen pop (i.e. browser window) after constructing final url based on Screen POP event
   * @param screenPopDetail
   */
  export const constructScreenPopUrl = (
    screenPopDetail: Service.Aqm.ScreenPop.ScreenPopDetail,
    mediaType: string,
    saveToSessionStorage = false
  ): string => {
    const settings: Service.Aqm.ScreenPop.Settings = screenPopDetail.data;
    let screenPopUrl = "";
    if (settings) {
      const baseUrl = settings.screenPopUrl;
      const url = new URL(
        baseUrl.indexOf(HTTPS_PROTOCOL) > -1 || baseUrl.indexOf(HTTP_PROTOCOL) > -1
          ? baseUrl
          : HTTPS_PROTOCOL.concat(baseUrl)
      );
      if (settings.queryParameters) {
        Object.keys(settings.queryParameters).forEach(paramKey => {
          url.searchParams.set(paramKey.trim(), settings.queryParameters[paramKey].trim());
        });
      }
      screenPopUrl = url.href;

      logger.info("[ScreenPop] Final screen pop url", url);

      if (saveToSessionStorage) {
        const existingScreenPopData = getAlreadyOpenedScreenPopConfig() || [];
        const screenPopConfig: ScreenPopConfig = {
          interactionId: screenPopDetail.data.interactionId,
          screenPopDesktopLabel:
            screenPopDetail.data.screenPopDesktopLabel || t("app:notifications.screenPopDesktopDefaultLabel"),
          screenPopUrl,
          target: settings.target,
          mediaType,
          screenPopName: settings.screenPopName
        };

        // get duplicate screenPop event
        const filteredScreenPop = existingScreenPopData.filter(obj => {
          return (
            obj.screenPopName === screenPopConfig.screenPopName && obj.interactionId === screenPopConfig.interactionId
          );
        });

        // push screenPop if no duplicates found.
        if (filteredScreenPop.length === 0) {
          existingScreenPopData.push(screenPopConfig);
          sessionStorage.setItem(SCREEN_POP_CONFIG, JSON.stringify(existingScreenPopData));
        }
      }
    }

    return screenPopUrl;
  };

  export const removeScreenPopFromSession = () => {
    return sessionStorage.removeItem(SCREEN_POP_CONFIG);
  };

  export const screenPopNavConfig: any = {
    navRouteName: "screenpop",
    navRoutePath: "/screenpop",
    navIcon: {
      label: "Screen-pop",
      icon: "pop-out",
      iconType: "momentum",
      navigateTo: "screenpop",
      align: "bottom"
    },
    area: "main-routed"
  };

  export type ScreenPopConfig = {
    screenPopUrl: string;
    screenPopDesktopLabel: string;
    target: string;
    interactionId: string;
    isSelected?: boolean;
    mediaType: string;
    screenPopName: string;
  };
}
