/* eslint-disable @typescript-eslint/no-explicit-any */
import { WidgetError } from "@/index";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import "@momentum-ui/web-components";
import { customElement, html, internalProperty, LitElement, property } from "lit-element";
import style from "../../NotesWidget.scss";

export namespace NotesErrorState {
  /**
   * @element agentx-wc-notes-error-state
   */
  @customElement("agentx-wc-notes-error-state")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Boolean }) isFeatureFlagEnabled = true;
    @property({ type: String }) trackingId = "";

    FEATURE_FLAG_ERROR_DETAILS = {
      header: t("app:notes.notConfigured")
    };

    DEFAULT_ERROR_DETAILS = {
      header: t("app:notes.failedToLoadNotes")
    };

    @internalProperty() detail: WidgetError.Detail | any = this.DEFAULT_ERROR_DETAILS;

    static get styles() {
      return style;
    }

    render() {
      return html`
        <agentx-wc-widget-error
          .detail=${this.isFeatureFlagEnabled
            ? { ...this.DEFAULT_ERROR_DETAILS, trackingId: this.trackingId }
            : this.FEATURE_FLAG_ERROR_DETAILS}
          .hideDetails=${!this.isFeatureFlagEnabled}
        ></agentx-wc-widget-error>
      `;
    }
  }
}
