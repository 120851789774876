import { i18nMixin } from "@/mixins/i18nMixin";
import { customElement, html, internalProperty, LitElement, property, PropertyValues, query } from "lit-element";
import "../../../components/CountDownTimer/CountDownTimer";
import { TIMER_FIELDS, TPW_EMPTY_COLUMN_VALUE } from "../../../constants";
import style from "./AidValues.scss";
export namespace AidValue {
  /**
   * @element agentx-aid-value
   * @fires close-modal
   */
  @customElement("agentx-aid-value")
  export class Element extends i18nMixin(LitElement) {
    @property() variableName = "";
    @property() variableValue: string | boolean = "";
    @internalProperty() toolTipDisabled = true;
    @query(".aid-value") valueContainer!: any;

    async connectedCallback() {
      super.connectedCallback();
    }

    disconnectedCallback(): void {
      super.disconnectedCallback();
    }
    static get styles() {
      return style;
    }

    private readonly disableTooltip = () => {
      let isTooltipVisible;

      if (this.valueContainer) {
        const divInsideValueField = this.valueContainer.querySelector("div");

        if (divInsideValueField) {
          isTooltipVisible = divInsideValueField?.offsetHeight < divInsideValueField?.scrollHeight;
        }

        this.toolTipDisabled = !isTooltipVisible;
      }
    };

    updated(changedProperties: PropertyValues): void {
      if (changedProperties.has("variableValue")) {
        setTimeout(() => this.disableTooltip(), 300);
      }
    }
    protected firstUpdated() {
      setTimeout(() => this.disableTooltip(), 300);
    }
    render() {
      return html`
        <md-tooltip
          message=${this.variableValue}
          ?disabled=${this.variableValue === TPW_EMPTY_COLUMN_VALUE || this.toolTipDisabled}
        >
          <div class="aid-value">
            ${TIMER_FIELDS.includes(this.variableName)
              ? html`
                  <agentx-wc-count-down-timer
                    duration="${this.variableValue}"
                    .isDurationTimer=${true}
                    .showDefaultHours=${false}
                  ></agentx-wc-count-down-timer>
                `
              : html`
                  <div>
                    ${this.variableValue}
                  </div>
                `}
          </div>
        </md-tooltip>
      `;
    }
  }
}
