import { ENTER_KEY_CODE, ESC_KEY_CODE, SPACE_KEY_CODE, TAB_KEY_CODE } from "@/components/NotesWidget/constants";
import { HANDLE_WEBEX_CHAT_CLOSE_EVENT, UPDATE_ACTION_CELL_VALUE_EVENT } from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { SERVICE } from "@agentx/agentx-services";
import { LitElement, PropertyValues, customElement, html, internalProperty, property } from "lit-element";
import { getAvatarUrlFromCache } from "../TeamPerformanceUtils";
import { getPersistedError, getPersistedText, setPersistedError, setPersistedText } from "../utils/WebexChat.utils";
import styles from "./WebexChat.scss";

export namespace WebexChat {
  @customElement("webex-chat")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Boolean, attribute: "is-open" }) isOpen = false;
    @property({ type: String }) name = "";
    @property({ type: String }) agentEmail = "";
    @property({ type: String }) agentId = "";

    @internalProperty() inputValue = "";
    @internalProperty() error = "";
    @internalProperty() isLoading = false;
    @internalProperty() agentAvatarUrl: string | undefined;
    @internalProperty() autoFocusEnabled = true;
    @internalProperty() tooltipElement: any;

    static get styles() {
      return [styles];
    }

    setAgentAvatarUrl() {
      this.agentAvatarUrl = getAvatarUrlFromCache(this.agentId);
    }

    connectedCallback() {
      super.connectedCallback();
      this.updatePersistedError();

      this.addCloseIconListener();
    }

    addCloseIconListener() {
      const closeIcon = this.shadowRoot?.querySelector(".close-icon");
      if (closeIcon && closeIcon.getAttribute("listener") !== "true") {
        closeIcon.addEventListener("keydown", this.handleCloseIconKeydown);
        closeIcon.setAttribute("listener", "true");
      }
    }

    updatePersistedError() {
      const isPersistedError: boolean = getPersistedError(this.name, this.agentEmail);
      if (isPersistedError) {
        this.error = t("app:notifications.webexChatTPWErrorMessage");
      } else {
        this.error = "";
      }
    }

    update(changedProperties: PropertyValues) {
      super.update(changedProperties);
      this.setAgentAvatarUrl();
      this.addCloseIconListener();
    }

    disconnectedCallback(): void {
      this.shadowRoot?.querySelector(".close-icon")?.removeEventListener("keydown", this.handleCloseIconKeydown);
    }

    private standardKeyCheck(event: KeyboardEvent) {
      return (
        event.key === SPACE_KEY_CODE ||
        event.code === SPACE_KEY_CODE ||
        event.key === ENTER_KEY_CODE ||
        event.code === ENTER_KEY_CODE
      );
    }

    private handleSendKeypress(event: KeyboardEvent) {
      event.stopPropagation();
      if (this.standardKeyCheck(event)) {
        event.preventDefault();
        this.handleOnSend();
        this.returnFocusToChatIcon();
      } else if (event.key === ESC_KEY_CODE) {
        this.handleCloseIconClick();
        this.returnFocusToChatIcon();
      }
    }

    private readonly handleOnSend = async () => {
      if (this.inputValue !== "") {
        this.isLoading = true;
        const agentId = this.agentId;
        let isWebExSendingMessageError = false;
        this.updateActionCellValue({ isWebExSendingMessage: true, isWebExSendingMessageError, agentId });

        this.closeOverlay();
        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.WEBEX_CHAT_TPW_MESSAGE_SEND, {
          agentEmail: this.agentEmail
        });
        const status: boolean = await SERVICE.webex.sendMessage(this.agentEmail, this.inputValue);
        if (status) {
          this.error = "";
          this.inputValue = "";
          setPersistedText(this.name, this.agentEmail, "");
          setPersistedError(this.name, this.agentEmail, false);
        } else {
          this.error = t("app:notifications.webexChatTPWErrorMessage");
          setPersistedError(this.name, this.agentEmail, true);
          isWebExSendingMessageError = true;
        }
        this.isLoading = false;
        setTimeout(() => {
          this.updateActionCellValue({ isWebExSendingMessage: false, isWebExSendingMessageError, agentId });
        }, 0);
      }
    };

    private readonly returnFocusToChatIcon = () => {
      const tooltipElement: any = this.shadowRoot?.querySelector("md-tooltip md-button");
      tooltipElement?.setAttribute("tabindex", "0");
      tooltipElement?.focus({ focusVisible: true });
    };

    private handleCancelKeypress(event: KeyboardEvent) {
      event.preventDefault();
      event.stopPropagation();
      if (this.standardKeyCheck(event)) {
        this.handleOnCancel();
        this.returnFocusToChatIcon();
      } else if (event.key === ESC_KEY_CODE) {
        this.handleCloseIconClick();
        this.returnFocusToChatIcon();
      } else if (!event?.shiftKey && event.key === TAB_KEY_CODE) {
        const closeIconElement: any = this.shadowRoot
          ?.querySelector(".close-icon")
          ?.querySelector("md-button")
          ?.shadowRoot?.querySelector("button");

        setTimeout(function() {
          closeIconElement?.focus();
        }, 0);
      } else if (event.shiftKey && event.key === TAB_KEY_CODE) {
        const sendButton = this.shadowRoot?.querySelector("md-button.send-btn")?.shadowRoot?.querySelector("button");
        if (sendButton) {
          (sendButton as any)?.focus();
        } else {
          this.focusInputField();
        }
      }
    }

    private readonly handleOnCancel = () => {
      setPersistedText(this.name, this.agentEmail, "");
      setPersistedError(this.name, this.agentEmail, false);
      this.inputValue = "";
      this.error = "";
      this.updateActionCellValue({
        isWebExSendingMessage: false,
        isWebExSendingMessageError: false,
        agentId: this.agentId
      });
      this.closeOverlay();
    };

    protected updated(_changedProperties: Map<string | number | symbol, unknown>): void {
      super.updated(_changedProperties);
      if ((_changedProperties.has("agentEmail") || _changedProperties.has("isOpen")) && this.isOpen) {
        this.updatePersistedError();
        this.inputValue = getPersistedText(this.name, this.agentEmail);
        setTimeout(() => {
          this.focusInputField();
        }, 0);
      }
    }

    focusInputField() {
      const inputElement = this.shadowRoot?.querySelector("md-input")?.shadowRoot?.querySelector("textarea");
      inputElement?.focus();
    }

    private readonly handleCloseIconClick = () => {
      this.closeOverlay();
    };

    private readonly handleInputChange = (event: CustomEvent) => {
      this.inputValue = event.detail.value;
      setPersistedText(this.name, this.agentEmail, event.detail.value);
    };

    private readonly handleKeyDown = (event: KeyboardEvent) => {
      //stopPropagation() is called to fix keyboard actions(copying, cutting, selecting all) to prevent bubbling up the these keyboard events in the hierarchy.
      event.stopPropagation();
      if (event.key === ESC_KEY_CODE) {
        this.handleCloseIconClick();
        this.returnFocusToChatIcon();
      } else if (!event.shiftKey && (event.code === ENTER_KEY_CODE || event.key === ENTER_KEY_CODE)) {
        event.preventDefault();
        this.handleOnSend();
        this.returnFocusToChatIcon();
      }
    };

    // function to close webex chat popover on keyboard navigation of 'close' icon inside the popover.
    private readonly handleCloseIconKeydown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.key === "Tab" && !event.shiftKey) {
        this.focusInputField();
      } else if (event.shiftKey && event.key === "Tab") {
        const discardIcon: any = this.shadowRoot?.querySelector(".discard-btn")?.shadowRoot?.querySelector("button");
        if (discardIcon) {
          discardIcon?.focus();
        }
      } else if (this.standardKeyCheck(event) || event.key === ESC_KEY_CODE) {
        this.returnFocusToChatIcon();
        event.preventDefault();
        this.handleCloseIconClick();
      }
    };

    private handleInputBoxClicked(event: CustomEvent) {
      event.stopPropagation();
    }

    private checkSendDisabled = (inputValue: string) => (inputValue === "" ? true : false);

    private readonly closeOverlay = () => {
      this.dispatchEvent(
        new CustomEvent(HANDLE_WEBEX_CHAT_CLOSE_EVENT, {
          composed: true,
          bubbles: true
        })
      );
    };

    private readonly updateActionCellValue = (values: Record<string, any>) => {
      this.dispatchEvent(
        new CustomEvent(UPDATE_ACTION_CELL_VALUE_EVENT, {
          composed: true,
          bubbles: true,
          detail: {
            ...values
          }
        })
      );
    };

    render() {
      return html`
        <div class="overlay-content webex-popup" role="dialog" aria-labelledby="webex-popup-title">
          <div class="header">
            <div class="user-info">
              <md-avatar
                title=${this.name}
                size=${28}
                src=${this.agentAvatarUrl ?? ""}
                class="agent-avatar"
                aria-hidden="true"
              ></md-avatar>
              <md-label htmlFor="htmlId">
                <h3 id="webex-popup-title" class="name">${this.name}</h3>
              </md-label>
            </div>
            <div class="close-icon">
              <md-button
                variant="white"
                circle
                @button-click=${this.handleCloseIconClick}
                ariaLabel=${t("app:common.close")}
              >
                <md-icon slot="icon" name="icon-cancel_16"></md-icon>
              </md-button>
            </div>
          </div>
          <md-input
            tab-index="0"
            class="webex-md-input"
            value=${this.inputValue}
            @input-change=${this.handleInputChange}
            style="border-bottom: 0"
            ui-grid-row="5"
            maxLength="1000"
            multiline
            containerSize="small-12"
            autofocus
            @click=${this.handleInputBoxClicked}
            @keydown=${this.handleKeyDown}
            placeholder=${t("app:notifications.webexChatPlaceHolder", "", { defaultValue: this.name })}
            ariaLabel=${t("app:notifications.webexChatPlaceHolder", "", { defaultValue: this.name }) +
              t("app:notifications.stringLengthText", "", { defaultValue: `${this.inputValue.length}` })}
          >
          </md-input>
          <div class="str-length">
            <md-label htmlFor="htmlId">
              <span class="length-label">${this.inputValue.length}/1000</span>
            </md-label>
          </div>
          <div class="error-msg">
            ${this.error &&
              html`
                <md-label>
                  <span class="error-label">
                    <md-icon slot="icon" name="icon-error_12"></md-icon>
                    <span class="error">${this.error}</span></span
                  >
                </md-label>
              `}
          </div>
          <div class="action-container">
            <md-button
              @button-click=${this.handleOnSend}
              @keydown="${(event: KeyboardEvent) => {
                this.handleSendKeypress(event);
              }}"
              ?disabled=${this.checkSendDisabled(this.inputValue)}
              class="${this.inputValue === "" ? "send-disabled" : "send-btn"}"
              color="blue"
            >
              <span slot="text">
                ${this.error ? t("app:notifications.webexChatReSend") : t("app:notifications.webexChatSend")}</span
              >
            </md-button>
            <md-button
              @button-click=${this.handleOnCancel}
              class="discard-btn"
              @keydown="${(event: KeyboardEvent) => {
                this.handleCancelKeypress(event);
              }}"
            >
              <span slot="text">${t("app:notifications.webexChatDiscard")}</span>
            </md-button>
          </div>
        </div>
      `;
    }
  }
}
