/* eslint-disable @typescript-eslint/no-explicit-any */

export type AgentxMfeWcBasedConfig = {
  ANALYZER_HOST: string;
  WXM_GADGET_URL: string;
  WEBEX_APP_URL: string;
  WEBEX_SETTING_SERVICE: string;
  AG_GRID_LICENSE_KEY: string;
};

export const CONF: { readonly [k in keyof AgentxMfeWcBasedConfig]: AgentxMfeWcBasedConfig[k] } = {} as any;
export function setAgentxMfeWcBasedConfig(config: AgentxMfeWcBasedConfig) {
  Object.keys(config).forEach(k => ((CONF as any)[k] = config[k as keyof AgentxMfeWcBasedConfig]));
}
