import { RTDTypes } from "@/../../agentx-services/dist/types";
import {
  AVAILABLE,
  AVAILABLE_CONSULTING,
  CONNECTED_CONSULTING,
  CONSULTING,
  CONSULT_ERROR,
  IDLE_CONSULTING,
  TPW_EMPTY_COLUMN_VALUE,
  WRAP_UP,
  WRAP_UP_ASSISTANCE
} from "@/constants";
import { t } from "@/mixins/i18nMixin";
import { IFilterParams } from "ag-grid-enterprise";

export namespace TPW {
  export type StringObject = Record<string, any>;

  export type FilterModel = { [key: string]: Record<string, boolean> };

  export type ColumnParams = IFilterParams | any;
}

export const fixedColumns: Record<string, boolean> = {
  agentName: true,
  actions: true
};

export const hiddenGroupColumn: Record<string, boolean> = {
  agentStateDuration: true,
  contactStatusTime: true,
  totalContactDuration: true,
  agentInteractionTime: true,
  loginTime: true
};

export const getIsCheckboxChecked = (event: CustomEvent) => {
  const path = event.composedPath();
  const checkboxElement = Array.from(path).find(
    element => (element as HTMLElement).nodeName.toLowerCase() === "md-checkbox"
  );
  return (checkboxElement as HTMLInputElement).checked;
};

export const EMPTY_AGENT_DETAILS: RTDTypes.AgentDetails = {
  agentId: TPW_EMPTY_COLUMN_VALUE,
  agentName: TPW_EMPTY_COLUMN_VALUE,
  agentEmail: "",
  agentState: TPW_EMPTY_COLUMN_VALUE,
  agentStateDuration: TPW_EMPTY_COLUMN_VALUE,
  phoneNumber: TPW_EMPTY_COLUMN_VALUE,
  channelsName: TPW_EMPTY_COLUMN_VALUE,
  contactStatus: TPW_EMPTY_COLUMN_VALUE,
  contactStatusCode: "",
  contactStatusTime: TPW_EMPTY_COLUMN_VALUE,
  contactQueueName: TPW_EMPTY_COLUMN_VALUE,
  totalContactDuration: TPW_EMPTY_COLUMN_VALUE,
  agentInteractionTime: TPW_EMPTY_COLUMN_VALUE,
  loginTime: TPW_EMPTY_COLUMN_VALUE,
  siteName: TPW_EMPTY_COLUMN_VALUE,
  teamName: TPW_EMPTY_COLUMN_VALUE,
  interactionId: "",
  contactEventName: TPW_EMPTY_COLUMN_VALUE,
  isMonitored: TPW_EMPTY_COLUMN_VALUE,
  monitorFullName: TPW_EMPTY_COLUMN_VALUE,
  contactPhoneNumber: TPW_EMPTY_COLUMN_VALUE,
  activeParticipantsName: TPW_EMPTY_COLUMN_VALUE,
  consultedParticipantName: TPW_EMPTY_COLUMN_VALUE,
  recording: TPW_EMPTY_COLUMN_VALUE,
  actions: {} as RTDTypes.ActionCellDetails,
  destinationAgentId: null,
  destinationAgentName: null,
  changedBy: null,
  changedById: null,
  changedByName: null
};

export const initialFilterModel: TPW.FilterModel = {
  agentName: {},
  siteName: {},
  teamName: {},
  loginTime: {},
  agentState: {},
  channelsName: {},
  phoneNumber: {},
  contactQueueName: {},
  contactStatus: {},
  agentNameDetails: {}
};

export const columnHeaderMapping = (): Record<string, string> => {
  return {
    agentName: t("app:tpw.columnName.agentName"),
    agentState: t("app:tpw.columnName.agentState"),
    agentStateDuration: t("app:tpw.columnName.agentStateDuration"),
    phoneNumber: t("app:tpw.columnName.phoneNumber"),
    siteName: t("app:tpw.columnName.siteName"),
    teamName: t("app:tpw.columnName.teamName"),
    contactStatus: t("app:tpw.columnName.contactStatus"),
    contactStatusTime: t("app:tpw.columnName.contactStatusTime"),
    agentInteractionTime: t("app:tpw.columnName.agentInteractionTime"),
    totalContactDuration: t("app:tpw.columnName.totalContactDuration"),
    loginTime: t("app:tpw.columnName.loginTime"),
    channelsName: t("app:tpw.columnName.channelsName"),
    contactQueueName: t("app:tpw.columnName.contactQueueName"),
    agentNameDetails: t("app:tpw.columnName.agentName")
  };
};

export const durationColumns = [
  "agentStateDuration",
  "agentInteractionTime",
  "totalContactDuration",
  "contactStatusTime"
];
export const filterColumnName = [
  "agentState",
  "phoneNumber",
  "siteName",
  "teamName",
  "contactStatus",
  "loginTime",
  "channelsName",
  "contactQueueName",
  "agentNameDetails"
];
export const disableForContactStatus = [
  WRAP_UP,
  WRAP_UP_ASSISTANCE,
  CONNECTED_CONSULTING,
  AVAILABLE_CONSULTING,
  IDLE_CONSULTING,
  CONSULTING,
  TPW_EMPTY_COLUMN_VALUE,
  CONSULT_ERROR
];

export const localiseAvailableState = (key: string) => {
  return key === AVAILABLE ? t("app:tpw.stateChange.available") : key;
};
