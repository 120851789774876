import { i18nMixin, t } from "@/mixins/i18nMixin";
import { SERVICE, Service } from "@agentx/agentx-services";
import "@momentum-ui/web-components";
import "@uuip/unified-ui-platform-common-components";
import { LitElement, PropertyValues, customElement, html, internalProperty, property, query } from "lit-element";
import { classMap } from "lit-html/directives/class-map";
import "../Common/EmptyStateWrapper/EmptyStateWrapper";
import style from "./ShortcutKeyModal.scss";

export namespace ShortcutKey {
  export type EShortcutModal = { detail: boolean };
  @customElement("agentx-wc-shortcut-keys-modal")
  export class ShortcutKeyModal extends i18nMixin(LitElement) {
    @property({ type: Boolean }) isModalOpen = false;
    @property({ type: Boolean }) isShortcutKeyConflict = false;
    @property({ type: String }) altOrOptionKey = "";

    @internalProperty() private shortcutKeyData: Array<Array<string>> = [];
    @internalProperty() private shortcutKeys: Array<Array<string>> = [];
    @internalProperty() private isSearchEmpty = false;
    @internalProperty() ShortkeyTable: any = {
      config: {
        isStickyHeader: true,
        cellTemplates: {
          _warn_: { templateName: "tmp2" }
        },

        default: {
          sort: {
            colId: "c1",
            order: "ascending"
          }
        },

        rows: {
          isDraggable: false
        },

        cols: {
          isResizable: true,
          define: [
            { id: "c1", title: t("app:keyboardShortcutKeys.group"), sorter: "byString", width: "30%" },
            { id: "c2", title: t("app:keyboardShortcutKeys.action"), sorter: "byString", width: "40%" },
            { id: "c3", title: t("app:keyboardShortcutKeys.shortcutKey"), sorter: "byString", width: "30%" }
          ]
        },
        head: {
          tableDescription: t("app:keyboardShortcutKeys.tableDescription")
        }
      },

      data: {
        list2d: [["", "", ""]]
      }
    };

    @query(".shortcut-key-list-box") shortcutList!: any;
    @query(".input-field") searchInput!: any;
    @query("#live-region") liveRegion!: any;

    SEARCH = "";
    ACTION = "";
    GROUP = "";
    SHORTCUTKEY = "";
    resultsLabel = "{{count}} search results available";

    inputKeydownHandler = (event: CustomEvent) => {
      const { srcEvent } = event.detail;
      if (this.shortcutList) {
        if (srcEvent.code === "Tab") {
          this.shortcutList.rovingPreventFocus = false;
        } else {
          this.shortcutList.rovingPreventFocus = true;
        }
      }
    };

    private updateConstant() {
      this.SEARCH = t("app:keyboardShortcutKeys.search");
      this.GROUP = t("app:keyboardShortcutKeys.group");
      this.ACTION = t("app:keyboardShortcutKeys.action");
      this.SHORTCUTKEY = t("app:keyboardShortcutKeys.shortcutKey");
    }

    protected updated(changedProperties: PropertyValues) {
      super.updated(changedProperties);
      this.updateConstant();
      changedProperties.forEach((oldValue, name) => {
        if (name === "isModalOpen") {
          const regis: Map<string, Service.shortcut.KeyInfo> = SERVICE.shortcut.getRegisteredKeys();
          this.combineShortcutKey(regis);
          this.requestUpdate();
        }
      });
      if (this.shortcutList) {
        this.shortcutList.rovingPreventFocus = true;
      }
      if (this.searchInput && this.searchInput.shadowRoot.querySelector("input")) {
        this.searchInput.shadowRoot.querySelector("input").focus();
      }
    }

    private combineShortcutKey(regis: Map<string, Service.shortcut.KeyInfo>) {
      this.shortcutKeyData = [];
      regis.forEach(value => {
        const keyData: Array<string> = [];
        value.action = t(value.action);
        value.group = t(value.group);
        keyData.push(
          value.group,
          value.action,
          `${this.formatShortcutKey(value.modifierKeys)} + ${value.key.toUpperCase()}${
            value.isConflict ? " _warn_" : ""
          }`
        );
        this.shortcutKeyData.push(keyData);
        this.shortcutKeys = this.shortcutKeyData;
      });
      this.ShortkeyTable = {
        ...this.ShortkeyTable,
        data: {
          list2d: this.shortcutKeyData
        }
      };
    }

    handleSearchInput(searchQuery: string) {
      const lowercasedValue = searchQuery.toLowerCase().trim();
      const filteredData = this.shortcutKeys.filter(item => {
        return item
          .join()
          .toLowerCase()
          .indexOf(lowercasedValue) > -1
          ? item
          : false;
      });
      this.ShortkeyTable = {
        ...this.ShortkeyTable,
        data: {
          list2d: filteredData
        }
      };
      this.isSearchEmpty = !filteredData.length;
      if (this.isSearchEmpty) {
        this.liveRegion.textContent = t("app:keyboardShortcutKeys.noSearchResult");
      } else {
        const countLabel = this.ShortkeyTable.data.list2d.length;
        this.liveRegion.textContent = t("app:keyboardShortcutKeys:searchResultsCount", this.resultsLabel, {
          count: countLabel
        });
      }
      this.requestUpdate();
    }

    handleCloseModal() {
      const event = new CustomEvent<boolean>("ax-close-shortcut-key-modal", {
        detail: false,
        bubbles: true,
        composed: true
      });
      this.dispatchEvent(event);
    }

    static get styles() {
      return style;
    }

    private formatShortcutKey(keyCombination: string) {
      keyCombination = keyCombination.toUpperCase();
      this.altOrOptionKey = this.detectOS();
      keyCombination = keyCombination.replace(/CTRLKEY/gi, "Ctrl");
      keyCombination = keyCombination.replace(/SHIFTKEY/gi, "Shift");
      keyCombination = keyCombination.replace(/ALTKEY/gi, this.altOrOptionKey);
      keyCombination = keyCombination.replace(/_/gi, " + ");
      return keyCombination;
    }

    private detectOS() {
      if (navigator.userAgent.indexOf("Mac") !== -1) {
        return "Option";
      }
      return "Alt";
    }

    renderSearchImage() {
      return html`
        <div class="search-illustration">
          <agentx-wc-empty-state-wrapper illustration-type="empty-result">
            <span class="search-message">${t("app:keyboardShortcutKeys.noSearchResult")}</span>
          </agentx-wc-empty-state-wrapper>
        </div>
      `;
    }

    renderConflict() {
      if (this.isShortcutKeyConflict) {
        return html`
          <div class="conflict-warning-message">
            ${t("app:keyboardShortcutKeys.conflict")}
          </div>
        `;
      }
      return html``;
    }

    render() {
      return html`
        <md-floating-modal
          ?show=${this.isModalOpen}
          class="shortcut-key-modal"
          id="shortcut-key-modal"
          heading=${t("app:keyboardShortcutKeys.keyboardShortcuts")}
          label=${t("app:keyboardShortcutKeys.keyboardShortcuts")}
          maximize-aria-label=${t("app:common.maximizeScreenLabel")}
          minimize-aria-label=${t("app:common.minimizeModal")}
          resize-aria-label=${t("app:common.restoreScreenLabel")}
          close-aria-label=${t("app:keyboardShortcutKeys.closeModal")}
          @floating-modal-close=${() => this.handleCloseModal()}
        >
          <div class="shortcut-header">
            ${this.renderConflict()}
            <div class="input-search-field">
              <md-input
                htmlid="inputSearchClearPill"
                class="input-field"
                placeholder=${this.SEARCH}
                ariaLabel=${this.SEARCH}
                searchable=""
                shape="pill"
                clear=""
                value=""
                autoFocus
                @input-keydown=${(event: CustomEvent) => this.inputKeydownHandler(event)}
                @input-change=${(e: CustomEvent) => this.handleSearchInput(e.detail.value)}
              ></md-input>
            </div>
          </div>
          ${!this.isSearchEmpty
            ? html`
                <div class="shortcut-list ${classMap({ "shortcut-list-conflict": this.isShortcutKeyConflict })}">
                  <md-table-advanced
                    .config=${this.ShortkeyTable.config}
                    .data=${this.ShortkeyTable.data}
                    isRowFocusable="false"
                  >
                    <template id="tmp1">
                      <md-icon class="warn-icon" name="warning_20" color="blue"></md-icon>
                    </template>

                    <template id="tmp2">
                      <md-icon class="warn-icon" name="warning_20" color="yellow"></md-icon>
                    </template>

                    <template id="tmp3">
                      <md-icon class="warn-icon" name="error_20" color="red"></md-icon>
                    </template>
                  </md-table-advanced>
                </div>
              `
            : this.renderSearchImage()}
          <div id="live-region" class="sr-only" aria-live="polite"></div>
        </md-floating-modal>
      `;
    }
  }
}
