import { i18nMixin, t } from "@/mixins/i18nMixin";
import { LitElement, customElement, html, property } from "lit-element";
import "../../Common/EmptyStateWrapper/EmptyStateWrapper";
import { RECORDING } from "../constants";
import style from "./EmptyGrid.scss";

export namespace EmptyGrid {
  @customElement("agentx-wc-qmw-empty-grid")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: String }) emptyElement = "";
    static get styles(): CSSResult {
      return style;
    }

    getMessageText(): TemplateResult {
      if (this.emptyElement === RECORDING) {
        return html`
          <p role="alert" class="agentx-wc-qmw-empty-grid-message">${t("app:qmw.noRecording")}</p>
        `;
      } else {
        return html`
          <p role="alert" class="agentx-wc-qmw-empty-grid-message">${t("app:qmw.noData")}</p>
          <p role="alert" class="agentx-wc-qmw-empty-grid-message">${t("app:qmw.useFilterBtn")}</p>
        `;
      }
    }

    render(): TemplateResult {
      return html`
        <section class="agentx-wc-qmw-empty-grid">
        <div class=${
          this.emptyElement !== RECORDING
            ? "agentx-wc-qmw-empty-grid-container"
            : "agentx-wc-qmw-empty-grid-container-modal"
        }>
          <agentx-wc-empty-state-wrapper illustration-type="empty-state">
          ${this.getMessageText()}
          </agentx-wc-empty-state-wrapper>
        <div>
        </section>
      `;
    }
  }
}
