import { CONF } from "@/config";

/**
 * *** AnalyzerSessionHelper used for workaround to create analyzer session before loading reports ***
 *
 * AnalyzerSessionHelper is a singleton instance, which created only
 *
 * creating analyzer session before stating to load reports is done to prevent
 * side effects of simultaneous session initializations by multiple reports
 * */
class AnalyzerSessionHelper {
  analyzerSessionIframe: HTMLIFrameElement = document.createElement("iframe");
  isAnalyzerSessionSet = false;
  initiated = false;
  promiseReference: Promise<any> | any;
  intervalRef: any;

  create(): Promise<boolean> {
    const clusterId = localStorage.getItem("clusterId");
    if (this.isAnalyzerSessionSet) {
      return Promise.resolve(true);
    }

    if (this.initiated) {
      return this.promiseReference;
    }

    this.initiated = true;
    this.promiseReference = new Promise(resolve => {
      this.analyzerSessionIframe.setAttribute("style", "display: none");
      this.analyzerSessionIframe.onload = () => {
        this.isAnalyzerSessionSet = true;
        resolve(true);
      };
      const clusterQueryParam = `?ciClusterId=${clusterId}`;
      const analyzerGetVersionUrl = `${CONF.ANALYZER_HOST}/analyzer/version/get${clusterQueryParam}`;
      this.analyzerSessionIframe.src = analyzerGetVersionUrl;
      document.body.appendChild(this.analyzerSessionIframe);

      /* Analyzer sessions are valid only for 30 mins, on calling /version/get api, session time will be extended for 30 mins */
      this.intervalRef = setInterval(() => {
        this.analyzerSessionIframe.src = `${analyzerGetVersionUrl}&t=${Date.now()}`;
      }, 25 * 60 * 1000);
    });

    return this.promiseReference;
  }

  analyzerLogout(): Promise<boolean> {
    let logoutTimeoutTracker: any = null;
    this.promiseReference = new Promise((resolve, reject) => {
      this.intervalRef && clearInterval(this.intervalRef); // stop analyzer session extender
      this.analyzerSessionIframe.setAttribute("style", "display: none");
      this.analyzerSessionIframe.onload = () => {
        logoutTimeoutTracker && clearTimeout(logoutTimeoutTracker);
        this.initiated = false;
        this.isAnalyzerSessionSet = false;
        resolve(true);
      };
      this.analyzerSessionIframe.src = `${CONF.ANALYZER_HOST}/analyzer/app-logout`;
      document.body.appendChild(this.analyzerSessionIframe);

      /* Reject the promise if analyzer logout fails to complete in 5 sec to allow the desktop CI logout */
      logoutTimeoutTracker = setTimeout(() => {
        reject(new Error("Analyzer logout request timed out"));
      }, 5000);
    });

    return this.promiseReference;
  }
}

export const analyzerSessionHelper = new AnalyzerSessionHelper();
