import { CollapseToggler } from "@/components/CollapseArea/components/CollapseToggler";
import { throttle } from "@/utils/helpers";
import { WINDOW_LISTENERS_HUB } from "@/utils/WindowListenersHub";
import { customElement, html, internalProperty, LitElement, property, query } from "lit-element";
import { classMap } from "lit-html/directives/class-map";
import style from "./CollapseArea.scss";

export namespace CollapseArea {
  export enum Direction {
    Left = "left",
    Right = "right"
  }

  export enum Event {
    Toggle = "toggle"
  }

  const VISIBLE_DISTANCE = 100; //px

  const isMouseCloseToRect = (e: MouseEvent, rect: DOMRect) => {
    return (
      rect.left - VISIBLE_DISTANCE <= e.clientX &&
      rect.right + VISIBLE_DISTANCE >= e.clientX &&
      rect.top - VISIBLE_DISTANCE <= e.clientY &&
      rect.bottom + VISIBLE_DISTANCE >= e.clientY
    );
  };

  /**
   * @element agentx-wc-collapse-area
   * @fires toggle
   */
  @customElement("agentx-wc-collapse-area")
  export class Element extends LitElement {
    @property({ type: Boolean, reflect: true }) collapsed = false;
    @property({ type: String, reflect: true }) direction: Direction = Direction.Left;

    @property({ type: String }) forceTogglerVisibilityOnMouseOverSelectors: string | string[] = "";

    @internalProperty() isMouseCloseToToggler = false;
    @internalProperty() isMouseInSelfArea = false;
    @internalProperty() isMouseOverSelector = false;

    @internalProperty() isMouseOverOnCollapseButton = false;

    @query("agentx-wc-collapse-toggler") toggler!: HTMLElement;

    private get isTogglerVisible() {
      return this.isMouseCloseToToggler || this.isMouseInSelfArea || this.isMouseOverSelector;
    }

    setHoverState(e: any) {
      this.isMouseOverOnCollapseButton = e.detail.hovered;
    }

    connectedCallback() {
      super.connectedCallback();

      this.addEventListener("mouseenter", this.onSelfMouseEnter);
      this.addEventListener("mouseleave", this.onSelfMouseLeave);

      WINDOW_LISTENERS_HUB.on("mouseenter", this.onWindowMouseMove);
      WINDOW_LISTENERS_HUB.on("mousemove", this.onWindowMouseMove);
      WINDOW_LISTENERS_HUB.on("mouseleave", this.onWindowMouseLeave);
      WINDOW_LISTENERS_HUB.on("collapseHover", this.setHoverState.bind(this));
    }

    disconnectedCallback() {
      super.disconnectedCallback();

      this.removeEventListener("mouseenter", this.onSelfMouseEnter);
      this.removeEventListener("mouseleave", this.onSelfMouseLeave);

      WINDOW_LISTENERS_HUB.off("mouseenter", this.onWindowMouseMove);
      WINDOW_LISTENERS_HUB.off("mousemove", this.onWindowMouseMove);
      WINDOW_LISTENERS_HUB.off("mouseleave", this.onWindowMouseLeave);
      WINDOW_LISTENERS_HUB.off("collapseHover", this.setHoverState.bind(this));
    }

    static get styles() {
      return style;
    }

    private readonly onSelfMouseEnter = throttle(() => (this.isMouseInSelfArea = true));
    private readonly onSelfMouseLeave = throttle(() => (this.isMouseInSelfArea = false));

    private readonly onWindowMouseMove = throttle((e: MouseEvent) => {
      const _path = e.composedPath();
      const _selectors = Array.isArray(this.forceTogglerVisibilityOnMouseOverSelectors)
        ? this.forceTogglerVisibilityOnMouseOverSelectors
        : this.forceTogglerVisibilityOnMouseOverSelectors.split(" ");

      if (_path.length) {
        this.isMouseOverSelector = !!_selectors.find(
          selector =>
            !!_path.find((eventTarget: EventTarget) => {
              return (
                eventTarget && (eventTarget as HTMLElement).matches && (eventTarget as HTMLElement).matches(selector)
              );
            })
        );
      }

      this.isMouseCloseToToggler = isMouseCloseToRect(e, this.toggler.getBoundingClientRect());
    });

    private readonly onWindowMouseLeave = throttle(() => {
      this.isMouseOverSelector = false;

      this.isMouseCloseToToggler = false;
    });

    private toggle(e: CustomEvent<CollapseToggler.EToggle>) {
      this.dispatchEvent(
        new CustomEvent<CollapseToggler.EToggle>(CollapseArea.Event.Toggle, {
          detail: {
            collapsed: e.detail.collapsed
          }
        })
      );
    }

    render() {
      return html`
        <div
          class="collapse-area ${classMap({
            hovered: this.isMouseOverOnCollapseButton
          })}"
        >
          <div
            class="collapse-area-inner ${classMap({
              displayTaskArea: this.collapsed
            })}"
          >
            <slot></slot>
          </div>
          <agentx-wc-collapse-toggler
            .direction="${this.direction}"
            .collapsed="${this.collapsed}"
            .visible="${this.isTogglerVisible}"
            @toggle="${(e: CustomEvent<CollapseToggler.EToggle>) => this.toggle(e)}"
          ></agentx-wc-collapse-toggler>
        </div>
      `;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "agentx-wc-collapse-area": CollapseArea.Element;
  }
}
