import { t } from "@/mixins/i18nMixin";
import "./SurveyDarkIcon";
import "./SurveyLightIcon";

import "@momentum-ui/web-components";
import { customElement, internalProperty, LitElement, property } from "lit-element";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { ifDefined } from "lit-html/directives/if-defined";
import { styleMap } from "lit-html/directives/style-map";
import { Navigation } from "../Navigation";
import style from "./NavigationItem.scss";

const DEFAULT_ICON_SIZE = 20;

const isImageUrl = (url: string) => {
  if (!url || url.length < 4) {
    return false;
  }
  const validImageExtensions = ["png", "gif", "jpg", "jpeg", "svg", "bmp", "ico"];
  const image = url.substring(url.lastIndexOf("/")).toLowerCase();
  return validImageExtensions.find(ext => image.includes(ext));
};

export namespace NavigationItem {
  export type ESelected = { item: Navigation.Item };

  /**
   * @element agentx-wc-navigation-item
   * @fires selected
   */
  @customElement("agentx-wc-navigation-item")
  export class Element extends LitElement {
    @property({ type: Object }) item!: Navigation.Item;
    @property({ type: Boolean }) selected = false;
    @property({ type: Boolean }) darkTheme = false;
    @property({ type: Number }) popoverCount: number | null = null;
    @property({ type: Number }) uid: number | null = null;

    @internalProperty() isImgLoaded = false;
    @internalProperty() isImgError = false;

    connectedCallback() {
      super.connectedCallback();
    }

    private get label() {
      return this.item && this.item.label ? this.item.label : "";
    }

    private get iconSize() {
      return this.item && this.item.iconSize ? this.item.iconSize : DEFAULT_ICON_SIZE;
    }

    private get isMomentum() {
      return this.item && this.item.iconType === "momentum";
    }

    private get isImg() {
      return this.item && this.item.iconType === "other" && !!isImageUrl(this.item.icon);
    }

    private get btnCssClassInfo() {
      return {
        "nav-button__imaged": this.isImg,
        "nav-button__selected": this.selected
      };
    }

    static get styles() {
      return style;
    }

    private readonly onBtnClick = () => {
      this.dispatchEvent(
        new CustomEvent<ESelected>("selected", {
          detail: {
            item: this.item
          }
        })
      );
    };

    render() {
      return html`
        <md-button
          id="navigation-item-${this.uid}"
          ?ariahaspopup=${false}
          ariaLabel=${this.label.startsWith("app:")
            ? t(this.label, this.label, { count: this.popoverCount ? this.popoverCount : 0 })
            : this.label.includes("Feedback")
            ? t("app:navigation.shareFeedback")
            : this.label}
          @click=${this.onBtnClick}
          class="nav-button ${classMap(this.btnCssClassInfo)}"
          hasRemoveStyle
          role="link"
          aria-current=${ifDefined(this.selected ? "page" : undefined)}
        >
          ${this.renderIcon()} ${this.item.taskBadge ? this.renderBadge() : null}
        </md-button>
      `;
    }

    renderBadge() {
      if (this.popoverCount) {
        return html`
          <md-badge class="wc-unread-popover-count" color="unreadcount" id="wc-unread-popover-count">
            <span>${this.popoverCount}</span>
          </md-badge>
        `;
      }
      return "";
    }

    renderIcon() {
      if (this.isMomentum) {
        if (this.item.icon === "survey") {
          return html`
            <div class="survey-img">
              ${this.darkTheme
                ? html`
                    <survey-dark-icon></survey-dark-icon>
                  `
                : html`
                    <survey-light-icon></survey-light-icon>
                  `}
            </div>
          `;
        } else {
          return html`
            <md-icon name="icon-${this.item.icon}_${this.iconSize}" class="nav-button-content"></md-icon>
          `;
        }
      }

      if (this.isImg) {
        return html`
          <div
            class="nav-button-content ${classMap({
              hidden: this.isImgError,
              "img-loading": !this.isImgError && !this.isImgLoaded
            })}"
          >
            <img
              src="${this.item.icon}"
              alt="${this.label}"
              style="${styleMap({
                "max-height": `${this.iconSize}px`,
                "max-width": `${this.iconSize}px`
              })}"
              @load="${() => {
                this.isImgError = false;
                this.isImgLoaded = true;
              }}"
              @error="${() => (this.isImgError = true)}"
            />
          </div>
          <md-icon
            name="icon-warning_20"
            title=${this.label}
            class="nav-button-content ${classMap({ hidden: !this.isImgError })}"
          ></md-icon>
        `;
      }

      return html`
        <span>${this.label}</span>
      `;
    }
  }
}
