import { AVAILABLE, AVAILABLE_ID } from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { LitElement, customElement, html, internalProperty, property } from "lit-element";
import { ifDefined } from "lit-html/directives/if-defined";
import style from "./scss/list.scss";
import { ChangeStateSelectorTypes } from "./types";
import { escapeRegExp, stateIndicator } from "./utils";

export namespace ChangeStateSelectorList {
  /**
   * @element agent-state-selector-list
   */
  @customElement("agent-state-selector-list")
  export class Element extends i18nMixin(LitElement) {
    @property() isOpen = false;
    @property() agentStateOption: ChangeStateSelectorTypes.AgentStateOption = {
      name: "",
      isSystem: false,
      isDefault: false,
      id: "0"
    };
    @property() searchTerm = "";
    @internalProperty() isTextOverflow: undefined | boolean = true;
    static get styles() {
      return style;
    }

    updated(changedProperties: Map<string | number | symbol, unknown>): void {
      super.updated(changedProperties);
      if (changedProperties.has("isOpen") && this.isOpen) {
        setTimeout(() => {
          const textContainer = this.shadowRoot?.querySelector(".agent-status-option-text");
          let isTextOverflowing = true;
          if (textContainer) {
            isTextOverflowing = (textContainer as any).offsetWidth < (textContainer as any).scrollWidth;
            if (isTextOverflowing) {
              this.isTextOverflow = undefined;
            }
          }
        }, 100);
      }
    }

    HighlightText = (text = "", highlight = "") => {
      if (!highlight.trim()) {
        return html`
          <span>${text}</span>
        `;
      }
      const regex = new RegExp(`(${escapeRegExp(highlight)})`, "gi");
      const parts = text.split(regex);
      return html`
        <span class="highlight-span">
          ${parts
            .filter(part => part)
            .map(part =>
              // prettier-ignore
              regex.test(part)
                ? html`<b>${part}</b>`
                : html`<span>${part}</span>`
            )}
        </span>
      `;
    };

    render() {
      const text =
        this.agentStateOption.name === AVAILABLE ? t("app:tpw.stateChange.available") : this.agentStateOption.name;

      return html`
        <span class="agent-status-select-option-badge">
          <!-- Render the SVG Icon -->
          ${stateIndicator(this.agentStateOption.id === AVAILABLE_ID ? AVAILABLE : this.agentStateOption.name)}
        </span>
        <md-tooltip placement="top" message=${text} disabled="${ifDefined(this.isTextOverflow)}">
          <div class="agent-status-option-text ellipsis-text">
            ${this.HighlightText(text, this.searchTerm)}
          </div>
        </md-tooltip>
      `;
    }
  }
}
