
        import {css} from 'lit-element';   
        export default css`:host {
  --filter-min-width: 150px;
  --filter-count-width: 100px;
}

.filter-container {
  min-width: var(--filter-min-width);
}
.filter-container md-combobox::part(multiwrap-input) {
  width: calc(100% - var(--filter-count-width));
}
.filter-container md-combobox::part(custom-error),
.filter-container md-combobox::part(custom-loader) {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--md-primary-bg-color);
  padding: 30px 0px;
  min-height: 300px;
}
/*# sourceURL=/Users/tachen2/wxcc-desktop/packages/agentx-mfe-wc-based/src/components/SupervisorFilters/index.scss */
/*# sourceURL=index.scss */
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9Vc2Vycy90YWNoZW4yL3d4Y2MtZGVza3RvcC9wYWNrYWdlcy9hZ2VudHgtbWZlLXdjLWJhc2VkL3NyYy9jb21wb25lbnRzL1N1cGVydmlzb3JGaWx0ZXJzL2luZGV4LnNjc3MiLCJpbmRleC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UseUJBQUE7RUFDQSwyQkFBQTtBQ0NGOztBREVBO0VBQ0Usa0NBQUE7QUNDRjtBRENFO0VBQ0UsNkNBQUE7QUNDSjtBREVFOztFQUVFLGFBQUE7RUFDQSxtQkFBQTtFQUNBLHVCQUFBO0VBQ0EsNENBQUE7RUFDQSxpQkFBQTtFQUNBLGlCQUFBO0FDQUoiLCJmaWxlIjoiaW5kZXguc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgLS1maWx0ZXItbWluLXdpZHRoOiAxNTBweDtcbiAgLS1maWx0ZXItY291bnQtd2lkdGg6IDEwMHB4O1xufVxuXG4uZmlsdGVyLWNvbnRhaW5lciB7XG4gIG1pbi13aWR0aDogdmFyKC0tZmlsdGVyLW1pbi13aWR0aCk7XG5cbiAgbWQtY29tYm9ib3g6OnBhcnQobXVsdGl3cmFwLWlucHV0KSB7XG4gICAgd2lkdGg6IGNhbGMoMTAwJSAtIHZhcigtLWZpbHRlci1jb3VudC13aWR0aCkpO1xuICB9XG5cbiAgbWQtY29tYm9ib3g6OnBhcnQoY3VzdG9tLWVycm9yKSxcbiAgbWQtY29tYm9ib3g6OnBhcnQoY3VzdG9tLWxvYWRlcikge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1tZC1wcmltYXJ5LWJnLWNvbG9yKTtcbiAgICBwYWRkaW5nOiAzMHB4IDBweDtcbiAgICBtaW4taGVpZ2h0OiAzMDBweDtcbiAgfVxufVxuIiwiOmhvc3Qge1xuICAtLWZpbHRlci1taW4td2lkdGg6IDE1MHB4O1xuICAtLWZpbHRlci1jb3VudC13aWR0aDogMTAwcHg7XG59XG5cbi5maWx0ZXItY29udGFpbmVyIHtcbiAgbWluLXdpZHRoOiB2YXIoLS1maWx0ZXItbWluLXdpZHRoKTtcbn1cbi5maWx0ZXItY29udGFpbmVyIG1kLWNvbWJvYm94OjpwYXJ0KG11bHRpd3JhcC1pbnB1dCkge1xuICB3aWR0aDogY2FsYygxMDAlIC0gdmFyKC0tZmlsdGVyLWNvdW50LXdpZHRoKSk7XG59XG4uZmlsdGVyLWNvbnRhaW5lciBtZC1jb21ib2JveDo6cGFydChjdXN0b20tZXJyb3IpLFxuLmZpbHRlci1jb250YWluZXIgbWQtY29tYm9ib3g6OnBhcnQoY3VzdG9tLWxvYWRlcikge1xuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tbWQtcHJpbWFyeS1iZy1jb2xvcik7XG4gIHBhZGRpbmc6IDMwcHggMHB4O1xuICBtaW4taGVpZ2h0OiAzMDBweDtcbn0iXX0= */`;
    