import { AgentPerformanceReports } from "./AgentPerformanceReports";

export const agentReportList: AgentPerformanceReports.ReportTab[] = [
  {
    reportId: "-1207",
    isDashboard: true,
    tabId: "agentSummary"
  },
  {
    reportId: "-1222",
    isDashboard: true,
    tabId: "agentHistoryStats"
  },
  {
    reportId: "-1225",
    isDashboard: true,
    tabId: "agentRealtimeStats"
  },
  {
    reportId: "-1229",
    isDashboard: true,
    tabId: "agentStatsByStateHist"
  },
  {
    reportId: "-1233",
    isDashboard: true,
    tabId: "agentStatsByStateRealtime"
  },
  {
    reportId: "-1234",
    isDashboard: false,
    tabId: "teamStatsHist"
  },
  {
    reportId: "-1235",
    isDashboard: false,
    tabId: "teamStatsRealtime"
  },
  {
    reportId: "-1209",
    isDashboard: false,
    tabId: "contactDetailsInQueueHist"
  },
  {
    reportId: "-1272",
    isDashboard: true,
    tabId: "contactDetailsInQueueRealtime"
  },
  {
    reportId: "-164",
    isDashboard: false,
    tabId: "outdialStatsHistoric"
  },
  {
    reportId: "-165",
    isDashboard: false,
    tabId: "outdialStatsRealtime"
  }
];
