/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin, t } from "@/mixins/i18nMixin";
import "@momentum-ui/web-components";
import { customElement, html, LitElement } from "lit-element";
import style from "./WidgetLoader.scss";

export namespace WidgetLoader {
  @customElement("agentx-wc-widget-loader")
  export class Element extends i18nMixin(LitElement) {
    static get styles() {
      return style;
    }

    render() {
      return html`
        <div class="widget-loader">
          <div class="widget-loader-wrapper">
            <md-spinner role="alert" aria-label=${t("app:commonWebComp.loading")} size="56"></md-spinner>
            <strong class="widget-loader-heading">${t("app:commonWebComp.loading")}...</strong>
          </div>
        </div>
      `;
    }
  }
}
