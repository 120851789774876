import {
  CONFERENCE,
  CONSULTING,
  CONTACT_DIRECTION,
  MEDIA_TYPES,
  OUTBOUND_TYPE,
  Play,
  TASKLIST_ITEMSTATUS,
  ICON_SRC,
  DIGITAL_OUTBOUND
} from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { getImageSrcTaskList, isIconSrc, makeTelephonyReadable, isDigitalOutboundCase } from "@/utils/helpers";
import "@momentum-ui/web-components";
import { css, customElement, html, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { ifDefined } from "lit-html/directives/if-defined";
import { TaskList } from "../TaskList";
import style from "./TaskListItem.scss";
import "./TaskTimer";

@customElement("agentx-wc-task-list-item")
export class TaskListItem extends i18nMixin(LitElement) {
  @property({ type: Object }) task!: TaskList.Task;
  @property({ type: Boolean }) selected = false;
  @property({ type: Object }) unreadMessage: TaskList.UnreadChatMessage = {};
  @property({ type: Boolean }) isInteractionModelOpen = false;
  @property({ type: Boolean }) isDarkMode = false;
  @property({ type: Boolean }) isProgressiveCampaignEnabled = false;
  @property({ type: Boolean }) isProgressiveCampaign1NEnabled = false;
  @property({ type: String }) monitoringOwnerName = "";
  @property({ type: String }) timerAriaLabel = "";

  static get styles() {
    return style;
  }

  private static get meetingAlertCss() {
    return css`
      :host .md-alert.md-alert--meeting {
        background-color: transparent;
        box-shadow: none;
        margin: 0;
        width: 100%;
        border-radius: 0;
        border: none;
      }
      :host .md-alert.md-alert--meeting.ax-list-item-content .md-alert__content--wide {
        width: auto;
      }
    `;
  }

  isProgressive1NCampaignCall(outboundType: string | undefined): boolean {
    return (
      (outboundType === OUTBOUND_TYPE.CAMPAIGN_OUTDIAL_CUSTOMER ||
        outboundType === OUTBOUND_TYPE.OUTDIAL_RESERVATION ||
        outboundType === OUTBOUND_TYPE.CAMPAIGN_PREDICTIVE_OUTDIAL_CUSTOMER ||
        outboundType === OUTBOUND_TYPE.CAMPAIGN) &&
      this.isProgressiveCampaign1NEnabled
    );
  }

  isCampaignOutBoundTypes(outboundType: string | undefined): boolean {
    return (
      (outboundType === OUTBOUND_TYPE.PROGRESSIVE_CAMPAIGN && this.isProgressiveCampaignEnabled) ||
      this.isProgressive1NCampaignCall(outboundType)
    );
  }

  getChannelType(): string {
    switch (this.task.mediaType) {
      case MEDIA_TYPES.SOCIAL:
        return this.task.mediaChannel;
      case MEDIA_TYPES.VOICE: {
        if (
          this.task.outboundType === OUTBOUND_TYPE.CALLBACK ||
          this.task.outboundType === OUTBOUND_TYPE.COURTESY_CALLBACK
        ) {
          return TASKLIST_ITEMSTATUS.CALLBACK_ICON;
        } else if (this.isCampaignOutBoundTypes(this.task.outboundType)) {
          return TASKLIST_ITEMSTATUS.CAMPAIGN_ICON;
        } else if (this.task.contactDirection === CONTACT_DIRECTION.OUTBOUND) {
          return TASKLIST_ITEMSTATUS.OUTBOUND_ICON;
        } else {
          return this.task.mediaType;
        }
      }
      default:
        return this.task.mediaType;
    }
  }

  getStatus() {
    if (this.task.isConferencing) {
      return CONFERENCE;
    } else if (this.task.state === CONSULTING) {
      return CONSULTING;
    } else if (this.task.isHold) {
      return Play;
    } else if (this.task.outboundType === OUTBOUND_TYPE.COURTESY_CALLBACK) {
      return "";
    } else {
      return "";
    }
  }

  getMediaTypeForMdTaskItem() {
    const mediaType = this.getChannelType();
    if (
      isIconSrc(this.task.mediaType, this.task.mediaChannel, this.task.contactDirection, this.task.outboundType ?? "")
    ) {
      return ICON_SRC;
    }
    return mediaType;
  }

  getTaskTitle() {
    const mediaType = this.task.mediaType;
    if (mediaType === "telephony") {
      return this.task.contactDirection === "INBOUND" ? this.task.displayAni : this.task.dnis;
    } else if (
      isDigitalOutboundCase(this.task.mediaChannel, this.task.contactDirection, this.task.outboundType ?? "")
    ) {
      return this.task.dnis;
    } else if (mediaType === "social") {
      return this.task.customerName ? this.task.customerName : this.task.appUser;
    } else if (mediaType === MEDIA_TYPES.MIDCALL_MONITORING) {
      return this.monitoringOwnerName || this.task.ownerName;
    }
    return this.task.customerName;
  }

  getTitleForAriaLabel() {
    const phoneNumberRegex = /^[0-9+-]+$/;
    const title = this.getTaskTitle() || "";
    if (phoneNumberRegex.test(title)) {
      return makeTelephonyReadable(title);
    }
    return this.getTaskTitle();
  }

  getTaskAriaLabel() {
    return `${this.getChannelType()}, ${this.getTitleForAriaLabel()}, ${this.task.virtualTeamName}, ${
      this.unreadMessage.unreadMessageCount
        ? t("app:taskBar.newMessages", "{{count}} new messages", { count: this.unreadMessage.unreadMessageCount })
        : ""
    }, ${this.unreadMessage.lastUnreadMessage ? this.unreadMessage.lastUnreadMessage : ""} ${
      this.timerAriaLabel !== "" ? `${t("app:outdial.outboundTaskTime")} ${this.timerAriaLabel}` : ""
    }`;
  }

  getAniTeamName() {
    if (this.task.mediaType === MEDIA_TYPES.MIDCALL_MONITORING) {
      return this.task.displayAni;
    }
    if (isDigitalOutboundCase(this.task.mediaChannel, this.task.contactDirection, this.task.outboundType ?? "")) {
      return t(DIGITAL_OUTBOUND);
    }
    return this.task.virtualTeamName;
  }

  render() {
    const listItemClassInfo = {
      "ax-activity-item-selected": this.selected,
      "not-allowed-cursor": this.isInteractionModelOpen && !this.selected
    };

    return this.task
      ? html`
          <md-task-item
            class="ax-list-item ${classMap(listItemClassInfo)}"
            tabindex="-1"
            mediaType="${this.getMediaTypeForMdTaskItem()}"
            title="${ifDefined(this.getTaskTitle())}"
            status="${this.getStatus()}"
            accepted
            ?selected="${this.selected}"
            lastmessage="${ifDefined(
              this.unreadMessage.lastUnreadMessage ? this.unreadMessage.lastUnreadMessage : undefined
            )}"
            quantity="${this.unreadMessage.unreadMessageCount ? this.unreadMessage.unreadMessageCount : 0}"
            customAriaLabel="${this.getTaskAriaLabel()}"
            iconSrc="${ifDefined(
              getImageSrcTaskList(
                this.task.mediaType,
                this.task.mediaChannel,
                this.isDarkMode,
                this.selected,
                this.task.contactDirection,
                this.task.outboundType ?? ""
              )
            )}"
          >
            <span slot="queue">
              ${this.task.mediaType === MEDIA_TYPES.MIDCALL_MONITORING
                ? html`
                    <md-icon class="channel-icon" name="handset-active_10"></md-icon>
                  `
                : nothing}
              <span className="queue-name">
                ${this.getAniTeamName()}
              </span>
            </span>
            <agentx-wc-task-list-timer startTimestamp=${this.task.timeStamp}></agentx-wc-task-list-timer>
          </md-task-item>
        `
      : nothing;
  }
}
