/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CONF } from "@/config";
import {
  COLUMN_MENU_FOCUS,
  REALTIME_FEED,
  SUBSCRIPTION_RESPONSE,
  TIMEOUT_THOUSAND_MS,
  TPW_DATA_WAIT_TIME,
  TPW_FILTER_MODAL_UPDATED,
  TPW_MONITOR_MODAL
} from "@/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { logger } from "@/sdk";
import { debounce } from "@/utils/helpers";
import { CMSTypes, RTDTypes, SERVICE, Service } from "@agentx/agentx-services";
import { Placement, createPopper } from "@popperjs/core";
import { arrow, defaultModifiers, flip, offset, preventOverflow } from "@popperjs/core/lib/popper";
import "@uuip/unified-ui-platform-common-components";
import * as workerTimers from "@uuip/unified-ui-platform-sdk";
import { ColumnPinnedEvent, GetRowIdParams } from "ag-grid-community";
import { Grid, GridOptions, IServerSideGetRowsParams, LicenseManager, RowNode } from "ag-grid-enterprise";
import { LitElement, customElement, html, internalProperty, property, query } from "lit-element";
import { nothing } from "lit-html/lib/part";
import _ from "lodash";
import { toJS } from "mobx";
import "../Common/WidgetLoader/WidgetLoader";
import "./ColumnMenu/ColumnMenu";
import "./ContextMenu";
import { gridConfig } from "./GridConfig";
import "./GridHeader/GridHeader";
import style from "./TeamPerformance.scss";
import {
  checkIfFilterModelIsEmpty,
  convertChannelStatusArrayToMap,
  convertCmsAgentDataArrayToMap,
  convertContactStatusArrayToMap,
  convertGlobalStatusArrayToMap,
  filterContactStatsData,
  filteredTelephonyChannelData,
  updateExistingMapFromChannelStatus,
  updateExistingMapFromContactStats,
  updateExistingMapFromGlobalStatus
} from "./TeamPerformanceUtils";
import { TPW, initialFilterModel } from "./Utils";
import "./components/ActiveInteraction";
import "./components/AppliedFilters";
import "./components/EmptyGrid";
import "./components/ReviewAndMonitorWrapper";
import "./components/StateSelector";
import "./components/WebexChat";
import "./components/agentStateSelector";
declare global {
  interface Window {
    wxcc: any;
  }
}
export type idleCodes = { isFetchingSuccess: boolean; data: Service.Aqm.Configs.Entity[] };

const NUM_ROWS_IN_RESPONSE = 500;

type TPWConfig = {
  gridId: string;
  orgId: string;
  isDarkMode: boolean;
  webexEnabled: boolean;
  isMCMEnabled: boolean;
  webexEventRegistered: boolean;
  supervisorId: string;
  supervisorName: string;
  isAIDFixedVariablesEnabled: boolean;
  isStateChangeBySupervisorEnabled: boolean;
  supervisorEmail: string;
  supervisorAnalyserId: string;
  role: string;
  onActiveCall: boolean;
  isSupervisorySendMessageEnabled: boolean;
  isStationLoginSuccess: boolean;
  isEnvironmentProd: boolean;
  idleCodes: idleCodes;
  isBargeInEnabled: boolean;
  voiceChannelCount: number;
  isMPCorPersistEnabled: boolean;
  isWxccAgentInteractionEnabled: boolean;
  isShowSignedOutAgentsEnabled: boolean;
};

export type ActionCellConfigs = {
  isMCMEnabled: boolean;
  isWebexEnabled: boolean;
  isDarkMode: boolean;
  isAIDEnabled: boolean;
  isSupervisorySendMessageEnabled: boolean;
  onActiveCall: boolean;
  handleAIDModal: () => void;
  orgId: string;
  voiceChannelCount: number;
};

const globalStatusDataSet = "wxcc.rtd.agentglobalstatus";
const agentChannelStatusDataSet = "wxcc.rtd.agentchannelstatus";
const contactStatsDataSet = "wxcc.rtd.contactstats";

export namespace TeamPerformance {
  @customElement("agentx-wc-team-performance-widget")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Object }) tpwConfigs: TPWConfig = {
      gridId: "tpw-configs",
      orgId: "",
      isDarkMode: false,
      webexEnabled: false,
      isMCMEnabled: false,
      webexEventRegistered: false,
      supervisorId: "",
      supervisorName: "",
      isAIDFixedVariablesEnabled: false,
      isStateChangeBySupervisorEnabled: false,
      supervisorEmail: "",
      supervisorAnalyserId: "",
      role: "",
      onActiveCall: false,
      isSupervisorySendMessageEnabled: false,
      isStationLoginSuccess: false,
      isEnvironmentProd: false,
      idleCodes: { isFetchingSuccess: false, data: [] },
      isBargeInEnabled: false,
      voiceChannelCount: 0,
      isMPCorPersistEnabled: false,
      isWxccAgentInteractionEnabled: false,
      isShowSignedOutAgentsEnabled: false
    };
    @internalProperty() gridId = "tpw-configs";
    @internalProperty() agGridCss = false;
    @internalProperty() agThemeAlpine = false;
    @internalProperty() agThemeAlpineDark = false;
    @internalProperty() agentStatusData: RTDTypes.AgentDetails[] = [];
    @internalProperty() agentStatusDataServer: RTDTypes.AgentDetails[] = [];
    @internalProperty() isModalOpen = false;
    @internalProperty() isGridRendered = false;
    @internalProperty() appliedFilters: TPW.FilterModel = initialFilterModel;
    @internalProperty() gridOptions: GridOptions | any = {};
    @internalProperty() displayedRowCount = 0;
    @internalProperty() isError = false;
    @internalProperty() isRequestTimeout = false;
    @internalProperty() requestTimerId = 0;
    @internalProperty() delayId = 0;
    @internalProperty() selectedAgentDetails!: RTDTypes.AgentDetails;
    @internalProperty() globalStatusSubscription: any = {};
    @internalProperty() channelStatusSubscription: any = {};
    @internalProperty() contactStatsSubscription: any = {};
    @internalProperty() appliedGroups = [];
    @internalProperty() selectedAgentId = "";
    @internalProperty() expandedGroupState: Array<string> = [];
    @internalProperty() agentDataMap: Map<string, RTDTypes.AgentDetails> = new Map();
    @internalProperty() cmsAgentData: CMSTypes.AgentDetails[] = [];
    @internalProperty() isAgentGlobalSubscribed = false;
    @internalProperty() isChannelStatusSubscribed = false;
    @internalProperty() isContactStatusSubscribed = false;
    @internalProperty() popperInstance: any = null;
    @internalProperty() contextMenuInstance: any = null;
    @internalProperty() selectedActionCellValue: any = {};
    @internalProperty() resizeObserver: any = {};
    @internalProperty() actionCellWidth = 0;
    @internalProperty() monitoringInProgress = false;
    @internalProperty() updateContextMenuValuesTimeout: ReturnType<typeof setTimeout> | null = null;

    @query("#agentx-wc-tpw-grid-container") eGridDiv!: HTMLElement;
    @query("agentx-wc-tpw-empty-grid") emptyGrid!: HTMLElement;
    @query("#filters") filters!: HTMLElement;
    @query(".tpw-chat-wrapper") overlayElement!: Element;
    @query(".context-menu-wrapper") contextMenu!: Element;
    @internalProperty() contextMenuOpened = false;
    @internalProperty() webexChatOpened = false;

    @internalProperty() stateSelectorInstance: any = null;
    @query(".agent-state-selector") stateSelector!: Element;
    @internalProperty() stateSelectorOpened = false;
    @internalProperty() contextMenuList = [];
    @internalProperty() isServerSidePaginated = false;

    static get styles() {
      return style;
    }

    //toggle subscription reset the subscription flags for future coming subscriptions
    toggleSubscriptionFlags() {
      this.isAgentGlobalSubscribed = false;
      this.isChannelStatusSubscribed = false;
      this.isContactStatusSubscribed = false;
    }

    //setRowDara convert map data to array data to make compatible for ag-grid
    setRowData() {
      this.agentStatusData = Array.from(this.agentDataMap, item => item[1]);
      this.gridOptions.api.setRowData(this.agentStatusData);
      this.gridOptions.api.hideOverlay();
    }

    //isFirstSubscription checks whether the subscription_response received is the very first subscription
    isFirstSubscription(type: string | undefined) {
      return (
        !this.isChannelStatusSubscribed &&
        !this.isAgentGlobalSubscribed &&
        (!this.isContactStatusSubscribed || !this.tpwConfigs.isAIDFixedVariablesEnabled) &&
        (type === SUBSCRIPTION_RESPONSE || this.agentStatusData.length === 0)
      );
    }

    updateDisplayRowCountOnDataUpdate() {
      if (this.delayId) {
        workerTimers.clearInterval(this.delayId);
      }
      this.delayId = toJS(
        workerTimers.setTimeout(() => {
          this.getDisplayedRowCount();
        }, 100)
      );
    }

    setAgentGlobalStatusData(
      agentGlobalStatusData: RTDTypes.AgentGlobalStatus[],
      type?: string,
      deltaData?: RTDTypes.AgentGlobalStatus[]
    ) {
      if (this.isAgentGlobalSubscribed && type === SUBSCRIPTION_RESPONSE) {
        this.toggleSubscriptionFlags();
      }
      if (this.isFirstSubscription(type)) {
        //Directly map the value received if the received data is first subscription data
        this.agentDataMap = convertGlobalStatusArrayToMap(
          agentGlobalStatusData,
          SERVICE.i18n.language,
          this.tpwConfigs.supervisorAnalyserId
        );
        this.enrichCMSDataWithRTDData();
        this.setRowData();
        this.isAgentGlobalSubscribed = true;
      } else if (
        !this.isChannelStatusSubscribed &&
        (!this.isContactStatusSubscribed || !this.tpwConfigs.isAIDFixedVariablesEnabled) &&
        this.isAgentGlobalSubscribed
      ) {
        //Directly map the value received if the received data is chunked subscription data
        this.agentDataMap = new Map([
          ...this.agentDataMap,
          ...convertGlobalStatusArrayToMap(
            agentGlobalStatusData,
            SERVICE.i18n.language,
            this.tpwConfigs.supervisorAnalyserId
          )
        ]);
        this.enrichCMSDataWithRTDData();
        this.setRowData();
      } else if (this.isChannelStatusSubscribed || this.isContactStatusSubscribed || type === REALTIME_FEED) {
        if (type === SUBSCRIPTION_RESPONSE) {
          this.isAgentGlobalSubscribed = true;
        }
        if (deltaData && type === REALTIME_FEED) {
          this.agentDataMap = updateExistingMapFromGlobalStatus(
            deltaData,
            this.agentDataMap,
            SERVICE.i18n.language,
            this.gridOptions,
            this,
            this.tpwConfigs.supervisorAnalyserId,
            this.isServerSidePaginated
          );
        } else {
          this.agentDataMap = updateExistingMapFromGlobalStatus(
            agentGlobalStatusData,
            this.agentDataMap,
            SERVICE.i18n.language,
            this.gridOptions,
            this,
            this.tpwConfigs.supervisorAnalyserId,
            this.isServerSidePaginated
          );
        }
        this.enrichCMSDataWithRTDData();
        this.agentStatusData = Array.from(this.agentDataMap, item => item[1]);
      }
      if (this.appliedGroups && this.appliedGroups.length > 0) {
        this.updateGroupFilter();
      }
      this.updateDisplayRowCountOnDataUpdate();
    }

    setAgentContactStatsData(
      contactStatsData: RTDTypes.AgentContactStats[],
      type?: string,
      deltaData?: RTDTypes.AgentContactStats[]
    ) {
      if (!deltaData || (deltaData && deltaData.length > 0 && deltaData[0].operation !== "DELETE")) {
        let filteredData: RTDTypes.AgentContactStats[] = [];
        if (deltaData) {
          filteredData = filterContactStatsData(deltaData);
        } else {
          filteredData = filterContactStatsData(contactStatsData);
        }
        if (filteredData && filteredData.length > 0) {
          if (this.isContactStatusSubscribed && type === SUBSCRIPTION_RESPONSE) {
            this.toggleSubscriptionFlags();
          }
          if (this.isFirstSubscription(type)) {
            //Directly map the value received if the received data is first subscription data
            this.agentDataMap = convertContactStatusArrayToMap(
              contactStatsData,
              this.tpwConfigs.supervisorAnalyserId,
              this.tpwConfigs.isMPCorPersistEnabled
            );
            this.enrichCMSDataWithRTDData();
            this.setRowData();
            this.isContactStatusSubscribed = true;
          } else if (
            this.isContactStatusSubscribed &&
            !this.isChannelStatusSubscribed &&
            !this.isAgentGlobalSubscribed
          ) {
            //Directly map the value received if the received data is chunked subscription data
            //chunked data is new data so directly appending the data recieved in subscription
            this.agentDataMap = new Map([
              ...this.agentDataMap,
              ...convertContactStatusArrayToMap(
                filteredData,
                this.tpwConfigs.supervisorAnalyserId,
                this.tpwConfigs.isMPCorPersistEnabled
              )
            ]);
            this.enrichCMSDataWithRTDData();
            this.setRowData();
          } else if (this.isAgentGlobalSubscribed || this.isChannelStatusSubscribed || type === REALTIME_FEED) {
            if (type === SUBSCRIPTION_RESPONSE) {
              this.isContactStatusSubscribed = true;
            }
            //updating the existing data id the subscription is not the first subscription or if the type of response is relatimefeed
            this.agentDataMap = updateExistingMapFromContactStats(
              filteredData,
              this.agentDataMap,
              this.gridOptions,
              this.tpwConfigs.supervisorAnalyserId,
              this,
              this.tpwConfigs.isMPCorPersistEnabled,
              this.isServerSidePaginated
            );
            this.enrichCMSDataWithRTDData();
            this.agentStatusData = Array.from(this.agentDataMap, item => item[1]);
            this.monitoringInProgress = this.agentStatusData.some(
              agentDetails => agentDetails.actions?.isSupervisorMonitoring
            );
          }
          this.updateSelectedAgentDetails();
        }
        this.updateDisplayRowCountOnDataUpdate();
      }
    }
    setAgentChannelStatusData(
      agentChannelStatusData: RTDTypes.AgentChannelStatus[],
      type?: string,
      deltaData?: RTDTypes.AgentChannelStatus[]
    ) {
      if (!deltaData || (deltaData && deltaData.length > 0 && deltaData[0].operation !== "DELETE")) {
        let filteredData: RTDTypes.AgentChannelStatus[] = [];
        if (deltaData) {
          filteredData = filteredTelephonyChannelData(deltaData);
        } else {
          filteredData = filteredTelephonyChannelData(agentChannelStatusData);
        }
        if (filteredData && filteredData.length > 0) {
          if (this.isChannelStatusSubscribed && type === SUBSCRIPTION_RESPONSE) {
            this.toggleSubscriptionFlags();
          }
          if (this.isFirstSubscription(type)) {
            //Directly map the value received if the received data is first subscription data
            this.agentDataMap = convertChannelStatusArrayToMap(filteredData, this.tpwConfigs.supervisorAnalyserId);
            this.enrichCMSDataWithRTDData();
            this.setRowData();
            this.isChannelStatusSubscribed = true;
          } else if (
            this.isChannelStatusSubscribed &&
            !this.isAgentGlobalSubscribed &&
            (!this.isContactStatusSubscribed || !this.tpwConfigs.isAIDFixedVariablesEnabled)
          ) {
            //Directly map the value received if the received data is chunked subscription data
            //chunked data is new data so directly appending the data recieved in subscription
            this.agentDataMap = new Map([
              ...this.agentDataMap,
              ...convertChannelStatusArrayToMap(filteredData, this.tpwConfigs.supervisorAnalyserId)
            ]);
            this.enrichCMSDataWithRTDData();
            this.setRowData();
          } else if (this.isAgentGlobalSubscribed || this.isContactStatusSubscribed || type === REALTIME_FEED) {
            if (type === SUBSCRIPTION_RESPONSE) {
              this.isChannelStatusSubscribed = true;
            }

            //updating the existing data id the subscription is not the first subscription or if the type of response is relatimefeed
            this.agentDataMap = updateExistingMapFromChannelStatus(
              filteredData,
              this.agentDataMap,
              this.gridOptions,
              this.tpwConfigs.supervisorAnalyserId,
              this,
              this.isServerSidePaginated
            );
            this.enrichCMSDataWithRTDData();
            this.agentStatusData = Array.from(this.agentDataMap, item => item[1]);
            //updating the selectedAgentDetails to update the contact status change received from channel status when any AID modal is open
            this.updateSelectedAgentDetails();
          }
          if (this.appliedGroups && this.appliedGroups.length > 0) {
            this.updateGroupFilter();
          }
          this.updateDisplayRowCountOnDataUpdate();
        }
      }
    }

    async fetchAllAgents(pageNumber = 0, searchValue = "", sortOrder = "") {
      const allAgentsResponse = await SERVICE.abs.fetchAgentsByOrgId({
        orgId: this.tpwConfigs.orgId,
        page: pageNumber,
        pageSize: NUM_ROWS_IN_RESPONSE,
        searchValue,
        sortOrder
      });

      const totalRecords = allAgentsResponse.meta.totalRecords;
      const fetchedAgents = allAgentsResponse.data;

      if (!this.isServerSidePaginated) {
        this.isServerSidePaginated = totalRecords > NUM_ROWS_IN_RESPONSE;
      }

      if (this.isServerSidePaginated) {
        this.displayedRowCount = totalRecords;
      }

      this.cmsAgentData = [
        ...fetchedAgents.map(agent => ({
          agentId: agent.id,
          agentName: `${agent.firstName} ${agent.lastName}`,
          agentEmail: agent.email
        })),
        ...this.cmsAgentData
      ];

      return convertCmsAgentDataArrayToMap([
        ...fetchedAgents.map(agent => ({
          agentId: agent.id,
          agentName: `${agent.firstName} ${agent.lastName}`,
          agentEmail: agent.email
        }))
      ]);
    }

    async fetchAgentStatus() {
      this.requestTimerId = toJS(
        workerTimers.setTimeout(() => {
          if (this.agentStatusData.length === 0) {
            // Show "No Records" if the data is not received within the timeout.
            this.isRequestTimeout = true;
            this.gridOptions.api.showNoRowsOverlay();
          }
        }, TPW_DATA_WAIT_TIME)
      );

      try {
        // subscribe to contact stats dataset if ESD-aid-fixed-variables FF is on
        if (this.tpwConfigs.isAIDFixedVariablesEnabled) {
          const [globalStatus, channelStatus, contactStats] = await Promise.all([
            this.subscribeGlobalStatusDataSet(),
            this.subscribeAgentChannelStatusDataSet(),
            this.subscribeContactStatsDataSet()
          ]);
          this.globalStatusSubscription = globalStatus;
          this.channelStatusSubscription = channelStatus;
          this.contactStatsSubscription = contactStats;
        } else {
          const [globalStatus, channelStatus] = await Promise.all([
            this.subscribeGlobalStatusDataSet(),
            this.subscribeAgentChannelStatusDataSet()
          ]);
          this.globalStatusSubscription = globalStatus;
          this.channelStatusSubscription = channelStatus;
        }
      } catch (error) {
        logger.error("[App:TPW] Subscription failed", error);
        this.isError = true;
      }
    }

    subscribeGlobalStatusDataSet = async (): Promise<any> => {
      try {
        // Subscribe to wxcc.rtd.globalstatus
        const response = await window.wxcc?.rtdwc.subscribe({
          datasetName: globalStatusDataSet,
          update: (data: any, deltaData: any) => {
            this.setAgentGlobalStatusData(data.datasetValues, data.type, deltaData?.datasetValues);
          }
        });
        logger.info("[App:TPW] subscribed AgentGlobalStatus successfully");
        return response;
      } catch (error) {
        logger.error("[App:TPW] Failed to subscribe AgentGlobalStatus", error);
        throw error;
      }
    };

    subscribeAgentChannelStatusDataSet = async (): Promise<any> => {
      try {
        // Subscribe to wxcc.rtd.agentchannelstatus
        const response = await window.wxcc?.rtdwc.subscribe({
          datasetName: agentChannelStatusDataSet,
          update: (data: any, deltaData: any) => {
            this.setAgentChannelStatusData(data?.datasetValues, data.type, deltaData?.datasetValues);
          }
        });
        logger.info("[App:TPW] subscribed AgentChannelStatus successfully");
        return response;
      } catch (error) {
        logger.error("[App:TPW] Failed to subscribe AgentChannelStatus", error);
        throw error;
      }
    };

    subscribeContactStatsDataSet = async (): Promise<any> => {
      try {
        // Subscribe to wxcc.rtd.contactstatus
        const response = await window.wxcc?.rtdwc.subscribe({
          datasetName: contactStatsDataSet,
          update: (data: any, deltaData: any) => {
            this.setAgentContactStatsData(data?.datasetValues, data.type, deltaData?.datasetValues);
          }
        });
        logger.info("[App:TPW] subscribed ContactStats successfully");
        return response;
      } catch (error) {
        logger.error("[App:TPW] Failed to subscribe ContactStats", error);
        throw error;
      }
    };

    handleStateChangeSelector = (values: any, event: any) => {
      const detail = { selectedAgentId: values.agentId };
      this.updateSelectedAgentId(detail);

      // Close the existing popper instance
      this.closeOverlayContent({ closeStateChange: false });

      this.selectedActionCellValue = values;
      this.stateSelector?.toggleAttribute("data-show", true);
      setTimeout(() => {
        this.stateSelectorInstance = this.createPopperInstance(event, this.stateSelector, "bottom");
        this.stateSelectorOpened = true;
        this.stateSelectorInstance.update();
      }, 0);
    };

    handleAIDModal = (agentId: string) => {
      this.closeOverlayContent();

      this.handleModal({ detail: { modalIsOpen: true } });
      this.selectedAgentId = agentId;
      const detail = { selectedAgentId: agentId };
      this.updateSelectedAgentId(detail);
    };

    handleContextMenu = (values: any, event: any, contextMenuList: any) => {
      this.closeOverlayContent({ closeContextMenu: false });

      this.contextMenuList = contextMenuList;
      this.selectedActionCellValue = values;
      this.contextMenu?.toggleAttribute("data-show", true);
      setTimeout(() => {
        this.contextMenuInstance = this.createPopperInstance(event, this.contextMenu, "bottom");
        this.contextMenuOpened = true;
        this.contextMenuInstance.update();
      }, 0);
    };

    handleWebexChat = (values: any, event: any) => {
      this.closeOverlayContent({ closeWebexSendMessage: false });

      this.selectedActionCellValue = values;
      this.overlayElement?.toggleAttribute("data-show", true);
      setTimeout(() => {
        this.popperInstance = this.createPopperInstance(event, this.overlayElement, "bottom");
        this.webexChatOpened = true;
        this.popperInstance.update();
      }, 0);
    };

    handleWebexChatContext(event: any) {
      this.closeOverlayContent({ closeContextMenu: false });

      this.overlayElement?.toggleAttribute("data-show", true);
      setTimeout(() => {
        this.popperInstance = this.createPopperInstance(event.detail.event, this.overlayElement, "left-start");
        this.webexChatOpened = true;
        this.popperInstance.update();
      }, 0);
    }

    handleStateChangeContext(event: any) {
      const detail = { selectedAgentId: event.detail.agentId };
      this.updateSelectedAgentId(detail);
      this.closeOverlayContent({ closeContextMenu: false });
      this.stateSelector?.toggleAttribute("data-show", true);
      setTimeout(() => {
        this.popperInstance = this.createPopperInstance(event.detail.event, this.stateSelector, "left-start");
        this.stateSelectorOpened = true;
        this.popperInstance.update();
      }, 0);
    }

    closeOverlayContent(
      config: { closeWebexSendMessage?: boolean; closeContextMenu?: boolean; closeStateChange?: boolean } = {
        closeWebexSendMessage: true,
        closeContextMenu: true,
        closeStateChange: true
      }
    ) {
      if (!Object.prototype.hasOwnProperty.call(config, "closeWebexSendMessage") || config.closeWebexSendMessage) {
        this.closeWebExChat();
      }

      if (!Object.prototype.hasOwnProperty.call(config, "closeContextMenu") || config.closeContextMenu) {
        this.closeContextMenu();
      }

      if (!Object.prototype.hasOwnProperty.call(config, "closeStateChange") || config.closeStateChange) {
        this.closeStateChangeOverlay();
      }
    }

    getGridConfigs = () => {
      return {
        webexEnabled: this.tpwConfigs.webexEnabled,
        isDarkMode: this.tpwConfigs.isDarkMode,
        isMCMEnabled: this.tpwConfigs.isMCMEnabled,
        isError: this.isError,
        isAIDEnabled: this.tpwConfigs.isAIDFixedVariablesEnabled,
        supervisorId: this.tpwConfigs.supervisorId,
        isStateChangeBySupervisorEnabled: this.tpwConfigs.isStateChangeBySupervisorEnabled,
        onActiveCall: this.tpwConfigs.onActiveCall,
        isSupervisorySendMessageEnabled: this.tpwConfigs.isSupervisorySendMessageEnabled,
        actionCellLocalizationValues: this.actionCellLocalizationValues(),
        actionCellWidth: this.actionCellWidth,
        isBargeInEnabled: this.tpwConfigs.isBargeInEnabled,
        voiceChannelCount: this.tpwConfigs.voiceChannelCount,
        isMPCorPersistEnabled: this.tpwConfigs.isMPCorPersistEnabled,
        handleAIDModal: this.handleAIDModal,
        handleStateChangeSelector: this.handleStateChangeSelector,
        handleContextMenu: this.handleContextMenu,
        handleWebexChat: this.handleWebexChat
      };
    };
    actionCellLocalizationValues = () => {
      return {
        monitoring: t("app:tpw.monitoring"),
        ownMonitoring: t("app:tpw.ownMonitoring"),
        supervisorMonitoring: t("app:tpw.supervisorMonitoring"),
        beingMonitored: t("app:tpw.beingMonitored"),
        cannotMonitorConsult: t("app:tpw.cannotMonitorConsult"),
        reviewAndMonitor: t("app:tpw.reviewAndMonitor"),
        review: t("app:tpw.review"),
        webexChatSendMessage: t("app:notifications.webexChatSendMessage"),
        webexChatSendingMessage: t("app:notifications.webexChatSendingMessage"),
        webexChatTPWErrorMessage: t("app:notifications.webexChatTPWErrorMessage"),
        moreAction: t("app:notes:moreActions"),
        stateChange: {
          default: t("app:tpw.stateChange.default"),
          progress: t("app:tpw.stateChange.progress"),
          failed: t("app:tpw.stateChange.failed")
        },
        alreadyBargedIn: t("app:tpw.alreadyBargedIn"),
        supervisorBargedIn: t("app:tpw.supervisorBargedIn"),
        cannotMonitorBargedcall: t("app:tpw.cannotMonitorBargedCall"),
        bargedIn: t("app:tpw.bargedIn"),
        notHaveChannels: t("app:tpw.notHaveChannels"),
        cannotMonitorWrapUp: t("app:tpw.cannotMonitorWrapUp"),
        cannotMonitorWrapUpAssistance: t("app:tpw.cannotMonitorWrapUpAssistance")
      };
    };

    createPopperInstance(event: Element, referenceEvent: Element, placement: Placement = "auto") {
      return createPopper(event, referenceEvent, {
        placement,
        modifiers: [
          ...defaultModifiers,
          flip,
          offset,
          preventOverflow,
          arrow,
          {
            name: "preventOverflow",
            options: {
              padding: 16
            }
          },
          {
            name: "offset",
            options: {
              offset: (({ placement, reference }: any) => {
                if (placement === "left-end" || placement === "right-end") {
                  return [reference.height + reference.y + 3, 14];
                } else if (placement === "left") {
                  return [100, 15];
                } else {
                  return [0, 5];
                }
              }) as any
            }
          },
          {
            name: "computeStyles",
            options: {
              adaptive: false // this will recompute popper position
            }
          }
        ]
      });
    }

    showFailedToLoadDataEmptyGrid = () => {
      this.gridOptions.api.hideOverlay();
      this.gridOptions.api.showNoRowsOverlay();
      this.emptyGrid.setAttribute("is-error", "1");
    };

    protected async updated(changedProperties: PropertyValues) {
      super.updated(changedProperties);
      if (this.isError) {
        workerTimers.setTimeout(() => {
          // showing row count 0 if error occurs in any subscription
          this.displayedRowCount = 0;
          this.showFailedToLoadDataEmptyGrid();
        }, 50);
      }
      const previousTPWConfigValue: any = changedProperties.get("tpwConfigs");
      if (this.filters && (changedProperties.has("appliedFilters") || changedProperties.has("appliedGroups"))) {
        setTimeout(() => {
          this.style.setProperty("--tpw-filter-height", `${this.filters.getBoundingClientRect()?.height || 0}px`);
        }, 50);
      }

      if (
        changedProperties.has("isError") ||
        changedProperties.has("isRequestTimeout") ||
        changedProperties.has("agentStatusData")
      ) {
        this.handleAccessibility();
      }

      const isStateChangeFeatureFlagUpdated = () => {
        return (
          previousTPWConfigValue["isStateChangeBySupervisorEnabled"] !==
          this.tpwConfigs.isStateChangeBySupervisorEnabled
        );
      };

      if (
        changedProperties.has("tpwConfigs") &&
        previousTPWConfigValue &&
        (previousTPWConfigValue["isDarkMode"] !== this.tpwConfigs.isDarkMode ||
          previousTPWConfigValue["onActiveCall"] !== this.tpwConfigs.onActiveCall ||
          isStateChangeFeatureFlagUpdated())
      ) {
        this.gridOptions.api?.refreshCells({ force: true, columns: ["actions"] });

        // If feature flag values are changed, Validate the context menu visibility
        if (isStateChangeFeatureFlagUpdated() && this.contextMenuOpened && this.selectedActionCellValue.agentId) {
          // Context menu is already opened while updating the FF value. So Update the context menu values.
          this.updateContextMenuValuesTimeout = setTimeout(() => this.updateContextMenuValues(), TIMEOUT_THOUSAND_MS);
        }
      }
      changedProperties.forEach((oldValue: any, name: any) => {
        if (name === "gridId" && oldValue === undefined && this.tpwConfigs.isStationLoginSuccess) {
          //checking for station login to be success in order ot load TPW table
          this.initializeGrid();
        }
      });
    }
    updateContextMenuValues() {
      // Check, Is context menu is required after FF Changes.
      const moreActionsElement: HTMLElement | undefined | null = this.shadowRoot?.querySelector(
        `.more-action-${this.selectedActionCellValue?.agentId}`
      );
      if (moreActionsElement) {
        // Update the values on existing opened context menu.
        moreActionsElement?.click();
      } else {
        // Context Menu is not required to display, Remove the Context menu and it's overlay contents.
        this.closeOverlayContent();
      }
    }
    // This method will be helpful to Screen Reader to read the "No Records" text.
    handleAccessibility() {
      const overlay = this.shadowRoot?.querySelector(".ag-overlay");
      if (this.isError || this.isRequestTimeout || this.agentStatusData.length === 0) {
        // error, no records
        overlay?.removeAttribute("aria-hidden");
      } else {
        // If records are exist
        overlay?.setAttribute("aria-hidden", "true");
      }
    }

    loadTPWScript() {
      const agGridLink = document.createElement("link");
      const agThemeAlpineLink = document.createElement("link");

      const momentumIcons = document.createElement("link");
      momentumIcons.setAttribute("rel", "stylesheet");

      const momentumCore = document.createElement("link");
      momentumCore.setAttribute("rel", "stylesheet");

      agGridLink.setAttribute("rel", "stylesheet");
      agThemeAlpineLink.setAttribute("rel", "stylesheet");

      agGridLink.setAttribute("href", "https://cdn.jsdelivr.net/npm/ag-grid-enterprise@30.0.0/styles/ag-grid.css");
      agThemeAlpineLink.setAttribute(
        "href",
        "https://cdn.jsdelivr.net/npm/ag-grid-enterprise@30.0.0/styles/ag-theme-alpine.css"
      );

      momentumIcons.setAttribute("href", "/css/momentum-ui-icons.min.css");
      momentumCore.setAttribute("href", "/css/momentum-ui.min.css");

      const rootElement = this.shadowRoot?.querySelector(".tpw-wrapper");
      rootElement?.appendChild(agGridLink);
      rootElement?.appendChild(agThemeAlpineLink);
      rootElement?.prepend(momentumIcons);
      rootElement?.prepend(momentumCore);

      agGridLink.onload = () => {
        this.agGridCss = true;
      };
      agThemeAlpineLink.onload = () => {
        this.agThemeAlpine = true;
      };
    }

    protected async firstUpdated(changedProperties: PropertyValues) {
      super.firstUpdated(changedProperties);
      this.loadTPWScript();
      this.gridId = this.tpwConfigs.gridId;
      LicenseManager.setLicenseKey(CONF.AG_GRID_LICENSE_KEY ?? "");
    }
    addGroupToState(status: boolean, node: { field: string; key: string }) {
      if (status) {
        this.expandedGroupState.push(`${node.field}:${node.key}`);
      } else {
        const index = this.expandedGroupState.indexOf(`${node.field}:${node.key}`);
        this.expandedGroupState?.splice(index, 1);
      }
      this.saveGridConfigs();
    }

    initializeGrid() {
      const setGridOptions = (fetchedAgents: Map<string, RTDTypes.AgentDetails> = new Map()) => {
        this.gridOptions = {
          ...gridConfig(this.getGridConfigs, this.isServerSidePaginated),
          debounceVerticalScrollbar: true,
          suppressAnimationFrame: true,
          enableBrowserTooltips: true,
          tooltipShowDelay: { tooltipShowDelay: 2 },
          rowData: this.agentStatusData.length === 0 ? null : this.agentStatusData,
          onGridReady: () => this.onGridReady(fetchedAgents),
          onDisplayedColumnsChanged: this.saveGridConfigs,
          onColumnResized: debounce(this.saveGridConfigs, 300),
          onSortChanged: this.onSortChanged,
          getRowId: (params: GetRowIdParams) => params.data.agentId,
          onRowGroupOpened: (params: { node: { expanded: boolean; field: string; key: string } }) =>
            this.addGroupToState(params.node.expanded, params.node),
          isGroupOpenByDefault: (params: { field: string; key: string }) =>
            this.expandedGroupState?.includes(`${params.field}:${params.key}`),
          rowHeight: 40,
          suppressRowGroupHidesColumns: true,
          ...(this.isServerSidePaginated && {
            rowModelType: "serverSide",
            suppressServerSideInfiniteScroll: false,
            cacheBlockSize: NUM_ROWS_IN_RESPONSE,
            maxBlocksInCache: 1,
            animateRows: true,
            maxConcurrentDatasourceRequests: 1
          })
        };

        if (!this.tpwConfigs.isWxccAgentInteractionEnabled) {
          this.gridOptions.columnDefs = this.gridOptions.columnDefs.filter(
            (item: { field: string }) => item.field !== "agentInteractionTime"
          );
        }
        new Grid(this.eGridDiv, this.gridOptions);
      };

      if (this.tpwConfigs.isShowSignedOutAgentsEnabled) {
        this.fetchAllAgents()
          .then(fetchedAgents => {
            setGridOptions(fetchedAgents);
          })
          .catch(error => {
            logger.error("[App:TPW]: Failed to fetch agents from CMS on initialize grid:", error.message);
            setGridOptions();
          });
      } else {
        setGridOptions();
      }
    }
    handleModal = (e: any) => {
      this.isModalOpen = e.detail?.modalIsOpen;
      if (this.isModalOpen) {
        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.AID_OPENED, {
          SupervisorId: this.tpwConfigs.supervisorId,
          Role: this.tpwConfigs.role
        });
      }
    };

    onResize = (entries: any) => {
      if (Array.isArray(entries)) {
        const width = entries[0]?.contentRect?.width;
        this.actionCellWidth = width;
        this.gridOptions.api?.refreshCells({ force: true, columns: ["actions"] });
      }
    };

    addActionColumnResizeObserver = () => {
      const actionCell = this.shadowRoot?.querySelector('[col-id="actions"]');
      if (actionCell && window.ResizeObserver) {
        this.resizeObserver = new window.ResizeObserver(this.onResize);
        this.resizeObserver.observe(actionCell);
      }
    };

    disconnectResizeObserver = () => {
      if (this.resizeObserver && this.resizeObserver?.disconnect) {
        this.resizeObserver?.disconnect();
      }
    };

    columnPositionChangesHeader = (event: ColumnPinnedEvent) => {
      // This function will called when column pinned position changes, also while removing the column
      const { column } = event;
      if (column) {
        const columnId = column.getColId();
        if (columnId === "actions") {
          // Disconnect the existing resize observer.
          this.disconnectResizeObserver();
          // Add the resize observer for column pinner position changes.
          this.addActionColumnResizeObserver();
        }
      }
    };

    onGridReady = (fetchedAgents: Map<string, RTDTypes.AgentDetails> = new Map()) => {
      const storedGridConfigs = JSON.parse(localStorage.getItem(this.tpwConfigs.gridId) || "{}");
      if (Object.keys(storedGridConfigs).length) {
        this.expandedGroupState = storedGridConfigs.rowGroupState || [];
        if (this.tpwConfigs.isWxccAgentInteractionEnabled) {
          const defaultState = this.gridOptions.columnApi
            .getColumnState()
            .filter((item: { colId: string }) => item.colId !== "agentInteractionTime");

          if (!_.isEqual(storedGridConfigs.columnState, defaultState)) {
            this.gridOptions.columnApi?.applyColumnState({
              state: storedGridConfigs.columnState,
              applyOrder: true
            });
          }
        } else {
          this.gridOptions.columnApi?.applyColumnState({
            state: storedGridConfigs.columnState,
            applyOrder: true
          });
        }
        this.updateFilterModel(storedGridConfigs.filterModel);
      }

      this.addActionColumnResizeObserver();

      this.gridOptions.api?.showLoadingOverlay();
      this.isGridRendered = true;
      this.fetchAgentStatus();
      if (this.tpwConfigs.isShowSignedOutAgentsEnabled) {
        if (this.isServerSidePaginated) {
          this.gridOptions.api?.setServerSideDatasource(this.createDatasource());
        } else {
          this.agentDataMap = fetchedAgents;
          this.setRowData();
        }
      }
      this.getDisplayedRowCount();
      this.requestUpdate();
      this.handleAccessibility();

      this.gridOptions.api.addEventListener("columnPinned", this.columnPositionChangesHeader);
    };

    enrichCMSDataWithRTDData(fetchedAgents?: Map<string, RTDTypes.AgentDetails>) {
      if (this.tpwConfigs.isShowSignedOutAgentsEnabled) {
        if (this.isServerSidePaginated && fetchedAgents) {
          this.agentDataMap.forEach(agent => {
            const agentId = agent.agentId;
            if (fetchedAgents.has(agentId)) {
              const existingAgentData = this.agentDataMap.get(agentId);
              const mergedAgentData = { ...existingAgentData, ...agent };
              fetchedAgents.set(agentId, mergedAgentData);
            }
          });
          return fetchedAgents;
        } else {
          this.agentDataMap = new Map([...convertCmsAgentDataArrayToMap(this.cmsAgentData), ...this.agentDataMap]);
        }
      }
      return new Map();
    }

    createDatasource = (): { getRows: (params: IServerSideGetRowsParams) => Promise<void> } => {
      const getPage = (startRow: number | undefined = 1): number => {
        if (startRow && startRow !== 0) {
          return Math.ceil(startRow / NUM_ROWS_IN_RESPONSE);
        } else {
          return startRow;
        }
      };
      const getSortOrder = (sortOrder: any): string => {
        const order = sortOrder.find((order: any) => order.colId === "agentNameDetails");
        if (order) {
          return order.sort;
        }
        return "asc"; //default
      };

      const fetchData = async (params: IServerSideGetRowsParams): Promise<void> => {
        const pageNumber = getPage(params.request.startRow);
        const searchValue = params.request.filterModel?.agentNameDetails?.filter;
        const sortOrder = getSortOrder(params.request.sortModel);
        const fetchedAgents = await this.fetchAllAgents(pageNumber, searchValue, sortOrder);
        const enrichedAgents = this.enrichCMSDataWithRTDData(fetchedAgents);

        const lastRow =
          enrichedAgents.size < NUM_ROWS_IN_RESPONSE ? pageNumber * NUM_ROWS_IN_RESPONSE + enrichedAgents.size : -1;

        params.success({ rowData: Array.from(enrichedAgents.values()), rowCount: lastRow });
      };

      return {
        getRows: async (params: IServerSideGetRowsParams): Promise<void> => {
          try {
            await fetchData(params);
          } catch (err) {
            logger.error(`Failed to fetch data paginated data. Error: ${err}`);
            params.fail();
          }
        }
      };
    };

    onSortChanged = () => {
      this.saveGridConfigs();

      const columnState = this.gridOptions?.columnApi?.getColumnState();
      if (columnState) {
        const sortedColumns = columnState.filter((column: any) => column.sort != null);
        sortedColumns.forEach((column: any) => {
          SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.TPW_COLUMN_SORTED, {
            ColumnName: column.colId,
            ColumnSortOrder: column.sort
          });
        });
      }
    };

    saveGridConfigs = () => {
      const columnState = this.gridOptions.columnApi?.getColumnState();
      if (columnState) {
        const groupedRow = columnState.filter((a: { rowGroup: boolean }) => a.rowGroup);
        this.appliedGroups = groupedRow;
      }

      if (this.isGridRendered) {
        localStorage.setItem(
          this.tpwConfigs.gridId,
          JSON.stringify({
            filterModel: this.appliedFilters,
            columnState,
            rowGroupState: this.expandedGroupState
          })
        );
      }
    };
    updateSelectedAgentDetails() {
      this.selectedAgentDetails =
        this.agentStatusData.find(agentData => agentData.agentId === this.selectedAgentId) ??
        ({} as RTDTypes.AgentDetails);
    }

    updateSelectedAgentId = (detail: any) => {
      if (detail.selectedAgentId && this.agentStatusData && this.agentStatusData.length > 0) {
        this.selectedAgentId = detail.selectedAgentId;
        this.updateSelectedAgentDetails();
      }
    };

    handleOutSideClick = (event: any) => {
      let insideMenuClick = false;
      const path = event.composedPath();
      if (path.length) {
        insideMenuClick = !!path.find(
          (element: any) =>
            element === this.overlayElement || element === this.contextMenu || element === this.stateSelector
        );
        if (!insideMenuClick || event.code === "Escape") {
          if (event.type === "keydown" && event.code === "Escape") {
            if (this.contextMenuOpened) {
              this.returnFocusToMoreActionIcon();
            } else if (this.webexChatOpened) {
              this.returnFocusToChatIcon();
            } else if (this.stateSelectorOpened) {
              this.returnFocusToChangeStateIcon();
            } else if (this.isModalOpen) {
              this.handleFocusOnCloseOfReviewAndMonitor();
            }
          }
          this.closeOverlayContent();
        }
      }
    };

    handleFocusOnEscape = (e: any) => {
      const headerElement: HTMLElement | null = this.eGridDiv.querySelector(`.ag-header-cell[col-id="${e.detail}"]`);
      if (headerElement) {
        headerElement.focus();
      }
    };

    handleTPWMonitorModal = (e: any) => {
      this.handleModal(e);
      this.handleFocusOnCloseOfReviewAndMonitor();
    };

    async connectedCallback() {
      super.connectedCallback();
      window.addEventListener(TPW_MONITOR_MODAL, this.handleTPWMonitorModal);

      window.addEventListener(TPW_FILTER_MODAL_UPDATED, this.saveGridConfigs);
      document.addEventListener("click", this.handleOutSideClick);
      document.addEventListener("keydown", this.handleOutSideClick);
      document.addEventListener(COLUMN_MENU_FOCUS, this.handleFocusOnEscape);
      if (!this.tpwConfigs.webexEventRegistered && this.tpwConfigs.isSupervisorySendMessageEnabled) {
        SERVICE?.webex
          ?.registerWebex(this.tpwConfigs.isEnvironmentProd)
          .then(() => {
            logger.info("[App:TPW] registering webex is success");
          })
          .catch(error => {
            logger.error("[App:TPW] Error in registering webex", error);
          });
      }
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      if (this.contactStatsSubscription?.unsubscribe) {
        this.contactStatsSubscription.unsubscribe();
      }
      if (this.channelStatusSubscription?.unsubscribe) {
        this.channelStatusSubscription.unsubscribe();
      }
      if (this.globalStatusSubscription?.unsubscribe) {
        this.globalStatusSubscription.unsubscribe();
      }
      workerTimers.clearTimeout(this.requestTimerId);
      workerTimers.clearTimeout(this.delayId);
      window.removeEventListener(TPW_MONITOR_MODAL, this.handleTPWMonitorModal);
      window.removeEventListener(TPW_FILTER_MODAL_UPDATED, this.saveGridConfigs);
      document.removeEventListener("click", this.handleOutSideClick);
      document.removeEventListener("keydown", this.handleOutSideClick);
      document.removeEventListener(COLUMN_MENU_FOCUS, this.handleFocusOnEscape);

      this.agentDataMap = new Map();
      this.agentStatusData = [];
      this.tpwConfigs = {} as TPWConfig;

      // Disconnect the existing resize observer.
      this.disconnectResizeObserver();

      if (this.updateContextMenuValuesTimeout) {
        clearTimeout(this.updateContextMenuValuesTimeout);
      }
    }

    updateFilterModel(filterModel: TPW.FilterModel) {
      this.appliedFilters = filterModel;
      this.gridOptions.api?.setFilterModel(filterModel);
    }

    setUpdatedFilter = (event: CustomEvent, fromRenderer?: boolean) => {
      event.stopPropagation();
      let updatedFilter = {};
      if (fromRenderer) {
        updatedFilter = {
          ...this.appliedFilters,
          [event.detail.field]: checkIfFilterModelIsEmpty(event.detail.data)
            ? {}
            : { ...this.appliedFilters[event.detail.field], ...event.detail.data }
        };
      } else {
        updatedFilter = event.detail.clearAllFilter
          ? initialFilterModel
          : {
              ...this.appliedFilters,
              [event.detail.field]: event.detail.data
            };
      }
      this.updateFilterModel(updatedFilter);
      this.getDisplayedRowCount();
      this.saveGridConfigs();
      this.requestUpdate();
    };
    updateGroupState = (rowGroupCol: Array<string>) => {
      this.expandedGroupState = this.expandedGroupState.filter((item: string) => {
        return rowGroupCol.some((col: string) => {
          return item.includes(col);
        });
      });
    };

    updateGroupStateAfterData = (rowGroupCol: Array<string>) => {
      let groups: Array<string> = [];
      rowGroupCol.forEach((col: string) => {
        groups = [
          ...groups,
          ...new Set(
            this.agentStatusData.map((item: any) => {
              if (col === "agentNameDetails") {
                return `${col}:${item[col].agentName}`;
              }
              return `${col}:${item[col]}`;
            })
          )
        ];
      });
      this.expandedGroupState = this.expandedGroupState.filter((groupState: string) => {
        return groups.includes(groupState);
      });
    };

    setUpdatedGroupFilter = (event: CustomEvent) => {
      event.stopPropagation();
      const { clearAllFilter, field } = event.detail;
      let updatedFilter: string[];
      if (clearAllFilter) {
        updatedFilter = [];
        this.appliedGroups = [];
      } else {
        const updatedGroupTemp = this.appliedGroups.filter((a: { colId: string }) => a.colId !== field);
        updatedFilter = updatedGroupTemp.map((a: { colId: string }) => a.colId);
        this.appliedGroups = updatedGroupTemp;
      }
      this.gridOptions.columnApi?.setRowGroupColumns(updatedFilter);
      this.updateGroupState(updatedFilter);
      this.getDisplayedRowCount();
      this.saveGridConfigs();
      this.requestUpdate();
    };

    updateGroupFilter = () => {
      const updatedFilter = this.appliedGroups.map((a: { colId: string }) => a.colId);
      this.updateGroupStateAfterData(updatedFilter);
    };

    private resetColumnState() {
      this.gridOptions.columnApi.resetColumnState();

      // When resetting the columns positions, Disconnect the existing resize observer.
      this.disconnectResizeObserver();
      // Add the resize observer for column pinner position changes.
      this.addActionColumnResizeObserver();
    }

    private getDisplayedRowCount() {
      if (this.isServerSidePaginated) {
        return;
      }

      let filteredRowCount = 0;
      this.gridOptions.api?.forEachNodeAfterFilter((rowNode: RowNode) => {
        if (rowNode.data) {
          filteredRowCount++;
        }
      });
      this.displayedRowCount = filteredRowCount;
    }

    closeContextMenu = () => {
      this.contextMenu?.toggleAttribute("data-show", false);
      this.contextMenuInstance?.destroy();
      this.contextMenuOpened = false;
      setTimeout(() => {
        this.contextMenuInstance = null;
      }, 0);
    };

    closeWebExChat = () => {
      this.overlayElement?.toggleAttribute("data-show", false);
      this.popperInstance?.destroy();
      this.webexChatOpened = false;
      setTimeout(() => {
        this.popperInstance = null;
      }, 0);
    };

    closeStateChangeOverlay = () => {
      this.stateSelector?.toggleAttribute("data-show", false);
      this.stateSelectorInstance?.destroy();
      this.stateSelectorOpened = false;
      setTimeout(() => {
        this.stateSelectorInstance = null;
      }, 0);
    };

    returnFocusToChatIcon() {
      // Focus back to the Chat Icon
      if (this.contextMenuOpened) {
        // Context Menu is opened.
        // Focus to the list
        if (this.contextMenu) {
          const contextMenuElement = this.contextMenu
            ?.querySelector("agentx-wc-tpw-context-menu")
            ?.shadowRoot?.querySelector(".webex-send-message");

          if (contextMenuElement) {
            (contextMenuElement as any)?.focus();
          }
        }
      } else {
        // Focus to the chat icon inside action cell.
        const webexButtonElement: any = this.shadowRoot?.querySelector(
          `.agentx-webex-chat-${this.selectedActionCellValue?.agentId}`
        );
        if (webexButtonElement) {
          webexButtonElement?.focus();
        }
      }
    }

    returnFocusToMoreActionIcon() {
      const moreActionsButtonElement: any = this.shadowRoot?.querySelector(
        `.more-action-${this.selectedActionCellValue?.agentId}`
      );
      if (moreActionsButtonElement) {
        moreActionsButtonElement?.focus();
      }
    }

    returnFocusToChangeStateIcon() {
      const stateChangeButtonElement: any = this.shadowRoot?.querySelector(
        `.state-change-${this.selectedActionCellValue?.agentId}`
      );
      if (stateChangeButtonElement) {
        stateChangeButtonElement?.focus();
      }
    }

    handleFocusOnCloseOfReviewAndMonitor() {
      const reviewAndMonitorButton: any = this.shadowRoot?.querySelector(`.review-monitor-${this.selectedAgentId}`);
      if (reviewAndMonitorButton) {
        reviewAndMonitorButton?.focus();
      } else {
        this.returnFocusToMoreActionIcon();
      }
    }

    updateActionCellValue = (params: CustomEvent, focusComponent: string) => {
      const rowNode = this.gridOptions?.api?.getRowNode(params.detail?.agentId);

      rowNode.setDataValue("actions", { ...rowNode.data.actions, ...params?.detail });
      if (this.selectedActionCellValue?.agentId === params.detail?.agentId) {
        this.selectedActionCellValue = { ...this.selectedActionCellValue, ...params?.detail };
        if (focusComponent === "send-message") {
          // Focus to webex send message icon
          this.returnFocusToChatIcon();
        } else {
          // Focus to agent state change icon
          this.returnFocusToChangeStateIcon();
        }
      }
      // Update Action Cell value to internal properties
      if (this.agentDataMap) {
        const currentValue = this.agentDataMap.get(params.detail?.agentId);
        if (currentValue && currentValue.actions) {
          // Update only action cell values.
          const mapValue = { ...currentValue, actions: { ...currentValue.actions, ...params?.detail } };
          this.agentDataMap.set(params.detail?.agentId, mapValue);
        }
      }
    };

    render() {
      return html`
        <div class="tpw-wrapper">
          <agentx-wc-tpw-grid-header
            .gridOptions=${this.gridOptions}
            .displayedRowCount=${this.displayedRowCount}
            .isServerSidePaginated=${this.isServerSidePaginated}
            @tpw-search-updated=${() => this.getDisplayedRowCount()}
            @tpw-reset-table=${(event: CustomEvent) => {
              this.expandedGroupState = [];
              this.setUpdatedFilter(event);
              this.resetColumnState();
            }}
          ></agentx-wc-tpw-grid-header>

          <agentx-wc-tpw-applied-filters
            id="filters"
            .appliedFilters=${this.appliedFilters}
            .appliedGroups=${this.appliedGroups}
            .gridOptions=${this.gridOptions}
            @tpw-group-filter-updated=${(event: CustomEvent) => this.setUpdatedGroupFilter(event)}
            @tpw-filter-updated=${(event: CustomEvent) => this.setUpdatedFilter(event)}
          ></agentx-wc-tpw-applied-filters>
          <div
            class=${`grid-container ${this.isGridRendered ? "" : "grid-rendering"} ${
              this.tpwConfigs.isDarkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"
            }`}
            id="agentx-wc-tpw-grid-container"
            @tpw-filter-updated=${(event: CustomEvent) => this.setUpdatedFilter(event, true)}
          ></div>
          <agentx-wc-aid
            @tpw-monitor-modal=${(e: any) => {
              this.handleModal(e);
              this.handleFocusOnCloseOfReviewAndMonitor();
            }}
            .selectedAgentDetails=${this.selectedAgentDetails}
            .isModalOpen=${this.isModalOpen}
            .isAIDFixedVariablesEnabled=${this.tpwConfigs.isAIDFixedVariablesEnabled}
            .isMCMEnabled=${this.tpwConfigs.isMCMEnabled}
            .role=${this.tpwConfigs.role}
            .isBargeInEnabled=${this.tpwConfigs.isBargeInEnabled}
            .monitoringInProgress=${this.monitoringInProgress}
            .isMPCorPersistEnabled=${this.tpwConfigs.isMPCorPersistEnabled}
            .isWxccAgentInteractionEnabled=${this.tpwConfigs.isWxccAgentInteractionEnabled}
          ></agentx-wc-aid>

          <div class="tpw-chat-wrapper custom-menu-overlay overlay-container" role="dialog">
            <div class="overlay-content" part="overlay-content">
              <webex-chat
                .isOpen="${this.webexChatOpened}"
                name="${this.selectedActionCellValue?.agentName}"
                agentId="${this.selectedActionCellValue?.agentId}"
                agentEmail="${this.selectedActionCellValue?.agentEmail}"
                @webexchat-overlay-close="${() => {
                  this.closeWebExChat();
                  this.returnFocusToChatIcon();
                }}"
                @update-actions-cell-value="${(event: CustomEvent) =>
                  this.updateActionCellValue(event, "send-message")}"
              ></webex-chat>
            </div>
          </div>

          <div class="context-menu-wrapper custom-menu-overlay overlay-container" role="dialog">
            <agentx-wc-tpw-context-menu
              .isOpen="${this.contextMenuOpened}"
              .contextMenuList="${this.contextMenuList}"
              agentId="${this.selectedActionCellValue?.agentId}"
              .actionCellConfigs="${this.selectedActionCellValue}"
              .isMCMEnabled=${this.tpwConfigs.isMCMEnabled}
              .webexEnabled=${this.tpwConfigs.webexEnabled}
              .isBargeInEnabled=${this.tpwConfigs.isBargeInEnabled}
              .isMPCorPersistEnabled=${this.tpwConfigs.isMPCorPersistEnabled}
              .isSupervisorySendMessageEnabled=${this.tpwConfigs.isSupervisorySendMessageEnabled}
              @handle-webex-chat=${this.handleWebexChatContext}
              @handle-state-change="${this.handleStateChangeContext}"
              @handle-aid-modal=${(event: CustomEvent) => this.handleAIDModal(event.detail.agentId)}
            ></agentx-wc-tpw-context-menu>
          </div>

          ${this.tpwConfigs.isStateChangeBySupervisorEnabled
            ? html`
                <div class="agent-state-selector custom-menu-overlay overlay-container" role="dialog">
                  <div class="overlay-content" part="overlay-content">
                    <agentx-wc-tpw-agent-state-selector
                      .orgId="${this.tpwConfigs.orgId}"
                      .selectedAgentDetails=${this.selectedAgentDetails}
                      .orgIdleCodes="${this.tpwConfigs.idleCodes}"
                      .isOpen="${this.stateSelectorOpened}"
                      agentId="${this.selectedActionCellValue?.agentId}"
                      @update-actions-cell-value="${(event: CustomEvent) =>
                        this.updateActionCellValue(event, "state-change")}"
                      @state-change-overlay-close="${() => {
                        this.closeStateChangeOverlay();
                        this.returnFocusToChangeStateIcon();
                      }}"
                    ></agentx-wc-tpw-agent-state-selector>
                  </div>
                </div>
              `
            : nothing}
        </div>
      `;
    }
  }
}
