import { i18nMixin, t } from "@/mixins/i18nMixin";
import { logger } from "@/sdk";
import "@momentum-ui/web-components";
import { customElement, html, LitElement, property } from "lit-element";
import style from "./IframeLogin.scss";
import iframeLogo from "@img/Cisco_Webex_Logo.svg";

export namespace IframeLogin {
  /**
   * @element agentx-wc-iframe-login
   * @fires request-iframe-login
   */
  @customElement("agentx-wc-iframe-login")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Boolean }) isLoading = false;

    static get styles() {
      return style;
    }

    private login() {
      this.dispatchEvent(new CustomEvent("request-iframe-login", { bubbles: true, composed: true }));
      logger.info("[IframeLogin]: request-iframe-login");
    }

    render() {
      return html`
        <div class="login-container">
          <div class="login-wrapper">
            <div class="ci-login-logo">
              <img src=${iframeLogo} alt="${t("app:common.webex")}" />
            </div>
            <div class="signin-button">
              ${this.isLoading
                ? html`
                    <md-spinner></md-spinner>
                  `
                : html`
                    <md-button ariaLabel="${t("app:iframeLogin.signIn")}" variant="primary" @click="${this.login}"
                      ><div class="signin-div">
                        <span> ${t("app:iframeLogin.signIn")} </span>
                      </div>
                    </md-button>
                  `}
            </div>
            <div class="user-message">
              ${this.isLoading ? t("app:iframeLogin.signingIn") : t("app:iframeLogin.userMessage")}
            </div>
          </div>
        </div>
      `;
    }
  }
}
