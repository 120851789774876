import { i18nMixin, t } from "@/mixins/i18nMixin";
import "@momentum-ui/web-components";
import { customElement, html, internalProperty, LitElement, property, query } from "lit-element";
import { nothing } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import style from "./WidgetError.scss";

export namespace WidgetError {
  export type Detail = {
    header: string;
    message: string;
    trackingId?: string;
  };

  @customElement("agentx-wc-widget-error")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Boolean }) show = false;
    @property({ type: Object }) detail!: Detail;
    @property({ type: Boolean }) hideDetails = false;
    @internalProperty() isCopied = false;

    @query(".trackingid-input") trackID!: HTMLElement;

    retry() {
      this.dispatchEvent(
        new CustomEvent("reload", {
          composed: true,
          bubbles: true
        })
      );
    }

    copyTrackingID() {
      const text = this.detail?.trackingId;
      const inputTemp: HTMLInputElement | undefined = document.createElement("input");
      if (inputTemp && text) {
        inputTemp.value = text;
        document.body.appendChild(inputTemp);
        inputTemp.select();
        document.execCommand("copy");
        document.body.removeChild(inputTemp);

        this.isCopied = true;
        setTimeout(() => {
          this.isCopied = false;
        }, 1500);
      }
    }

    showMore() {
      this.show = !this.show;
    }

    get widgetErrorClassMap() {
      return {
        visible: this.show
      };
    }

    static get styles() {
      return style;
    }

    render() {
      return html`
        <div class="widget-error">
          <div class="widget-error-content">
            <div class="widget-error-icon">
              <md-icon name="icon-warning_32"></md-icon>
            </div>
            <strong class="widget-error-heading">${this.detail?.header || t("app:commonWebComp.loadFail")}</strong>
            ${!this.hideDetails
              ? html`
                  <md-button ariaLabel="${t("app:commonWebComp.tryAgain")}" @click=${this.retry} variant="primary"
                    >${t("app:commonWebComp.tryAgain")}</md-button
                  >
                  <div class="widget-error-detail">
                    <span class="widget-error-toggle" @click="${this.showMore}" part="widget-error-btn">
                      ${this.show
                        ? html`
                            ${t("app:errorDetail.errorLessButton")}
                          `
                        : html`
                            ${t("app:errorDetail.errorMoreButton")}
                          `}
                    </span>
                    <div class="widget-error-info ${classMap(this.widgetErrorClassMap)}">
                      <span>${this.detail?.message}</span>
                      <div class="widget-error-wrapper">
                        <label class="trackingid-label">${t("app:errorModal.trackingIdLabel")}:</label>
                        <div id="trackingid-input" class="trackingid-input">${this.detail?.trackingId}</div>
                        <md-icon
                          id="copy-trackingid-icon"
                          name=${this.isCopied ? "icon-check_12" : "icon-copy_12"}
                          title=${t("app:errorModal.trackingIdTitle")}
                          class="copy-trackingid-icon"
                          @click=${this.copyTrackingID}
                          ariaLabel=${t("app:errorModal.trackingIdTitle")}
                          tabindex="0"
                        ></md-icon>
                      </div>
                    </div>
                  </div>
                `
              : nothing}
          </div>
        </div>
      `;
    }
  }
}
