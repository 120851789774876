import { i18nMixin, t } from "@/mixins/i18nMixin";
import "@momentum-ui/web-components";
import { LitElement, customElement, html, property } from "lit-element";
import "../../Common/EmptyStateWrapper/EmptyStateWrapper";
import style from "./style.scss";

export namespace EmptyGrid {
  /**
   * @element agentx-wc-tpw-empty-grid
   */
  @customElement("agentx-wc-tpw-empty-grid")
  export class Element extends i18nMixin(LitElement) {
    @property({ reflect: true, attribute: "is-error" }) isError = "1";
    static get styles() {
      return style;
    }
    connectedCallback() {
      super.connectedCallback();
    }

    render() {
      return this.isError === "1"
        ? html`
            <div class="agentx-wc-tpw-empty-grid">
              <agentx-wc-empty-state-wrapper illustration-type="error-cloud">
                <div class="empty-grid-message-container">
                  <p class="empty-grid-message" role="alert">${t("app:tpw.errorHeader")}</p>
                </div>
              </agentx-wc-empty-state-wrapper>
            </div>
          `
        : html`
            <div class="agentx-wc-tpw-empty-grid">
              <agentx-wc-empty-state-wrapper illustration-type="empty-state">
                <span role="alert" class="empty-grid-message">${t("app:tpw.noData")}</span>
              </agentx-wc-empty-state-wrapper>
            </div>
          `;
    }
  }
}
