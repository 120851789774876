import { SERVICE } from "@agentx/agentx-services";
import { html } from "lit-html";

const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
const reHasRegExpChar = RegExp(reRegExpChar.source);

export const escapeRegExp = (string: string) => {
  return string && reHasRegExpChar.test(string) ? string.replace(reRegExpChar, "\\$&") : string || "";
};

export const stateIndicator = (state: string): any => {
  /* eslint-disable max-len */
  const getIndicator = () => {
    if (state === SERVICE.constants.AgentState.Available) {
      return html`
        <svg
          class="available-indicator"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          //sonar.issue.ignore.block.start
          <path
            d="M13.5 6.75C13.5 10.4779 10.4779 13.5 6.75 13.5C3.02208 13.5 0 10.4779 0 6.75C0 3.02208 3.02208 0 6.75 0C10.4779 0 13.5 3.02208 13.5 6.75ZM10.1084 3.65616L5.49972 8.26486L3.37523 6.14037C2.92891 5.69405 2.2053 5.69405 1.75898 6.14037C1.31267 6.58668 1.31267 7.3103 1.75898 7.75661L4.18335 10.181C4.19466 10.1923 4.20615 10.2033 4.21781 10.214L4.69229 10.6885C5.1386 11.1348 5.86222 11.1348 6.30854 10.6885L11.7247 5.27241C12.171 4.82609 12.171 4.10248 11.7247 3.65616C11.2783 3.20985 10.5547 3.20985 10.1084 3.65616Z"
            fill="currentColor"
          />
          //sonar.issue.ignore.block.end
        </svg>
      `;
    } else {
      return html`
        <svg
          class="default-indicator"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          //sonar.issue.ignore.block.start
          <path
            d="M6.75 13.5C10.4779 13.5 13.5 10.4779 13.5 6.75C13.5 3.02208 10.4779 0 6.75 0C3.02208 0 0 3.02208 0 6.75C0 10.4779 3.02208 13.5 6.75 13.5ZM2.8125 5.625H10.6875C11.3088 5.625 11.8125 6.12868 11.8125 6.75C11.8125 7.37132 11.3088 7.875 10.6875 7.875H2.8125C2.19118 7.875 1.6875 7.37132 1.6875 6.75C1.6875 6.12868 2.19118 5.625 2.8125 5.625Z"
            fill="currentColor"
          />
          //sonar.issue.ignore.block.end
        </svg>
      `;
    }
  };

  return state ? getIndicator() : null;
};
