import { columnHeaderMapping, localiseAvailableState } from "../Utils";

export default class GroupRowInnerRenderer {
  private eGui: HTMLElement = document.createElement("div");

  init(params: any): void {
    this.updateValue(params);
  }

  updateValue(params: any) {
    this.eGui.innerHTML = `
      <span class="groupTitle">
        ${columnHeaderMapping()[params.node?.field]}: ${localiseAvailableState(params.node?.key)} ( ${
      params.node?.allChildrenCount
    } Agents )
      </span>
    `;
  }
  refresh(params: any): boolean {
    this.updateValue(params);
    return true;
  }
  getGui(): HTMLElement {
    return this.eGui;
  }
}
