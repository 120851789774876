import { ENTER_KEY_CODE, SPACE_KEY_CODE } from "@/components/NotesWidget/constants";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { GridOptions } from "ag-grid-enterprise";
import { LitElement, PropertyValues, customElement, html, internalProperty, property } from "lit-element";
import { fixedColumns } from "../../Utils";
import style from "../GridHeader.scss";
import "./GroupByCustomization";
import "./ShowHideCustomization";

export namespace Customization {
  /**
   * @element agentx-wc-tpw-customization
   */
  @customElement("agentx-wc-tpw-customization")
  export class Element extends i18nMixin(LitElement) {
    @property({ reflect: true }) gridOptions: GridOptions = {};

    @internalProperty() visibleColumns: Record<string, boolean> = {};
    @internalProperty() rowGroupedColumns: string[] = [];

    static get styles() {
      return style;
    }

    private initializeRowGroupedColumns() {
      if (this.gridOptions.columnApi) {
        this.rowGroupedColumns = this.gridOptions.columnApi.getRowGroupColumns().map((column: any) => column.colId);
      }
    }
    columnRowGroupChangedHandler = () => {
      this?.initializeRowGroupedColumns();
    };
    columnValueChangedHandler = () => {
      // reset visible columns.
      this.visibleColumns = {};
      this.initializeVisibleColumns();
    };

    handleVisibleColumnsUpdate = (event: any) => {
      this.visibleColumns = event.detail;
    };
    connectedCallback(): void {
      super.connectedCallback();
      this.gridOptions.api?.addEventListener("columnRowGroupChanged", this.columnRowGroupChangedHandler);
      this.gridOptions.api?.addEventListener("gridColumnsChanged", this.columnValueChangedHandler);
      window.addEventListener("tpw-update-visible-columns", this.handleVisibleColumnsUpdate);
    }

    disconnectedCallback(): void {
      super.disconnectedCallback();
      this.gridOptions.api?.removeEventListener("columnRowGroupChanged", this.columnRowGroupChangedHandler);
      this.gridOptions.api?.removeEventListener("gridColumnsChanged", this.columnValueChangedHandler);
      window.removeEventListener("tpw-update-visible-columns", this.handleVisibleColumnsUpdate);
    }

    private initializeVisibleColumns() {
      if (Object.keys(this.visibleColumns).length === 0 && this.gridOptions.columnApi) {
        const columnsState = this.gridOptions.columnApi?.getColumnState();
        columnsState.forEach((column: any) => {
          if (!fixedColumns[column.colId]) {
            this.visibleColumns[column.colId] = !column.hide;
          }
        });
      }
    }

    protected async updated(changedProperties: PropertyValues) {
      super.updated(changedProperties);
      if (Object.keys(this.visibleColumns).length === 0 && this.gridOptions.columnApi) {
        this.initializeRowGroupedColumns();
        this.initializeVisibleColumns();
      }
    }

    private handleKeyPressed(e: KeyboardEvent) {
      if (e.code === ENTER_KEY_CODE || e.code === SPACE_KEY_CODE) {
        (e.target as any)?.children[0]?.shadowRoot?.querySelector(".menu-trigger-button")?.click();
      }
    }

    render() {
      return html`
        <md-menu-overlay custom-width="154px" class="customization">
          <md-button slot="menu-trigger" color="color-none" class="customization-btn"
            ><md-icon slot="icon" name="icon-settings_16"></md-icon
            ><span slot="text">${t("app:tpw.customization")}</span></md-button
          >
          <div class="customization-menu-overlay">
            <md-list>
              <md-list-item
                class="custom-list"
                slot="list-item"
                @keypress=${(e: KeyboardEvent) => this.handleKeyPressed(e)}
              >
                <agentx-wc-tpw-show-hide
                  .gridOptions=${this.gridOptions}
                  .visibleColumns=${this.visibleColumns}
                ></agentx-wc-tpw-show-hide>
              </md-list-item>
              <md-list-item
                class="custom-list"
                slot="list-item"
                @keypress=${(e: KeyboardEvent) => this.handleKeyPressed(e)}
              >
                <agentx-wc-tpw-group-by
                  .gridOptions=${this.gridOptions}
                  .visibleColumns=${this.visibleColumns}
                  .rowGroupedColumns=${this.rowGroupedColumns}
                ></agentx-wc-tpw-group-by>
              </md-list-item>
            </md-list>
          </div>
        </md-menu-overlay>
      `;
    }
  }
}
