export const WEBEX_CHAT_STORAGE_KEY = "tpw-webex-chat";
export const WEBEX_CHAT_STORAGE_ERROR_KEY = "tpw-webex-chat-error";

// TODO: Remove Agent name when implementing TPW API
export const getAgentKey = (agentName: string, email: string): string => {
  agentName = agentName.replace(/ /g, "-").toLowerCase();
  return `${agentName}-${email}`;
};

export const getPersistedText = (agentName: string, email: string): string => {
  try {
    const storageData: string | null = window.sessionStorage.getItem(WEBEX_CHAT_STORAGE_KEY);
    if (storageData) {
      const parsedStorage = JSON.parse(storageData);
      return parsedStorage[getAgentKey(agentName, email)] || "";
    } else {
      return "";
    }
  } catch {
    return "";
  }
};

export const setPersistedText = (agentName: string, email: string, value: string): void => {
  const agentKey = getAgentKey(agentName, email);
  try {
    const storageData: string | null = window.sessionStorage.getItem(WEBEX_CHAT_STORAGE_KEY);
    if (storageData) {
      const parsedStorage = JSON.parse(storageData);
      if (value) {
        parsedStorage[agentKey] = value;
      } else {
        delete parsedStorage[agentKey];
      }
      window.sessionStorage.setItem(WEBEX_CHAT_STORAGE_KEY, JSON.stringify(parsedStorage));
    } else {
      if (value) {
        const data = {
          [agentKey]: value
        };
        window.sessionStorage.setItem(WEBEX_CHAT_STORAGE_KEY, JSON.stringify(data));
      }
    }
  } catch {
    if (value) {
      const data = {
        [agentKey]: value
      };
      window.sessionStorage.setItem(WEBEX_CHAT_STORAGE_KEY, JSON.stringify(data));
    }
  }
};

export const getPersistedError = (agentName: string, email: string): boolean => {
  try {
    const storageData: string | null = window.sessionStorage.getItem(WEBEX_CHAT_STORAGE_ERROR_KEY);
    if (storageData) {
      const parsedStorage = JSON.parse(storageData);
      return !!parsedStorage[getAgentKey(agentName, email)];
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

export const setPersistedError = (agentName: string, email: string, value: boolean) => {
  const agentKey = getAgentKey(agentName, email);
  try {
    const storageData: string | null = window.sessionStorage.getItem(WEBEX_CHAT_STORAGE_ERROR_KEY);
    if (storageData) {
      const parsedStorage = JSON.parse(storageData);
      if (value) {
        parsedStorage[agentKey] = value;
      } else {
        delete parsedStorage[agentKey];
      }
      window.sessionStorage.setItem(WEBEX_CHAT_STORAGE_ERROR_KEY, JSON.stringify(parsedStorage));
    } else {
      if (value) {
        const data = {
          [agentKey]: value
        };
        window.sessionStorage.setItem(WEBEX_CHAT_STORAGE_ERROR_KEY, JSON.stringify(data));
      }
    }
  } catch {
    if (value) {
      const data = { [agentKey]: value };
      window.sessionStorage.setItem(WEBEX_CHAT_STORAGE_ERROR_KEY, JSON.stringify(data));
    }
  }
};
