/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { logger } from "@/sdk";
import { checkForMixPanelTracking } from "@/utils/helpers";
import { SERVICE, Service } from "@agentx/agentx-services";
import "@momentum-ui/web-components";
import { LitElement, PropertyValues, customElement, html, internalProperty, property, query } from "lit-element";
import { nothing } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { repeat } from "lit-html/directives/repeat";
import { AGENT, HELP_LINK } from "../../constants";
import style from "./Navigation.scss";
import "./comp/NavigationItem";
import { NavigationItem } from "./comp/NavigationItem";

export namespace Navigation {
  const DIRECTION = {
    UP: -1,
    DOWN: 1
  };
  const SCROLL_MARGIN = 20;

  const localizedLabels = {
    scrollDown: "app:navigation.scrollDown",
    hideNav: "app:navigation.hideNav",
    showNav: "app:navigation.showNav",
    scrollUp: "app:navigation.scrollUp",
    reload: "app:navigation.reload",
    hamburgerLabel: "app:navigation.hamburgerLabel",
    navigationRegionLabel: "app:navigation.navigationRegionLabel"
  };

  export enum RouteName {
    TASK = "task",
    TASKPANEL = "task-panel",
    APS = "aps",
    METRICS = "wxm-metrics",
    DEFAULT_HOME = "defaulthome"
  }

  const NAVIGATION_CUSTOM_ELEMENT = "agentx-wc-navigation";
  const FEEDBACK = "app:navigation.shareFeedback";

  /**
   * @element agentx-wc-navigation
   * @fires item-selected
   */

  @customElement(NAVIGATION_CUSTOM_ELEMENT)
  export class Element extends i18nMixin(LitElement) {
    @property({ type: String }) selectedNavigateTo: string | null = null;
    @property({ type: Array }) items: Item[] = [];
    @property({ type: Object }) agentDetails: any = {};
    @property({ type: Number }) popoverCount: number | null = null;
    @property({ type: String }) navtitle = "Cisco Systems Inc.";
    @property({ type: Boolean }) darkTheme = false;
    @property({ type: Array }) userRole: string[] = [];
    @internalProperty() top: Item[] = [];
    @internalProperty() bottom: Item[] = [];
    @internalProperty() itemSelectedIdx = 0;
    @internalProperty() showNavList = false;
    @internalProperty() showTopScrollButton = false;
    @internalProperty() showBottomScrollButton = false;
    @internalProperty() userRoleString = "";

    // Primary nav-list reference
    @query("#primaryNav") primaryNav: any;

    connectedCallback() {
      super.connectedCallback();
      SERVICE.shortcut.event.listenKeyPress((event: Service.shortcut.EKeyInfo) => {
        const { data } = event;
        if (data && data.modifierKeys === SERVICE.shortcut.MODIFIERS.CTRL_ALT) {
          if (data.key === SERVICE.shortcut.REGISTERED_KEYS.OPEN_NAVIGATION_TAB_KEY) {
            this.showNavList = !this.showNavList;
            this.getHamburgerButton(this.showNavList);
          }

          if (data.key === SERVICE.shortcut.REGISTERED_KEYS.REFRESH_KEY && window !== window.top) {
            location.reload();
          }
        }
      });
      window.addEventListener("resize", this.handleScrollButtonCreation);
    }

    disconnectedCallback() {
      window.removeEventListener("resize", this.handleScrollButtonCreation);
      super.disconnectedCallback();
    }

    private registerShortcutKey(topItems: Item[]): void {
      const registerKey: Service.shortcut.KeyInfo[] = [];
      topItems.forEach((item, index) => {
        registerKey.push({
          widgetElement: NAVIGATION_CUSTOM_ELEMENT,
          group: t("app:keyboardShortcutKeys.navigation.componentName"),
          action: `${t("app:keyboardShortcutKeys.open")} ${t(item.label)} ${t("app:keyboardShortcutKeys.page")}`,
          modifierKeys: SERVICE.shortcut.MODIFIERS.CTRL_ALT,
          key: (index + 1).toString(),
          listenKeyPress: (event: Service.shortcut.EKeyInfo): void => {
            const { data } = event;
            if (data) {
              const { key, modifierKeys } = data;
              const nav = Number(key);
              if (modifierKeys === SERVICE.shortcut.MODIFIERS.CTRL_ALT && nav <= this.top.length && nav > 0) {
                this.selected(this.items[nav - 1].navigateTo);
              }
            }
          },
          role: "all"
        });
      });
      SERVICE.shortcut.register(registerKey);
    }
    private handleFocusNavigation() {
      const navi = this.renderRoot.querySelector(".nav-selected");
      if (navi) {
        navi.scrollIntoView({ block: "center" });
      }
    }

    protected updated(changedProperties: PropertyValues) {
      super.updated(changedProperties);
      changedProperties.forEach((oldValue, name) => {
        if (name === "items" || name === "selectedNavigateTo" || name === "itemSelectedIdx") {
          // Updating render collections
          if (name === "items") {
            this.top = this.items.filter(i => i.align === "top");
            if (Array.isArray(this.top) && this.top.length > 0) {
              const isTaskContain = this.top.findIndex(item => item.navigateTo === "task");
              if (isTaskContain >= 0) {
                this.top[isTaskContain].taskBadge = true;
              } else {
                this.top[0].taskBadge = true;
              }
            }
            this.bottom = this.items.filter(i => i.align === "bottom");
            SERVICE.shortcut.unregisterKeys(NAVIGATION_CUSTOM_ELEMENT);
            this.registerShortcutKey(this.top);
            this.handleFocusNavigation();
          }
          if (oldValue === -1) {
            this.handleFocusNavigation();
          }
          // Updating Select
          if (this.selectedNavigateTo) {
            this.itemSelectedIdx = this.top.findIndex(i => i.navigateTo === this.selectedNavigateTo);
          }
        }
      });

      if (this.showNavList !== changedProperties.get("showNavList")) {
        this.evt<ENavBarToggled>("navbar-toggled", { showNavList: this.showNavList });
      }
      // dispatching a custom event to initiate the scroll buttons
      this.handleScrollButtonCreation();
      // setting the userRoleString on update of role
      this.setUserRoleString();
    }

    selected(navigateTo: Navigation.Item["navigateTo"]) {
      checkForMixPanelTracking(navigateTo);
      this.evt<EItemSelected>("item-selected", { navigateTo });
    }

    // ROST: create decorator fot custom event
    private evt<T extends { detail: any }>(name: string, detail: T["detail"]) {
      this.dispatchEvent(new CustomEvent(name, { bubbles: true, composed: true, detail }));
      logger.info("[Navigation]: Dispatching event", name, detail);
    }

    static get styles() {
      return style;
    }

    private setUserRoleString() {
      this.userRoleString = this.userRole && this.userRole.length > 0 ? this.userRole.join("_") : "";
    }

    private isAgent = () => {
      return this.userRoleString === AGENT;
    };

    private newTabHelpMenu = () => {
      const url = this.isAgent() ? HELP_LINK.AGENT_HELP : HELP_LINK.SUPERVISOR_HELP;
      window.open(url, "_blank")?.focus();
    };

    bottomClickHandler = (item: Navigation.Item) => {
      if (item.icon.indexOf("help") >= 0) {
        SERVICE.telemetry.timeEvent(SERVICE.telemetry.MIX_EVENT.HELP_ICON_CLICKED);
        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.HELP_ICON_CLICKED, {
          Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS
        });
        this.newTabHelpMenu();
      } else if (item.label === FEEDBACK) {
        const queryString = `&a=${this.agentDetails.orgId}&t=${this.agentDetails.teamId}&i=${this.agentDetails.agentId}&selectedLanguage=${this.agentDetails.selectedLanguage}`;
        const url = `${item.navigateTo}${queryString}`;
        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.SURVEY_ICON_CLICKED, {
          Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS
        });
        window.open(url, "_blank")?.focus();
      } else {
        this.selected(item.navigateTo);
      }
    };

    getScrollButton(dir: number) {
      const scrollBtnDirectionLabel =
        dir === DIRECTION.UP ? t(localizedLabels.scrollUp) : t(localizedLabels.scrollDown);
      return html`
        <md-button
          @click=${() => this.scrollNavList(dir)}
          ariaLabel="${scrollBtnDirectionLabel}"
          role="scrollbar"
          title="${scrollBtnDirectionLabel}"
          class="${classMap({ top: dir === DIRECTION.UP, bottom: DIRECTION.UP, "nav-scroll-button": true })}"
          expanded
          hasRemoveStyle
        >
          <md-icon
            class="nav-scroll-icon"
            name="${dir === DIRECTION.UP ? "icon-arrow-up_24" : "icon-arrow-down_24"}"
            title="${scrollBtnDirectionLabel}"
          ></md-icon>
        </md-button>
      `;
    }

    getHamburgerButton(isNavShown: boolean) {
      return html`
        <md-button
          ariaLabel="${isNavShown ? t(localizedLabels.hideNav) : t(localizedLabels.showNav)}"
          ariaExpanded="${isNavShown}"
          variant="white"
          class="nav-button"
          hasRemoveStyle
          @click=${() => {
            this.showNavList = !isNavShown;
            if (this.showNavList) {
              this.setAttribute("showNavList", "");
            } else {
              this.removeAttribute("showNavList");
            }
          }}
        >
          <md-icon name="${isNavShown ? "icon-cancel_24" : "icon-list-menu_24"}" class="nav-button-content"></md-icon>
        </md-button>
      `;
    }

    onPrimaryScroll = (event: UIEvent) => {
      const target = event.target as HTMLElement;

      this.showTopScrollButton = target.scrollTop >= SCROLL_MARGIN;

      this.showBottomScrollButton = target.scrollHeight - target.clientHeight - SCROLL_MARGIN >= target.scrollTop;
    };

    scrollNavList = (direction: number) =>
      this.primaryNav.scrollBy({
        top: (direction * this.primaryNav.clientHeight) / 2,
        behavior: "smooth"
      });

    handleScrollButtonCreation = () => {
      // dispatching a custom event to initiate the scroll buttons
      if (this.primaryNav) {
        this.primaryNav.dispatchEvent(new CustomEvent("scroll"));
      }
    };

    render() {
      return html`
        <nav
          aria-label="${t(localizedLabels.navigationRegionLabel)}"
          class="column ${classMap({ shadow: this.showNavList })}"
        >
          <div class="top-container">
            <md-tooltip
              slot="menu-trigger"
              placement="bottom"
              class="hamburger-container-tooltip"
              message=${t(localizedLabels.hamburgerLabel)}
            >
              <div class="hamburger-container">
                ${this.getHamburgerButton(this.showNavList)}
              </div>
            </md-tooltip>
          </div>
          <div class="${classMap({ "show-nav-list": this.showNavList, "hidable-container": true })}">
            <div class="column container">
              <div class="scroll-button-placer">
                ${this.showTopScrollButton ? this.getScrollButton(DIRECTION.UP) : nothing}
              </div>
              <ul
                @scroll=${this.onPrimaryScroll}
                id="primaryNav"
                aria-label="${t("app:navigation.mainSectionlabel")}"
                class="nav-list primary"
              >
                ${repeat(
                  this.top,
                  (item, index) => html`
                    <li
                      class="nav-list__item ${this.itemSelectedIdx === index ? "nav-selected" : ""}"
                      aria-label="${item.label.includes("home") && this.popoverCount
                        ? `${this.popoverCount} contact requests`
                        : ""}"
                    >
                      <md-tooltip message="${item.label.startsWith("app:") ? t(item.label) : item.label}">
                        <agentx-wc-navigation-item
                          uid="${index}"
                          .item="${item}"
                          .popoverCount="${this.popoverCount}"
                          ?selected="${this.itemSelectedIdx === index}"
                          @selected="${(e: CustomEvent<NavigationItem.ESelected>) =>
                            this.selected(e.detail.item.navigateTo)}"
                        ></agentx-wc-navigation-item>
                      </md-tooltip>
                    </li>
                  `
                )}
              </ul>

              <div class="scroll-button-placer">
                ${this.showBottomScrollButton ? this.getScrollButton(DIRECTION.DOWN) : nothing}
              </div>
              <div>
                <!-- Bottom of the navigation bar is designated for quick links - they will not have 'selected' state -->
                <ul class="nav-list secondary">
                  ${repeat(
                    this.bottom,
                    item => html`
                      <li class="nav-list__item">
                        <md-tooltip message="${item.label.startsWith("app:") ? t(item.label) : item.label}">
                          <agentx-wc-navigation-item
                            .item="${item}"
                            .popoverCount="${this.popoverCount}"
                            .darkTheme="${this.darkTheme}"
                            ?selected="${false}"
                            @selected="${(e: CustomEvent<NavigationItem.ESelected>) =>
                              this.bottomClickHandler(e.detail.item)}"
                          ></agentx-wc-navigation-item>
                        </md-tooltip>
                      </li>
                    `
                  )}
                </ul>
                <!-- Display reload icon if agentx loaded in an iframe -->
                ${window !== window.top
                  ? html`
                      <md-tooltip message="${t(localizedLabels.reload)}">
                        <md-button
                          @click=${() => location.reload()}
                          ariaLabel="${t(localizedLabels.reload)}"
                          class="reload-button"
                          expanded
                          hasRemoveStyle
                          tabindex="0"
                        >
                          <md-icon
                            aria-label="${t(localizedLabels.reload)}"
                            title="${t(localizedLabels.reload)}"
                            name="icon-refresh_24"
                            class="reload-icon"
                          ></md-icon>
                        </md-button>
                      </md-tooltip>
                    `
                  : ""}
              </div>
            </div>
          </div>
        </nav>
      `;
    }
  }

  export type EItemSelected = {
    detail: {
      navigateTo: Item["navigateTo"];
    };
  };

  export type ENavBarToggled = {
    detail: {
      showNavList: boolean;
    };
  };

  export type Item = {
    label: string;
    icon: string;
    iconType: "momentum" | "other";
    iconSize?: number;
    iconActive?: string;
    align: "top" | "bottom";
    navigateTo: string;
    isResponsive?: boolean;
    isDefaultLandingPage?: boolean; //field to determine the landing oage after station login
    taskBadge?: boolean;
  };

  export type HelpItem = {
    detail: boolean;
  };
}
