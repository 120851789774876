const BUTTON_DEFAULT_CLASSNAMES = ["aid-button", "btn-action"];
const ICON_DEFAULT_CLASSNAMES = ["md-icon", "icon"];
export default class BaseComponent {
  // Base component contains the common required code for action renderer component.
  private themeSelector: Element | undefined | null;
  constructor() {
    this.themeSelector = document?.querySelector("agentx-app")?.shadowRoot?.querySelector("md-theme");
  }
  createButtonElement(classList: Array<string>, isDisabled = false): HTMLButtonElement {
    const button = document.createElement("button");
    button.classList.add(...BUTTON_DEFAULT_CLASSNAMES);
    // Add custom class names.
    if (Array.isArray(classList)) {
      button.classList.add(...classList);
    }

    button.setAttribute("type", "button");
    button.tabIndex = 0;
    if (isDisabled) {
      button.classList.add("disabled");
      button.setAttribute("aria-disabled", "true");
    }

    const onmouseenter = (event: any) => {
      if (
        !event.target.firstChild.classList.contains("disabled") ||
        event?.target?.firstChild.getAttribute("data-show-tooltip")
      ) {
        const content = button.getAttribute("data-message");
        if (content) {
          this.createToolTip(event.target.firstChild, content);
        }
      }
    };

    button.onmouseleave = () => {
      this.destroyToolTip();
    };
    button.onkeydown = () => {
      this.destroyToolTip();
    };

    button.onmouseenter = onmouseenter;
    button.onfocus = onmouseenter;
    return button;
  }

  createIconElement = (classList: Array<string>) => {
    const iconElement = document.createElement("i");
    // Add Default momentum class names for icon element.
    iconElement.classList.add(...ICON_DEFAULT_CLASSNAMES);
    // Add custom class names.
    if (Array.isArray(classList)) {
      iconElement.classList.add(...classList);
    }

    return iconElement;
  };
  createToolTip = (reference: Element, message: string, placement = "top") => {
    const popperElement = this.createToolTipContent(message);

    this.themeSelector?.dispatchEvent(
      new CustomEvent("tooltip-create", {
        bubbles: true,
        composed: true,
        detail: {
          placement,
          reference,
          popper: popperElement
        }
      })
    );
  };

  destroyToolTip = () => {
    this.themeSelector?.dispatchEvent(
      new CustomEvent("tooltip-destroy", {
        bubbles: true,
        composed: true,
        detail: {}
      })
    );
  };

  createToolTipContent = (message: string) => {
    const popperElement = document.createElement("div");
    popperElement.classList.add("md-tooltip__popper");
    popperElement.innerHTML = `<div class="md-tooltip__content" part="tooltip-content">
                                ${message}
                               </div>
                               <div id="arrow" class="md-tooltip__arrow" data-popper-arrow></div>`;

    return popperElement;
  };

  toolTipElement = (message: string) => {
    const element = document.createElement("div");
    element.classList.add("tpw-custom-tooltip");
    element.setAttribute("data-message", message);

    element.onmouseenter = (event: any) => {
      if (!event.target.firstChild.disabled || event?.target?.firstChild.getAttribute("data-show-tooltip")) {
        const content = element.getAttribute("data-message");
        if (content) {
          this.createToolTip(event.target.firstChild, content);
        }
      }
    };

    element.onmouseleave = () => {
      this.destroyToolTip();
    };
    element.onkeydown = () => {
      this.destroyToolTip();
    };
    return element;
  };
}
