/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin } from "@/mixins/i18nMixin";
import { Service } from "@agentx/agentx-services";
import "@momentum-ui/web-components";
import { customElement, html, LitElement, property } from "lit-element";
import { ifDefined } from "lit-html/directives/if-defined";
import { repeat } from "lit-html/directives/repeat";
import { DEFAULT_TRANSCRIPT_COLOR, IvrTranscripts } from "../../IvrTranscripts";
import { getTime, removeEmptyValues } from "../../utils/utils";
import "../ConfidenceBadge/ConfidenceBadge";
import style from "./Intent.scss";

export namespace Intent {
  /**
   * @element agentx-wc-ivr-intent
   * @fires item-selected
   */
  @customElement("agentx-wc-ivr-intent")
  export class Element extends i18nMixin(LitElement) {
    @property({ type: Number }) confidence: number | undefined = 1;
    @property({ type: String }) intentName: string | undefined = undefined;
    @property({ type: String }) color: IvrTranscripts.TranscriptColor = DEFAULT_TRANSCRIPT_COLOR;
    @property({ type: Number }) timestamp = new Date().getTime();
    @property({ type: Object }) params: Service.MediaStorage.BotTranscript["parameters"] = {};

    static get styles() {
      return style;
    }

    renderSubParams = (subParams: Service.MediaStorage.BotTranscriptParams) => {
      const subParamKeys: Array<string> = subParams ? Object.keys(subParams) : [];
      return repeat(subParamKeys, key => {
        return html`
          <div>${key}: ${subParams[key]}</div>
        `;
      });
    };

    renderParamValue = (value: string | number | Service.MediaStorage.BotTranscriptParams) => {
      return typeof value === "string" || typeof value === "number"
        ? html`
            <span>${value}</span>
          `
        : html`
            <span>${this.renderSubParams(value)}</span>
          `;
    };

    renderParams = () => {
      const params = Object.keys(this.params).length > 0 ? removeEmptyValues(this.params) : null;
      const paramKeys: Array<string> = params ? Object.keys(params) : [];
      return repeat(paramKeys, key => {
        return (
          params &&
          html`
            <div class="intent-params">
              <span title=${key}>${key}</span>
              ${this.renderParamValue(params[key])}
            </div>
          `
        );
      });
    };

    render() {
      return html`
        <div class="ivr-intent">
          <agentx-wc-confidence-badge
            intentName=${ifDefined(this.intentName)}
            confidence=${ifDefined(this.confidence)}
            color=${this.color}
          ></agentx-wc-confidence-badge>
          <span class="transcript-timer">${getTime(this.timestamp)}</span>
          <div class="params-wrapper">${this.renderParams()}</div>
        </div>
      `;
    }
  }
}
